import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { useNavigate, createSearchParams } from "react-router-dom";
import moment from "moment";
import { R, Text, Button, IconAdd, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { ManageLayout, PageTitleArea } from "../../layout/Layout";
import { StatusCell, StatusCellText } from "../../ag-grid/StatusCell/StatusCell";
import { VerticalMenu } from "../../ag-grid/verticalMenu/VerticalMenu";
import { NoWorkflows } from "../NoWorkflows";
import { AuditCell, auditCellText } from "./AuditCell/AuditCell";
import { MODAL_IDS } from "../../../utils/workflows/enums";
import { AuthWrapper, isUserAuthorized } from "../../AuthorizationWrapper";
import { DownloadTable } from "../../reusable/Button/DownloadTable";
import { AUTHORITIES } from "../../../utils/enums";
import { DeleteWorkflowModal } from "../WorkflowDetail/modals/DeleteWorkflow";
import { DropdownPanelCell } from "./dropdownPanel/DropdownPanelCell";
import { Table } from "components/ag-grid/Ag-grid";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

const WorkflowsList = observer(() => {
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState({});
  const [gridApi, setGridApi] = useState(null);

  const { workflowsStore, rolesStore, meStore } = useStore();
  const [workflows, setWorkflows] = useState(null);

  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All workflows for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  useEffect(() => {
    if (workflowsStore.getWorkflows()?.length > 0) {
      setWorkflows(workflowsStore.getWorkflows());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Show ag-grid spinner until reaction updates state with data from store
    gridApi?.api?.showLoadingOverlay();

    /**
     * Mobx Reaction to keep useState in sync with store
     * Whenever the observable data changes, it triggers the effect
     * The effect checks if the component is mounted and the store already finished to fetch the data
     *
     * For more details refer to:
     * https://mobx.js.org/reactions.html#reaction
     *
     * @param {Function} ObservableData - Tracks the observable.
     * @param {Function} Effect - Reacts to changes in the observable.
     */
    const disposer = reaction(
      () => workflowsStore.getWorkflows(), // Observable data
      (workflowsListForSelectedTeam) => {
        // Effect
        setWorkflows(workflowsListForSelectedTeam?.length > 0 ? workflowsListForSelectedTeam : []);
      }
    );
    // Cleanup reaction
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const onCellClick = (event) => {
    if (event?.column?.colId !== "rowEndSettings") {
      const {
        data: { uuid: workflowUuid, status },
      } = event;

      // navigate to configure screen if the Workflow is still in DRAFT, otherwise go to detail view
      const pathname = status === "DRAFT" ? `${workflowUuid}/configure` : `${workflowUuid}`;
      navigate({ pathname, search: `?${createSearchParams({ teamId: event?.data?.teamId })}` });
    }
  };

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} param.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if undefined
    const cellValue = node.data?.[columnId] ?? "";

    switch (columnId) {
      case "status":
        return StatusCellText({ value: cellValue });
      case "createdAt":
        return auditCellText(node);
      default:
        return cellValue;
    }
  };

  const toggleDeleteModal = (props) => {
    const { data } = props;
    setWorkflow(data);
    modalInstance(MODAL_IDS.DELETE_WORKFLOW).toggle();
  };

  const handleDeleteWorkflow = async (teamId, uuid, name) => {
    const response = await workflowsStore.deleteWorkflow(teamId, uuid, name);
    const { success } = response;

    if (success) {
      workflowsStore.loadWorkflows(teamId);
    }
  };

  const onItemClick = (option, props) => {
    switch (option) {
      case "view":
        const {
          data: { uuid: workflowUuid, status },
        } = props;

        // navigate to configure screen if the Workflow is still in DRAFT, otherwise go to detail view
        const pathname = status === "DRAFT" ? `${workflowUuid}/configure` : `${workflowUuid}`;
        navigate({ pathname, search: `?${createSearchParams({ teamId: props?.data?.teamId })}` });

        break;
      case "delete":
        toggleDeleteModal(props);
        break;
      default:
        break;
    }
  };

  const returnMenuItems = (params) => {
    const {
      data: { status },
    } = params;

    const menuItems = [
      {
        key: "view",
        label: status === "DRAFT" ? "Edit" : "View",
        visible: true,
      },

      {
        key: "delete",
        label: "Delete",
        visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.WORKFLOW_DELETE]) }),
      },
    ];

    return menuItems;
  };

  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: true,
    detailRowAutoHeight: true,
    suppressRowClickSelection: true,
    noRowsOverlayComponent: NoWorkflows,
    onCellClicked: onCellClick,
    onGridReady: onGridReady,
    detailCellRenderer: DropdownPanelCell,
  };

  const columns = [
    {
      headerName: "",
      field: "dropdown_trigger",
      cellRenderer: "agGroupCellRenderer",
      width: 60,
      suppressMenu: true,
      sortable: false,
    },
    {
      headerName: "Name",
      field: "name",
      flex: 2,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 2,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: StatusCell,
      maxWidth: 200,
    },
    {
      headerName: "Audit log",
      field: "createdAt",
      cellRenderer: AuditCell,
      sort: "desc",
      flex: 2,
      filter: "agTextColumnFilter",
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const { createdAt: createdAtA, lastModifiedAt: lastModifiedAtA, publishedAt: publishedAtA } = nodeA.data;
        const { createdAt: createdAtB, lastModifiedAt: lastModifiedAtB, publishedAt: publishedAtB } = nodeB.data;

        const timestampA = publishedAtA || lastModifiedAtA || createdAtA;
        const timestampB = publishedAtB || lastModifiedAtB || createdAtB;

        const date1 = moment(new Date(timestampA));
        const date2 = moment(new Date(timestampB));

        if (date1.isSame(date2)) return 0;
        return date1.isAfter(date2) ? 1 : -1;
      },
      filterParams: {
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          switch (filter) {
            case "contains":
              return auditCellText(value).includes(filterText);
            case "notContains":
              return !auditCellText(value).includes(filterText);
            case "equals":
              return auditCellText(value) === filterText;
            case "notEqual":
              return auditCellText(value) !== filterText;
            case "startsWith":
              return auditCellText(value).startsWith(filterText);
            case "endsWith":
              return auditCellText(value).endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
    {
      headerName: "",
      suppressMenu: true,
      sortable: false,
      field: "rowEndSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: (params) => {
        return { menuItems: returnMenuItems(params), onItemClick: onItemClick };
      },
    },
  ];

  return (
    <>
      <DeleteWorkflowModal workflow={workflow} modalId={MODAL_IDS.DELETE_WORKFLOW} handleDeleteWorkflow={handleDeleteWorkflow} />
      <PageTitleArea title="Workflows" description={<div className="text-medium pb-4">View your team's data workflows</div>} additionalChildClasses="pr-20">
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_CREATE])}>
          <Button size="md" color="primary" onClick={handleCreateWorkflowClick}>
            <IconAdd className={"btn-md-svg"} />
            <Text size="sm">New workflow</Text>
          </Button>
          <DownloadTable gridRef={gridApi} processCellCallback={processCellCallback} fileName={fileName} direction="top" excludeEmpty={true} />
        </AuthWrapper>
      </PageTitleArea>
      <ManageLayout>
        <R props="d-flex pt-32 pl-0" />
        <Table columns={columns} rowData={workflows} agGridOptions={gridOptions} />
      </ManageLayout>
    </>
  );
});

const handleCreateWorkflowClick = () => {
  modalInstance(MODAL_IDS.CREATE_WORKFLOW).show();
};

export { WorkflowsList, handleCreateWorkflowClick };
