import { useRef } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Text, LinkText } from "@fundrecs/ui-library";
import { Table } from "components/ag-grid/Ag-grid";
import { useStore } from "store/Store";
import { RowIndex } from "components/ag-grid/config";
import { ToggleRenderer } from "components/ag-grid/toggle/ToggleRenderer";
import { ToggleEditor } from "components/ag-grid/toggle/ToggleEditor";
import { DATA_TYPE_KEYS } from "utils/mappings/enums";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

/**
 * The ColumnsTable component renders the ag-grid table.
 * It sets up the ag-grid table with specific column headers.
 * It will only re-render the table if the data changes.
 */
const ColumnsTable = observer(() => {
  const { meStore, outputMappingsStore } = useStore();
  const rowData = outputMappingsStore.getColumns();

  const { uuid: outputMappingId, name: outputMappingName } = outputMappingsStore.getOutputMapping();

  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All columns for output mapping ${outputMappingName} for ${teamName}-${today}`;

  const gridRef = useRef({ api: null, columnApi: null });

  const columns = [
    RowIndex,
    { headerName: "Column name", field: "columnName", rowDrag: true, editable: true },
    {
      headerName: "Data type",
      suppressMenu: true,
      field: "type",
      editable: false,
      cellRenderer: (props) => {
        const { value } = props;
        return DATA_TYPE_KEYS[value].toString();
      },
    },
    {
      headerName: "Required",
      suppressMenu: true,
      field: "required",
      editable: true,
      cellRenderer: ToggleRenderer,
      cellRendererParams: {
        textTrue: "Required",
        textFalse: "Not required",
      },
      cellEditor: ToggleEditor,
      cellEditorParams: {
        textTrue: "Required",
        textFalse: "Not required",
      },
    },
    {
      headerName: "Actions",
      suppressMenu: true,
      field: "actions",
      cellRenderer: (props) => {
        return (
          <span
            onClick={() => {
              onDelete(props);
            }}
          >
            <LinkText>Delete</LinkText>
          </span>
        );
      },
    },
  ];

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {any} params.value - The value of the cell.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, value }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if undefined
    const cellValue = value ?? "";

    switch (columnId) {
      case "required":
        // custom processing for the "required" column
        return cellValue ? "Required" : "Not required";
      case "type":
        // custom processing for the "type" column
        return DATA_TYPE_KEYS[cellValue]?.toString() ?? cellValue;
      case "actions":
        // custom processing for the "actions" column
        return "Delete";
      default:
        // return the processed value
        return cellValue;
    }
  };

  const updateColumnsOrder = async (gridApi) => {
    // Backend expects array with only column id and columnOrderNumber
    const columnsOrder = [];

    // This is only used to update the store with correct columnOrderNumber if update is successful
    const updatedColumns = [];

    gridApi.refreshCells();
    gridApi.forEachNode((node) => {
      const { rowIndex } = node;

      columnsOrder.push({
        id: node.data.id,
        columnOrderNumber: rowIndex + 1,
      });

      const column = { ...node.data };
      column.columnOrderNumber = rowIndex + 1;
      updatedColumns.push(column);
    });

    const response = await outputMappingsStore.updateOutputMappingColumnsOrder({
      teamId: teamId,
      outputMappingId: outputMappingId,
      columnsOrder: columnsOrder,
    });

    const { success } = response;
    if (success) {
      // Update store
      outputMappingsStore.setColumns(updatedColumns);
    }
  };

  const onDelete = async (params) => {
    const { data } = params;

    const requestObject = {
      teamId: teamId,
      outputMappingId: outputMappingId,
      id: data.id,
    };

    const response = await outputMappingsStore.deleteOutputMappingsColumns(requestObject);
    params.api.refreshCells();

    const { success } = response;
    if (success) {
      params.api.applyTransaction({ remove: [data] });

      // Update all columns order to avoid future issues when creating new columns
      updateColumnsOrder(params.api);
    }
  };

  const onGridReady = (params) => {
    gridRef.current.api = params.api;
    gridRef.current.columnApi = params.columnApi;
  };

  const onCellEditingStopped = async (params) => {
    const { data } = params;

    // Only try to update if column name is valid
    if (data.columnName.trim().length > 0) {
      // Generate idValue again based on column name and order
      const idValue = outputMappingsStore.generateColumnIdValue(data);

      const requestObject = {
        teamId: teamId,
        outputMappingId: outputMappingId,
        ...data,
        idValue: idValue,
      };

      const response = await outputMappingsStore.updateOutputMappingColumn(requestObject);

      const { success } = response;
      if (!success) {
        //Undo changes if fails
        params.api.undoCellEditing();
      }
    } else {
      params.api.undoCellEditing();
    }
  };

  const onRowDragEnd = (params) => {
    updateColumnsOrder(params.api);
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const gridOptions = {
    onCellEditingStopped: onCellEditingStopped,
    onRowDragEnd: onRowDragEnd,
    onGridSizeChanged: onGridSizeChanged,
    onGridReady: onGridReady,
    rowDragManaged: true,
    rowDragEntireRow: true,
    rowDragMultiRow: true,
    suppressRowClickSelection: true,
    style: { height: "40vh" },
  };

  return (
    <div className="mt-32 mb-32">
      {rowData?.length > 0 && (
        <>
          <div className="d-flex mb-16 justify-content-between align-items-center">
            <Text weight="medium">{`${rowData?.length.toString()} column${rowData?.length > 1 ? "s" : ""} added`}</Text>
            <div className="ms-auto">
              <DownloadTable gridRef={gridRef.current} fileName={fileName} processCellCallback={processCellCallback} direction="left" />
            </div>
          </div>
          <div className="pb-32">
            <Table columns={columns} rowData={rowData} agGridOptions={gridOptions} gridRef={gridRef} />
          </div>
        </>
      )}
    </div>
  );
});

export { ColumnsTable };
