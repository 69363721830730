import { Text } from "@fundrecs/ui-library";

const NoTemplates = () => {
  return (
    <Text variant="primary" size="sm" align="center" weight="regular" element="div">
      There are no templates assigned to this source.
    </Text>
  );
};

export { NoTemplates };
