import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { ListItemText } from "@material-ui/core/List";
import { tbAppChannel } from "../../utils/communication/AppChannels";
import HeaderSelectorButton from "../../selectandtransform/module/HeaderSelectorButton";
import CommandArgsModel from "../../utils/domain/CommandArgsModel";
import IndexedValue from "../../utils/domain/selectandtransform/IndexedValue";

export default class DropdownWithColumnMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownAnchor: null,
      menuOpen: false,
    };
  }

  render() {
    const {
      uiCommand,
      uiCommand: { dropDownKeyValuePair },
      userInputArgs,
      argsNameWraper: { peName, defaultValue },
      isNew,
    } = this.props;

    /**
     * When the dropdown button clicked, sets the anchorElement for the menu and sets peName-MenuOpen to true
     * @param event
     */
    const handleDropdownMenuClick = (event) => {
      this.setState({
        menuOpen: true,
        dropdownAnchor: event.currentTarget,
      });
    };

    /**
     * Return a list of MenuItem components, used to populate the dropdown
     * @returns {*}
     */
    const getDropDownList = () => {
      return dropDownKeyValuePair.map((aliasTable, index) => {
        return aliasTable.deleted ? null : (
          <MenuItem
            key={index}
            onClick={() => {
              selectAliasTable(aliasTable);
            }}
          >
            {aliasTable.name}
          </MenuItem>
        );
      });
    };

    const getColumnSelectorList = () => {
      return [undefined, null].includes(userInputArgs) ||
        [undefined, null].includes(userInputArgs[peName])
        ? null
        : Object.keys(userInputArgs[peName]["lookupMappings"]).map(
            (mappingKey, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <HeaderSelectorButton
                    indexedValue={userInputArgs[peName]}
                    onSelection={(indexedValue) => {
                      selectColumn(mappingKey, indexedValue);
                    }}
                  />
                  <p>
                    <span style={{ fontWeight: "bold" }}>{mappingKey}: </span>
                    <span>{getSelectedColumnText(mappingKey)}</span>
                  </p>
                </div>
              );
            }
          );
    };

    const selectAliasTable = (aliasTable) => {
      let args = userInputArgs || {};
      args[peName] = {};
      args[peName]["aliasFileUuid"] = aliasTable.uuid;
      args[peName]["lookupMappings"] = {};
      aliasTable.lookupColumns.forEach((columnName) => {
        args[peName]["lookupMappings"][columnName] = new IndexedValue({
          index: null,
          value: null,
        });
      });

      tbAppChannel.publish(
        "onCommandParamsChange",
        new CommandArgsModel({
          uiCommand: uiCommand,
          userInputargs: args,
          isNew: isNew,
        })
      );
      this.setState({ menuOpen: false });
    };

    const selectColumn = (mappingKey, indexedValue) => {
      let args = userInputArgs || {};
      args[peName]["lookupMappings"][mappingKey] = indexedValue;
      tbAppChannel.publish(
        "onCommandParamsChange",
        new CommandArgsModel({
          uiCommand: uiCommand,
          userInputargs: args,
          isNew: isNew,
        })
      );
    };

    const generateButtonText = () => {
      return !userInputArgs || userInputArgs[peName] === undefined
        ? defaultValue
        : "Selected table: " +
            findAliasTableNameById(userInputArgs[peName]["aliasFileUuid"]);
    };

    const getSelectedColumnText = (mappingKey) => {
      return !userInputArgs ||
        userInputArgs[peName] === undefined ||
        [undefined, null].includes(
          userInputArgs[peName]["lookupMappings"][mappingKey]["value"]
        )
        ? "Nothing Selected"
        : userInputArgs[peName]["lookupMappings"][mappingKey]["value"];
    };

    /**
     * Retrieve the alias table name from it's UUID
     * @param id Alias table UUID
     * @returns {*}
     */
    const findAliasTableNameById = (id) => {
      for (let aliasTable of dropDownKeyValuePair) {
        if (aliasTable.uuid === id) {
          return aliasTable.name;
        }
      }
    };

    return (
      <div data-name="dropdownWithColumnMapping">
        <div className="row">
          <Button
            className="dropdownButton"
            disabled={this.state.selected}
            onClick={(event) => {
              handleDropdownMenuClick(event);
            }}
          >
            {generateButtonText()}
          </Button>

          <Menu
            id="alias-menu"
            open={this.state.menuOpen}
            onClose={() => {
              this.setState({ menuOpen: false });
            }}
            anchorEl={this.state.dropdownAnchor}
          >
            {getDropDownList()}
          </Menu>
        </div>
        <div className="row">{getColumnSelectorList()}</div>
      </div>
    );
  }
}

export { DropdownWithColumnMappings };
