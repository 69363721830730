import { observer } from "mobx-react-lite";
import { Text, C, C4, R, Panel, PanelBody, PanelHeader, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { SectionLayout } from "components/layout/Layout";
import { EditableText } from "components/editable/EditableText";
import { MODAL_IDS } from "utils/templates/enums";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";

const TemplateSettings = observer(({ template, setTemplate, teamId }) => {
  const { templatesStore } = useStore();
  const {
    name,
    description,
    status,
    createdBy: { name: createdByName } = {},
    submittedByUser: { name: submittedByName } = {},
    rejectedByUser: { name: rejectedByName } = {},
    publishedByUser: { name: publishedByName } = {},
    approvedByUser: { name: approvedByName } = {},
    publishedDate,
    rejectedDate,
    submittedDate,
    createdDate,
    approvedDate,
    tmoDetail: { name: tmoName } = {},
  } = template || {};

  const submitTemplateSettings = async (key, value) => {
    const updatedObject = { ...template, ...{ [key]: value } };

    const { success, data } = await templatesStore.updateTemplate(teamId, updatedObject);
    if (success) {
      templatesStore.updateTemplateinStore(data);
      setTemplate(data);
    }
  };

  return (
    <>
      <Panel panelId={MODAL_IDS.EDIT_TEMPLATE_SETTINGS}>
        <PanelBody>
          <PanelHeader header="Template Settings" />

          <SectionLayout title="">
            <R props={"pb-12"}>
              <EditableText
                name="name"
                label="Name"
                value={name}
                onSave={(value) => {
                  submitTemplateSettings("name", value);
                }}
                warningText="Template name is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <EditableText
                name="description"
                label="Description"
                value={description}
                onSave={(value) => {
                  submitTemplateSettings("description", value);
                }}
                warningText="Template description is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Status
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  <StatusCell value={status} />
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12"}>
              <C4>
                <Text size="sm" variant="tertiary" weight="regular">
                  Mapped to output mapping
                </Text>
              </C4>
              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  {tmoName}
                </Text>
              </C>
            </R>
            {publishedDate && (
              <>
                <SectionDivider />
                <R props={"pt-12"}>
                  <C4>
                    <Text size="sm" variant="tertiary" weight="regular">
                      Published by
                    </Text>
                  </C4>
                  <C>
                    <CreatedBy user={{ name: publishedByName }} date={publishedDate} />
                  </C>
                </R>
              </>
            )}
            {approvedDate && (
              <>
                <SectionDivider />
                <R props={"pt-12"}>
                  <C4>
                    <Text size="sm" variant="tertiary" weight="regular">
                      Approved by
                    </Text>
                  </C4>
                  <C>
                    <CreatedBy user={{ name: approvedByName }} date={approvedDate} />
                  </C>
                </R>
              </>
            )}
            {rejectedDate && (
              <>
                <SectionDivider />
                <R props={"pt-12"}>
                  <C4>
                    <Text size="sm" variant="tertiary" weight="regular">
                      Rejected by
                    </Text>
                  </C4>
                  <C>
                    <CreatedBy user={{ name: rejectedByName }} date={rejectedDate} />
                  </C>
                </R>
              </>
            )}
            {submittedDate && (
              <>
                <SectionDivider />
                <R props={"pt-12"}>
                  <C4>
                    <Text size="sm" variant="tertiary" weight="regular">
                      Submitted by
                    </Text>
                  </C4>
                  <C>
                    <CreatedBy user={{ name: submittedByName }} date={submittedDate} />
                  </C>
                </R>
              </>
            )}
            <SectionDivider />
            <R props={"pt-12"}>
              <C4>
                <Text size="sm" variant="tertiary" weight="regular">
                  Created by
                </Text>
              </C4>
              <C>
                <CreatedBy user={{ name: createdByName }} date={createdDate} />
              </C>
            </R>
          </SectionLayout>
        </PanelBody>
      </Panel>
    </>
  );
});

export { TemplateSettings };
