import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams, createSearchParams, useNavigate } from "react-router-dom";
import { modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";
import { ModalHeader } from "components/layout/ModalHeader";
import styles from "./Template.module.scss";
import { MODAL_IDS } from "utils/templates/enums";
import { ROUTES } from "utils/enums";
import { useStore } from "store/Store";
import { ReloadTemplateBuilderModal } from "./Modals/ReloadTemplateBuilder";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";
import { TemplateSettings } from "./Modals/TemplateSettings";
import { HeaderButtons } from "./Header/HeaderButtons";
import { DeleteTemplateModal } from "./Modals/DeleteTemplate";
import { useTeamId } from "store/hooks/useTeamId";

const Template = observer(() => {
  const navigate = useNavigate();
  const { tbStore, templatesStore } = useStore();

  const teamId = useTeamId();
  const { templateUuid } = useParams();

  const [template, setTemplate] = useState({});

  useEffect(async () => {
    tbStore.init(templateUuid, teamId);
    tbStore.mountTemplateBuilder({ showDoneButton: false });

    templatesStore.getTemplateByUuidAndTeamId(templateUuid, teamId).then((response) => {
      const { data, success } = response || {};
      if (success) {
        setTemplate(data.tbTemplate);
      }
    });
  }, []);

  const closeModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_CLOSE_EDIT_TEMPLATE).toggle();
  };

  const confirmCloseModal = () => {
    tbStore.unmountTemplateBuilder();
    tbStore.setErrorModal(false);
    navigate({ pathname: ROUTES.TEMPLATES, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <DeleteTemplateModal teamId={teamId} template={template} onConfirm={confirmCloseModal} />
      <TemplateSettings template={template} setTemplate={setTemplate} teamId={teamId} />
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_CLOSE_EDIT_TEMPLATE}
        heading="Are you sure you want to quit?"
        text="All progress in this session will be lost"
        cancel="Cancel"
        confirm="Quit"
        onConfirm={confirmCloseModal}
      />
      <div className={styles.headerWrapper}>
        {template && (
          <ModalHeader
            classes="mb-20 pl-32 pr-32"
            onCloseClick={closeModal}
            title={template.name}
            contentLeft={<StatusCell value={template.status} />}
            contentRight={<HeaderButtons teamId={teamId} template={template} onClose={confirmCloseModal} />}
          />
        )}
        <div id="TemplateBuilderApp">{tbStore.getErrorModal() && tbStore.mountErrorModal(<ReloadTemplateBuilderModal teamId={teamId} />)}</div>
      </div>
    </>
  );
});

export { Template };
