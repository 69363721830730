import React from "react";
import { EmptyWorkflowRun, Heading, R, C, Text, Button } from "@fundrecs/ui-library";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../utils/enums";
import styles from "./NoRunResults.module.scss";
import { useTeamId } from "store/hooks/useTeamId";

const NoRunResults = () => {
  let navigate = useNavigate();
  const teamId = useTeamId();

  const pointerStyle = {
    pointerEvents: "auto",
  };

  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyWorkflowRun />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No run results for this date
          </Heading>
        </C>
      </R>
      <R>
        <C>
          <div className="pt-16">
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              Go to the workflows section to run your
            </Text>
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              workflows and generate results
            </Text>
          </div>
        </C>
      </R>
      <R>
        <C>
          <div className="pt-32 pb-32" style={pointerStyle}>
            <Button
              className={styles.pointer}
              onClick={() => {
                navigate({ pathname: ROUTES.WORKFLOWS, search: `?${createSearchParams({ teamId: teamId })}` });
              }}
              size="lg"
            >
              <Text size="md">Go to workflows</Text>
            </Button>
          </div>
        </C>
      </R>
    </>
  );
};
export { NoRunResults };
