export default class CommandArgsModel {
  constructor(props) {
    this.uiCommand = props.uiCommand;
    this.userInputargs = props.userInputargs;
    if (
      props.userInputargs === undefined ||
      Object.keys(props.userInputargs).length == 0
    ) {
      this.hasUserInputArgs = false;
    } else this.hasUserInputArgs = true;
    this.isNew = props.isNew;
  }
}
