import { useState } from "react";
import { Method } from "../method/method";

const MethodList = ({ methods, def }) => {
  const [selected, setSelected] = useState(def);

  return (
    <>
      <form style={{ width: "100%" }}>
        {methods.map((method, index) => {
          const { name, description, disabled, value, props } = method;
          return (
            <Method
              key={index}
              name={name}
              description={description}
              disabled={disabled}
              selected={selected}
              setSelected={setSelected}
              value={value}
              props={props}
            />
          );
        })}
      </form>
    </>
  );
};

export { MethodList };
