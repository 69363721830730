import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const API_PREFIX = "/teams";

const importRules = {
  getImportRules: ({ teamId, type }) => fusionRequest.get(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.IMPORT_RULES.GET_IMPORT_RULES}/${type}`),
};

export { importRules };
