import { useState } from "react";
import { modalInstance } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { MODAL_IDS } from "utils/templates/enums";
import styles from "./useExplainTemplate.module.scss";

const useExplainTemplate = () => {
  const { templatesStore, uiStore } = useStore();
  const teamId = useTeamId();

  const [requesting, setRequesting] = useState(false);
  const [explainedTemplate, setExplainedTemplate] = useState([]);

  const errorToastWithRetryOption = (templateUuid) => {
    return (
      <>
        Our AI model was unable to generate a summary of rules belonging to this template! This feature is in beta testing and may fail occasionally.
        <br />
        <div
          className={styles.tryAgain}
          onClick={() => {
            handleExplainTemplate({ templateUuid });
          }}
        >
          Try again
        </div>
      </>
    );
  };

  const handleExplainTemplate = async ({ templateUuid }) => {
    const notificationId = uiStore.addNotification("status", "Generating summary of rules using AI...");

    setTimeout(async () => {
      uiStore.removeNotification(notificationId);
      const response = await templatesStore.explainTemplate(teamId, templateUuid, errorToastWithRetryOption(templateUuid));
      const { success, data } = response;

      if (success) {
        setExplainedTemplate(data);
        modalInstance(MODAL_IDS.EXPLAIN_TEMPLATE).toggle();
      }
      setRequesting(false);
    }, 2000);
  };

  return { requesting, setRequesting, explainedTemplate, setExplainedTemplate, handleExplainTemplate };
};

export { useExplainTemplate };
