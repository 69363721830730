import { Modal, ModalBody, ModalSection, ModalHeader, ModalText, Button, Text, ModalFooter, modalInstance, ModalCheck } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";

const PendingTemplateApproveAndExitModal = ({ name, onConfirm }) => {
  return (
    <Modal modalId={MODAL_IDS.PENDING_TEMPLATE_APPROVE_AND_EXIT}>
      <ModalBody>
        <ModalSection>
          <ModalCheck />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={`Are you sure you want to approve template '${name}' ?`} />
          <ModalText text="Please note approved templates cannot be edited. This action cannot be undone." />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(MODAL_IDS.PENDING_TEMPLATE_APPROVE_AND_EXIT).toggle();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          color="success-primary"
          onClick={() => {
            onConfirm();
          }}
        >
          <Text size="sm">Approve & exit</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { PendingTemplateApproveAndExitModal };
