import { useContext } from "react";
import PropTypes from "prop-types";
import { Text, C, C4, R, Panel, PanelBody, PanelHeader, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { SectionLayout } from "components/layout/Layout";
import { EditableText } from "components/editable/EditableText";
import { LookupsTableContext } from "../LookupsTableType/LookupsTable";
import { meStore, lookupsStore } from "store/Store";

const EditLookupTableModal = ({ modalId }) => {
  const { lookupTable } = useContext(LookupsTableContext);

  const {
    name,
    description,
    type,
    createdBy,
    createdAt,
    settings: { caseSensitivity, fourEyesEnabled, overwrite },
    teamId,
  } = lookupTable || {};

  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitLookupTable = async (key, value) => {
    await handleEditLookup(teamId, handleChange(lookupTable, key, value));
  };

  const handleEditLookup = async (teamId, lookup) => {
    const user = { editedBy: meStore.getEmail() };
    const { success, data } = await lookupsStore.updateTable(teamId, { ...lookup, ...user });
    if (success) {
      lookupsStore.updateLookupTable(data);
    }
  };

  return (
    <>
      <Panel panelId={modalId}>
        <PanelBody>
          <PanelHeader header="Lookup Settings" description="These are the settings for this lookup table" />

          <SectionLayout title="Overview">
            <R props={"pb-12"}>
              <EditableText
                name="name"
                label="Name"
                value={name}
                onSave={(value) => {
                  submitLookupTable("name", value);
                }}
                warningText="Lookup name is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <EditableText
                name="description"
                label="Description"
                value={description}
                onSave={(value) => {
                  submitLookupTable("description", value);
                }}
                warningText="Lookup description is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Type
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  {type}
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12"}>
              <C4>
                <Text size="sm" variant="tertiary" weight="regular">
                  Created by
                </Text>
              </C4>
              <C props="pl-0">
                <CreatedBy user={{ name: createdBy.name }} date={createdAt} />
              </C>
            </R>
          </SectionLayout>

          <SectionLayout title="Other Settings">
            <R props={"pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Overwrite
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  {overwrite ? "Enabled" : "Disabled"}
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Case-Sensitive
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  {caseSensitivity ? "Enabled" : "Disabled"}
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Approval
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  {fourEyesEnabled ? "Required" : "Not Required"}
                </Text>
              </C>
            </R>
          </SectionLayout>
        </PanelBody>
      </Panel>
    </>
  );
};

// Specifies the types for props:
EditLookupTableModal.propTypes = {
  modalId: PropTypes.string,
};

// Specifies the default values for props:
EditLookupTableModal.defaultProps = {
  modalId: "",
};

export { EditLookupTableModal };
