import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { useNavigate, createSearchParams } from "react-router-dom";
import moment from "moment";
import { R, Text, Button, IconAdd, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { ManageLayout, PageTitleArea } from "../../layout/Layout";
import { Table } from "../../ag-grid/Ag-grid";
import { StatusCell } from "../../ag-grid/StatusCell/StatusCell";
import { VerticalMenu } from "../../ag-grid/verticalMenu/VerticalMenu";
import { NoTemplates } from "../NoTemplates";
import { AuditCell, auditCellText, getDateTimeStamp } from "./AuditCell/AuditCell";
import { DropdownPanelCell } from "./dropdownPanel/DropdownPanelCell";
import { getMenuItems } from "./verticalMenu/verticalMenu";
import { AUTHORITIES } from "../../../utils/enums";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { DeleteTemplateModal } from "../EditTemplate/Modals/DeleteTemplate";
import { MODAL_IDS } from "utils/templates/enums";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";
import { ShareTemplateModal } from "../ShareTemplateModal/ShareTemplateModal";
import { ExplainTemplateModal } from "../EditTemplate/Modals/ExplainTemplateModal";
import { useExplainTemplate } from "../hooks/useExplainTemplate";

const TemplatesList = observer(() => {
  const navigate = useNavigate();
  const { templatesStore, rolesStore, meStore } = useStore();
  const [templates, setTemplates] = useState(null);

  const [gridApi, setGridApi] = useState(null);
  const [template, setTemplate] = useState({});

  const [shareTemplateUuid, setSharedTemplateUuid] = useState(null);

  const { requesting, setRequesting, explainedTemplate, setExplainedTemplate, handleExplainTemplate } = useExplainTemplate();

  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All templates for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  useEffect(() => {
    // Show ag-grid spinner until reaction updates state with data from store
    gridApi?.api?.showLoadingOverlay();

    if (teamId) templatesStore.loadTemplates(teamId);

    /**
     * Mobx Reaction to keep useState in sync with store
     * Whenever the observable data changes, it triggers the effect
     * The effect checks if the component is mounted and the store already finished to fetch the data
     *
     * For more details refer to:
     * https://mobx.js.org/reactions.html#reaction
     *
     * @param {Function} ObservableData - Tracks the observable.
     * @param {Function} Effect - Reacts to changes in the observable.
     */
    const disposer = reaction(
      () => templatesStore.getTemplates(), // Observable data
      (templatesListForSelectedTeam) => {
        // Effect
        setTemplates(templatesListForSelectedTeam?.length > 0 ? templatesListForSelectedTeam : []);
      }
    );
    // Cleanup reaction
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const onCellClick = (event) => {
    if (event?.column?.colId !== "rowEndSettings") {
      const {
        data: {
          tmoDetail: { teamId: tmoTeamId },
          templateUuid,
        },
      } = event;

      navigate({ pathname: `${templateUuid}`, search: `?${createSearchParams({ teamId: tmoTeamId })}` });
    }
  };

  const shareRow = (event) => {
    const { templateUuid } = event.data;
    setSharedTemplateUuid(templateUuid);
    modalInstance(MODAL_IDS.SHARE_TEMPLATE).toggle();
  };

  const onItemClick = (option, props) => {
    const {
      data: { templateUuid },
    } = props;

    switch (option) {
      case "viewRow":
      case "editRow":
        const {
          data: {
            tmoDetail: { teamId: tmoTeamId },
          },
        } = props;
        navigate({ pathname: `${templateUuid}`, search: `?${createSearchParams({ teamId: tmoTeamId })}` });
        break;
      case "shareRow":
        shareRow(props);
        break;
      case "cloneRow":
        templatesStore.cloneTemplate(teamId, templateUuid).then((response) => {
          const { success } = response;
          if (success) {
            templatesStore.loadTemplates(teamId);
          }
        });
        break;
      case "deleteRow":
        setTemplate(props.data);
        modalInstance(MODAL_IDS.DELETE_TEMPLATE).toggle();
        break;
      case "explainRow":
        setRequesting(true);
        setTemplate(props.data);
        handleExplainTemplate(props.data);
        break;
      case "exportRow":
        break;
      default:
        break;
    }
  };

  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: true,
    detailRowAutoHeight: true,
    suppressContextMenu: true,
    suppressRowClickSelection: true,
    onGridReady: onGridReady,
    noRowsOverlayComponent: NoTemplates,
    onCellClicked: onCellClick,
    detailCellRenderer: DropdownPanelCell,
  };

  const columns = [
    {
      headerName: "",
      field: "dropdown_trigger",
      sortable: false,
      cellRenderer: "agGroupCellRenderer",
      width: 60,
      suppressMenu: true,
    },
    {
      headerName: "Name",
      field: "name",
      flex: 2,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 2,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: StatusCell,
      maxWidth: 200,
    },
    {
      headerName: "Audit log",
      field: "createdAt",
      cellRenderer: AuditCell,
      sort: "desc",
      flex: 2,
      filter: "agTextColumnFilter",
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const date1 = moment(new Date(getDateTimeStamp(nodeA)));
        const date2 = moment(new Date(getDateTimeStamp(nodeB)));

        if (date1.isSame(date2)) return 0;
        return date1.isAfter(date2) ? 1 : -1;
      },
      filterParams: {
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          switch (filter) {
            case "contains":
              return auditCellText(value).includes(filterText);
            case "notContains":
              return !auditCellText(value).includes(filterText);
            case "equals":
              return auditCellText(value) === filterText;
            case "notEqual":
              return auditCellText(value) !== filterText;
            case "startsWith":
              return auditCellText(value).startsWith(filterText);
            case "endsWith":
              return auditCellText(value).endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
    {
      headerName: "",
      suppressMenu: true,
      sortable: false,
      field: "rowEndSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: { menuItems: [], onItemClick: onItemClick, getMenuItemsFromRowData: getMenuItems, disableMenu: requesting },
    },
  ];

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} params.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if it isn't available
    const cellValue = node.data?.[columnId] ?? "";

    if (columnId === "createdAt") {
      // custom cell processing for "createdAt" column
      return auditCellText(node);
    }

    return cellValue;
  };

  const handleCreateTemplate = () => {
    navigate({ pathname: "/templates/create", search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <ExplainTemplateModal template={template} explainedTemplate={explainedTemplate} setExplainedTemplate={setExplainedTemplate} />
      <ShareTemplateModal shareTemplateUuid={shareTemplateUuid} />
      <DeleteTemplateModal teamId={teamId} template={template} />
      <PageTitleArea
        title="Templates"
        description={<div className="text-medium pb-4">View your team's data transformation templates</div>}
        additionalChildClasses="pr-20"
      >
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.TEMPLATE_CREATE])}>
          <Button size="md" color="primary" onClick={handleCreateTemplate}>
            <IconAdd className={"btn-md-svg"} />
            <Text size="sm">New template</Text>
          </Button>
          <DownloadTable
            gridRef={gridApi}
            processCellCallback={processCellCallback}
            fileName={fileName}
            direction="left"
            excludeLastColumn={true}
            excludeFirstColumn={true}
          />
        </AuthWrapper>
      </PageTitleArea>
      <ManageLayout>
        <R props="d-flex pt-32 pl-0 pb-20" />
        <Table columns={columns} rowData={templates} agGridOptions={gridOptions} />
      </ManageLayout>
    </>
  );
});

export { TemplatesList };
