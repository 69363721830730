import { SummarySettingsList, SummarySetting } from "../../SettingsList/SummarySettingsList";
import { daysForInfoLabel } from "components/workflows/reusable";
import { WORKFLOW_TRIGGER_DESCRIPTION } from "utils/workflows/enums";
import _ from "lodash";

const TriggerSectionSummaryView = ({ trigger }) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const getTypeContent = (trigger) => {
    const { type } = trigger;
    switch (type) {
      case "AUTO":
        return "Automated";
      case "SCHEDULE":
        return "Scheduled";
      case "INTERVAL":
        return "Scheduled";
      default:
        return "Automated";
    }
  };
  const getRunContent = (trigger) => {
    const { type, params = {} } = trigger;
    const { daysOfWeek = [] } = params || {};
    const runDays = daysOfWeek.length === 7 ? "every day" : `every ${daysForInfoLabel(daysOfWeek) ?? "day"}`;
    switch (type) {
      case "AUTO":
        return <SummarySetting name="Summary" value={WORKFLOW_TRIGGER_DESCRIPTION.AUTO} />;
      case "SCHEDULE":
        const { minuteOfHour, hourOfDay } = params;
        const textSpecific = `Run at ${("0" + hourOfDay).slice(-2)}:${("0" + minuteOfHour).slice(-2)} (${timeZone}) ${runDays + "."} `;
        return <SummarySetting name="Summary" value={textSpecific} />;
      case "INTERVAL":
        const { intervalUnit, interval, startTime = "", endTime = "" } = params;
        const textRecurring = `Run every ${
          interval === 1
            ? ` 1 ${_.startCase(_.camelCase(intervalUnit.toLowerCase()))}(s)`
            : `${interval} ${_.startCase(_.camelCase(intervalUnit.toLowerCase()))}(s)`
        } from  ${startTime.toString().concat(":00")} to ${endTime.toString().concat(":00")}  ${runDays}.`;
        return <SummarySetting name="Summary" value={textRecurring} />;
      default:
        return <SummarySetting name="Summary" value="When new data comes in from source" />;
    }
  };

  return (
    <SummarySettingsList>
      <SummarySetting name="Trigger type" value={getTypeContent(trigger)} />
      {getRunContent(trigger)}
    </SummarySettingsList>
  );
};

export { TriggerSectionSummaryView };
