import { observer } from "mobx-react-lite";
import {
  R,
  C,
  C6,
  Input,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  IconDelete,
} from "@fundrecs/ui-library";
import styles from "./Condition.module.scss";
const containsDropdown = [
  { value: "string_contains", label: "Contains" },
  { value: "string_not_contains", label: "Does not contain" },
];
const Condition = observer(({ index, condition, handleChangeCondition, canDelete, handleRemoveCondition }) => {
  const { operator = "string_contains" } = condition || {};
  return (
    <div className={styles.container} key={index}>
      <R props="pt-8 pb-8">
        <div className="col-auto me-auto" style={{ marginLeft: "10px", alignSelf: "center" }}>
          If
        </div>
        <C6 props="d-inline-flex pr-0 pl-0 align-items-center">
          <Dropdown>
            <DropdownButton size="md">
              <DropdownButtonText>
                <ItemBody>Filename</ItemBody>
                <IconDownArrow className="btn-md-svg" />
              </DropdownButtonText>
            </DropdownButton>
            <DropdownList>
              <DropdownListItem>
                <DropdownItem>
                  <ItemBody>Filename</ItemBody>
                </DropdownItem>
              </DropdownListItem>
            </DropdownList>
          </Dropdown>
          <span className="pl-12" />
          <Dropdown>
            <DropdownButton size="md">
              <DropdownButtonText>
                <ItemBody>{operator === "string_contains" ? "Contains" : "Does not contain"}</ItemBody>
                <IconDownArrow className="btn-md-svg" />
              </DropdownButtonText>
            </DropdownButton>
            <DropdownList>
              {containsDropdown.map((item, indexContains) => {
                return (
                  <DropdownListItem
                    key={indexContains}
                    onClick={() => {
                      handleChangeCondition(index, { ...condition, ...{ operator: item.value } });
                    }}
                  >
                    <DropdownItem index={indexContains}>
                      <ItemBody>{item.label}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
        </C6>
        <C props="d-inline-flex justify-content-end align-items-center">
          <Input
            onChange={(event) => {
              handleChangeCondition(index, { ...condition, ...{ paramValue: event.target.value } });
            }}
            value={condition.paramValue}
          />
          {canDelete && (
            <button
              className={styles.deleteButton}
              onClick={() => {
                handleRemoveCondition(index);
              }}
            >
              <IconDelete className="btn-md-svg" />
            </button>
          )}
        </C>
      </R>
    </div>
  );
});
export { Condition };
