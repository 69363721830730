import React from "react";
import "./TbApp.css";
import TbToolbar from "./TbToolbar";
import UICommand from "./utils/domain/UICommand";
import CommandStackModel from "./utils/domain/CommandStackModel";
import CommandArgsModel from "./utils/domain/CommandArgsModel";
import SelectOptions from "./utils/domain/selectandtransform/SelectOptions";
import TransformOptions from "./utils/domain/selectandtransform/TransformOptions";
import ParsersDesc from "./utils/domain/selectandtransform/ParsersDesc";
import CommandStack from "./commandstack/CommandStack";
import CommandParams from "./commandparams/CommandParams";
import SelectBackendModel from "./utils/domain/selectandtransform/SelectBackendModel";
import TransformBackendModel from "./utils/domain/selectandtransform/TransformBackendModel";
import SelectAndTransformPanel from "./commandparams/SelectAndTransformPanel";
import DataTable from "./datatable/DataTable";
import HttpMediator from "./utils/http/HttpMediator";
import FileUploader from "./fileuploader/FileUploader";
import CommandResult from "./utils/domain/CommandResult";
import { tbAppChannel } from "./utils/communication/AppChannels";
import { appSingleton } from "./utils/AppSingleton";
import TemplateMapper from "./templateMapper/TemplateMapper";
import GeneralCommands from "./menu/commandsMenu/GeneralCommands";
import IndexedValue from "./utils/domain/selectandtransform/IndexedValue";
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import SubmitApproveButtons from "./SubmitApproveButtons";
import "./Fusion15App.css";
import { uiStore, tbStore } from "../../store/Store";

export default class TbApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commandResultArray: [],
      availableCommands: [],
      selectAndTransformCommands: {},
      uiCommandStack: [],
      structure: "TEXT",
      isSelectAndTransformVisible: false,
      commandParams: {},
      isGeneralCommandsMenuVisible: false,
      applyMappings: false,
      fileUploadVisible: false,
      templateColumns: [],
      isLoading: true,
      paramsMode: null,
      allRequiredMappedState: false,
    };
    this.initAppBuilderUIState = this.initAppBuilderUIState.bind(this);
    this.getTableHeaders = this.getTableHeaders.bind(this);
    this.deserialzieSelectAndTransform = this.deserialzieSelectAndTransform.bind(this);
    this.deleteIncompleteRule = this.deleteIncompleteRule.bind(this);
    this.clearSelectAndTransformData = this.clearSelectAndTransformData.bind(this);
  }

  /**
   * Determines whether sliding window view is visible or not*
   * @returns {boolean}
   */
  commandParamsVisible() {
    if (
      // this.state.isGeneralCommandsMenuVisible ||
      this.state.isSelectAndTransformVisible ||
      this.state.commandParams.hasUserInputArgs != undefined
    )
      return true;
    return false;
  }

  checkRender = () => {
    if (this.state.fileUploadVisible) {
      <FileUploader />;
    } else {
      <span></span>;
    }
  };

  checkCommandSection(rightPanelClasses) {
    if (document.getElementById("#dataTable") !== null) {
      if (rightPanelClasses.includes("command-params-visible")) {
        document.getElementById("#dataTable").style.height = "52vh";
      } else if (this.commandParamsVisible()) {
        document.getElementById("#dataTable").style.height = "52vh";
      } else {
        document.getElementById("#dataTable").style.height = "77vh";
      }
    }
  }

  // optional configuration
  options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  render() {
    let tableHeaders = new Array();
    if (this.state.commandResultArray.length > 0 && this.state.commandResultArray[0].fundsData != null && this.state.commandResultArray[0].fundsData.headers) {
      tableHeaders = this.state.commandResultArray[0].fundsData.headers;
    }

    const selectedCommandName = this.state.commandParams && this.state.commandParams.uiCommand && this.state.commandParams.uiCommand.name.peName;

    const rightPanelClasses = [];

    if (this.commandParamsVisible()) {
      rightPanelClasses.push("command-params-visible");
    }

    if (selectedCommandName === "lumsCommand") {
      rightPanelClasses.push("lums-view");
    }

    this.checkCommandSection(rightPanelClasses);
    return (
      <AlertProvider template={AlertTemplate} {...this.options}>
        <div id="appFrame">
          <div className={this.commandParamsVisible() ? "mainView command-params-visible" : "mainView"}>
            <div id="left-panel">
              <TbToolbar className="toolbar" applyMappings={this.state.applyMappings} />
              <CommandStack
                uiCommandStack={this.state.uiCommandStack}
                commandMenuVisible={this.commandParamsVisible()}
                addNewCommand={this.state.commandParams.isNew}
                disabled={this.state.applyMappings}
                clearSelectAndTransformData={this.clearSelectAndTransformData}
              />
              <TemplateMapper
                disabled={!this.state.applyMappings}
                allRequiredMappedChange={this.allRequiredMappedChange.bind(this)}
                commandResult={this.state.commandResultArray[0]}
              />
            </div>
            <div id="right-panel" className={rightPanelClasses.join(" ")}>
              <CommandParams
                mode={this.state.paramsMode}
                commandParams={this.state.commandParams}
                commandResultArray={this.state.commandResultArray}
                commandStackLength={this.state.uiCommandStack.length}
              />
              <SelectAndTransformPanel
                isVisible={this.state.isSelectAndTransformVisible}
                tableHeaders={tableHeaders}
                selectAndTransformCommands={this.state.selectAndTransformCommands}
                isNew={this.state.selectAndTransformCommands.transform == undefined}
                mode={this.state.sAndTMode}
              />
              <DataTable commandResultArray={this.state.commandResultArray} templateColumns={this.state.templateColumns} />
            </div>
          </div>
          <GeneralCommands isVisible={this.state.isGeneralCommandsMenuVisible} />
          {this.checkRender()}
          {/*<AlertContainer ref={(a) => (this.msg = a)} position="top right"></AlertContainer>*/}
        </div>
        {tbStore.doneButtonRender && (
          <SubmitApproveButtons
            applyMappings={this.state.applyMappings}
            allRequiredMappedState={this.state.allRequiredMappedState}
            commandResult={this.state.commandResultArray[0]}
            templateColumns={this.state.templateColumns}
          />
        )}
      </AlertProvider>
    );
  }

  saveUnstructured(event) {
    HttpMediator.saveUnstructured(null);
  }

  leaveTemplateMappings(event) {
    this.setState({ applyMappings: false });
  }

  updateState(data, envelope) {
    appSingleton.dataUpdated = true; //causes Data Table to be redrawn
    let commandResultArrayAndCommandStack = data.commandResultArrayAndCommandStack;
    let commandResultList = commandResultArrayAndCommandStack.commandResultArray.map((commandResult) => {
      return CommandResult.buildResultFromJson(commandResult);
    });

    if (commandResultArrayAndCommandStack.commandResultArray[0]) {
      appSingleton.state = commandResultArrayAndCommandStack.commandResultArray[0].structure;
    }
    let commandStack = commandResultArrayAndCommandStack.commandStack.map((jsonCommand) => {
      let command = CommandStackModel.buildCommandStackFromJson(jsonCommand);
      return command;
    });

    if (commandResultArrayAndCommandStack.readOnly != undefined) {
      appSingleton.readOnly = commandResultArrayAndCommandStack.readOnly;
    }
    if (commandResultArrayAndCommandStack.name != undefined) {
      appSingleton.templateName = commandResultArrayAndCommandStack.name;
    }
    if (commandResultArrayAndCommandStack.templateType != undefined) {
      appSingleton.templateType = commandResultArrayAndCommandStack.templateType;
    }

    if (commandResultArrayAndCommandStack.allFileS3Urns != undefined) {
      appSingleton.allFileS3Urns = commandResultArrayAndCommandStack.allFileS3Urns;
    }

    let skipToMappings = this.state.applyMappings;
    if (commandResultArrayAndCommandStack.readOnly && data.step == "-1") skipToMappings = true;

    this.setState({
      commandResultArray: commandResultList,
      uiCommandStack: commandStack,
      templateColumns: commandResultArrayAndCommandStack.templateColumns,
      commandParams: { isNew: false },
      applyMappings: skipToMappings,
    });
  }

  componentWillMount() {
    this.subscription = new Array();
    this.subscription.push(tbAppChannel.subscribe("dataRefresh", this.updateState.bind(this)));
    this.subscription.push(
      tbAppChannel.subscribe("showMappings", () => {
        //set selected command to last
        var step = this.state.uiCommandStack.length - 1;
        HttpMediator.fetchResultAndStack(step, () => {
          appSingleton.step = step;
        });
        this.setState({
          isSelectAndTransformVisible: false,
          isGeneralCommandsMenuVisible: false,
          selectAndTransformCommands: {},
          applyMappings: true,
        });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("hideMappings", () => {
        let mappingsVisibleState = this.hideParamsState();
        mappingsVisibleState.applyMappings = false;
        this.setState(mappingsVisibleState);
      })
    );

    this.subscription.push(
      tbAppChannel.subscribe("showFileUpload", () => {
        this.setState({ fileUploadVisible: true });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("hideUploadModal", () => {
        this.setState({ fileUploadVisible: false });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("hideCommandParams", () => {
        this.setState(this.hideParamsState);
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("hideCommandParamsBackToCommandList", () => {
        this.setState(this.hideParamsStateBackToCommandList);
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("showGeneralCommandsMenu", () => {
        this.setState({
          isGeneralCommandsMenuVisible: true,
          commandParams: { isNew: true },
        });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("commandParamsRefresh", (data) => {
        if (data.commandArgsModel.uiCommand.name.peName === "selectAndTransform") {
          let selectAndTransformArgs = this.deserialzieSelectAndTransform(data.commandArgsModel);
          this.setState({
            selectAndTransformCommands: selectAndTransformArgs,
            isSelectAndTransformVisible: true,
            commandParams: { isNew: false },
            paramsMode: "Edit",
          });
          return;
        }
        this.setState({
          commandParams: data.commandArgsModel,
          isSelectAndTransformVisible: false,
          isGeneralCommandsMenuVisible: false,
          paramsMode: "Edit",
        });
      })
    );

    this.subscription.push(
      tbAppChannel.subscribe("commandResultRefresh", (data) => {
        this.setState({
          commandParams: { isNew: false },
          isSelectAndTransformVisible: false,
          isGeneralCommandsMenuVisible: false,
        });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("onCommandParamsChange", (commandArgsModel) => {
        this.setState({ commandParams: commandArgsModel });
      })
    );
    this.subscription.push(
      tbAppChannel.subscribe("showSelectAndTransform", () => {
        this.setState({
          isSelectAndTransformVisible: true,
          isGeneralCommandsMenuVisible: false,
          commandParams: { isNew: true },
        });
      })
    );
    this.subscription.push(tbAppChannel.subscribe("newGeneralCommandSelected", this.addNewGeneralCommand.bind(this)));
    this.subscription.push(
      tbAppChannel.subscribe("error", (object) => {
        //Object will be either FundrecsMessage object or string
        //alert(object.message==undefined?object:object.message)
        /*this.msg.show(object, {
          time: 5000,
          type: "info",
        });*/
        console.log("object", object);
        if (object === "ERROR_LOADING") {
          tbStore.showErrorModal();
        } else {
          if (object) {
            const message = object.replace("com.fundrecs.command.CommandExecutionException: ", "");
            uiStore.addNotification("error", message);
          } else {
            uiStore.addNotification("error", "Error");
          }
        }
      })
    );

    this.subscription.push(
      tbAppChannel.subscribe("success", (object) => {
        //Object will be either FundrecsMessage object or string
        //alert(object.message==undefined?object:object.message)
        this.msg.show(object, {
          time: 3000,
          type: "success",
        });
      })
    );

    this.subscription.push(
      tbAppChannel.subscribe("addModeSandT", (mode) => {
        this.setState({
          isSelectAndTransformVisible: true,
          selectAndTransformCommands: {},
          isGeneralCommandsMenuVisible: false,
          commandParams: { isNew: true },
          sAndTMode: mode,
          paramsMode: "New",
        });
      })
    );

    this.subscription.push(
      tbAppChannel.subscribe("deleteIncompleteRule", (deleteIndex) => {
        this.deleteIncompleteRule(deleteIndex);
      })
    );
  }

  componentDidMount() {
    let templateAndStep = this.props;
    appSingleton.teamId = templateAndStep.teamId;
    appSingleton.onComplete = templateAndStep.onComplete;

    let commandListWillReturn = HttpMediator.fetchCommandsList();
    commandListWillReturn.then((jsonResponse) => {
      const { status } = jsonResponse;

      if (status === 200) {
        this.initAppBuilderUIState(jsonResponse.data);
        if (templateAndStep.templateUuid) {
          appSingleton.templateUuid = templateAndStep.templateUuid;

          HttpMediator.getTmos().then(({ data }) => {
            appSingleton.approvalOptions = data.approvalOptions;
            appSingleton.templateTypes = {};

            data.tmos.map(({ uuid, name }) => {
              appSingleton.templateTypes[uuid] = name;
            });

            appSingleton.step = -1;

            HttpMediator.fetchResultAndStack(appSingleton.step).catch((error) => {
              appSingleton.state = "corrupted";
              tbAppChannel.publish("error", "Template Corrupted. Undo or start again");
              this.setState({ commandResultArray: [], uiCommandStack: [] });
            });
          });
        } else {
          tbAppChannel.publish("error", jsonResponse.data.errorMessage);
        }
      }
    });
  }

  onCommandParamsChange(commandArgsModel) {
    this.setState({ commandParams: commandArgsModel });
  }

  componentWillUnmount() {
    this.subscription.map((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Initialize all General Commands and set the state of general commands dropdown to all.
   * The generalCommands Component will be responsible to filter it based on the structured,unstructured,treestructured*
   * @param generalCommands - List<UICommand>. UICommand from utils package
   */
  initAppBuilderUIState(jsonResponseData) {
    appSingleton.generalCommands = UICommand.buildCommandListFromJson(jsonResponseData.generalCommands);
    appSingleton.selectOptions = SelectOptions.buildSelectOptionsFromJson(jsonResponseData.selectOptions);
    appSingleton.transformOptions = TransformOptions.buildTransformOptionsFromJson(jsonResponseData.transformOptions);
    appSingleton.parsers = ParsersDesc.buildParserDescFromJson(jsonResponseData.parsers);
  }

  addNewGeneralCommand(data, envelope) {
    let uiCommand = data.uiCommand;
    let commandArgs = new CommandArgsModel({
      uiCommand: uiCommand,
      isNew: true,
    });
    this.setState({
      commandParams: commandArgs,
      isSelectAndTransformVisible: false,
      isGeneralCommandsMenuVisible: false,
      paramsMode: "New",
    });
  }

  getTableHeaders() {
    if (this.state.commandResultArray.length > 0) return this.state.commandResultArray[0].fundsData.headers;
    return new Array();
  }

  deserialzieSelectAndTransform(commandArgsModel) {
    let selectAndTransform = { transform: {} };
    selectAndTransform.mode = commandArgsModel.userInputargs.mode;

    if (commandArgsModel.userInputargs.selectExpr != undefined && commandArgsModel.userInputargs.selectExpr != "")
      selectAndTransform.selectExpr = commandArgsModel.userInputargs.selectExpr;
    else {
      selectAndTransform.selectExpr = "";
      if (commandArgsModel.userInputargs.andSelect != undefined && commandArgsModel.userInputargs.andSelect.length > 0) {
        selectAndTransform.andSelect = commandArgsModel.userInputargs.andSelect.split(" AND ").map((it) => {
          let andExpression = it.trim().split(" ");
          //2nd parameter may be blank, if so enter as empty string
          let param2 = andExpression[2] ? andExpression[2] : " ";
          for (let i = 3; i < andExpression.length; i++) {
            param2 = param2.concat(" " + andExpression[i]);
          }
          return new SelectBackendModel({
            peName: andExpression[1],
            arg1: andExpression[0],
            arg2: param2,
          });
        });
      } else selectAndTransform.andSelect = [];
      if (commandArgsModel.userInputargs.orSelect != undefined && commandArgsModel.userInputargs.orSelect.length > 0) {
        selectAndTransform.orSelect = commandArgsModel.userInputargs.orSelect.split(" OR ").map((it) => {
          let orExpression = it.trim().split(" ");
          //2nd parameter may be blank, if so enter as empty string
          let param2 = orExpression[2] ? orExpression[2] : " ";
          for (let i = 3; i < orExpression.length; i++) {
            param2 = param2.concat(" " + orExpression[i]);
          }
          return new SelectBackendModel({
            peName: orExpression[1],
            arg1: orExpression[0],
            arg2: param2,
          });
        });
      } else selectAndTransform.orSelect = [];
    }
    if (commandArgsModel.userInputargs.transformValue != undefined && commandArgsModel.userInputargs.transformValue != "")
      selectAndTransform.transform.transformValue = commandArgsModel.userInputargs.transformValue;
    else {
      selectAndTransform.transform.transformValue = "";
      if (commandArgsModel.userInputargs.transformChain && commandArgsModel.userInputargs.transformChain.length > 0) {
        selectAndTransform.transform.transformChain = commandArgsModel.userInputargs.transformChain.split("THEN").map((it) => {
          let transformRuleConf = it.trim().split(" ");
          let subCommand = transformRuleConf[0];
          let transformCommand = appSingleton.transformOptions.find((transformOptionElement) => {
            return transformOptionElement.peName === subCommand;
          });
          let transformBackendModel = new TransformBackendModel({
            peName: subCommand,
          });
          if (transformCommand.type === "unary") {
            transformBackendModel.arg1 = transformRuleConf[1];
          } else if (transformCommand.type === "binarySeparated") {
            let args = transformRuleConf[1].split(transformCommand.argSeperator);
            transformBackendModel.arg1 = args[0];
            transformBackendModel.arg2 = args[1];
          }
          return transformBackendModel;
        });
      }
    }
    let transformColumn = parseInt(commandArgsModel.userInputargs.transformColumn);
    let headerValue = this.state.commandResultArray[0].fundsData.headers[transformColumn];
    let indexedArg1 = new IndexedValue({
      value: headerValue,
      index: transformColumn,
    });
    selectAndTransform.transform.transformColumn = indexedArg1;
    selectAndTransform.transform.transformOperation = commandArgsModel.userInputargs.transformOperation;
    return selectAndTransform;
  }

  hideParamsState() {
    return {
      isGeneralCommandsMenuVisible: false,
      isSelectAndTransformVisible: false,
      commandParams: {},
      selectAndTransformCommands: {},
    };
  }

  /**
   * Called if user is creating a command and wants to return to list of available commands
   * If command being worked on is partially complete, their work is undone
   * @returns {{isGeneralCommandsMenuVisible: boolean, isSelectAndTransformVisible: boolean, commandParams: {}, selectAndTransformCommands: {}}}
   */
  hideParamsStateBackToCommandList() {
    return {
      isGeneralCommandsMenuVisible: true,
      isSelectAndTransformVisible: false,
      commandParams: {},
      selectAndTransformCommands: {},
    };
  }

  /**
   * if the rule at the specified index is inccomplete then delete it
   * @param index
   */
  deleteIncompleteRule(params) {
    if (
      this.state.uiCommandStack[params.deleteIndex].argsUser.transformOperation == "NOP" &&
      this.state.uiCommandStack[params.deleteIndex].argsUser.mode != "Filter"
    ) {
      let deleteParams = {};
      deleteParams["commandIndex"] = params.deleteIndex;
      deleteParams["updateType"] = "deleteCommand";
      HttpMediator.updateCommand(deleteParams);
    }
  }

  allRequiredMappedChange(allMapped) {
    this.setState({ allRequiredMappedState: allMapped });
  }

  /**
   * Clears the temporary select and transform data from state
   */
  clearSelectAndTransformData() {
    this.setState({
      isSelectAndTransformVisible: false,
      selectAndTransformCommands: {},
    });
  }
}
