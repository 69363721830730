import { rolesStore } from "store/Store";
import { isUserAuthorized } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";

const assembleData = (data) => {
  const tableData = [];
  data?.forEach((row) => {
    const { columnMappings, status, createdAt, priority, id, createdBy, tags = [] } = row;

    // If columnMappings it not present, row was already assembled before
    if (columnMappings) {
      tableData.push({ priority: priority, status: status, createdAt: createdAt, id: id, createdBy: createdBy, tags: tags, ...columnMappings });
    } else {
      tableData.push(row);
    }
  });

  return tableData;
};

const assembleColumnHeaders = ({ tableColumns, teamId }) => {
  const checkEditable = (params) => {
    if (params.data.status === "Draft") {
      return isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_EDIT]) });
    } else {
      return false;
    }
  };

  const columnHeaders = [];
  tableColumns.forEach((header) => {
    const { name, unique } = header;
    columnHeaders.push({
      sortable: false, // [FS-1511] Sorting disabled for all columns as add/edit expects index 0
      colId: name,
      headerName: unique === true ? name.concat("(Unique)") : name,
      resizable: true,
      field: name,
      minWidth: 150,
      cellStyle: { height: "42px !important", backgroundColor: unique && "#F2F3F5" },
      headerClass: unique && "#e6e8eb",
      editable: (params) => checkEditable(params),
    });
  });

  return columnHeaders;
};

/**
 * The reduce method executes some action on every item in the array.
 * This would be similar to looping over the array.
 *
 * Link to source for reduce:
 * https://stackoverflow.com/questions/54789406/convert-array-to-object-keys
 *
 * Link to source to explain comma operator:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Comma_Operator
 *
 * We use the array of headers to search for matching keys within the data object.
 * We then add these to a new object storing just our colum mappings.
 */
const createColumnMappingsObject = (data, columnHeaders) => {
  const headers = columnHeaders.map((header) => header.name);
  const columnMappings = headers.reduce((header, key) => ((header[key] = data[key]), header), {});

  return columnMappings;
};
export { assembleData, assembleColumnHeaders, createColumnMappingsObject };
