import { useStore } from "../../../../../store/Store";
import { InputSection } from "./InputSection";
import { useFusion20WorkflowState } from "../../useFusion20WorkflowState";

const InputSummaryView = ({ workflow }) => {
  const { workflowsStore } = useStore();

  const templateImportRules = workflowsStore.getReadOnlyWorkflowTemplates();
  const [editingStage] = useFusion20WorkflowState(workflow);

  return (
    <>
      {templateImportRules.map((importRule) => {
        return <InputSection importRule={importRule} workflow={workflow} editingStage={editingStage} />;
      })}
    </>
  );
};

export { InputSummaryView };
