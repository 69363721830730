import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadingChannel } from "./utils/communication/AppChannels";

export default class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div id="spinner-container">
          <CircularProgress size="22px" thickness={4} id="spinner" />
        </div>
      );
    }
    return null;
  }

  componentWillMount() {
    loadingChannel.subscribe("loading", (object) => {
      this.setState({ isLoading: true });
    });
    loadingChannel.subscribe("finishedLoading", (object) => {
      this.setState({ isLoading: false });
    });
  }
}
