import { TabsSection } from "@fundrecs/ui-library";
import styles from "./Tabs.module.scss";

const Tabs = ({ activeTabIndex, setActiveTabIndex }) => {
  return (
    <div className="pl-32 pr-32 ml-32">
      <TabsSection>
        <ul className={["nav"].join(" ")}>
          <li
            onClick={() => {
              setActiveTabIndex(0);
            }}
          >
            <span className={["tab-link text-sm text-medium", `${activeTabIndex === 0 ? [`tab-link-active ${styles.active}`] : styles.inactive}`].join(" ")}>
              SFTP directories
            </span>
          </li>
          <li
            onClick={() => {
              setActiveTabIndex(1);
            }}
          >
            <span className={["tab-link text-sm text-medium", `${activeTabIndex === 1 ? [`tab-link-active ${styles.active}`] : styles.inactive}`].join(" ")}>
              Email addresses
            </span>
          </li>
        </ul>
      </TabsSection>
    </div>
  );
};

export { Tabs };
