import { useEffect, useState } from "react";
import { recTypeStore } from "store/Store";

const useExportReconciliationData = (teamId) => {
  const [recData, setRecData] = useState([]);
  useEffect(() => {
    recTypeStore.getRecTypes(teamId).then((data) => {
      setRecData(data.data);
    });
  }, []);

  return recData;
};

export { useExportReconciliationData };
