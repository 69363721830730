import { useContext } from "react";
import PropTypes from "prop-types";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { meStore, lookupsStore } from "store/Store";
import { LookupsTableContext } from "../LookupsTableType/LookupsTable";
import { redrawRowsToUpdateVerticalMenu } from "../LookupsTableType/utils/agGrid";

const RejectLookupsModal = ({ modalId }) => {
  const { gridApi, lookupTable, selectedRowsCount, verticalMenuRow, setVerticalMenuRow } = useContext(LookupsTableContext);
  const { teamId, uuid, name } = lookupTable || {};

  const handleConfirmation = async () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length > 0 || verticalMenuRow) {
      const itemsToUpdate = selectedRows.length > 0 ? selectedRows : [verticalMenuRow];

      const lookupIds = itemsToUpdate.map((item) => item.id);
      const requestObject = { rejectedBy: meStore.getEmail(), rowIdList: lookupIds };

      const response = await lookupsStore.rejectTableRows(uuid, teamId, { ...requestObject });
      const { success } = response || {};

      if (success) {
        const rejectedRows = itemsToUpdate.map((item) => {
          return { ...item, ...{ status: "Rejected" } };
        });

        gridApi.applyTransactionAsync({ update: rejectedRows }, () => {
          gridApi.deselectAll();
          redrawRowsToUpdateVerticalMenu({ gridApi, ids: lookupIds });
        });
      }
    }

    if (verticalMenuRow) {
      setVerticalMenuRow();
    }
  };

  const multipleRows = selectedRowsCount > 1;
  const heading = multipleRows ? `Are you sure you want to reject all the selected rows for ${name} ?` : "Are you sure you want to reject the selected row?";
  const text = multipleRows ? "These rows will be unavailable to use once rejected." : "This row will become unavailable once rejected.";
  const confirm = multipleRows ? "Reject Entries" : "Reject Entry";

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading={heading}
      body=""
      text={text}
      cancel="Cancel"
      confirm={confirm}
      onConfirm={() => handleConfirmation()}
      onCancel={() => {
        gridApi.deselectAll();
      }}
    />
  );
};

// Specifies the types for props:
RejectLookupsModal.propTypes = {
  modalId: PropTypes.string,
};

// Specifies the default values for props:
RejectLookupsModal.defaultProps = {
  modalId: "",
};

export { RejectLookupsModal };
