import { useState, useEffect } from "react";
import moment from "moment";
import { reaction } from "mobx";
import { useStore } from "store/Store";
import { Table } from "components/ag-grid/Ag-grid";
import { Text } from "@fundrecs/ui-library";
import { DATA_TYPE_KEYS } from "utils/mappings/enums";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";

const ApprovedOutputMapping = () => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  const { meStore, outputMappingsStore } = useStore();
  const { name: outputMappingName } = outputMappingsStore.getOutputMapping();

  const { teamName } = useSelectedTeam();
  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All columns for output mapping ${outputMappingName} for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  useEffect(() => {
    /**
     * Mobx Reaction to keep useState in sync with store
     * Whenever the observable data changes, it triggers the effect
     * The effect checks if the component is mounted and the store already finished to fetch the data
     *
     * For more details refer to:
     * https://mobx.js.org/reactions.html#reaction
     *
     * @param {Function} ObservableData - Tracks the observable.
     * @param {Function} Effect - Reacts to changes in the observable.
     */
    const disposer = reaction(
      () => outputMappingsStore.getColumns(), // Observable data
      (columnsListForSelectedOutputMapping) => {
        // Effect
        setRowData(columnsListForSelectedOutputMapping?.length > 0 ? columnsListForSelectedOutputMapping : []);
      }
    );
    // Cleanup reaction
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridOptions = {
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    getRowId: (data) => data.uuid,
    onGridReady: onGridReady,
  };

  const columns = [
    { headerName: "", field: "columnOrderNumber", width: 100 },
    { headerName: "Column name", field: "columnName", flex: 2 },
    {
      headerName: "Data type",
      field: "type",
      flex: 2,
      cellRenderer: (props) => {
        const { value } = props;
        return DATA_TYPE_KEYS[value].toString();
      },
    },
    {
      headerName: "Required",
      field: "required",
      flex: 2,
      filter: "agTextColumnFilter",
      cellRenderer: (props) => {
        const { value } = props;
        if (value === true) {
          return (
            <Text size="sm" weight="medium" variant="success">
              Required
            </Text>
          );
        } else {
          return (
            <Text size="sm" weight="regular">
              Not required
            </Text>
          );
        }
      },
      filterParams: {
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          let cellValue = value.data.required ? "Required" : "Not required";

          switch (filter) {
            case "contains":
              return cellValue.includes(filterText);
            case "notContains":
              return cellValue.includes(filterText);
            case "equals":
              return cellValue === filterText;
            case "notEqual":
              return cellValue !== filterText;
            case "startsWith":
              return cellValue.startsWith(filterText);
            case "endsWith":
              return cellValue.endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
  ];

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {any} params.value - The value of the cell.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, value }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if undefined
    const cellValue = value ?? "";

    switch (columnId) {
      case "required":
        // custom processing for the "required" column
        return cellValue ? "Required" : "Not required";
      case "type":
        // custom processing for the "type" column
        return DATA_TYPE_KEYS[cellValue]?.toString() ?? cellValue;
      case "actions":
        // custom processing for the "actions" column
        return "Delete";
      default:
        // return the processed value
        return cellValue;
    }
  };

  return (
    <div className="ml-72 mr-72">
      <div className="d-flex mb-20 justify-content-between align-items-center">
        {rowData?.length > 0 && (
          <Text size="md" weight="medium">
            {`${rowData?.length.toString()} column${rowData?.length > 1 ? "s" : ""} added`}
          </Text>
        )}
        <div className="ms-auto">
          <DownloadTable direction="left" fileName={fileName} gridRef={gridApi} processCellCallback={processCellCallback} />
        </div>
      </div>
      <div className="pb-72">
        <Table columns={columns} rowData={rowData} agGridOptions={gridOptions} />
      </div>
    </div>
  );
};

export { ApprovedOutputMapping };
