import React from "react";
import PropTypes from "prop-types";
import { Tag } from "./Tag";

const LookupTagsRenderer = (props) => {
  const { value = [] } = props;

  return (
    <>
      {value.length === 0 ? (
        <>
          {/* Needs return empty clickable component - if nothing is returned, editor won't work */}
          <label key="empty" />
        </>
      ) : (
        <div className="d-flex flex-wrap pt-8 pb-8 ">
          {value.map((tag, index) => {
            return <Tag key={index} value={tag} />;
          })}
        </div>
      )}
    </>
  );
};

LookupTagsRenderer.propTypes = {
  value: PropTypes.array,
};

LookupTagsRenderer.defaultProps = {};

export { LookupTagsRenderer };
