import { observer } from "mobx-react-lite";
import { Text, C, C4, R, Panel, PanelBody, PanelHeader, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { SectionLayout } from "components/layout/Layout";
import { EditableText } from "components/editable/EditableText";
import { MODAL_IDS, STATUS_VALUES } from "utils/mappings/enums";
import { useStore } from "store/Store";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";

const MappingSettings = observer(({ teamId }) => {
  const { outputMappingsStore } = useStore();
  const {
    name,
    description,
    status,
    createdBy: { name: createdByName } = {},
    createdAt,
    assignedToWorkflows = [],
    mappedToTemplates = [],
  } = outputMappingsStore.getOutputMapping();

  const submitMappingSettings = async (key, value) => {
    const updatedObject = { ...outputMappingsStore.getOutputMapping(), ...{ [key]: value } };

    const { success, data } = await outputMappingsStore.updateOutputMapping(teamId, updatedObject, key);
    if (success) {
      outputMappingsStore.setOutputMapping(data);
    }
  };

  return (
    <>
      <Panel panelId={MODAL_IDS.EDIT_MAPPING_SETTINGS}>
        <PanelBody>
          <PanelHeader header="Output Mapping Settings" />

          <SectionLayout title="">
            <R props={"pb-12"}>
              <EditableText
                name="name"
                label="Name"
                value={name}
                onSave={(value) => {
                  submitMappingSettings("name", value);
                }}
                warningText="Output mapping name is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <EditableText
                name="description"
                label="Description"
                value={description}
                onSave={(value) => {
                  submitMappingSettings("description", value);
                }}
                warningText="Output mapping description is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Status
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  <StatusCell value={status} />
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Assigned to workflow
                </Text>
              </C4>

              <C>
                <div className="d-flex flex-column">
                  {assignedToWorkflows.map(({ name }) => {
                    return (
                      <Text variant="primary" size="sm" weight="medium" element="div">
                        {name}
                      </Text>
                    );
                  })}
                  {assignedToWorkflows.length === 0 && "-"}
                </div>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Mapped to template(s)
                </Text>
              </C4>

              <C>
                <div className="d-flex flex-column">
                  {mappedToTemplates.map(({ name }) => {
                    return (
                      <Text variant="primary" size="sm" weight="medium" element="div">
                        {name}
                      </Text>
                    );
                  })}
                  {mappedToTemplates.length === 0 && "-"}
                </div>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text size="sm" variant="tertiary" weight="regular">
                  Created by
                </Text>
              </C4>
              <C props="pl-0">
                <CreatedBy user={{ name: createdByName }} date={createdAt} />
              </C>
            </R>

            {status === STATUS_VALUES.APPROVED && (
              <>
                <SectionDivider />
                <R props={"pt-12 pb-12"}>
                  <C4>
                    <Text size="sm" variant="tertiary" weight="regular">
                      Approved by
                    </Text>
                  </C4>
                  <C props="pl-0">{<CreatedBy user={{ name: createdByName }} date={createdAt} />}</C>
                </R>
              </>
            )}
          </SectionLayout>
        </PanelBody>
      </Panel>
    </>
  );
});

export { MappingSettings };
