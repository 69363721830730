import { useNavigate, createSearchParams } from "react-router-dom";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/mappings/enums";
import { ROUTES } from "utils/enums";
import { outputMappingsStore } from "store/Store";

const DeleteMapping = ({ teamId, outputMapping, onConfirm = false }) => {
  const { name = "", uuid = "" } = outputMapping;
  const navigate = useNavigate();

  const handleDeleteMapping = async () => {
    const response = await outputMappingsStore.deleteOutputMapping(teamId, uuid);
    const { success } = response;
    if (success) {
      outputMappingsStore.setOutputMapping({});
      outputMappingsStore.loadOutputMappings({ teamId });
      navigate({ pathname: ROUTES.OUTPUT_MAPPINGS, search: `?${createSearchParams({ teamId: teamId })}` });
      if (onConfirm) {
        onConfirm();
      }
    }
  };

  return (
    <WarningConfirmationModal
      modalId={MODAL_IDS.DELETE_MAPPING}
      heading={`Are you sure you want to delete output mapping '${name}' ?`}
      text="This action will affect any workflows and templates using this output mapping and cannot be undone."
      cancel="Cancel"
      confirm="Delete output mapping"
      onConfirm={handleDeleteMapping}
    />
  );
};

export { DeleteMapping };
