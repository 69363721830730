import PropTypes from "prop-types";
import { Step } from "../../reusable/step/step";
/**
 * The ModalsTabSection component sits at the top of the Modal on both views.
 * It controls which portion of the modal can be viewed at specific times.
 */
const ModalTabsSection = ({ steps, state }) => {
  const { step } = state;
  return (
    <div className="mt-32 mb-72">
      <Step steps={steps} activeStep={step} />
    </div>
  );
};

//Proptypes for ModalTabsSection
ModalTabsSection.propTypes = {
  steps: PropTypes.array,
  state: PropTypes.object,
};

// Specifies the default values for props:
ModalTabsSection.defaultProps = {
  steps: [],
  state: {},
};

export { ModalTabsSection };
