import React, { useState, useEffect } from "react";
import { C, C6, C4, Input, LinkText, Text, SaveEditable } from "@fundrecs/ui-library";

const EditableText = ({ name, label, value, onSave, onChange, warningText }) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [warningBoolean, setWarningBoolean] = useState(false);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {label}
        </Text>
      </C4>

      {editing ? (
        <>
          <C6>
            <Input
              name={name}
              value={fieldValue}
              onChange={(event) => {
                if (event.target.value.trimStart().length === 0) {
                  setWarningBoolean(true);
                } else {
                  setWarningBoolean(false);
                }

                setFieldValue(event.target.value.trimStart());

                if (onChange) {
                  onChange(event.target.value.trimStart());
                }
              }}
              warning={warningBoolean}
              warningMessage={warningText}
            />
          </C6>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                if (fieldValue.length > 0) {
                  onSave(fieldValue);
                  setEditing(false);
                }
              }}
              onClickCancel={() => {
                setFieldValue(value);
                setEditing(false);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {value}
            </Text>
          </C6>
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditing(true);
                setFieldValue(value);
                setWarningBoolean(false);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        </>
      )}
    </>
  );
};

export { EditableText };
