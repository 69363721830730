/**
 * Created by Naman on 10/17/2016.
 */
import React from "react";
import { fundsDataTableChannel } from "../../utils/communication/AppChannels";
import IndexedValue from "../../utils/domain/selectandtransform/IndexedValue";
import Button from "@material-ui/core/Button";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";

export default class HeaderSelectorButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      indexedValues: [],
    };
  }

  render() {
    return (
      <Button title="Select column header" disabled={this.state.selected} onClick={this.selectHeader.bind(this)}>
        <ViewCarouselIcon />
      </Button>
    );
  }

  selectHeader() {
    this.onHeaderClickedSubscription = fundsDataTableChannel.subscribe("headerClicked", this.onHeaderClicked.bind(this));
    document.querySelector(".structure-table").classList.add("header-clickable");
    this.setState({ selected: true });
  }

  onHeaderClicked(data) {
    if (this.props.multiSelectionEnabled) {
      let indexedValue = new IndexedValue({
        index: data.event.target.cellIndex,
        value: data.event.target.innerHTML,
      });
      const exists = this.state.indexedValues.some((e) => {
        return e.value === indexedValue.value;
      });
      if (!exists) {
        /* Disallow duplicates */
        this.state.indexedValues.push(indexedValue);
        this.props.onSelection(this.state.indexedValues);
      }
    } else {
      this.onHeaderClickedSubscription.unsubscribe();
      document.querySelector(".structure-table").classList.remove("header-clickable");
      let indexedValue = new IndexedValue({
        index: data.event.target.cellIndex,
        value: data.event.target.innerHTML,
      });
      this.props.onSelection(indexedValue);
      this.setState({ selected: false });
    }
  }

  componentWillUnmount() {
    if (this.onHeaderClickedSubscription != undefined) {
      this.onHeaderClickedSubscription.unsubscribe();
      document.querySelector(".structure-table").classList.remove("header-clickable");
    }
  }
}
