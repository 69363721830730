import { makeAutoObservable } from "mobx";

class WorkflowRunResult {
  store = null;

  id = null;
  state = null;
  workflowName = null;
  workflowUuid = null;
  startTimestamp = null;
  endTimestamp = null;
  duration = null;
  metadata = null;

  constructor(store, teamId, json) {
    makeAutoObservable(this, {
      /**
       * The properties below can't be modified by the user or the backend
       * after creation, so we can disable reactivity for them
       */
      store: false,
      id: false,
      state: false,
      teamId: false,
      workflowUuid: false,
      workflowName: false,
      startTimestamp: false,
    });

    this.store = store;
    this.teamId = teamId;
    
    this.updateFromJson(json);
  }

  updateFromJson(json) {
    const { id, state, workflowUuid, workflowName, startTimestamp, endTimestamp, duration, metadata } = json;

    this.id = id;
    this.state = state;
    this.workflowUuid = workflowUuid;
    this.workflowName = workflowName;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
    this.duration = duration;
    this.metadata = metadata;
  }
}

export { WorkflowRunResult };

