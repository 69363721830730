import { observer } from "mobx-react-lite";
import { useStore } from "store/Store";
import { Text, Panel, PanelBody, PanelHeader, PanelFooter, Button, modalInstance, IconCheck } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import styles from "./AddOutputMappingPanel.module.scss";

const AddOutputMappingPanel = observer(({ newOutputMappingUuid, changeOutputMappingUuid, report, addOutputMappingUuid, sectionIndex }) => {
  const { outputMappingsStore } = useStore();

  const handleClose = () => {
    modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)).hide();
  };

  const OutputMappingElement = ({ outputMapping, index }) => {
    const { name, uuid } = outputMapping;
    return (
      <div
        onClick={() => {
          changeOutputMappingUuid(uuid);
        }}
        className={[
          `${styles.cursorPointer}`,
          `${styles.outputMappingElement}`,
          " d-flex mb-12 pb-8 pt-8 pl-12 pr-12",
          `${newOutputMappingUuid === uuid && styles.activeText}`,
          `${newOutputMappingUuid === uuid && styles.activeBackground}`,
        ].join(" ")}
        key={index}
      >
        <div className="me-auto">{name}</div>
        <div>{newOutputMappingUuid === uuid && <IconCheck className={`${styles.checkIconSize}`} />}</div>
      </div>
    );
  };
  return (
    report !== undefined && (
      <Panel panelId={MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)}>
        <PanelBody>
          <PanelHeader description="" header="Select output mapping" onClick={handleClose} />
          <div style={{ height: "70vh", overflowY: "auto" }} className="pt-0 pb-12 pl-0 pr-32">
            {outputMappingsStore.getOutputMappings().map((outputMapping, index) => {
              return <OutputMappingElement outputMapping={outputMapping} index={index} />;
            })}
          </div>
        </PanelBody>
        <PanelFooter>
          <Button color="tertiary" onClick={handleClose}>
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            onClick={() => {
              addOutputMappingUuid(newOutputMappingUuid);
              modalInstance(MODAL_IDS.ADD_OUTPUT_MAPPING.concat(sectionIndex)).hide();
            }}
            size="md"
            disabled={newOutputMappingUuid === ""}
          >
            <Text size="sm">Add output mapping</Text>
          </Button>
        </PanelFooter>
      </Panel>
    )
  );
});

export { AddOutputMappingPanel };
