import { Modal, ModalBody, ModalSection, ModalHeader, ModalText, Button, Text, ModalFooter, ModalAlert, modalInstance } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";

const FourEyesDisabledPublishAndExitModal = ({ name, onConfirm }) => {
  return (
    <Modal modalId={MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT}>
      <ModalBody>
        <ModalSection>
          <ModalAlert />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={`Are you sure you want to publish template '${name}' ?`} />
          <ModalText text="Please note published templates cannot be edited. This action cannot be undone." />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT).toggle();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          <Text size="sm">Publish & exit</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { FourEyesDisabledPublishAndExitModal };
