import { useContext } from "react";
import { Button, Text, IconCloseRemove, IconSave, IconSaveAndSubmit } from "@fundrecs/ui-library";
import { rolesStore, meStore, lookupsStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { LookupsTableContext } from "../../LookupsTable";
import { createColumnMappingsObject, assembleData } from "../../utils/transformData";
import { ROW_STATUS_VALUES } from "utils/lookups/enums";

const SaveRowButtons = () => {
  const { gridApi, lookupTable, setIsAddRow } = useContext(LookupsTableContext);
  const { teamId, uuid, columns, settings } = lookupTable || {};

  const handleClearRow = () => {
    const { data } = gridApi.getDisplayedRowAtIndex(0);
    gridApi.applyTransactionAsync({ remove: [data] }, () => {
      gridApi.addRow = false;
      setIsAddRow(false);
    });
  };

  const handleSaveRow = () => {
    const status = ROW_STATUS_VALUES.DRAFT;
    createRow(status);
  };

  const handleSaveAndSubmit = () => {
    const { fourEyesEnabled } = settings;
    const status = fourEyesEnabled ? ROW_STATUS_VALUES.PENDING : ROW_STATUS_VALUES.APPROVED;
    createRow(status);
  };

  const createRow = async (status) => {
    gridApi.stopEditing();
    const { data } = gridApi.getDisplayedRowAtIndex(0);

    const columnMappings = createColumnMappingsObject(data, columns);

    // Set default default priority and tags if undefined
    const priority = data["priority"] ?? 3;
    const tags = data["tags"] ?? [];

    const requestObject = {
      columnMappings: columnMappings,
      priority: priority,
      status: status,
      createdBy: meStore.getEmail(),
      editedBy: meStore.getEmail(),
      tags: tags,
    };

    const response = await lookupsStore.createTableRow(uuid, teamId, { ...requestObject });
    const { success, data: responseData } = response || {};

    if (success) {
      gridApi.addRow = false;
      setIsAddRow(false);

      gridApi.applyTransactionAsync({ remove: [data] });
      gridApi.applyTransactionAsync({ add: assembleData([responseData]), addIndex: 0 });
    }
  };

  return (
    <>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_CREATE])}>
        <Button
          size="md"
          color="primary-secondary"
          onClick={() => {
            handleClearRow();
          }}
        >
          <IconCloseRemove className="btn-md-svg" />
          <Text size="sm">Clear Row</Text>
        </Button>
        <span className="pl-8" />
        <Button
          size="md"
          color="primary"
          onClick={() => {
            handleSaveRow();
          }}
        >
          <IconSaveAndSubmit className="btn-md-svg" />
          <Text size="sm">Save Row</Text>
        </Button>

        <span className="pl-8" />
        <Button
          size="md"
          color="success-primary"
          onClick={() => {
            handleSaveAndSubmit();
          }}
        >
          <IconSave className="btn-md-svg" />
          <Text size="sm">Save and Submit Row</Text>
        </Button>
      </AuthWrapper>
    </>
  );
};

export { SaveRowButtons };
