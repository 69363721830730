import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconDownArrow,
  Text,
  SelectedIcon,
} from "@fundrecs/ui-library";

/**
 * Component to display a multiple values in a dropdown with an associated label.
 * The Dropdown contains multiple options.
 * disabled is a boolean which enables or disabled the dropdown.
 * options is an object we will turn into an array and map over.
 * option is an object containing the active index and value.
 * setOption is a function used to update the active option in the parent.
 * disabledOptions is a function used to check if a specific list item should be disabled.
 * disabledText is a text string for no data available or disabled options.
 * objKey dynamic key
 */
const ObjectOptionDropdown = ({ objKey, label, disabled, options, option, setOption, disabledOptions, disabledText }) => {
  const { index, [objKey]: name } = option;
  return (
    <div className="mb-24">
      <div className="mb-8">
        <Text size="sm" weight="medium" variant="secondary">
          {label}
        </Text>
      </div>
      <Dropdown>
        <DropdownButton size="md" disabled={disabled}>
          <DropdownButtonText>
            <ItemBody>{name}</ItemBody>
            <IconDownArrow className="btn-md-svg" />
          </DropdownButtonText>
        </DropdownButton>
        <DropdownList>
          {Object.values(options).map((optionValue, optionIndex) => {
            return (
              <DropdownListItem
                onClick={() => {
                  if (!disabledOptions(optionValue)) {
                    setOption({ index: optionIndex, [objKey]: optionValue });
                  }
                }}
                key={optionIndex}
              >
                <DropdownItem active={index} index={optionIndex} classes={disabledOptions(optionValue) ? "disabled" : ""}>
                  {disabledOptions(optionValue) ? (
                    <ItemBody>
                      <Text size="sm" weight="regular" variant="muted">
                        {optionValue}
                      </Text>
                    </ItemBody>
                  ) : (
                    <ItemBody>{optionValue}</ItemBody>
                  )}
                  <SelectedIcon active={index} index={optionIndex} size="sm" />
                </DropdownItem>
              </DropdownListItem>
            );
          })}
        </DropdownList>
      </Dropdown>
    </div>
  );
};

ObjectOptionDropdown.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  option: PropTypes.object,
  setOption: PropTypes.func,
  disabledOptions: PropTypes.func,
  disabledText: PropTypes.string,
  objKey: PropTypes.string,
};

//Specifies the default values for props:
ObjectOptionDropdown.defaultProps = {
  label: "",
  disabled: false,
  options: {},
  option: {},
  setOption: () => {},
  disabledOptions: () => {
    return false;
  },
  disabledText: "Disabled",
};

export { ObjectOptionDropdown };
