import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import { agMultiColumnFilter } from "./filters/filters";

// TODO: create custom theme and replace here
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-grid-fundrecs-styles.css";

import { Spinner } from "./Spinner/Spinner";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE_KEY);

const Table = ({ columns, rowData, agGridOptions = {}, gridStyle = {} }) => {
  let gridOptions = {
    // PROPERTIES
    suppressContextMenu: true,
    rowSelection: "multiple",
    domLayout: "normal",
    ...agGridOptions,
  };

  const style = Object.keys(gridStyle).length === 0 ? { height: "70vh", width: "100% !important" } : gridStyle;
  const defaultColDef = { sortable: true, filter: true, resizable: true, ...agMultiColumnFilter };

  return (
    <div style={style}>
      <AgGridReact
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        className="ag-theme-alpine muli-font-family"
        rowData={rowData}
        columnDefs={columns}
        loadingOverlayComponent={Spinner}
      />
    </div>
  );
};

// Specifies the types for props:
Table.propTypes = {
  columns: PropTypes.array,
  rowData: PropTypes.array,
  gridStyle: PropTypes.object,
  agGridOptions: PropTypes.object,
};

// Specifies the default values for props:
Table.defaultProps = {
  columns: [],
  rowData: [],
  agGridOptions: {},
  gridStyle: {},
};

export { Table };
