import moment from "moment";
import { Modal, ModalBody, ModalSection, Heading, modalInstance, IconAiRed, IconDownload, IconCloseRemove } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";
import styles from "./ExplainTemplateModal.module.scss";

const ExplainTemplateModal = ({ template, explainedTemplate, setExplainedTemplate }) => {
  const closeExplainTemplateModal = () => {
    setExplainedTemplate([]);
    modalInstance(MODAL_IDS.EXPLAIN_TEMPLATE).toggle();
  };

  // Function to download CSV
  const downloadCSV = () => {
    const filteredArray = explainedTemplate
      .map((item) => item.replace(/\r?\n|\r/g, "").trim()) // Remove newlines and trim spaces
      .filter((item) => item !== ""); // Filter out empty strings

    // Convert filtered array to CSV format
    const csvContent = filteredArray.join("\n");

    // Create a blob with the CSV content and specify the MIME type as text/csv
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Create a URL for the blob and set it as the href attribute
    const url = URL.createObjectURL(blob);
    link.href = url;

    // Set the download attribute with a filename
    link.download = "AI summary for " + template?.name + moment().format(" YYYY-MM-DD hh-mm-ss");

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <Modal modalId={MODAL_IDS.EXPLAIN_TEMPLATE} size="xl">
      <ModalBody>
        <ModalSection classes={styles.modalSection}>
          <div className="d-flex">
            <div className="d-flex">
              <Heading variant="h5" element="p" classes="mb-0 mr-20">
                AI Summary
              </Heading>
              <div className={styles.aIIcon}>
                <IconAiRed />
                <span className="pl-4 pr-4"> Generated by AI</span>
              </div>
            </div>
            <div className={[`${styles.modalIconsContainer}`, "ms-auto"].join(" ")}>
              <IconDownload className="btn-lg-svg" onClick={downloadCSV} />
              <IconCloseRemove className="btn-lg-svg ml-12" onClick={closeExplainTemplateModal} />
            </div>
          </div>

          <ol className={styles.orderedList}>
            {explainedTemplate.map((explanation, index) => {
              return <li key={index}>{explanation}</li>;
            })}
          </ol>
        </ModalSection>
      </ModalBody>
    </Modal>
  );
};

export { ExplainTemplateModal };
