import React, { createContext, useContext } from "react";
import { configure } from "mobx";
import { UIStore } from "./UIStore";
import { LookupsStore } from "./LookupsStore";
import { MeStore } from "./MeStore";
import { WorkflowsStore } from "./WorkflowsStore";
import { TbStore } from "./TbStore";
import { RolesStore } from "./RolesStore";
import { OutputMappingsStore } from "./OutputMappingsStore";
import { TenantConfigStore } from "./TenantConfigStore";
import { TemplatesStore } from "./TemplatesStore";
import { ImportsStore } from "./ImportsStore";
import { RecTypeStore } from "./RecTypeStore";
import { FrontendPropertiesStore } from "./FrontendPropertiesStore";

// DEBUG
configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true,
});

const StoreContext = createContext();

const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useStore must be used within StoresProvider");
  }

  return context;
};

const uiStore = new UIStore();
const lookupsStore = new LookupsStore();
const meStore = new MeStore();
const workflowsStore = new WorkflowsStore();
const tbStore = new TbStore();
const rolesStore = new RolesStore();
const outputMappingsStore = new OutputMappingsStore();
const tenantConfigStore = new TenantConfigStore();
const templatesStore = new TemplatesStore();
const importsStore = new ImportsStore();
const recTypeStore = new RecTypeStore();
const frontendPropertiesStore = new FrontendPropertiesStore();

const storeContext = {
  uiStore,
  lookupsStore,
  meStore,
  workflowsStore,
  tbStore,
  rolesStore,
  outputMappingsStore,
  tenantConfigStore,
  templatesStore,
  importsStore,
  recTypeStore,
  frontendPropertiesStore,
};

const StoreProvider = ({ children }) => <StoreContext.Provider value={storeContext}>{children}</StoreContext.Provider>;

export {
  StoreProvider,
  useStore,
  uiStore,
  lookupsStore,
  meStore,
  rolesStore,
  tbStore,
  workflowsStore,
  outputMappingsStore,
  tenantConfigStore,
  templatesStore,
  recTypeStore,
  frontendPropertiesStore,
};
