import { lookups } from "./entities/lookups";
import { fusionAdmin } from "./entities/fusionAdmin";
import { me } from "./entities/me";
import { workflows } from "./entities/workflows";
import { tbUi } from "./entities/templateBuilder";
import { outputMappings } from "./entities/outputMappings";
import { tenantConfig } from "./entities/tenantConfig";
import { templates } from "./entities/templates";
import { importRules } from "./entities/importRules";
import { reconciliations } from "./entities/reconciliations";

const fusionDataApi = {
  lookups,
  fusionAdmin,
  me,
  workflows,
  tbUi,
  outputMappings,
  tenantConfig,
  templates,
  importRules,
  reconciliations,
};

export { fusionDataApi };
