import { useEffect, createContext, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance } from "@fundrecs/ui-library";
import { useFusion20WorkflowState } from "./useFusion20WorkflowState";
import { CreateOutputMappingStage } from "../OutputMapping/CreateOutputMappingStage";
import { ConfigureWorkflowStages } from "./ConfigureWorkflowStages";
import { CreateTemplateStage } from "components/templates/CreateTemplate/CreateTemplateStage";
import { MODAL_IDS, WORKFLOW_CONFIG_STAGE, WORKFLOW_STATUS } from "../../../utils/workflows/enums";
import { useStore } from "../../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";
/**
 * sub-views for each stage
 */

const ConfigureWorkflow = observer(() => {
  const { workflowsStore, meStore } = useStore();
  const { workflowUuid } = useParams();
  const teamId = useTeamId();

  const [workflow, setWorkflow] = useState();

  useEffect(() => {
    const loadWorkflows = async () => {
      return await workflowsStore.loadWorkflows(teamId);
    };

    if (teamId) {
      loadWorkflows().then(() => {
        const currentWorkflow = workflowsStore.getWorkflowByUuid(workflowUuid);

        if (currentWorkflow === undefined) {
          const team = meStore.getTeamById(teamId);
          if (team === undefined) {
            modalInstance(MODAL_IDS.ERROR_TEAM_NOT_FOUND).show();
          } else {
            modalInstance(MODAL_IDS.ERROR_WORKFLOW_NOT_FOUND).show();
          }
        }

        setWorkflow(currentWorkflow);
        workflowsStore.setReadOnlyWorkflow(currentWorkflow);
      });
    }
  }, []);

  if (workflow) {
    return <RenderConfigurationArea workflow={workflow} setWorkflow={setWorkflow} />;
  } else {
    return <></>;
  }
});

const WorkflowConfigurationContext = createContext();

const RenderConfigurationArea = observer(({ workflow, setWorkflow }) => {
  const navigate = useNavigate();
  const { outputMappingsStore, tbStore, workflowsStore } = useStore();
  const teamId = useTeamId();
  const [workflowStage, setWorkflowStage] = useState(WORKFLOW_CONFIG_STAGE.OVERVIEW);

  //This is here so we can update the trigger object triggerUuid when we create one from scratch
  const [triggerIndex, setTriggerIndex] = useState(null);
  //This is here so we can update the report object outputMappingUuid when we create one from scratch
  const [reportIndex, setReportIndex] = useState(null);
  const [importRuleIndex, setImportRuleIndex] = useState(null);
  // create our local state store from the Workflow
  const [fieldValues, stageState, setFieldValue, resetStage, saveStage, editingStage, setEditingStage, onStageEdit, onStageComplete] = useFusion20WorkflowState(
    workflow,
    outputMappingsStore,
    tbStore
  );

  // enable if all the stages are complete and it's not already published
  const enablePublishButton = !(workflow.status === WORKFLOW_STATUS.PUBLISHED) && Object.values(stageState).every((stage) => stage.isComplete);

  const handleCloseButtonClick = () => {
    // user must confirm they want to cancel
    modalInstance(MODAL_IDS.CONFIRM_CLOSE_CONFIGURE_WORKFLOW).show();
  };

  const handlePublishButtonClick = async () => {
    const { success } = await workflow.publish();
    if (success) {
      navigate({ pathname: `/workflows/${workflow.uuid}`, search: `?${createSearchParams({ teamId: teamId })}` });
    }
  };

  const handleClose = () => {
    // close this modal and navigate user to Workflow overview
    workflowsStore.setIsWorkflow(false);
    if (workflow.status === WORKFLOW_STATUS.DRAFT) {
      navigate({ pathname: "/workflows", search: `?${createSearchParams({ teamId: teamId })}` });
    } else {
      navigate({ pathname: `/workflows/${workflow.uuid}`, search: `?${createSearchParams({ teamId: teamId })}` });
    }
  };

  useEffect(() => {
    workflowsStore.setIsWorkflow(true);
  }, []);

  const handleOutputMappingCreated = (outputMappingId) => {
    //No harm leaving this here to manage the visual aspects
    setFieldValue("outputMappingUuid", outputMappingId);
    workflow.updateCurrentWorkflowReportOutputMapping(reportIndex, outputMappingId);
    setWorkflowStage(WORKFLOW_CONFIG_STAGE.OVERVIEW);
  };

  const onConfirm = () => {
    let reset = new Promise((resolve) => {
      resolve(setWorkflowStage(WORKFLOW_CONFIG_STAGE.OVERVIEW));
    });
    reset.then(() => {
      outputMappingsStore.setOutputMapping({});
    });
  };

  const handleStepCompleted = (onComplete, outputMappingUuid, setActiveStep) => {
    onComplete(outputMappingUuid);
    setActiveStep({ name: "STEP 01", index: 1 });
  };

  const workflowConfigurationContext = {
    fieldValues,
    stageState,
    setFieldValue,
    resetStage,
    onStageComplete,
    saveStage,
    editingStage,
    setEditingStage,
    onStageEdit,
    teamId,
    workflow,
    handlePublishButtonClick,
    handleCloseButtonClick,
    handleClose,
    enablePublishButton,
    setWorkflowStage,
    setWorkflow,
    setTriggerIndex,
    setReportIndex,
    setImportRuleIndex,
    importRuleIndex,
  };

  return (
    <>
      <WorkflowConfigurationContext.Provider value={workflowConfigurationContext}>
        <div style={{ display: workflowStage === WORKFLOW_CONFIG_STAGE.OVERVIEW ? "block" : "none" }}>
          <ConfigureWorkflowStages />
        </div>
        <div style={{ display: workflowStage === WORKFLOW_CONFIG_STAGE.OUTPUT ? "block" : "none" }}>
          <CreateOutputMappingStage handleOutputMappingCreated={handleOutputMappingCreated} onConfirm={onConfirm} handleStepCompleted={handleStepCompleted} />
        </div>

        {workflowStage === WORKFLOW_CONFIG_STAGE.INPUT && <CreateTemplateStage />}
      </WorkflowConfigurationContext.Provider>
    </>
  );
});

export { ConfigureWorkflow, WorkflowConfigurationContext };
