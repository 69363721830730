import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  IconDownArrow,
  IconRightArrow,
  IconPencil,
  modalInstance,
  IconDelete,
  WarningConfirmationModal,
  IconClone,
  Input,
  C8,
  Text,
} from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { MODAL_IDS, WORKFLOW_STAGES } from "utils/workflows/enums";
import { useStore } from "store/Store";
import { v4 as uuidv4 } from "uuid";
import styles from "./TriggerSection.module.scss";
import { TriggerSectionSummaryView } from "./TriggerSectionSummaryView";

const TriggerSection = observer(({ children, trigger, workflow, editingStage }) => {
  const [summaryView, setSummaryView] = useState(false);
  const [editable, setEditable] = useState(false);
  const { triggerUuid, triggerName, saved = true } = trigger;
  const triggers = workflow.getCurrentWorkflowTriggers();
  const { workflowsStore } = useStore();
  const getTriggerIndex = () => {
    return triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid);
  };

  const location = useLocation();
  const { pathname } = location;
  const configure = pathname.includes("configure");
  useEffect(() => {
    setEditable(!saved);
  }, [configure]);

  const toggleSummaryView = (value) => {
    setSummaryView(value);
  };
  const deleteIcon = () => {
    if (workflow.getCurrentWorkflowTriggers().length === 1) {
      return styles.disabledDeleteCommand;
    } else {
      return styles.deleteCommand;
    }
  };
  const cloneTrigger = async () => {
    const triggers = workflow.getCurrentWorkflowTriggers();
    const clonedTrigger = { ...triggers[getTriggerIndex()] };
    clonedTrigger.triggerName = triggers[getTriggerIndex()]["triggerName"].concat(" (copy)");
    clonedTrigger.triggerUuid = uuidv4();
    workflowsStore.addClonedTriggerToWorkflow(clonedTrigger);
    const response = await workflowsStore.saveWorkflowClonedTrigger();
    const { success } = response;
    if (success) {
      triggers.push(clonedTrigger);
    } else {
      workflowsStore.removeLastTriggerFromWorkflow();
      triggers.pop();
    }
  };
  const deleteTrigger = async () => {
    const triggers = workflow.getCurrentWorkflowTriggers();
    if (triggerUuid === null || saved === false) {
      triggers.splice(
        triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid),
        1
      );
    } else {
      const { success } = await workflowsStore.deleteWorkflowTrigger(triggerUuid);
      if (success) {
        triggers.splice(
          triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid),
          1
        );
      }
    }
  };
  const toggleRemoveTriggerModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_DELETE_TRIGGER.concat(getTriggerIndex())).toggle();
  };
  const updateTrigger = (event) => {
    const {
      target: { value },
    } = event;
    workflow.updateTriggerName(value, getTriggerIndex());
  };
  const checkIfEditViewAndLastTrigger = () => {
    if (workflowsStore.getReadOnlyWorkflow() !== null) {
      if (editingStage !== WORKFLOW_STAGES.TRIGGER) {
        let triggerIndex = getTriggerIndex();
        const triggersLength = workflowsStore.getReadOnlyWorkflowTriggers().length;
        return triggerIndex + 1 === triggersLength ? "" : styles.triggerSectionBottomBorder;
      } else {
        return styles.triggerSectionBottomBorder;
      }
    }
  };

  return (
    <>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_DELETE_TRIGGER.concat(getTriggerIndex())}
        heading={`"Are you sure you want to delete trigger '${triggerName}'?"`}
        text="This action will affect your workflow. This action cannot be undone."
        cancel="Cancel"
        confirm="Delete trigger"
        onConfirm={() => {
          deleteTrigger();
        }}
      />
      <div
        className={[
          `${getTriggerIndex() === 0 && editingStage === WORKFLOW_STAGES.TRIGGER ? styles.triggerSectionTopBorder : `${checkIfEditViewAndLastTrigger()}`}`,
        ].join(" ")}
      >
        <div className={["d-flex align-items-center", `${!summaryView ? "pt-12 pb-12" : "pt-12"}`].join(" ")}>
          {editable || summaryView ? (
            <IconDownArrow
              className={[`${styles.iconDownArrow}`, `${editable && styles.iconDownArrowDisabled}`].join(" ")}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          ) : (
            <IconRightArrow
              className={styles.iconRightArrow}
              onClick={() => {
                toggleSummaryView(!summaryView);
              }}
            />
          )}
          <div className={[`${styles.iconSpaceLeft}`, "flex-grow-1"].join(" ")}>
            <C8>
              {editable && (
                <Input
                  onChange={(event) => {
                    updateTrigger(event);
                  }}
                  placeholderText={triggerName}
                  size="md"
                  value={triggerName}
                />
              )}
              {!editable && (
                <Text variant="secondary" size="sm" weight="medium">
                  {triggerName === null ? "Trigger_".concat(getTriggerIndex() + 1) : triggerName}
                </Text>
              )}
            </C8>
          </div>
          <div className="d-flex ">
            {editingStage === WORKFLOW_STAGES.TRIGGER && (
              <>
                <IconPencil
                  className={[`${styles.editCommand}`, `${editable && styles.disabledEditCommand}`].join(" ")}
                  onClick={() => {
                    setEditable(true);
                    workflowsStore.addEditableTrigger(triggerUuid);
                  }}
                />
                <IconClone
                  className={[`${styles.cloneCommand}`, `${editable && styles.disabledCloneCommand}`].join(" ")}
                  onClick={() => {
                    cloneTrigger();
                  }}
                />
                <IconDelete
                  className={[`${deleteIcon()}`].join(" ")}
                  onClick={() => {
                    toggleRemoveTriggerModal();
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className={[`${summaryView && "pb-12"}`].join(" ")}>
          {summaryView !== false && !editable && <TriggerSectionSummaryView trigger={trigger} workflow={workflow} />}
        </div>
        <div>{editable && React.cloneElement(children, { editable: editable, setEditable: setEditable })}</div>
      </div>
    </>
  );
});
export { TriggerSection };
