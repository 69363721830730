import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const TEMPLATE_BUILDER_ROOT = "/templateBuilder";

const templates = {
  getTemplates: (teamId) => fusionRequest.get(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.TEMPLATES}`, {}),
  getRequiredTemplate: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.GET_REQUIRED_TEMPLATE}`, data),
  updateTemplate: (teamId, templateUuid, update) =>
    fusionRequest.patch(
      `${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.TEMPLATE}/${templateUuid}/${API_ENDPOINTS.TEMPLATES.UPDATE_TEMPLATE_DETAILS}`,
      update
    ),
  saveDraftTemplate: (teamId, templateUuid) =>
    fusionRequest.patch(
      `${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.TEMPLATE}/${templateUuid}/${API_ENDPOINTS.TEMPLATES.SAVE_DRAFT_TEMPLATE}`
    ),
  cloneTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.CLONE_TEMPLATE}/${templateUuid}`),
  deleteTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.DELETE_TEMPLATE}/${templateUuid}`),
  publishTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.PUBLISH_TEMPLATE}/${templateUuid}`),
  approveTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.APPROVE_TEMPLATE}/${templateUuid}`),
  submitTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.SUBMIT_TEMPLATE}/${templateUuid}`),
  rejectTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.REJECT_TEMPLATE}/${templateUuid}`),
  getTemplatesLinkedToOutputMapping: (teamId, outputMappingUuid) =>
    fusionRequest.get(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.TEMPLATES_LINKED_TO_OUTPUT_MAPPING}/${outputMappingUuid}`, {}),
  getTemplatesLinkedToOutputMappings: (teamId, outputMappingUuids) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.TEMPLATES_LINKED_TO_OUTPUT_MAPPINGS}`, {
      teamId: teamId,
      tmoUuids: outputMappingUuids,
    }),
  explainTemplate: (teamId, templateUuid) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.EXPLAIN_TEMPLATE}/${templateUuid}`),
  shareTemplate: (teamId, templateUuid, data) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TEMPLATES.SHARE_TEMPLATE}/${templateUuid}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
};

export { templates };
