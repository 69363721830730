import moment from "moment";
import { UserAvatar, Text } from "@fundrecs/ui-library";

import styles from "./AuditItem.module.scss";

const AuditItem = ({ user, timestamp }) => {
  const displayDate = moment(timestamp).format("D MMM YYYY, HH:mm");

  return (
    <div className={styles.container}>
      <UserAvatar size="s" />
      <div>
        <Text size="sm" weight="medium" element="p" variant="primary">
          {user}
        </Text>
        <Text size="xs" weight="regular" element="p" variant="secondary">
          {displayDate}
        </Text>
      </div>
    </div>
  );
};

export { AuditItem };
