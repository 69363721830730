import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Button, Text } from "@fundrecs/ui-library";
import { rolesStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { QueueContext } from "../tabs/QueueTab";


const RunSelectedFilesButton = observer(({ checkDisabled, handleRunPendingFiles }) => {
  const { teamId, gridApi, verticalMenuRow } = useContext(QueueContext);

  const handleRunFiles = () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length > 0 || verticalMenuRow) {
      const itemsToUpdate = selectedRows.length > 0 ? selectedRows : [verticalMenuRow];

      const queuedFiles = itemsToUpdate.map((item) => item.fileUuid);
      handleRunPendingFiles(teamId, queuedFiles);
    }
  }

  return (
    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_SUBMIT])}>
      <Button
        size="md"
        color="tertiary"
        disabled={checkDisabled}
        onClick={() => {
          handleRunFiles()
        }}
      >
        <Text size="sm">Run selected files</Text>
      </Button>
    </AuthWrapper>
  );
});

export { RunSelectedFilesButton };
