/**
 * isToday
 * 
 * Check if a date is today's date i.e. same day, month and year
 *
 * @param {Date} date - a JS Date instance to compare with today's date
 * @returns boolean - `true` if the given date is today's date
 */
const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export { isToday };
