import { useContext } from "react";
import { Text, Button, IconAdd, Heading } from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { useStore } from "store/Store";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_REPORT_OBJECT, WORKFLOW_STAGES } from "utils/workflows/enums";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const OutputEditSectionHeader = observer(() => {
  const { workflowsStore } = useStore();
  const { workflow, editingStage } = useContext(WorkflowConfigurationContext);

  const outputsAdded = workflow.getCurrentWorkflowReports().filter((report) => {
    return report.saved ?? report;
  });

  const addNewOutput = () => {
    const reportUuid = uuidv4();
    workflow.addNewCurrentWorkflowReport({
      ...DEFAULT_REPORT_OBJECT,
      ...{ reportName: "Output_".concat(workflow.getCurrentWorkflowReports().length + 1), reportUuid: reportUuid },
    });
    workflowsStore.addEditableOutput(reportUuid);
  };

  return (
    <div className="d-flex  pb-12">
      <div className="me-auto">
        <Heading variant="caption3" align="right" element="span">
          Outputs: {workflowsStore.getReadOnlyWorkflow() !== null ? outputsAdded.length : 0}
        </Heading>
      </div>
      <div>
        {editingStage === WORKFLOW_STAGES.OUTPUT && (
          <span className="ml-20">
            <Button
              size="md"
              color="primary-secondary"
              onClick={() => {
                addNewOutput();
              }}
            >
              <IconAdd className="btn-md-svg" />
              <Text size="sm" weight="medium">
                New output
              </Text>
            </Button>
          </span>
        )}
      </div>
    </div>
  );
});

export { OutputEditSectionHeader };
