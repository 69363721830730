import { useEffect, useState } from "react";
import { lookupsStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const useExportLookupTableData = () => {
  const teamId = useTeamId();
  const [lookupData, setLookupData] = useState([]);
  useEffect(() => {
    lookupsStore.getTables(teamId, {}, true).then((data) => {
      setLookupData(data.data);
    });
  }, []);

  return lookupData;
};

export { useExportLookupTableData };
