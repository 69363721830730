import { observer } from "mobx-react-lite";
import { EmptyLookups, R, C, Heading, Text } from "@fundrecs/ui-library";
import { AUTHORITIES } from "utils/enums";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { rolesStore } from "store/Store";
import { AddRowButton } from "../subHeader/buttonsType/AddRow";

const NoLookupsRows = observer((props) => {
  const {
    lookupTable: { teamId, name },
  } = props || {};

  return (
    <>
      <R>
        <span className="emptyState-icon">{<EmptyLookups />}</span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No entries
          </Heading>
        </C>
      </R>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_CREATE])}>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                Click Add Row below to add entries to the
              </Text>
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                {` ${name} Table`}
              </Text>
            </div>
            <div style={{ pointerEvents: "auto" }}>
              <AddRowButton />
            </div>
          </C>
        </R>
      </AuthWrapper>
    </>
  );
});

export { NoLookupsRows };
