import React from "react";
import IndexedValue from "../utils/domain/selectandtransform/IndexedValue";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import HeaderSelectorButton from "../selectandtransform/module/HeaderSelectorButton";
import DoneIcon from "@material-ui/icons/Done";
import { fundsDataTableChannel } from "../utils/communication/AppChannels";

export default class SelectAndTransformInputsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getFreshState();
    this.getFreshState = this.getFreshState.bind(this);
    this.getInputHtml = this.getInputHtml.bind(this);
    this.insertTextValue = this.insertTextValue.bind(this);
    this.areParamsValid = this.areParamsValid.bind(this);

    //Use postal subscription to handle user clicking on a data table cell
    fundsDataTableChannel.subscribe(
      "bodyClicked",
      this.onBodyClicked.bind(this)
    );
  }

  /**
   * Returns a blank state object, used when props passed to component to ensure state is clean
   * @returns {{arg1: IndexedValue, arg2: IndexedValue}}
   */
  getFreshState() {
    return {
      arg1: new IndexedValue({ index: undefined, value: "" }),
      arg2: new IndexedValue({ index: undefined, value: "" }),
    };
  }

  /**
   * If props indicate the inputs view is closed, reset state
   * This ensures state is reset when inputs view is closed,
   * but if new props arrive while inputs view is open (ie. when header selector button clicked), state is untouched
   * @param props
   */
  componentWillReceiveProps(props) {
    if (props.isVisible === "") {
      this.setState(this.getFreshState());
    }
  }

  render() {
    //Check that props.isVisible is either 'select' or 'transform'
    if (
      this.props.isVisible != "select" &&
      this.props.isVisible != "transform"
    ) {
      return null;
    } else {
      //The second input can be either a column selector or text input, the following is used to display the
      //Set columnB to 'B', columnBSelection to state.arg2.value and arg2ColumnSize to 2 if two columns inputs required
      let columnB = "";
      let columnBSelection = "";
      let arg2ColumnSize = 4;
      if (
        (this.props.isVisible === "select" &&
          this.props.selectOption.isArg2Index) ||
        (this.props.isVisible === "transform" && this.props.mode !== "Modify")
      ) {
        //If it is a merge transformation, make text 'X' to fit with description string
        columnB = this.props.mode == "Merge" ? "X" : "B";
        arg2ColumnSize = 2;
        columnBSelection = this.state.arg2.value;
      }
      return (
        <Grid container spacing={0} className="margin-top-6">
          <Grid item sm={1}></Grid>
          <Grid item sm={2}>
            <span className="font-size-large">A</span>
            {this.getInputHtml("arg1", "isArg1Index")}
            <div className="cellWithEllipsis" title={this.state.arg1.value}>
              {this.state.arg1.value}
            </div>
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={arg2ColumnSize}>
            <span className="font-size-large">{columnB}</span>
            {this.getInputHtml("arg2", "isArg2Index")}
            <div className="cellWithEllipsis" title={columnBSelection}>
              {columnBSelection}
            </div>
          </Grid>
          <Grid item sm={2}>
            <IconButton
              disabled={!this.areParamsValid()}
              className="margin-top-22"
              onClick={this.submitInputs.bind(this)}
            >
              <DoneIcon/>
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  }

  /**
   * Gets the input field
   * @returns {XML}
   */
  getInputHtml(arg, isArgIndex) {
    //If the selection argument requires a text input
    if (
      this.props.isVisible == "select" &&
      this.props.selectOption[isArgIndex] == false
    ) {
      return (
        <input
          className="selectAndTransformTextInput"
          type="text"
          placeholder="Enter text"
          value={this.state[arg].value}
          onChange={(e) => this.insertTextValue(e.target.value, arg)}
        />
      );
    } else if (this.props.isVisible == "transform") {
      //The only transformation which requires a text input is a 'Modify' transformation
      if (
        this.props.mode == "Modify" &&
        arg === "arg2" &&
        this.props.transformOptionArgsType == "binary"
      ) {
        return (
          <input
            className="selectAndTransformTextInput"
            type="text"
            placeholder="Enter text"
            value={this.state[arg].value}
            onChange={(e) => this.insertTextValue(e.target.value, arg)}
          />
        );
      }
      //A unary transformation only requires a single argument
      if (this.props.transformOptionArgsType == "unary" && arg === "arg2") {
        return null;
      }
    }
    //For all other cases return a HeaderSelectorButton
    return (
      <HeaderSelectorButton
        onSelection={(valueAndIndex) => {
          this.headerSelectionCompleted(arg, valueAndIndex);
        }}
      />
    );
  }

  /**
   * Calls updateStateWithParams in SelectAndTransformPanel.jsx
   * Updates description string when user inputs a value
   * @param arg 'arg1' or 'arg2' to identify which part of the string to update
   * @param value User input value
   */
  headerSelectionCompleted(arg, indexedValue) {
    let newStateObj = {};
    newStateObj[arg] = indexedValue;
    this.setState(newStateObj);
  }

  /**
   * Called when a user clicks a cell in the data table
   * If this selection/ transformation requires a text input, the text from the clicked cell is entered as arg2
   * @param data JS click event
   */
  onBodyClicked(data) {
    if (data && !data.startsWith("<")) {
      if (
        this.props.isVisible === "select" &&
        this.props.selectOption.isArg2Index == false
      ) {
        this.insertTextValue(data, "arg2");
      } else if (
        this.props.isVisible === "transform" &&
        this.props.mode == "Modify"
      ) {
        this.insertTextValue(data, "arg2");
      }
    }
  }

  /**
   * Listener for text input field
   * @param event
   * @param arg
   */
  insertTextValue(value, arg) {
    let newState = {};
    newState[arg] = new IndexedValue({ value: value });
    this.setState(newState);
  }

  /**
   * Checks if the valid inputs have been entered by the user
   */
  areParamsValid() {
    if (this.state.arg1.value !== "" && this.state.arg2.value !== "") {
      return true;
    }
    if (
      this.props.transformOptionArgsType == "unary" &&
      this.state.arg1.value !== ""
    ) {
      return true;
    }
    return false;
  }

  /**
   * Called when input submit button clicked
   */
  submitInputs() {
    this.props.submitUserInputs(this.state, this.props.isVisible);
  }
}
