import { useContext } from "react";
import { Button, Text, IconCheck } from "@fundrecs/ui-library";
import { rolesStore, meStore, lookupsStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { LookupsTableContext } from "../../LookupsTable";
import { ROW_STATUS_VALUES } from "utils/lookups/enums";
import { redrawRowsToUpdateVerticalMenu } from "../../utils/agGrid";

const SubmitRowsButton = () => {
  const { gridApi, lookupTable } = useContext(LookupsTableContext);
  const { teamId, uuid, settings } = lookupTable || {};
  const { fourEyesEnabled } = settings;

  const handleSubmitRow = () => {
    const selectedRowsIds = gridApi.getSelectedRows().map((row) => row.id);
    const requestObject = { approvedBy: meStore.getEmail(), rowIdList: selectedRowsIds };

    if (fourEyesEnabled) {
      submitForApprovalAndChangeToPending(requestObject);
    } else {
      submitAndChangeToApproved(requestObject);
    }
  };

  const getRowsWithUpdatedStatus = (status) => {
    return gridApi.getSelectedRows().map((item) => {
      item.status = status;
      return item;
    });
  };

  const submitAndChangeToApproved = async (requestObject) => {
    const response = await lookupsStore.submitWithoutApprovalTableRows(uuid, teamId, { ...requestObject });
    const { success } = response || {};

    if (success) {
      const approvedRows = getRowsWithUpdatedStatus(ROW_STATUS_VALUES.APPROVED);

      gridApi.applyTransactionAsync({ update: approvedRows }, () => {
        gridApi.deselectAll();

        const { rowIdList } = requestObject;
        redrawRowsToUpdateVerticalMenu({ gridApi, ids: rowIdList });
      });
    }
  };

  const submitForApprovalAndChangeToPending = async (requestObject) => {
    const response = await lookupsStore.submitForApprovalTableRows(uuid, teamId, { ...requestObject });
    const { success } = response || {};

    if (success) {
      const pendingRows = getRowsWithUpdatedStatus(ROW_STATUS_VALUES.PENDING);
      gridApi.applyTransactionAsync({ update: pendingRows }, () => {
        gridApi.deselectAll();

        const { rowIdList } = requestObject;
        redrawRowsToUpdateVerticalMenu({ gridApi, ids: rowIdList });
      });
    }
  };

  return (
    <>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_EDIT])}>
        <Button
          size="md"
          color="success-secondary"
          onClick={() => {
            handleSubmitRow();
          }}
        >
          <IconCheck className="btn-md-svg" />
          <Text size="sm">{fourEyesEnabled ? "Submit for approval" : "Submit Row"}</Text>
        </Button>
      </AuthWrapper>
    </>
  );
};

export { SubmitRowsButton };
