import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, createSearchParams, useLocation, useNavigate, useSearchParams, matchPath } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { init as initApm } from "@elastic/apm-rum";
import { ErrorFallback } from "./components/ErrorFallback";
import { observer } from "mobx-react-lite";
import { BaseLayout, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "./store/Store";
import { Header } from "./components/layout/Header";
import { Sidebar } from "./components/layout/Sidebar";
import { FusionRoutes } from "./components/Routes";
import { Toaster } from "./components/layout/Toaster";
import { MODAL_IDS } from "utils/workflows/enums";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { ROUTES, ROUTES_FULLSCREEN_MODAL } from "utils/enums";
import "./scrollbars.css";

const App = observer(() => {
  const { meStore, rolesStore, tenantConfigStore, frontendPropertiesStore } = useStore();

  const [initialised, setInitialised] = useState(false);

  const initFusionApm = (storeLoadSuccess) => {
    storeLoadSuccess &&
      frontendPropertiesStore.getFrontendPropertyApmEnabled() &&
      initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: process.env.REACT_APP_ELASTIC_APM_RUM_SERVICE_NAME,
        environment: frontendPropertiesStore.getFrontendPropertyApmEnvironment(),
        serverUrl: frontendPropertiesStore.getFrontendPropertyApmServerUrl(),
        // Set service version (required for sourcemap feature)
        serviceVersion: frontendPropertiesStore.getFrontendPropertyApmServiceVersion(),
      });
    return storeLoadSuccess;
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        frontendPropertiesStore.requestFrontendProperties().then(initFusionApm),
        rolesStore.requestRoles(),
        tenantConfigStore.requestTenantConfig(),
        meStore.requestMe(),
      ]).then((successArray) => {
        setInitialised(successArray.every((s) => s === true));
      });
    };

    if (!initialised) {
      fetchData();
    }
  });

  if (!initialised) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        <Toaster />
        <ConditionalLayout />
      </Router>
    </ErrorBoundary>
  );
});

const ConditionalLayout = observer(() => {
  const { uiStore } = useStore();
  const { sideMenuOpen } = uiStore;

  const location = useLocation();
  const { pathname } = { ...location };

  const isFullScreenModal = (pathname) =>
    Object.keys(ROUTES_FULLSCREEN_MODAL).some((route) => {
      const pattern = ROUTES_FULLSCREEN_MODAL[route];
      return matchPath({ path: pattern }, pathname);
    });

  const isRouteFullScreenModal = useMemo(() => isFullScreenModal(pathname), [pathname]);

  return (
    <BaseLayout
      renderHeader={() => !isRouteFullScreenModal && <Header onMenuButtonClick={() => uiStore.toggleSideMenu()} />}
      renderSidebar={() => !isRouteFullScreenModal && <Sidebar expanded={sideMenuOpen} />}
      renderMain={() => <Main />}
    />
  );
});

const Main = () => {
  const location = useLocation();
  const { pathname } = { ...location };
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { meStore } = useStore();

  useEffect(() => {
    const teamId = searchParams.get("teamId");

    if (teamId) {
      const team = meStore.getTeamById(teamId);
      if (team === undefined) {
        // Get last segment of url
        const lastSegment = `/${pathname.substring(pathname.lastIndexOf("/") + 1)}`;

        // Only show modal if in the manage/list pages
        if (lastSegment === ROUTES.WORKFLOWS || lastSegment === ROUTES.LOOKUPS || lastSegment === "/") {
          modalInstance(MODAL_IDS.ERROR_TEAM_NOT_FOUND).show();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmModal = () => {
    const defaultTeam = meStore.getDefaultTeam();

    if (defaultTeam) {
      const initialPath = `/${pathname.split("/")[1]}`;
      navigate({ pathname: initialPath, search: `?${createSearchParams({ teamId: defaultTeam.id })}` });
    }
  };

  return (
    <>
      <FusionRoutes />
      <ErrorModal
        modalId={MODAL_IDS.ERROR_TEAM_NOT_FOUND}
        heading="Sorry, you don't have access to this team workspace!"
        text="Please contact your administrator to be added as a member of the team."
        confirm="Okay"
        onConfirm={handleConfirmModal}
      />
    </>
  );
};

export default App;
