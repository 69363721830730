import { useState } from "react";
import { Text, Checkbox, Radio } from "@fundrecs/ui-library";
import { WORKFLOW_TRIGGER_SCHEDULE_TYPES } from "utils/workflows/enums";
import { v4 as uuidv4 } from "uuid";
import { TriggerTimesView } from "./TriggerTimesView";
import { generateScheduledDaysString } from "components/workflows/reusable";
import styles from "./ScheduledTriggerView.module.scss";

const ScheduledTriggerView = ({
  scheduleType,
  setScheduleType,
  daysOfWeek,
  setDaysOfWeek,
  scheduledHour,
  setScheduledHour,
  scheduledMinute,
  setScheduledMinute,
  recurringValue,
  setRecurringValue,
  recurringInterval,
  setRecurringInterval,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const [isAllDaysChecked, setIsAllDaysChecked] = useState(true);
  const [selectedDays, setSelectedDays] = useState(daysOfWeek); // This is used to control the UI only
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const availableDaysOfWeek = [
    { value: 1, label: "Mon", id: uuidv4() },
    { value: 2, label: "Tue", id: uuidv4() },
    { value: 3, label: "Wed", id: uuidv4() },
    { value: 4, label: "Thu", id: uuidv4() },
    { value: 5, label: "Fri", id: uuidv4() },
    { value: 6, label: "Sat", id: uuidv4() },
    { value: 7, label: "Sun", id: uuidv4() },
  ];

  const handleDaysChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // If any day selected, unselect All
      if (value === "all") {
        setDaysOfWeek([1, 2, 3, 4, 5, 6, 7]);

        setIsAllDaysChecked(!isAllDaysChecked);
        setSelectedDays([]);
      } else {
        setDaysOfWeek((prev) => [...prev, parseInt(value)]);

        setIsAllDaysChecked(false);
        setSelectedDays((prev) => [...prev, parseInt(value)]);
      }
    } else {
      setSelectedDays((prev) => prev.filter((x) => x !== parseInt(value)));
      setDaysOfWeek((prev) => prev.filter((x) => x !== parseInt(value)));
    }
  };

  return (
    <>
      <div className="mb-24">
        <Text size="sm" weight="medium" variant="secondary">
          Run workflow
        </Text>
        <div className="pt-12 pb-20 d-flex">
          <form className="d-flex">
            <Radio
              checked={scheduleType === WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC}
              onClick={() => {
                setScheduleType(WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC);
              }}
            />
            <div className="pl-12 pr-32">
              <div className="pt-4">
                <Text variant="primary" size="sm" weight="regular" element="div">
                  At a specific time
                </Text>
              </div>
            </div>

            <Radio
              checked={scheduleType === WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING}
              onClick={() => {
                setScheduleType(WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING);
              }}
            />
          </form>
          <div className="pl-12">
            <div className="pt-4">
              <Text variant="primary" size="sm" weight="regular" element="div">
                At a recurring time
              </Text>
            </div>
          </div>
        </div>
        <TriggerTimesView
          {...{
            scheduleType,
            scheduledHour,
            setScheduledHour,
            scheduledMinute,
            setScheduledMinute,
            recurringValue,
            setRecurringValue,
            recurringInterval,
            setRecurringInterval,
            startTime,
            setStartTime,
            endTime,
            setEndTime,
          }}
        />
      </div>
      <div className="mb-12">
        <Text size="sm" weight="medium" variant="secondary">
          On days
        </Text>
        <div className="d-flex mt-12">
          {availableDaysOfWeek.map((day, index) => (
            <div key={Math.random()} className={[styles.roundCheckbox, "pr-12"].join(" ")}>
              <Checkbox id={day.id} onClick={handleDaysChange} value={day.value} checked={selectedDays.includes(day.value)} />
              <label htmlFor={day.id}>
                <Text size="xs" weight="medium" element="span" variant={selectedDays.includes(day.value) ? "link" : "muted"}>
                  {day.label}
                </Text>
              </label>
            </div>
          ))}
        </div>
      </div>
      {WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC === scheduleType && (
        <div className="mb-12">
          <Text size="sm" weight="medium" variant="secondary">
            Summary: Trigger workflow to run at {scheduledHour + ":" + scheduledMinute} ({timeZone}) {generateScheduledDaysString(selectedDays)}
            {daysOfWeek.length > 0 && "."}
          </Text>
        </div>
      )}
      {WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING === scheduleType && (
        <div className="mb-12">
          <Text size="sm" weight="medium" variant="secondary">
            Summary: Trigger workflow to run every {recurringValue} {recurringInterval + " from "}
            {startTime} to {endTime} {generateScheduledDaysString(selectedDays)}
            {daysOfWeek.length > 0 && "."}
          </Text>
        </div>
      )}
    </>
  );
};

export { ScheduledTriggerView };
