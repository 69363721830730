import { useContext } from "react";
import { Text, Button, IconAdd, Heading } from "@fundrecs/ui-library";
import { observer } from "mobx-react-lite";
import { useStore } from "store/Store";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_IMPORT_RULE_OBJECT, WORKFLOW_STAGES } from "utils/workflows/enums";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const InputEditSectionHeader = observer(() => {
  const { workflowsStore } = useStore();
  const { workflow, editingStage } = useContext(WorkflowConfigurationContext);

  const inputsAdded = workflow.getCurrentWorkflowTemplates().filter((template) => {
    return template.saved ?? template;
  });

  const addNewInput = () => {
    const importRuleUuid = uuidv4();
    workflow.addNewCurrentWorkflowImportRule({
      ...DEFAULT_IMPORT_RULE_OBJECT,
      ...{ importRuleName: "Input_".concat(workflow.getCurrentWorkflowTemplates().length + 1), importRuleUuid: importRuleUuid },
    });
    workflowsStore.addEditableInput(importRuleUuid);
  };

  return (
    <div className="d-flex  pb-12">
      <div className="me-auto">
        <Heading variant="caption3" align="right" element="span">
          Inputs: {workflowsStore.getReadOnlyWorkflow() !== null ? inputsAdded.length : 0}
        </Heading>
      </div>
      <div>
        {editingStage === WORKFLOW_STAGES.INPUT && (
          <span className="ml-20">
            <Button
              size="md"
              color="primary-secondary"
              onClick={() => {
                addNewInput();
              }}
            >
              <IconAdd className="btn-md-svg" />
              <Text size="sm" weight="medium">
                New input
              </Text>
            </Button>
          </span>
        )}
      </div>
    </div>
  );
});

export { InputEditSectionHeader };
