import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { FullScreenModal, modalInstance } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { ModalTabsSection } from "./ModalTabsSection";
import { SetupLookup } from "./setup/SetupLookup";
import { SetupTable } from "./setup/SetupTable";
import { MODAL_IDS } from "../../../utils/lookups/enums";

/**
 * The CreateNewLookup component sets the initial state and renders
 * all the components required to create a new lookup.
 *
 * Props are passed down to the children as needed.
 */
const CreateLookup = observer(({ CREATE_NEW_LOOKUP_MODAL_ID, teamId }) => {
  //Default initial state for a new Lookup
  const initialState = {
    lookupEntriesRequireApproval: false,
    lookupEntriesAreCaseSensitive: false,
    lookupEntriesCanBeOverwritten: false,
    lookupTypeSaved: false,
    lookupName: "",
    lookupDescription: "",
    lookupDescriptionWarning: false,
    lookupNameWarning: false,
    dropdownWarning: false,
    step: { name: "STEP 01", index: 1 },
    tableType: "",
    teamId: teamId,
  };

  const [state, setState] = useState({ ...initialState });
  const { lookupName, tableType, lookupDescription } = state;
  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
  ];
  /**
   * Update existing state with new state object
   * @param {newState} object
   */
  const dispatch = (object) => {
    setState({ ...state, ...object });
  };

  /**
   * Close modal and reset fields to initial state
   */
  const closeModalAndResetFields = () => {
    dispatch(initialState);
    modalInstance(CREATE_NEW_LOOKUP_MODAL_ID).toggle();
  };

  /**
   * Check if current step string equals value stored in state.
   * @param {String} value
   * @returns {Boolean}
   */
  const equalsOpenStep = (value) => {
    const {
      step: { index },
    } = state;
    return index === value;
  };

  const saveLookupTableType = () => {
    let updateState = {};
    if (tableType !== "" && lookupName !== "" && lookupDescription !== "") {
      updateState.dropdownWarning = false;
      updateState.lookupDescriptionWarning = false;
      updateState.lookupNameWarning = false;
      updateState.lookupTypeSaved = true;
      updateState.step = { name: "STEP 02", index: 2 };
    }
    if (lookupName === "") {
      updateState.lookupNameWarning = true;
    }
    if (tableType === "") {
      updateState.dropdownWarning = true;
    }
    if (lookupDescription === "") {
      updateState.lookupDescriptionWarning = true;
    }

    updateState.teamId = teamId;
    dispatch(updateState);
  };

  return (
    <>
      <FullScreenModal modalId={CREATE_NEW_LOOKUP_MODAL_ID}>
        <ModalHeader
          dispatch={dispatch}
          onCloseClick={closeModalAndResetFields}
          CREATE_NEW_LOOKUP_MODAL_ID={CREATE_NEW_LOOKUP_MODAL_ID}
          title="Create New Lookup"
        />
        <ModalTabsSection {...{ steps, state }} />

        <div className="ml-72 mr-72 mt-48 text-left">
          {equalsOpenStep(1) && <SetupLookup state={state} dispatch={dispatch} saveLookupTableType={saveLookupTableType} />}
          {equalsOpenStep(2) && <SetupTable state={state} tableType={tableType} dispatch={dispatch} closeModalAndResetFields={closeModalAndResetFields} />}
        </div>
      </FullScreenModal>
    </>
  );
});

CreateLookup.propTypes = {
  CREATE_NEW_LOOKUP_MODAL_ID: PropTypes.oneOf([MODAL_IDS.CREATE_NEW_LOOKUP_MODAL_ID]),
  teamId: PropTypes.string,
};

CreateLookup.defaultProps = {
  CREATE_NEW_LOOKUP_MODAL_ID: PropTypes.oneOf([MODAL_IDS.CREATE_NEW_LOOKUP_MODAL_ID]),
};

export { CreateLookup };
