import React from "react";
import { Text } from "@fundrecs/ui-library";

import styles from "./SummarySettingsList.module.scss";

const SummarySettingsList = ({ children }) => <dl className={styles.container}>{children}</dl>;

const SummarySetting = ({ name, value }) => {
  return (
    <div className={styles.item}>
      <span className={[`${styles.labels}`, "mr-4"].join(" ")}>
        <Text element="dt" size="sm" variant="tertiary" weight="regular">
          {name.concat(" : ")}
        </Text>
      </span>
      <span>
        <Text element="dd" size="sm" variant="secondary">
          {value || "–"}
        </Text>
      </span>
    </div>
  );
};

export { SummarySettingsList, SummarySetting };
