import { Text } from "@fundrecs/ui-library";
import { AuditItem } from "./AuditItem";
import { SummaryItemList } from "./SummaryItemList";

import styles from "./DropdownPanelCell.module.scss";

const DropdownPanelCell = (rowNode) => {
  const { description, createdDate, createdBy, lastModifiedDate, lastModifiedBy, assignedToWorkflows, mappedToTemplates } = rowNode.data;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <Text size="xs" weight="medium" element="p" variant="primary">
          Description
        </Text>
        <Text size="xs" weight="regular" element="p" variant="secondary">
          {description}
        </Text>
        <div>
          <SummaryItemList name="Assigned to workflow" list={assignedToWorkflows} />
          <SummaryItemList name="Mapped to template(s)" list={mappedToTemplates} />
        </div>
      </div>
      <div>
        {lastModifiedDate !== null && lastModifiedDate !== undefined ? (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Last edited by
            </Text>
            <AuditItem user={lastModifiedBy.name} timestamp={lastModifiedDate} />
          </>
        ) : (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Created by
            </Text>
            <AuditItem user={createdBy.name} timestamp={createdDate} />
          </>
        )}
      </div>
    </div>
  );
};

export { DropdownPanelCell };
