import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { PRIORITY_VALUES } from "utils/lookups/enums";

const LookupPriorityEditor = forwardRef((props, ref) => {
  //Set default priority to 3 (Medium)
  const { value: initialValue = 3, stopEditing } = props;
  const [value, setValue] = useState(initialValue);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useEffect(() => {
    if (value !== initialValue) {
      stopEditing();
    }
  }, [value]);

  return (
    <select
      onChange={(event) => {
        setValue(event.target.value);
      }}
      className="form-select"
      value={value}
    >
      {Object.entries(PRIORITY_VALUES).map(([key, value]) => {
        return (
          <option key={key} value={key}>
            {value}
          </option>
        );
      })}
    </select>
  );
});

LookupPriorityEditor.propTypes = {
  value: PropTypes.number,
  stopEditing: PropTypes.func,
};

LookupPriorityEditor.defaultProps = {};

export { LookupPriorityEditor };
