import style from "./FixedFooter.module.scss";
const FixedFooter = ({ children }) => {
  return (
    <div className="pt-48 ">
      <div className={style.footer}>
        <div className="d-flex flex-row-reverse">{children}</div>
      </div>
    </div>
  );
};

export { FixedFooter };
