import React from "react";
import { VerticalMenuDropdown, VerticalMenuListItem } from "@fundrecs/ui-library";

const ActionMenu = ({ items, onItemClick }) => (
  <VerticalMenuDropdown>
    {items.map(({ key, label, disabled }) => (
      <VerticalMenuListItem key={key} onClick={() => onItemClick(key)} text={label} disabled={disabled} />
    ))}
  </VerticalMenuDropdown>
);

export { ActionMenu };

