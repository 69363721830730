import React from "react";

import { EmptyWorkflowRun, EmptyQueuedFiles, Heading, R, C, Text, Button, IconAdd, EmptyWorkflow } from "@fundrecs/ui-library";
import { handleCreateWorkflowClick } from "./WorkflowsList/WorkflowsList";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { useStore } from "../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const NoWorkflows = () => {
  const pointerStyle = {
    pointerEvents: "auto",
  };

  const { rolesStore } = useStore();
  const teamId = useTeamId();

  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyWorkflow />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No workflows
          </Heading>
        </C>
      </R>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_CREATE])}>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                Click "+ New workflow" button below
              </Text>
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                to add a new workflow
              </Text>
            </div>
          </C>
        </R>
        <R>
          <C>
            <div className="pt-32 pb-32" style={pointerStyle}>
              <Button
                className={pointerStyle}
                onClick={() => {
                  handleCreateWorkflowClick();
                }}
                size="md"
              >
                <IconAdd className={"btn-md-svg"} />
                <Text size="sm">New workflow</Text>
              </Button>
            </div>
          </C>
        </R>
      </AuthWrapper>
    </>
  );
};

const NoWorkflowRunResults = () => {
  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyWorkflowRun />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No run results for this workflow
          </Heading>
        </C>
      </R>
      <R>
        <C>
          <div className="pt-16 pb-32">
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              Upload a file to trigger the workflow to run
            </Text>
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              and generate reports
            </Text>
          </div>
        </C>
      </R>
    </>
  );
};

const NoQueuedFiles = () => {
  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyQueuedFiles />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No queued files
          </Heading>
        </C>
      </R>
      <R>
        <C>
          <div className="pt-16 pb-32">
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              You can upload files above, or to any of the
            </Text>
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              workflow's sources, to add them to this queue
            </Text>
            <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
              for the workflow to process when it runs.
            </Text>
          </div>
        </C>
      </R>
    </>
  );
};
export { NoWorkflows, NoWorkflowRunResults, NoQueuedFiles };
