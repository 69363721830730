import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { Table } from "components/ag-grid/Ag-grid";
import { CreatedEditedDate } from "components/ag-grid/createdEditedDate/CreatedEditedDate";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";
import { LookupPriorityEditor } from "../lookupPriority/LookupPriorityEditor";
import { LookupPriorityRenderer } from "../lookupPriority/LookupPriorityRenderer";
import { assembleData, assembleColumnHeaders } from "../utils/transformData";
import { LookupsTableContext } from "../LookupsTable";
import { LookupTagsRenderer } from "../lookupTags/TagsRenderer";
import { LookupTagsEditor } from "../lookupTags/LookupTagsEditor";
import { PRIORITY_VALUES } from "utils/lookups/enums";
import { useStore } from "store/Store";

const ContainsTable = observer(({ gridOptions, verticalMenu }) => {
  const { lookupTable } = useContext(LookupsTableContext);
  const { lookupsStore } = useStore();
  const { teamId, uuid, columns: tableColumns } = lookupTable;
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    if (uuid && teamId) {
      lookupsStore.getTableRows({ teamId, tableUuid: uuid }).then(({ data }) => {
        setRowData(assembleData(data.reverse()));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnBase = {
    sortable: false, // [FS-1511] Sorting disabled for all columns as add/edit expects index 0
  };

  const columns = [
    {
      ...columnBase,
      headerName: "",
      suppressMenu: true,
      field: "rowStartSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "left",
      width: 50,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => (!params.api.addRow ? true : false),
    },
    {
      ...columnBase,
      headerName: "Priority",
      field: "priority",
      rowDrag: false,
      wrapText: false,
      editable: true,
      minWidth: 150,
      cellRenderer: LookupPriorityRenderer,
      cellEditor: LookupPriorityEditor,
      filter: "agSetColumnFilter",
      filterParams: {
        valueGetter: function (params) {
          return PRIORITY_VALUES[params.data.priority];
        },
        textFormatter: function (r) {
          return r;
        },
      },
    },
    {
      ...columnBase,
      headerName: "Status",
      resizable: true,
      field: "status",
      minWidth: 150,
      rowDrag: false,
      wrapText: false,
      cellRenderer: StatusCell,
    },
    {
      ...columnBase,
      headerName: "Time Created",
      resizable: true,
      field: "createdAt",
      minWidth: 150,
      rowDrag: false,
      editable: false,
      wrapText: false,
      // [FS-1511] Must be sorted descending by date so newest row on top (Add/edit expects index 0)
      sort: "desc",
      cellRenderer: CreatedEditedDate,
    },
    {
      ...columnBase,
      headerName: "Tags",
      field: "tags",
      rowDrag: false,
      editable: (params) => params.data.status === "Draft",
      cellRenderer: LookupTagsRenderer,
      cellEditor: LookupTagsEditor,
      resizable: true,
      detailRowAutoHeight: true,
      wrapText: true,
      minWidth: 400,
    },
    ...assembleColumnHeaders({ tableColumns, teamId }),
    verticalMenu,
  ];

  return <Table columns={columns} rowData={rowData} agGridOptions={gridOptions} />;
});

// Specifies the types for props:
ContainsTable.propTypes = {
  gridOptions: PropTypes.object,
  verticalMenu: PropTypes.object,
};

// Specifies the default values for props:
ContainsTable.defaultProps = {
  gridOptions: {},
  verticalMenu: {},
};

export { ContainsTable };
