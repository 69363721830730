import React from "react";
import "../TbApp.css";
import CommandUtils from "../utils/CommandUtils";
import HttpMediator from "../utils/http/HttpMediator";
import CommandArgsModel from "../utils/domain/CommandArgsModel";
import { tbAppChannel } from "../utils/communication/AppChannels";
import { List, ListItem, ListItemSecondaryAction, IconButton, Paper } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import LockIcon from "@material-ui/icons/Lock";
import UndoIcon from "@material-ui/icons/Undo";

import { appSingleton } from "../utils/AppSingleton";
import { loadingChannel } from "../utils/communication/AppChannels";
import { meStore } from "../../../store/Store";
import { checkAllRequired } from "../../../utils/authorization";

export default class CommandStack extends React.Component {
  constructor(props) {
    super(props);
    this.getAppliedRuleList = this.getAppliedRuleList.bind(this);
    this.stepClicked = this.stepClicked.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.selectAndTransform = this.selectAndTransform.bind(this);
    this.showArgsAndFetchData = this.showArgsAndFetchData.bind(this);
  }

  render() {
    if (this.props.disabled) return null;
    if (this.props.uiCommandStack.length == 0) return <List className="command-stack-view large-panel"></List>;
    return <List className="command-stack-view large-panel">{this.getAppliedRuleList()}</List>;
  }

  componentWillMount() {
    loadingChannel.subscribe("loading", (object) => {
      this.setState({ isLoading: true });
    });
    loadingChannel.subscribe("finishedLoading", (object) => {
      this.setState({ isLoading: false });
    });
  }

  // react event is showGeneralCommandsMenu
  showArgsAndFetchData(reactEventObject, event) {
    const { reactEvent, index } = reactEventObject;

    event.stopPropagation();
    let currentstep = appSingleton.step;

    if (currentstep == undefined) {
      currentstep = -1;
    } else {
      currentstep = parseInt(currentstep);
    }

    let commandClickedIndex = index;

    if (commandClickedIndex == undefined) {
      commandClickedIndex = event.target.dataset.index;
    }
    let commandStackModelClicked = this.props.uiCommandStack[commandClickedIndex];
    if (commandStackModelClicked == undefined) return;
    let uiCommandSelected = CommandUtils.findUiCommand(commandStackModelClicked.name.peName);
    let commandArgsWithData = {
      uiCommand: uiCommandSelected,
      userInputargs: commandStackModelClicked.argsUser,
    };
    if (commandClickedIndex == currentstep) {
      tbAppChannel.publish(reactEvent, {
        commandArgsModel: new CommandArgsModel(commandArgsWithData),
      });
    } else {
      HttpMediator.fetchResultAndStack(commandClickedIndex, () => {
        appSingleton.step = parseInt(commandClickedIndex);

        tbAppChannel.publish(reactEvent, {
          commandArgsModel: new CommandArgsModel(commandArgsWithData),
        });
      });
    }
  }

  stepClicked(commandClickedIndex) {
    let commandStackModelClicked = this.props.uiCommandStack[commandClickedIndex];
    if (commandStackModelClicked == undefined) return;
    let uiCommandSelected = CommandUtils.findUiCommand(commandStackModelClicked.name.peName);
    let commandArgsWithData = {
      uiCommand: uiCommandSelected,
      userInputargs: commandStackModelClicked.argsUser,
    };
    HttpMediator.fetchResultAndStack(commandClickedIndex, () => {
      appSingleton.step = parseInt(commandClickedIndex);

      tbAppChannel.publish("commandResultRefresh", {
        commandArgsModel: new CommandArgsModel(commandArgsWithData),
      });
    });
  }

  // react event is showSelectAndTransform
  selectAndTransform(reactEvent, commandClickedIndex) {
    HttpMediator.fetchResultAndStack(commandClickedIndex, () => {
      appSingleton.step = parseInt(commandClickedIndex);

      tbAppChannel.publish(reactEvent, commandClickedIndex);
    });
  }

  getAppliedRuleList() {
    let step = appSingleton.step;

    if (step == undefined) {
      step = -1;
    } else {
      step = parseInt(step);
    }
    if (step == -1) step = this.props.uiCommandStack.length - 1;
    //keyIndex is used for the key attribute on each of the listItems. Must be unique
    let keyIndex = 0;
    let commandStackItems = this.props.uiCommandStack.map((commandStack, index) => {
      let stepElevation = 1;
      if (index == step) {
        stepElevation = 6;
      }
      let commandNameTextAndIndex = index + 1 + ".  " + this.getStepUIName(commandStack);
      let opacity = step == index ? 1 : 0.5;
      keyIndex = index;

      let hasParams = Object.keys(commandStack.argsUser).length > 0;

      const CommandStackLaunch = () => {
        if ((appSingleton.readOnly || commandStack.name.peName == "deleteColumn") && hasParams) {
          return (
            <IconButton
              title="View command settings"
              data-index={index}
              onClick={this.showArgsAndFetchData.bind(this, { reactEvent: "commandParamsRefresh", index: index })}
            >
              <LaunchIcon />
            </IconButton>
          );
        }
      };

      const CommandStackModeEdit = () => {
        if (!appSingleton.readOnly && hasParams) {
          return (
            <span>
              <IconButton
                title="Edit this command"
                data-index={index}
                onClick={this.showArgsAndFetchData.bind(this, { reactEvent: "commandParamsRefresh", index: index })}
                disabled={!checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:edit"])}
              >
                <EditIcon />
              </IconButton>
            </span>
          );
        }
      };

      const CommandStackAdd = () => {
        if (!appSingleton.readOnly && step == index) {
          return (
            <span>
              <IconButton
                title="Add a command here"
                data-index={index}
                onClick={this.showArgsAndFetchData.bind(this, { reactEvent: "showGeneralCommandsMenu", index: index })}
                disabled={!checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:edit"])}
              >
                <AddIcon />
              </IconButton>
            </span>
          );
        }
      };

      const CommandStackDelete = () => {
        if (commandStack.name.peName != "parseFile" && !appSingleton.readOnly && step == index) {
          return (
            <IconButton
              title="Delete command"
              onClick={(e) => {
                e.stopPropagation();
                this.deleteRule(index);
              }}
              disabled={!checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:edit"])}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
      };

      return (
        <ListItem
          data-index={index}
          key={index}
          title={commandNameTextAndIndex}
          button
          onClick={(event) => {
            this.stepClicked(index);
          }}
          className="padding-0"
          style={{ opacity: opacity }}
        >
          <Paper className="listItemPaper" elevation={stepElevation}>
            <div className="font-100 max-width-65-pc cellWithEllipsis padding-5">{commandNameTextAndIndex}</div>
            <ListItemSecondaryAction className="margin-right-minus-5" style={{ right: "0px" }}>
              {CommandStackLaunch()}
              {CommandStackModeEdit()}

              {CommandStackAdd()}
              {CommandStackDelete()}
            </ListItemSecondaryAction>
          </Paper>
        </ListItem>
      );
    });

    const CommandStackLockUndo = () => {
      if (appSingleton.readOnly) {
        return <LockIcon title="Template is locked">lock</LockIcon>;
      } else {
        return (
          <IconButton
            className="margin-right-minus-5 margin-bottom-15"
            title="Undo last change"
            onClick={() => {
              HttpMediator.undoCommand();
            }}
            disabled={this.state.isLoading}
          >
            <UndoIcon />
          </IconButton>
        );
      }
    };

    //Add undo button to the top of the list
    keyIndex++;
    commandStackItems.unshift(
      <ListItem key={keyIndex}>
        <ListItemSecondaryAction style={{ right: "5px" }}>{CommandStackLockUndo()}</ListItemSecondaryAction>
      </ListItem>
    );
    return commandStackItems;
  }

  getStepUIName(commandStack) {
    let commandNameText = commandStack.name.uiName;
    if (commandStack.name.peName == "parseFile") {
      commandNameText = commandStack.name.uiName + " >> " + this.getParserUiName(commandStack.argsUser.parserName);
    } else if (commandStack.name.peName == "selectAndTransform") {
      let paramUI = "  (" + commandStack.argsUser.transformOperation + ")";
      if (commandStack.argsUser.transformOperation == "NOP") {
        if (commandStack.argsUser.transformChain) {
          if (
            commandStack.argsUser.transformChain.startsWith("findDuplicateAndCopyLargerValue") ||
            commandStack.argsUser.transformChain.startsWith("SwapColumns") ||
            commandStack.argsUser.transformChain.startsWith("swapColumnsInOriginalRows")
          ) {
            paramUI = "";
          } else {
            paramUI = "  (" + commandStack.argsUser.transformChain.substring(0, commandStack.argsUser.transformChain.indexOf(" ")) + ")";
          }
        } else {
          paramUI = commandStack.argsUser.mode == "Modify" || commandStack.argsUser.mode == "Merge" ? " (Incomplete)" : "";
        }
      }
      commandNameText = this.getModeUiName(commandStack.argsUser.mode) + paramUI;
    }
    return commandNameText;
  }

  getModeUiName(mode) {
    if (mode == "CopyAndModifyRows") {
      return "Copy and modify rows";
    }
    if (mode == "SwapColumns") {
      return "Swap Columns";
    }
    if (mode == "findDuplicateAndCopyLargerValue") {
      return "Find duplicate and copy larger value";
    }

    return mode;
  }

  deleteRule(index) {
    let deleteParams = {};
    deleteParams["commandIndex"] = index;
    deleteParams["updateType"] = "deleteCommand";
    HttpMediator.updateCommand(deleteParams);
    //If command to be deleted is the current step, clear the selectAndTransform data in TbApp, ensures edit panel is closed if it were open for this rule
    if (appSingleton.step == index) {
      this.props.clearSelectAndTransformData();
    }
  }

  getParserUiName(parserName) {
    let parserDesc = appSingleton.parsers.find((parserDesc) => parserDesc.parserName == parserName);
    return parserDesc.uiName;
  }
}
