/**
 * Created by Naman on 10/18/2016.
 */
import { appSingleton } from "../../AppSingleton";
import SelectLineItem from "./SelectLineItem";
import IndexedValue from "./IndexedValue";
import PropTypes from "prop-types";

export default class SelectBackendModel {
  constructor(props) {
    this.peName = props.peName;
    this.arg1 = props.arg1;
    this.arg2 = props.arg2;
  }

  /**
   * Converts SelectBackendModel to SelectLineItem. SelectLineItem fills in all the indexes using header form table context on which it is called*
   * @param selectBackendModel
   * @param tableHeaders
   * @returns {SelectLineItem}
   */
  getSelectLineItem(tableHeaders) {
    let subCommandPeName = this.peName;

    let selectSubCommand = appSingleton.selectOptions.find(
      (selectSubCommand) => selectSubCommand.peName === subCommandPeName
    );
    let selectLineItem = new SelectLineItem({ subCommand: selectSubCommand });

    if (subCommandPeName == "ALL") {
      selectLineItem.subCommand = { uiName: "Select All Rows" };
      selectLineItem.indexedArg1 = new IndexedValue({ value: "", index: 0 });
      selectLineItem.indexedArg2 = new IndexedValue({ value: "", index: 0 });
      return selectLineItem;
    }

    if (selectSubCommand.isArg1Index) {
      let headerValue = tableHeaders[this.arg1];
      let indexedArg1 = new IndexedValue({
        value: headerValue,
        index: this.arg1,
      });
      selectLineItem.indexedArg1 = indexedArg1;
    } else {
      selectLineItem.indexedArg1 = new IndexedValue({ value: this.arg1 });
    }
    if (selectSubCommand.isArg2Index) {
      let headerValue = tableHeaders[this.arg2];
      let indexedArg2 = new IndexedValue({
        value: headerValue,
        index: this.arg2,
      });
      selectLineItem.indexedArg2 = indexedArg2;
    } else {
      selectLineItem.indexedArg2 = new IndexedValue({ value: this.arg2 });
    }
    return selectLineItem;
  }
}

SelectBackendModel.propTypes = {
  peName: PropTypes.string,
  arg1: PropTypes.string,
  arg2: PropTypes.string,
};
