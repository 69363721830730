import { TriggerSection } from "./TriggerSection";
import { useFusion20WorkflowState } from "../../useFusion20WorkflowState";
import { useStore } from "store/Store";

const TriggerSummaryView = ({ workflow }) => {
 
  const { workflowsStore } = useStore();
  const triggers = workflowsStore.getReadOnlyWorkflowTriggers();
  const [editingStage] = useFusion20WorkflowState(workflow);

  return (
    <>
      {triggers.map((trigger) => {
        return <TriggerSection trigger={trigger} workflow={workflow} editingStage={editingStage} />;
      })}
    </>
  );
};

export { TriggerSummaryView };