import { WarningConfirmationModal, modalInstance, ModalSection, Text } from "@fundrecs/ui-library";
import { useState } from "react";
import { MODAL_IDS } from "utils/templates/enums";
import { templatesStore } from "store/Store";
import { CannotDeleteTemplateModal } from "./CannotDeleteTemplateModal";

const DeleteTemplateModal = ({ teamId, template, onConfirm = false }) => {
  const { name = "", templateUuid = "" } = template;

  const [workflows, setWorkflows] = useState([]);

  const handleDeleteTemplate = async () => {
    const response = await templatesStore.deleteTemplate(teamId, templateUuid, name);
    const { success } = response;
    if (success) {
      templatesStore.loadTemplates(teamId);
      if (onConfirm) {
        onConfirm();
      }
    } else {
      const { fieldValue } = response;

      if (fieldValue.length > 0) {
        setWorkflows(fieldValue);
        modalInstance(MODAL_IDS.CANNOT_DELETE_TEMPLATE).toggle();
      }
    }
  };

  return (
    <>
      <CannotDeleteTemplateModal modalId={MODAL_IDS.CANNOT_DELETE_TEMPLATE} workflows={workflows} name={name} teamId={teamId} />
      <WarningConfirmationModal
        modalId={MODAL_IDS.DELETE_TEMPLATE}
        heading={`Are you sure you want to delete template '${name}' ?`}
        text={
          <>
            <ModalSection>
              <Text size="sm" variant="secondary" weight="regular" element="div">
                Deleting this template may affect any workflows your team want to assign this template to.
              </Text>
            </ModalSection>
            <ModalSection>
              <Text size="sm" variant="secondary" weight="regular" element="div">
                This action is <b>permanent</b> and cannot be reversed.
              </Text>
            </ModalSection>
          </>
        }
        cancel="Cancel"
        confirm="Delete template"
        onConfirm={handleDeleteTemplate}
      />
    </>
  );
};

export { DeleteTemplateModal };
