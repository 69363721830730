import { Modal, ModalBody, ModalSection, ModalHeader, ModalText, Button, Text, ModalFooter, ModalError, modalInstance } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";

const PendingTemplateRejectAndExitModal = ({ name, onConfirm }) => {
  return (
    <Modal modalId={MODAL_IDS.PENDING_TEMPLATE_REJECT_AND_EXIT}>
      <ModalBody>
        <ModalSection>
          <ModalError />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={`Are you sure you want to reject template '${name}' ?`} />
          <ModalText text="This template can be edited and re-submitted for approval. This action cannot be undone." />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(MODAL_IDS.PENDING_TEMPLATE_REJECT_AND_EXIT).toggle();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onConfirm();
          }}
        >
          <Text size="sm">Reject & exit</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { PendingTemplateRejectAndExitModal };
