import React from "react";
import { Text, IconAlertMessage } from "@fundrecs/ui-library";
import styles from "./EntityField.module.scss";

/**
 * A field-like component representing an entity added to the Workflow
 * i.e. an Output Mapping or Template Builder Template
 *
 * The field displays the entity name and controls for modifying the entity (edit, delete etc)
 *
 * @param {string} name - Entity name
 * @param {function} onEditClick - a callback to be called when edit button is clicked
 * @param {function} onDeleteClick - a callback to be called when delete button is clicked
 */
const EntityField = ({ name, onEditClick, onDeleteClick }) => (
  <div className={styles.container}>
    <div>{name}</div>
    {/* NOTE: "edit" control removed for MVP */}
    {/* <button className={styles.actionButton} onClick={onEditClick}>
            <Text variant="link" weight="regular">edit</Text>
        </button> */}
    <button className={styles.actionButton} onClick={onDeleteClick}>
      <Text variant="link" weight="regular">
        delete
      </Text>
    </button>
  </div>
);

/**
 * A field-like component representing an entity added to the Workflow
 * i.e. an Input Mapping or Template Builder Template
 *
 * The field displays the entity name and controls for modifying the entity (edit, delete etc)
 *
 * @param {string} name - Entity name
 * @param {boolean} error - Boolean to show error or not

 */
const EntityInputField = ({ name, error, children }) => (
  <div className={styles.containerWrapper}>
    <div className={styles.containerInputEditView}>
      <Text variant="primary" weight="medium" size="sm">
        {name}
        <span className="pl-12">
          {error && (
            <Text variant="error" weight="regular" size="sm">
              <IconAlertMessage />
              Template must be mapped to output mapping in step 1
            </Text>
          )}
        </span>
      </Text>
      {children}
    </div>
  </div>
);

export { EntityField, EntityInputField };
