import { useContext } from "react";
import PropTypes from "prop-types";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { lookupsStore, meStore } from "store/Store";
import { LookupsTableContext } from "../LookupsTableType/LookupsTable";

const DeleteLookupsModal = ({ modalId }) => {
  const { gridApi, lookupTable, selectedRowsCount, verticalMenuRow, setVerticalMenuRow } = useContext(LookupsTableContext);
  const { teamId, uuid } = lookupTable || {};

  const handleConfirmation = async () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length > 0 || verticalMenuRow) {
      const itemsToUpdate = selectedRows.length > 0 ? selectedRows : [verticalMenuRow];

      const lookupIds = itemsToUpdate.map((item) => item.id);
      const requestObject = { rejectedBy: meStore.getEmail(), rowIdList: lookupIds };

      const response = await lookupsStore.deleteTableRows(uuid, teamId, { ...requestObject });
      const { success } = response || {};

      if (success) {
        gridApi.applyTransactionAsync({ remove: itemsToUpdate });
      }
    }
    if (verticalMenuRow) {
      setVerticalMenuRow();
    }
  };

  const multipleRows = selectedRowsCount > 1;
  const heading = multipleRows ? "Are you sure you want to delete the selected rows?" : "Are you sure you want to delete the selected row?";
  const text = multipleRows
    ? "This action will remove these rows from the table. They can be restored."
    : "This action will remove this row from the table. It can be restored.";
  const confirm = multipleRows ? `Delete ${selectedRowsCount} Rows` : "Delete Row";

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading={heading}
      body=""
      text={text}
      cancel="Cancel"
      confirm={confirm}
      onConfirm={() => handleConfirmation()}
      onCancel={() => {
        gridApi.deselectAll();
      }}
    />
  );
};

// Specifies the types for props:
DeleteLookupsModal.propTypes = {
  modalId: PropTypes.string,
};

// Specifies the default values for props:
DeleteLookupsModal.defaultProps = {
  modalId: "",
};

export { DeleteLookupsModal };
