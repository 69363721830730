import { useEffect, useState, useMemo } from "react";
import { WORKFLOW_STAGES } from "../../../utils/workflows/enums";
import { workflowsStore } from "store/Store";
const { OUTPUT, INPUT, TRIGGER } = WORKFLOW_STAGES;

const initialState = {
  outputMappingUuid: null,
  outputMappingName: null,
  templates: [],
  triggerType: [],
};

const fieldMappings = {
  outputMappingUuid: {
    fieldKey: "outputMappingUuid",
    stageId: OUTPUT,
    loadValue: (workflow) => workflow.configuration.steps["DELIVER_FILES_V1-1"].params.reports[0]?.outputMappingUuid ?? null,
    saveValue: (workflow, value) => () => {},
  },
  templates: {
    fieldKey: "templates",
    stageId: INPUT,
    loadValue: (workflow) => {
      let templatesDuplicating =
        workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules?.flatMap((rule) => rule.inclusionRules).map((r) => r.templateUuid) ?? [];

      let result = [];
      new Set(templatesDuplicating).forEach((uuid) => result.push({ templateUuid: uuid }));

      return result;
    },
    saveValue: (workflow, value) => {
      if (workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules.length === 0) {
        return null;
      } else {
        return (workflow.configuration.steps["APPLY_TEMPLATE_V1-1"].params.templateImportRules[0].inclusionRules = value);
      }
    },
  },
  triggerType: {
    fieldKey: "triggerType",
    stageId: TRIGGER,
    //loadValue: (workflow) => "SCHEDULE_TRIGGER",
    loadValue: (workflow) => workflow.configuration.triggers?.type ?? null,
    // MVP HACK: the user can't change this field so save a fixed object with type "AUTO"
    saveValue: (workflow, value) => () => {},
  },
};

function useFusion20WorkflowState(workflow) {
  const [valuesLoaded, setValuesLoaded] = useState(false);
  const [unsavedStages, setUnsavedStages] = useState([]);
  const [completedStages, setCompletedStages] = useState([]);
  const [editingStage, setEditingStage] = useState(OUTPUT);

  const [fieldValues, setFieldValues] = useState(initialState);

  // compute derived state for each Stage
  const stageState = useMemo(
    () => ({
      [OUTPUT]: {
        stageId: OUTPUT,
        nextStageId: INPUT,
        stageFields: ["outputMappingUuid"],
        isEditing: editingStage === OUTPUT,
        isEnabled: true,
        isValid: fieldValues.outputMappingUuid !== null,
        isComplete: completedStages.includes(OUTPUT) && !unsavedStages.includes(OUTPUT),
      },
      [INPUT]: {
        stageId: INPUT,
        nextStageId: TRIGGER,
        stageFields: ["templates"],
        isEditing: editingStage === INPUT,
        isEnabled: completedStages.includes(OUTPUT),
        isValid: fieldValues.templates.length > 0,
        isComplete: completedStages.includes(INPUT) && !unsavedStages.includes(INPUT),
      },
      [TRIGGER]: {
        stageId: TRIGGER,
        nextStageId: null,
        stageFields: ["triggerType"],
        isEditing: editingStage === TRIGGER,
        isEnabled: completedStages.includes(INPUT),
        isValid: fieldValues.triggerType.length > 0,
        isComplete: completedStages.includes(TRIGGER) && !unsavedStages.includes(TRIGGER),
      },
    }),
    [editingStage, fieldValues, completedStages, unsavedStages]
  );

  useEffect(() => {
    Object.values(fieldMappings).forEach((mapping) => {
      const { fieldKey, loadValue } = mapping;
      const value = loadValue(workflow);

      setFieldValue(fieldKey, value);
    });

    setValuesLoaded(true);
  }, []);

  useEffect(() => {
    if (valuesLoaded) {
      Object.values(stageState).forEach((stage) => {
        if (stage.isValid) {
          onStageComplete(stage.stageId);
        }
      });
    }
  }, [valuesLoaded]);

  const setFieldValue = (key, value) => {
    // create an update object for new field value
    const updatedFields = { [key]: value };

    // conditionally update other (UI only) fields based on the current field
    if (key === "outputMappingUuid") {
      if (value) {
        updatedFields.outputMappingUuid = value;
      } else {
        updatedFields.outputMappingName = null;
      }
    }

    if (key === "templates") {
      if (value) {
        updatedFields.templates = value;
      } else {
        updatedFields.templates = [];
      }
    }

    if (key === "triggerType") {
      if (value) {
        updatedFields.triggerType = value;
      } else {
        updatedFields.triggerType = [];
      }
    }

    // merge updated fields with exisiting state
    setFieldValues((state) => ({
      ...state,
      ...updatedFields,
    }));

    onFieldModified(key);
  };

  const resetStage = (stageId) => {
    return new Promise((resolve) => {
      const { stageFields } = stageState[stageId];
      stageFields.forEach((fieldKey) => {
        const { saveValue } = fieldMappings[fieldKey];
        resolve(saveValue(workflow, null));
      });
    });
  };

  const saveStage = (stageId) => {
    const { stageFields } = stageState[stageId];
    stageFields.forEach((fieldKey) => {
      const value = fieldValues[fieldKey];
      const { saveValue } = fieldMappings[fieldKey];
      saveValue(workflow, value);
    });

    workflow.save().then(() => {
      //Set the outputMappingUuid to the workflow outputMappingUuid on save to stay in sync with the API.
      fieldValues.outputMappingUuid = fieldMappings.outputMappingUuid.loadValue(workflow);

      onStageComplete(stageId);
    });
  };

  const reOpenUncompletedTriggers = () => {
    const triggers = workflow.getCurrentWorkflowTriggers();
    triggers.forEach((trigger) => {
      const { triggerUuid, saved } = trigger;
      if (saved === false) {
        workflowsStore.addEditableTrigger(triggerUuid);
      }
    });
  };

  const reOpenUncompletedOutputs = () => {
    const reports = workflow.getCurrentWorkflowReports();
    reports.forEach((report) => {
      const { reportUuid, saved } = report;
      if (saved === false) {
        workflowsStore.addEditableOutput(reportUuid);
      }
    });
  };

  const onStageEdit = (stageId) => {
    // remove stage from completed if exists
    // This is to prevent the save button being disabled
    setCompletedStages((stages) => stages.filter((stage) => stage !== stageId));

    // add to unsaved stages
    setUnsavedStages((stages) => [stages.filter((stage) => stage !== stageId), stageId]);

    // start editing this stage
    setEditingStage(stageId);

    //Check if there are any incomplete triggers to re-open
    stageId === TRIGGER && reOpenUncompletedTriggers();
    //Check if there are any incomplete outputs to re-open
    stageId === OUTPUT && reOpenUncompletedOutputs();
  };

  const onStageComplete = (stageId) => {
    // remove stage from unsaved ids
    setUnsavedStages((stages) => stages.filter((stage) => stage !== stageId));

    // flag as complete
    setCompletedStages((stages) => [...stages, stageId]);

    // start editing next stage
    setEditingStage(stageState[stageId].nextStageId);
  };

  const onFieldModified = (fieldKey) => {
    if (!fieldMappings[fieldKey]) return;
    const { stageId } = fieldMappings[fieldKey];

    setUnsavedStages((stages) => [...stages, stageId]);
  };

  return [fieldValues, stageState, setFieldValue, resetStage, saveStage, editingStage, setEditingStage, onStageEdit, onStageComplete];
}

export { useFusion20WorkflowState };
