/**
 * Proposal: custom Error types
 */
function FSAuthException(message, error) {
  this.type = "FSAuthException";
  this.message = message;
  this.originalError = error;
}

function BadRequestErrorObject(response) {
  const {
    data: {
      errorDetails: [errorObject],
    },
    status,
  } = response;

  const { errorMessage, fieldValue } = errorObject;

  this.type = "frErrorException";
  this.fieldValue = fieldValue;
  this.errorMessage = errorMessage;
  this.status = status;

  this.data = response.data;
  this.status = status;
}

/**
 * Handles API request failures from Axios calls to the Fusion backend
 */
const errorHandler = (error) => {
  if (error.response) {
    /**
     * The request was made and the server responded with a status code
     * that falls out of the range of 2xx
     */
    const {
      data: { message },
      status,
    } = error.response;

    if ([401].includes(status)) {
      if (!window.isRedirectingToLogin) {
        window.isRedirectingToLogin = true;
        window.location = "/login";
      }
    }

    if ([400].includes(status)) {
      throw new BadRequestErrorObject(error.response);
    }

    if ([403].includes(status)) {
      throw new FSAuthException(`API request failed - not authorised. ${message}`, error);
    }

    return error.response;
  } else if (error.request) {
    /**
     * The request was made but no response was received
     * `error.request` is an instance of XMLHttpRequest
     */
    throw new Error(`API request failed - no response. ${error.message}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new Error(`API request failed - bad config. ${error.message}`);
  }
};

export { errorHandler };
