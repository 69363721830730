import React from "react";

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";

import { fundsDataTableChannel } from "../../utils/communication/AppChannels";

import IndexedValue from "../../utils/domain/selectandtransform/IndexedValue";

export default class HeaderSelectorButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selecting: false,
    };
  }

  componentWillUnmount() {
    this.endSelecting();
  }

  startSelecting() {
    this.onHeaderClickedSubscription = fundsDataTableChannel.subscribe(
      "headerClicked",
      (data) => {
        this.onHeaderClicked(data);
      }
    );

    document
      .querySelector(".structure-table")
      .classList.add("header-clickable");

    this.setState({ selecting: true });
  }

  endSelecting() {
    if (this.onHeaderClickedSubscription != undefined) {
      this.onHeaderClickedSubscription.unsubscribe();
    }

    document
      .querySelector(".structure-table")
      .classList.remove("header-clickable");

    this.setState({ selecting: false });
  }

  onHeaderClicked(data) {
    const { multiple, onSelect } = this.props;

    const {
      event: {
        target: { cellIndex: index, innerHTML: value },
      },
    } = data;

    const indexedValue = new IndexedValue({
      index,
      value,
    });

    onSelect(indexedValue);

    if (!multiple) {
      this.endSelecting();
    }
  }

  render() {
    const { selecting } = this.state;

    return (
      // <span className="icon">
      <Button
        className={
          selecting
            ? "icon header-selector-button inverted"
            : "icon header-selector-button normal"
        }
        onClick={() => {
          if (selecting) {
            this.endSelecting();
          } else {
            this.startSelecting();
          }
        }}
      >
        <ViewCarouselIcon/>
      </Button>
      // </span>
    );
  }
}
