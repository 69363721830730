import PropTypes from "prop-types";
import { Button, Text } from "@fundrecs/ui-library";
import { TABLE_TYPES } from "../../../../utils/lookups/enums";
import { lookupsStore, uiStore, meStore } from "../../../../store/Store";
import { lookupValidationOneUniqueColumn } from "../../../../validation/lookup/createNewLookup";

/**
 *
 * The prop state is passed down from the parent. It contains values set in Step 1 or Tab 1 on the setup.
 * @param {state} state
 *
 * The rowData is the newest rowData that the ag-grid table will show.
 * @param {rowData} rowData
 *
 * The closeModalAndResetFields prop is a function which is called to close the modal and reset form fields for further use.
 * @returns
 */
const SubmitLookupTable = ({ state, rowData, closeModalAndResetFields }) => {
  const { tableType, lookupEntriesAreCaseSensitive, lookupEntriesCanBeOverwritten, lookupEntriesRequireApproval, lookupName, lookupDescription, teamId } =
    state;

  const requestCreateTable = async (tableType) => {
    if (tableType === TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS && !lookupValidationOneUniqueColumn(rowData, uiStore)) {
      // EQUALS type tables must have one unique column - validation failure shows error message
      return;
    }

    const columns = rowData.map(({ header: name, unique }) => ({ name, unique }));
    const settings = {
      overwrite: lookupEntriesCanBeOverwritten,
      caseSensitivity: lookupEntriesAreCaseSensitive,
      fourEyesEnabled: lookupEntriesRequireApproval,
    };
    const requestObject = {
      teamId: teamId,
      name: lookupName,
      description: lookupDescription,
      type: tableType,
      columns,
      settings,
      createdBy: meStore.getEmail(),
    };

    const { success } = await lookupsStore.createTable(requestObject);

    if (success) {
      const { success: done } = await lookupsStore.getTables(teamId, {}, true);
      if (done) {
        closeModalAndResetFields();
      }
    }
  };

  return (
    <div className="d-flex justify-content-end align-items-center">
      {rowData.length > 0 && (
        <Button
          onClick={() => {
            requestCreateTable(tableType);
          }}
          size="md"
        >
          <Text size="md">Done</Text>
        </Button>
      )}
    </div>
  );
};

//Proptypes for SubmitLookupTable
SubmitLookupTable.propTypes = {
  state: PropTypes.shape({
    tableType: PropTypes.string,
    lookupEntriesAreCaseSensitive: PropTypes.bool,
    lookupEntriesCanBeOverwritten: PropTypes.bool,
    lookupEntriesRequireApproval: PropTypes.bool,
    lookupName: PropTypes.string,
    lookupDescription: PropTypes.string,
    teamId: PropTypes.string,
  }),
  rowData: PropTypes.array,
  closeModalAndResetFields: PropTypes.func,
};

// Specifies the default values for props:
SubmitLookupTable.defaultProps = {
  state: {},
  rowData: [],
  closeModalAndResetFields: () => {},
};

export { SubmitLookupTable };
