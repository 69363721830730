import { useEffect, useState } from "react";
import { HEADER_BUTTON_TYPES, ROW_STATUS_VALUES } from "utils/lookups/enums";

const useSubheaderButtonsType = (gridApi, selectedRowsCount, isAddRow) => {
  const [buttonsType, setButtonsType] = useState([]);

  useEffect(() => {
    if (gridApi) {
      updatetButtons();
    }
  }, [selectedRowsCount, isAddRow]);

  const getDistinctStatus = (rows = []) => {
    return [...new Set(rows.map((row) => row.status))];
  };

  const updatetButtons = () => {
    if (isAddRow) {
      setButtonsType(HEADER_BUTTON_TYPES.SAVE_ROW);
    } else {
      const distinctSelectedStatus = getDistinctStatus(gridApi.getSelectedRows());

      if (distinctSelectedStatus.length === 1) {
        switch (distinctSelectedStatus[0]) {
          case ROW_STATUS_VALUES.PENDING:
            setButtonsType(HEADER_BUTTON_TYPES.APPROVE_REJECT_ROWS);
            break;
          case ROW_STATUS_VALUES.DRAFT:
            setButtonsType(HEADER_BUTTON_TYPES.SUBMIT_FOR_APPROVAL_OR_DELETE);
            break;
          default:
            setButtonsType(HEADER_BUTTON_TYPES.ADD_ROW);
            break;
        }
      } else {
        setButtonsType(HEADER_BUTTON_TYPES.ADD_ROW);
      }
    }
  };

  return buttonsType;
};

export { useSubheaderButtonsType };
