import { Heading } from "@fundrecs/ui-library";
import { SectionWithDescriptionLayout } from "../../../layout/Layout";
import { useStore } from "../../../../store/Store";
import { TriggerSummaryView } from "components/workflows/ConfigureWorkflow/stages/trigger/TriggerSummaryView";
import styles from "./ConfigurationTab.module.scss";
import { OutputSummaryView } from "components/workflows/ConfigureWorkflow/stages/output/OutputSummaryView";
import { InputSummaryView } from "components/workflows/ConfigureWorkflow/stages/input/InputSummaryView";

const ConfigurationTab = ({ workflow }) => {
  const { workflowsStore } = useStore();

  const outputsAdded = workflow.getCurrentWorkflowReports().filter((report) => {
    return report.saved ?? report;
  });
  const triggersAdded = workflow.getCurrentWorkflowTriggers().filter((trigger) => {
    return trigger.saved ?? trigger;
  });

  const inputsAdded = workflow.getCurrentWorkflowTemplates().filter((template) => {
    return template.saved ?? template;
  });

  return (
    <>
      <div className="pt-12 pr-0 pl-0">
        <SectionWithDescriptionLayout
          title="Outputs"
          description="Each output tells the workflow which destination to send transformed data to, in the specified output structure."
        >
          <div className="pl-16 pr-16">
            <div className={[`${styles.outputSectionBottomBorder}`, "pb-12"].join(" ")}>
              <div className="d-flex  pb-12">
                <div className="me-auto">
                  <Heading variant="caption3" align="right" element="span">
                    Outputs: {workflowsStore.getReadOnlyWorkflow() !== null ? outputsAdded.length : 0}
                  </Heading>
                </div>
              </div>
            </div>
            <OutputSummaryView workflow={workflow} />
          </div>
        </SectionWithDescriptionLayout>
        <SectionWithDescriptionLayout
          title="Input"
          description="Each input tells the workflow which source to ingest data from and which templates to apply to transform it."
        >
          <div className="pl-16 pr-16">
            <div className={[`${styles.outputSectionBottomBorder}`, "pb-12"].join(" ")}>
              <div className="d-flex  pb-12">
                <div className="me-auto">
                  <Heading variant="caption3" align="right" element="span">
                    Inputs: {workflowsStore.getReadOnlyWorkflow() !== null ? inputsAdded.length : 0}
                  </Heading>
                </div>
              </div>
            </div>
            <InputSummaryView workflow={workflow} />
          </div>
        </SectionWithDescriptionLayout>
        <SectionWithDescriptionLayout title="Run trigger" description="How and when the workflow should start a run">
          <div className="pl-16 pr-16">
            <div className={[`${styles.triggerSectionBottomBorder}`, "pb-12"].join(" ")}>
              <div className="d-flex pb-12">
                <div className="me-auto">
                  <Heading variant="caption3" align="right" element="span">
                    Triggers: {workflowsStore.getReadOnlyWorkflow() !== null ? triggersAdded.length : 0}
                  </Heading>
                </div>
              </div>
            </div>
            <TriggerSummaryView workflow={workflow} />
          </div>
        </SectionWithDescriptionLayout>
      </div>
    </>
  );
};

export { ConfigurationTab };
