import React from "react";
import CommandUtils from "../../utils/CommandUtils";
import { tbAppChannel } from "../../utils/communication/AppChannels";
import { appSingleton } from "../../utils/AppSingleton";
import { Dialog, DialogContent } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import { Button, Text, ToolTip, IconInformation, C10, C8, Input, C4, PanelBody, PanelFooter, Heading, R, C, IconVideoCamera } from "@fundrecs/ui-library";

const renderSection = (heading, description) => {
  return (
    <>
      <C10 props="mt-0 mb-0 pl-0 pr-0">
        <div className="d-flex align-items-center">
          <Text element="div" size="sm" weight="medium" variant="primary">
            {heading}
          </Text>
        </div>
        <div className="d-flex pb-8">
          <Text element="div" size="xs" weight="regular" variant="tertiary">
            {description}
          </Text>
        </div>
        <hr className="dropdown-divider"></hr>
      </C10>
      <C props="text-end mt-0 mb-0 pl-0 pr-0">
        <div className="d-flex">
          <ToolTip text={description} direction="left">
            <IconInformation className="light-text-muted icon-size" />
          </ToolTip>
          <IconVideoCamera className="light-text-muted icon-size" />
        </div>
      </C>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    // change to this
    scrollPaper: {
      justifyContent: "right",
    },
    paper: {
      margin: "0px",
    },
    container: {
      height: "unset",
    },
    paperWidthMd: {
      maxWidth: "50vw",
    },
    radioButtonInput: {
      marginRight: "16px",
      width: "20px",
      height: "20px",
    },
    listPadding: {
      paddingLeft: "0px",
    },
    footerBottom: {
      position: "absolute",
      bottom: "0",
      width: "100%",
    },
  });

class GeneralCommands extends React.Component {
  constructor(props) {
    super(props);
    this.state = { command: undefined };
  }

  render() {
    const { classes } = this.props;

    let testCommands = appSingleton.generalCommands;
    var groupMap = new Map(); //map of Maps of Lists
    for (var i = 1; i < 11; i++) {
      groupMap.set(i + "", new Map());
    }

    testCommands.forEach((command) => {
      if (command.name.reqStructure === appSingleton.state) {
        let groupAndOrder = command.name.order.split(",");
        var group = groupMap.get(groupAndOrder[0]);
        var positionList = group.get(groupAndOrder[1]);
        if (positionList == undefined) {
          positionList = [];
        }

        positionList.push(command);

        group.set(groupAndOrder[1], positionList);
      }
    });

    let commandsListHtml = [];
    var count = 0;
    for (var i = 1; i < 11; i++) {
      var group = groupMap.get(i + "");
      if (group.size > 0) {
        for (var x = 1; x < 20; x++) {
          var uiCommandList = group.get(x + "");
          if (uiCommandList != undefined) {
            uiCommandList.forEach((uiCommand) => {
              commandsListHtml.push(
                <div key={uiCommand.name.uiName}>
                  <R props="pl-0">
                    <input
                      className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
                      type="radio"
                      key={count}
                      name="command"
                      onClick={() => {
                        this.setState({
                          command: () => {
                            this.commandSelected(uiCommand);
                          },
                        });
                      }}
                    />
                    {renderSection(uiCommand.name.uiName, uiCommand.name.uiDesc)}
                  </R>
                </div>
              );
              count++;
            });
          }
        }
        //commandsListHtml.push(<Divider key={count} />);
        count++;
      }
    }

    if (appSingleton.state == "STRUCTURED") {
      //commandsListHtml.unshift(<Divider key={count} />);
      count++;
      commandsListHtml.unshift(
        <div key={"Filter"}>
          <R props="pl-0 pt-8">
            <input
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              type="radio"
              key={count}
              name="command"
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("Filter");
                  },
                });
              }}
            />
            {renderSection("Filter", "Filter rows based on conditions")}
          </R>
        </div>
      );
      count++;
      commandsListHtml.unshift(
        <div key={"Modify"}>
          <R props="pl-0 pt-8">
            <input
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              type="radio"
              name="command"
              key={count}
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("Modify");
                  },
                });
              }}
            />
            {renderSection("Modify", "Modify a column based on conditions and a selected transformation")}
          </R>
        </div>
      );
      count++;
      commandsListHtml.unshift(
        <div key={"Merge"}>
          <R props="pl-0 pt-8">
            <input
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              type="radio"
              key={count}
              name="command"
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("Merge");
                  },
                });
              }}
            />
            {renderSection("Merge", "Merge two columns based on conditions and a selected transformation")}
          </R>
        </div>
      );
      count++;
      commandsListHtml.push(
        <div key={"Swap Columns"}>
          <R props="pl-0 pt-8">
            <input
              type="radio"
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              key={count}
              name="command"
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("SwapColumns");
                  },
                });
              }}
            />
            {renderSection("Swap Columns", "Swap Columns")}
          </R>
        </div>
      );
      count++;
      commandsListHtml.push(
        <div key={"Copy and modify rows"}>
          <R props="pl-0 pt-8">
            <input
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              type="radio"
              name="command"
              key={count}
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("CopyAndModifyRows");
                  },
                });
              }}
            />
            {renderSection("Copy and modify rows", "Copy and modify rows")}
          </R>
        </div>
      );
      count++;
      commandsListHtml.push(
        <div key={"Find duplicate and copy larger value"}>
          <R props="pl-0 pt-8">
            <input
              className={(`commandListItemPadding`, `${classes.radioButtonInput}`)}
              name="command"
              type="radio"
              key={count}
              onClick={() => {
                this.setState({
                  command: () => {
                    this.sAndTcommandSelected("findDuplicateAndCopyLargerValue");
                  },
                });
              }}
            />
            {renderSection("Find duplicate and copy larger value", "Find duplicate and copy larger value")}
          </R>
        </div>
      );
    }

    const style = { paddingTop: 0, overflowX: "hidden", minHeight: "100vh" };

    return (
      <Dialog
        disableEnforceFocus
        open={this.props.isVisible}
        fullWidth={true}
        maxWidth="md"
        classes={{
          scrollPaper: classes.scrollPaper,
          paper: classes.paper,
          container: classes.container,
          paperWidthMd: classes.paperWidthMd,
        }}
      >
        <DialogContent className="padding-0" style={style}>
          <PanelBody>
            <div className="panel-header">
              <div className="panel-header-right">
                <button
                  type="button"
                  title="Close"
                  className="btn-close"
                  onClick={() => {
                    tbAppChannel.publish("hideCommandParams", {});
                    this.setState({
                      command: undefined,
                    });
                  }}
                ></button>
              </div>
            </div>
          </PanelBody>

          <div style={{ marginLeft: "48px" }}>
            <div style={{ marginBottom: "48px" }}>
              <Heading variant="h4" element="span">
                Add a new Rule
              </Heading>
            </div>
            <div style={{ height: "70vh", overflowY: "scroll" }}>
              <form
                onChange={(event) => {
                  console.log("event", event.target.value);
                }}
              >
                {commandsListHtml}
              </form>
            </div>
          </div>
          <PanelFooter classes={classes.footerBottom}>
            <Button
              disabled={this.state.command === undefined}
              onClick={() => {
                const { command } = this.state;
                command();
                this.setState({
                  command: undefined,
                });
              }}
              size="lg"
            >
              <Text size="md">Done</Text>
            </Button>
          </PanelFooter>
        </DialogContent>
      </Dialog>
    );
  }

  commandSelected = (uiCommand) => {
    let commandSelectedName = uiCommand.name.peName;

    let uiCommandSelected = CommandUtils.findUiCommand(commandSelectedName);
    tbAppChannel.publish("newGeneralCommandSelected", {
      uiCommand: uiCommandSelected,
    });
  };

  sAndTcommandSelected = (commandSelectedName) => {
    tbAppChannel.publish("addModeSandT", commandSelectedName);
  };
}

export default withStyles(styles)(GeneralCommands);
