import { makeAutoObservable, toJS } from "mobx";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const outputMappingsApi = fusionDataApi.outputMappings;

class OutputMappingsStore {
  isLoading = false;
  outputMappings = [];
  outputMapping = {};
  outputMappings = [];
  columns = [];

  constructor() {
    makeAutoObservable(this);
  }

  getOutputMapping = () => {
    return toJS(this.outputMapping);
  };

  setOutputMappings = (outputMappings) => {
    this.outputMappings = outputMappings;
  };

  getOutputMappings = () => {
    return toJS(this.outputMappings);
  };

  setOutputMapping = (outputMapping) => {
    this.outputMapping = outputMapping;
  };

  setOutputMappings = (value) => {
    this.outputMappings = value;
  };

  getColumns = () => {
    return toJS(this.columns);
  };

  setColumns = (columns) => {
    this.columns = columns.sort((a, b) => a.columnOrderNumber - b.columnOrderNumber);
  };

  getColumnById(columnId) {
    // ensure lookup ID is an integer
    const id = parseInt(columnId, 10);
    return this.getColumns().find((col) => col.id === id);
  }

  addColumn = (column) => {
    const columns = this.getColumns();
    columns.push(column);
    this.setColumns(columns);
  };

  removeColumn = (columnId) => {
    const filtered = this.getColumns().filter((c) => c.id !== columnId);
    this.setColumns(filtered);
  };

  replaceColumn = (column) => {
    let columns = this.getColumns();
    const index = columns.findIndex((index) => index.id === column.id);
    columns[index] = column;
    this.setColumns(columns);
  };

  generateColumnIdValue = (column) => {
    return `${column.columnName}-${column.columnOrderNumber}`;
  };

  loadApprovedOutputMappings = (request) => {
    this.isLoading = true;
    const { teamId } = request;

    return outputMappingsApi
      .getApprovedOutputMappings({ teamId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          this.setOutputMappings(response.data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get output mappings! Please try again. ");
      });
  };

  loadOutputMappings = (request) => {
    this.isLoading = true;
    const { teamId } = request;
    return outputMappingsApi
      .getOutputMappings({ teamId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          this.setOutputMappings(response.data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get output mappings! Please try again. ");
      });
  };

  createOutputMapping = (request) => {
    this.isLoading = true;
    const { name, teamId } = request;

    return outputMappingsApi
      .createOutputMapping({ teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          const { data } = response;

          this.setOutputMapping(data);
          this.setColumns([]);

          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add output mapping "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to add output mapping "${name}" ! Please try again`);
      });
  };

  getOutputMappingColumnsById = (request) => {
    this.isLoading = true;
    const { teamId, outputMappingId } = request;
    return outputMappingsApi
      .getOutputMappingsColumns({ teamId, outputMappingId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          const { data } = response;
          this.setColumns(data);
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get columns for this output mapping! Please try again. ");
      });
  };

  createOutputMappingColumn = (request) => {
    this.isLoading = true;
    const { name, teamId, outputMappingId } = request;

    return outputMappingsApi
      .createOutputMappingColumn({ teamId, outputMappingId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          const { data } = response;
          // Add new created column to store
          this.addColumn(data);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add output mapping "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to add output mapping "${name}" ! Please try again`);
      });
  };

  updateOutputMappingColumn = (request) => {
    this.isLoading = true;
    const { columnName: name, teamId, id: columnId, outputMappingId } = request;

    return outputMappingsApi
      .updateOutputMappingColumn({ teamId, outputMappingId, columnId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          const { data } = response;
          // Replace updated column
          this.replaceColumn(data);
          uiStore.addNotification("success", "Success column updated");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to update column "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to update column "${name}" ! Please try again`);
      });
  };

  updateOutputMappingColumnsOrder = (request) => {
    this.isLoading = true;
    const { teamId, outputMappingId, columnsOrder } = request;

    return outputMappingsApi
      .updateOutputMappingColumnsOrder({ teamId, outputMappingId, requestBody: columnsOrder })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success columns order updated");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to update columns order ! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to update columns order ! Please try again");
      });
  };

  deleteOutputMappingsColumns = (request) => {
    this.isLoading = true;
    const { teamId, outputMappingId, id: columnId } = request;

    return outputMappingsApi
      .deleteOutputMappingsColumns({ teamId, outputMappingId, columnId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 204) {
          // Remove column from store
          this.removeColumn(columnId);
          uiStore.addNotification("success", "Success column deleted");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry column could not be deleted at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry column could not be deleted at this time");
      });
  };

  getOutputMappingById = (request) => {
    this.isLoading = true;
    const { teamId, id } = request;
    return outputMappingsApi
      .getOutputMappingById({ teamId, outputMappingId: id })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to get output mapping! Please try again. ");
      });
  };

  updateOutputMapping = (teamId, requestBody, key) => {
    this.isLoading = true;
    const { uuid } = requestBody;
    return outputMappingsApi
      .updateMappingDetails({ teamId, outputMappingId: uuid, requestBody })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Output mapping ${key} successfully updated`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to update output mapping ${key}! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to update output mapping ${key}! Please try again`);
      });
  };

  deleteOutputMapping = (teamId, outputMappingId) => {
    this.isLoading = true;
    return outputMappingsApi
      .deleteOutputMapping({ teamId, outputMappingId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if ([200, 204].includes(status)) {
          uiStore.addNotification("success", "Output mapping successfully deleted");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to delete output mapping! Please try again");

          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to delete output mapping! Please try again");
        return { ...{ success: false } };
      });
  };

  approveOutputMapping = (teamId, outputMappingId, name) => {
    this.isLoading = true;
    return outputMappingsApi
      .approveOutputMapping({ teamId, outputMappingId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Output mapping '${name}' successfully approved`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to approve output mapping '${name}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to approve output mapping '${name}'! Please try again`);
        return { ...{ success: false } };
      });
  };

  cloneOutputMapping = (teamId, outputMappingId, name) => {
    this.isLoading = true;
    return outputMappingsApi
      .cloneOutputMapping({ teamId, outputMappingId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Output mapping '${name}' successfully cloned`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to clone output mapping '${name}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to clone output mapping '${name}'! Please try again`);
        return { ...{ success: false } };
      });
  };

  shareOutputMapping = (teamId, outputMappingId, selectedTeam, requestBody, successToastWithTeamLink) => {
    this.isLoading = true;
    const { name } = selectedTeam;
    return outputMappingsApi
      .shareOutputMapping({ teamId, outputMappingId, requestBody })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", successToastWithTeamLink);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to share output mapping with ${name} team workspace! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to share output mapping with ${name} team workspace! Please try again`);
        return { success: false };
      });
  };
}

export { OutputMappingsStore };
