import PropTypes from "prop-types";
import { Text, Input } from "@fundrecs/ui-library";

const TextInput = ({ title, update, value, warningBoolean, warningMessage, classes, maxCharacter }) => {
  return (
    <div className={classes}>
      <div className="pb-4 d-flex">
        <div>
          <Text size="sm" weight="medium" variant="secondary" element="div">
            {title}
          </Text>
        </div>
        {maxCharacter && (
          <div className="ms-auto">
            {value.length}
            {maxCharacter && `/${maxCharacter}`}
          </div>
        )}
      </div>
      <Input
        onChange={(event) => {
          if (maxCharacter && event.target.value.length <= maxCharacter) {
            update(event.target.value);
          }
        }}
        warning={warningBoolean}
        warningMessage={warningMessage}
        value={value}
      />
    </div>
  );
};

//Proptypes for TextInput
TextInput.propTypes = {
  title: PropTypes.string,
  update: PropTypes.func,
  value: PropTypes.string,
  warningBoolean: PropTypes.bool,
  warningMessage: PropTypes.node,
  classes: PropTypes.string,
  maxCharacter: PropTypes.number,
};

//Proptypes for TextInput
TextInput.defaultProps = {
  title: "",
  update: () => {},
  value: PropTypes.string,
  warningBoolean: PropTypes.bool,
  warningMessage: PropTypes.node,
  classes: "",
  maxCharacter: PropTypes.number,
};

export { TextInput };
