import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS, API_REQUEST_TB_GET_RESULT_AND_STACK_MS } from "../config";
import { loadingChannel, tbAppChannel } from "../../TemplateBuilder/components/utils/communication/AppChannels";

const TEMPLATE_BUILDER_ROOT = "/templateBuilder";

const tbUi = {
  draft: (data) => fusionRequest.post(API_ENDPOINTS.TBUI.DRAFT, data),
  updateParseFile: (data) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/template/${data.templateUuid}/${API_ENDPOINTS.TBUI.UPDATE_PARSE_FILE}`, data),
  updateSampleFile: (teamId, templateUuid, formData) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/template/${templateUuid}/${API_ENDPOINTS.TBUI.UPDATE_SAMPLE_FILE}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  applyCommand: (data) => {},
  updateCommand: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.UPDATE_COMMAND}`, data),
  deleteCommand: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.DELETE_COMMAND}`, data),
  undo: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.UNDO}`, data),
  getResultAndStack: (data) =>
    errorHandledFetch(
      fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.GET_RESULT_AND_STACK}`, data, {
        timeout: API_REQUEST_TB_GET_RESULT_AND_STACK_MS,
      })
    ),
  commandsList: (data) => errorHandledFetch(fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.COMMANDS_LIST}`, data)),
  getRequiredTemplateMapping: (data) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.GET_REQUIRED_TEMPLATE_MAPPING}`, data),
  saveTemplateMappings: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.SAVE_TEMPLATE_MAPPINGS}`, data),
  //saveTemplateMappings: (data) => fusionRequest.post(API_ENDPOINTS.TBUI.SAVE_TEMPLATE_MAPPINGS, data),
  submitTemplate: (data) => fusionRequest.post(API_ENDPOINTS.TBUI.SUBMIT_TEMPLATE_FOR_APPROVAL, data),
  approveTemplate: (data) => fusionRequest.post(API_ENDPOINTS.TBUI.APPROVE_TEMPLATE, data),
  rejectTemplate: (data) => fusionRequest.post(API_ENDPOINTS.TBUI.REJECT_TEMPLATE, data),
  getTmos: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.GET_TMOS_FUSION}`, data),
  updateTemplateType: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/template/${data.templateUuid}/tmo/${data.tmoUuid}`, data),
  createNewTemplate: (teamId, formData) =>
    fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${teamId}/${API_ENDPOINTS.TBUI.CREATE_NEW_TEMPLATE}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  getRequiredTemplate: (data) => fusionRequest.post(`${TEMPLATE_BUILDER_ROOT}/team/${data.teamId}/${API_ENDPOINTS.TBUI.GET_REQUIRED_TEMPLATE}`, data),
};

const errorHandledFetch = (request) => {
  loadingChannel.publish("loading", "Loading something");
  request
    .then((response) => {
      const { data, status } = response;

      if (status === 200) {
        loadingChannel.publish("finishedLoading", "Loading something");
        return data;
      } else {
        tbAppChannel.publish("error", "ERROR_LOADING");
      }
    })
    .catch((error) => {
      tbAppChannel.publish("error", "ERROR_LOADING");
    });

  return request;
};

export { tbUi };
