import PropTypes from "prop-types";
import { Button, Text, IconRightArrow } from "@fundrecs/ui-library";
import { TABLE_TYPES } from "../../../../utils/lookups/enums";
import { ToggleSwitchSection } from "../reusable/ToggleSwitchSection";
import { SetupLookupType } from "./SetupLookupType";
import { SetupNameAndDescription } from "./SetupNameAndDescription";
import { SetupAdditonalSettings } from "./SetupAdditonalSettings";
/**
 *
 * dispatch props is a function which is used to update the state object.
 * @param {dispatch} dispatch
 *
 * State prop value passed down from parent. It is stored in parent because it is shared accross multiple children.
 * @param {state} state
 *
 * saveLookupTableType prop is a function which checks form fields pass validation before saving the first step on the Lookup setup.
 * @param {saveLookupTableType} saveLookupTableType
 * @returns
 */
const SetupLookup = ({ state, dispatch, saveLookupTableType }) => {
  // These are the two lookup table types equals & contains
  const { LOOKUP_TABLE_TYPE_CONTAINS, LOOKUP_TABLE_TYPE_EQUALS } = TABLE_TYPES;

  const tableTypeArray = [LOOKUP_TABLE_TYPE_EQUALS, LOOKUP_TABLE_TYPE_CONTAINS];
  const {
    lookupEntriesRequireApproval,
    lookupEntriesAreCaseSensitive,
    lookupEntriesCanBeOverwritten,
    lookupTypeSaved,
    lookupName,
    lookupDescription,
    lookupNameWarning,
    lookupDescriptionWarning,
    dropdownWarning,
    tableType,
  } = state;
  return (
    <>
      <Text size="lg" weight="medium">
        Setup your new lookup
      </Text>
      <div className="mt-16">
        <Text weight="regular">
          This is the template for setting up your new lookup table. This will create the structure of the lookup table. Entries can be added after the table
          has been created.
        </Text>
      </div>
      <SetupNameAndDescription
        lookupNameWarning={lookupNameWarning}
        lookupName={lookupName}
        lookupDescription={lookupDescription}
        dispatch={dispatch}
        lookupDescriptionWarning={lookupDescriptionWarning}
      />
      <SetupLookupType
        tableType={tableType}
        dropdownWarning={dropdownWarning}
        lookupTypeSaved={lookupTypeSaved}
        tableTypeArray={tableTypeArray}
        dispatch={dispatch}
      />
      <SetupAdditonalSettings tableType={tableType}>
        {tableType === LOOKUP_TABLE_TYPE_EQUALS && (
          <>
            <ToggleSwitchSection
              title="Lookup entries require approval"
              description="If the lookup table entries require 4-eyes approval, members can submit entries for approval by a second individual that is independent to ensure they are reviewed and double checked."
              checked={lookupEntriesRequireApproval}
              disabled={lookupTypeSaved}
              onChange={() => {
                dispatch({ lookupEntriesRequireApproval: !lookupEntriesRequireApproval });
              }}
            />
            <ToggleSwitchSection
              title="Entries must be case sensitive"
              description="If the correct input of capitals and lowercase is required then case sensitivity should be turned on"
              checked={lookupEntriesAreCaseSensitive}
              disabled={lookupTypeSaved}
              onChange={() => {
                dispatch({ lookupEntriesAreCaseSensitive: !lookupEntriesAreCaseSensitive });
              }}
            />
            <ToggleSwitchSection
              title="Allow entries to be overwritten"
              description="If you require entries to be overwritten then this should be turned on"
              checked={lookupEntriesCanBeOverwritten}
              disabled={lookupTypeSaved}
              onChange={() => {
                dispatch({ lookupEntriesCanBeOverwritten: !lookupEntriesCanBeOverwritten });
              }}
            />
          </>
        )}

        {tableType === LOOKUP_TABLE_TYPE_CONTAINS && (
          <ToggleSwitchSection
            title="Lookup entries require approval"
            description="If the lookup table entries require 4-eyes approval, members can submit entries for approval by a second individual that is independent to ensure they are reviewed and double checked. Note: Lookup tags are case-insensitive by default"
            checked={lookupEntriesRequireApproval}
            disabled={lookupTypeSaved}
            onChange={() => {
              dispatch({
                lookupEntriesRequireApproval: !lookupEntriesRequireApproval,
              });
            }}
          />
        )}
      </SetupAdditonalSettings>
      <div className="text-right">
        <Text weight="regular">* Required</Text>
      </div>
      <div className="mt-32 text-right">
        <Button
          size="lg"
          color="primary"
          disabled={false}
          onClick={() => {
            saveLookupTableType();
          }}
        >
          <Text size="md" weight="medium">
            Next - Lookup Settings <IconRightArrow />
          </Text>
        </Button>
      </div>
    </>
  );
};

//Proptypes for SetupLookup
SetupLookup.propTypes = {
  state: PropTypes.shape({
    lookupEntriesRequireApproval: PropTypes.bool,
    lookupEntriesAreCaseSensitive: PropTypes.bool,
    lookupEntriesCanBeOverwritten: PropTypes.bool,
    lookupTypeSaved: PropTypes.bool,
    lookupName: PropTypes.string,
    lookupDescription: PropTypes.string,
    lookupNameWarning: PropTypes.bool,
    lookupDescriptionWarning: PropTypes.bool,
    dropdownWarning: PropTypes.bool,
    tableType: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  saveLookupTableType: PropTypes.func,
};

// Specifies the default values for props:
SetupLookup.defaultProps = {
  dispatch: () => {},
  saveLookupTableType: () => {},
  //state: Will let parent define default state.
};
export { SetupLookup };
