import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  ItemBody,
  IconDownArrow,
  Text,
} from "@fundrecs/ui-library";

/**
 * Temporary component to display a single fixed value in a dropdown with an associated label.
 * The Dropdown contains a single option, selected by default, and remains interactive
 * (if not disabled) to indicate that these fields will support multiple options post MVP.
 */
const FixedOptionDropdown = ({ label, value, disabled }) => (
  <div className="mb-24">
    <div className="mb-8">
      <Text size="sm" weight="medium" variant="secondary">
        {label}
      </Text>
    </div>
    <Dropdown>
      <DropdownButton size="md" disabled={disabled}>
        <DropdownButtonText>
          <ItemBody>{value}</ItemBody>
          <IconDownArrow className="btn-md-svg" />
        </DropdownButtonText>
      </DropdownButton>
      <DropdownList>
        <DropdownListItem>
          <DropdownItem>
            <ItemBody>{value}</ItemBody>
          </DropdownItem>
        </DropdownListItem>
      </DropdownList>
    </Dropdown>
  </div>
);

//Proptypes for ArrayOptionDropdown
FixedOptionDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

//Specifies the default values for props:
FixedOptionDropdown.defaultProps = {
  label: "",
  value: "",
  disabled: false,
};

export { FixedOptionDropdown };
