import { Routes, Route, Navigate } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { Lookups } from "./lookups/Lookups";
import { Workflows } from "./workflows";
import { SftpDirectories } from "./imports/sftp/SftpDirectories";
import { EmailAddresses } from "./imports/email/EmailAddresses";
import { WorkflowRunResults } from "./workflows/WorkflowRunResults/WorkflowRunResults";
import { Templates } from "./templates/Templates";
import { OutputMappings } from "./outputMappings/OutputMappings";
import { ImportDetail } from "./imports/importDetail/ImportDetail";
import { FE_SOURCE_TYPES } from "utils/enums";

const FusionRoutes = () => {
  return (
    <Routes>
      <ApmRoutes>
        <Route path="/" element={<WorkflowRunResults />} />
        <Route path="workflows/*" element={<Workflows />} />

        <Route>
          <Route path="imports/email/" element={<EmailAddresses />} />
          <Route path="imports/sftp/" element={<SftpDirectories />} />
          <Route
            path="imports/sftp/connectionUuid/:connectionUuid/inputMappingId/:inputMappingId"
            element={<ImportDetail importType={FE_SOURCE_TYPES.SFTP} />}
          />
          <Route path="imports/email/:connectionUuid" element={<ImportDetail importType={FE_SOURCE_TYPES.EMAIL} />} />
          <Route path="*" element={<Navigate to="imports/sftp" replace />} />
        </Route>

        <Route path="lookups/*" element={<Lookups />} />
        <Route path="templates/*" element={<Templates />} />
        <Route path="mappings/*" element={<OutputMappings />} />
      </ApmRoutes>
    </Routes>
  );
};
export { FusionRoutes };
