import PropTypes from "prop-types";
import { Text, IconCloseRemove } from "@fundrecs/ui-library";

import styles from "./Tag.module.scss";

const Tag = ({ value, canDelete = false, handleDelete }) => {
  return (
    <div className={[styles.tag, "flex-shrink text-break"].join(" ")}>
      <Text size="xs" weight="regular" element="span" variant="tertiary">
        {value}
      </Text>
      {canDelete && <IconCloseRemove className={[styles.close, styles.cursorPointer, "light-text-muted"].join(" ")} onClick={() => handleDelete()} />}
    </div>
  );
};

Tag.propTypes = {
  value: PropTypes.string,
  canDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
};

Tag.defaultProps = {
  value: "",
  canDelete: false,
  handleDelete: () => {},
};

export { Tag };
