import { OUTPUT_DESTINATION_TYPES, OUTPUT_FORMAT_TYPES } from "utils/workflows/enums";

const getExportConnectionName = (exportConnectionData, exportConnectionUuid) => {
  const exportConnection = exportConnectionData.find((connection) => exportConnectionUuid === connection.connectionUuid) || {
    name: OUTPUT_DESTINATION_TYPES.FUSION_UI,
  };
  const { name } = exportConnection;
  return name;
};

const getImportConnectionName = (importConnectionData, importConnectionUuid) => {
  const importConnection = importConnectionData.find((connection) => importConnectionUuid === connection.connectionUuid) || {
    name: OUTPUT_DESTINATION_TYPES.FUSION_UI,
  };

  const { name } = importConnection;
  return name;
};

const getImportConnectionEmailAddress = (importConnectionData, importConnectionUuid) => {
  const importConnection = importConnectionData.find((connection) => importConnectionUuid === connection.connectionUuid) || {
    name: OUTPUT_DESTINATION_TYPES.FUSION_UI,
  };

  const { emailAddress } = importConnection;
  return emailAddress;
};

const getInputMappingName = (importConnectionData, importConnectionUuid, inputMappingId) => {
  const importConnection = importConnectionData?.find((connection) => importConnectionUuid === connection.connectionUuid);

  const { inputMappings } = importConnection;

  const mapping = inputMappings.find((mapping) => mapping.id === inputMappingId);

  const { name } = mapping;

  return name;
};

const getInputMappingPath = (importConnectionData, importConnectionUuid, inputMappingId) => {
  const importConnection = importConnectionData?.find((connection) => importConnectionUuid === connection.connectionUuid);

  const { inputMappings } = importConnection;

  const mapping = inputMappings.find((mapping) => mapping.id === inputMappingId);

  const { fileSource } = mapping;

  return fileSource;
};
const getExportMappingName = (exportConnectionData, exportConnectionUuid, exportMappingId) => {
  if (exportMappingId == null) {
    return null;
  }
  const exportConnection = exportConnectionData?.find((connection) => exportConnectionUuid === connection.connectionUuid);

  if (exportConnection == null) {
    return null;
  }
  const { exportMappings } = exportConnection;

  const mapping = exportMappings.find((mapping) => mapping.id === exportMappingId);

  if (mapping == null) {
    return null;
  }
  const { name } = mapping;

  return name;
};

const getExportMappingDiretoryPath = (exportConnectionData, exportConnectionUuid, exportMappingId) => {
  if (exportMappingId == null) {
    return null;
  }
  const exportConnection = exportConnectionData?.find((connection) => exportConnectionUuid === connection.connectionUuid);

  if (exportConnection == null) {
    return null;
  }
  const { exportMappings } = exportConnection;

  const mapping = exportMappings.find((mapping) => mapping.id === exportMappingId);

  if (mapping == null) {
    return null;
  }
  const { fileSource } = mapping;

  return fileSource;
};

const getReconciliationTypeName = (reconciliationData, recTypeId) => {
  const reconciliation = reconciliationData?.find((rec) => recTypeId === rec.recTypeId) || { recTypeName: "" };
  const { recTypeName } = reconciliation;
  return recTypeName;
};

const getReconciliationSideName = (reconciliationData, recSideTmoUuid) => {
  let recTmo = null;

  reconciliationData.some((rec) => {
    recTmo = rec.tmoList.find((tmo) => tmo.tmoUuid === recSideTmoUuid);
    return recTmo !== undefined;
  });

  const tmoName = recTmo ? recTmo.tmoName : "";

  return tmoName;
};

const getDestinationType = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports },
        },
      },
    },
  } = workflow;

  const destination = reports[0] ?? { destinations: [{ type: OUTPUT_DESTINATION_TYPES.FUSION_UI }] };

  const { destinations } = destination;
  const [destinationObject] = destinations;
  const { type } = destinationObject;

  return type;
};

const getFormatType = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports },
        },
      },
    },
  } = workflow;

  const format = reports[0] ?? { writer: { type: OUTPUT_FORMAT_TYPES.CSV } };

  const { writer } = format;
  const formatType = writer.type;

  return formatType;
};

const getDestinationConnectionUuid = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: {
            reports: {
              0: {
                destinations: {
                  0: { connectionUuid },
                },
              },
            },
          },
        },
      },
    },
  } = workflow;

  return connectionUuid;
};

const getExportMappingId = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: {
            reports: {
              0: {
                destinations: {
                  0: { exportMappingId },
                },
              },
            },
          },
        },
      },
    },
  } = workflow;

  return exportMappingId;
};

const getDestinationRecTypeId = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: {
            reports: {
              0: {
                destinations: {
                  0: { recTypeId },
                },
              },
            },
          },
        },
      },
    },
  } = workflow;

  return recTypeId;
};

const getDestinationRecSideUuid = (workflow, reportUuid) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports },
        },
      },
    },
  } = workflow;

  const getReportIndex = () => {
    return reports.findIndex((report) => report.reportUuid === reportUuid);
  };

  const report = reports[getReportIndex()];

  const {
    destinations: {
      0: { recSideTmoUuid },
    },
  } = report;

  return recSideTmoUuid;
};

const replaceTemplateAtIndex = (workflow, index, newTemplate) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: {
            templateImportRules: {
              0: { inclusionRules },
            },
          },
        },
      },
    },
  } = workflow;

  inclusionRules.splice(index, 1, newTemplate);

  return workflow;
};

const addTemplateToInclusionRules = (workflow, template) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: {
            templateImportRules: {
              0: { inclusionRules },
            },
          },
        },
      },
    },
  } = workflow;

  inclusionRules.push(template);

  return workflow;
};

const removeInclusionRuleWithinSouceAtIndex = (importRuleUuid, workflow, ruleIndex) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules = [] },
        },
      },
    },
  } = workflow;

  templateImportRules.forEach((importRule, index) => {
    if (importRule.importRuleUuid === importRuleUuid) {
      importRule["inclusionRules"].splice(ruleIndex, 1);
    }
  });

  return workflow;
};

const getInclusionRules = (workflow) => {
  const {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: {
            templateImportRules: {
              0: { inclusionRules },
            },
          },
        },
      },
    },
  } = workflow;

  return inclusionRules;
};

const getReportOutputMappingUuid = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports },
        },
      },
    },
  } = workflow;

  return reports.length > 0 ? reports[0]["outputMappingUuid"] : null;
};

const getDaysOfWeekName = (daysIntArray) => {
  const daysOfWeek = [
    { value: 1, label: "Mon" },
    { value: 2, label: "Tue" },
    { value: 3, label: "Wed" },
    { value: 4, label: "Thu" },
    { value: 5, label: "Fri" },
    { value: 6, label: "Sat" },
    { value: 7, label: "Sun" },
  ];
  const daysName = daysIntArray.map((dayInt) => daysOfWeek.find((day) => day.value === dayInt)?.label);
  return daysName;
};

const daysForInfoLabel = (daysIntArray) => {
  const fullDayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const days = daysIntArray.map((dayNumber) => fullDayOfWeek[dayNumber - 1]);

  if (days.length === 7) {
    return "day";
  } else if (days.length > 1) {
    const lastDay = days.pop();
    return days.join(", ") + " and " + lastDay;
  } else {
    // If there's only one day, return it without any commas or "and"
    return days[0];
  }
};

const generateScheduledDaysString = (daysOfWeek) => {
  return daysOfWeek.length === 7 ? "every day" : `every ${daysForInfoLabel(daysOfWeek) ?? "day"}`;
};

const getReports = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports },
        },
      },
    },
  } = workflow;

  return reports;
};

const getWorkflowReportsOutputMappingUuids = (workflow) => {
  const {
    configuration: {
      steps: {
        "DELIVER_FILES_V1-1": {
          params: { reports = [] },
        },
      },
    },
  } = workflow;

  let outputMappingUuidsList = [];
  reports.forEach((report) => {
    const { outputMappingUuid } = report;
    if (![undefined, null].includes(outputMappingUuid)) {
      outputMappingUuidsList.push(outputMappingUuid);
    }
  });

  return outputMappingUuidsList;
};

const getImportRules = (workflow) => {
  const {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules = [] },
        },
      },
    },
  } = workflow;

  return templateImportRules;
};

const addSourceToWorkflow = (workflow, source) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules },
        },
      },
    },
  } = workflow;

  templateImportRules.push(source);

  return workflow;
};

const addNewTemplateRuleToWorkflow = (importRule, workflow, template) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules },
        },
      },
    },
  } = workflow;

  templateImportRules.forEach((templateImportRule) => {
    const { importRuleUuid, inclusionRules } = templateImportRule;

    if (importRuleUuid === importRule.importRuleUuid) {
      inclusionRules.push(template);
    }
  });

  return workflow;
};

const getImportRulesForSource = (sourceKey, sourceKeyValue, workflow) => {
  const {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules = [] },
        },
      },
    },
  } = workflow;

  const importRules = templateImportRules.filter((importRule) => {
    const {
      source: { [sourceKey]: importRuleSourceValue },
    } = importRule;

    if (importRuleSourceValue === sourceKeyValue) {
      return importRule;
    }
  });

  return importRules;
};

const replaceTemplateRule = (workflow, ruleIndex, importRuleUuid, newTemplate) => {
  let {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules = [] },
        },
      },
    },
  } = workflow;

  templateImportRules.forEach((importRule) => {
    if (importRule.importRuleUuid === importRuleUuid) {
      let { inclusionRules } = importRule;
      inclusionRules[ruleIndex] = newTemplate;
    }
  });

  return workflow;
};

export {
  getExportConnectionName,
  getInputMappingName,
  getImportConnectionName,
  getDestinationType,
  getFormatType,
  getDestinationConnectionUuid,
  getDestinationRecTypeId,
  getDestinationRecSideUuid,
  getReconciliationTypeName,
  getReconciliationSideName,
  getExportMappingId,
  getExportMappingName,
  getInclusionRules,
  replaceTemplateAtIndex,
  addTemplateToInclusionRules,
  removeInclusionRuleWithinSouceAtIndex,
  getReportOutputMappingUuid,
  getDaysOfWeekName,
  daysForInfoLabel,
  getExportMappingDiretoryPath,
  getInputMappingPath,
  getImportConnectionEmailAddress,
  generateScheduledDaysString,
  getReports,
  getWorkflowReportsOutputMappingUuids,
  getImportRules,
  addSourceToWorkflow,
  addNewTemplateRuleToWorkflow,
  getImportRulesForSource,
  replaceTemplateRule,
};
