import { useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Text,
  Card,
  Toggle,
  R,
  C2,
  C3,
  C5,
  Input,
  IconAdd,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  SelectedIcon,
} from "@fundrecs/ui-library";
import { useStore } from "../../../../store/Store";
import { ColumnsTable } from "./ColumnsTable";
import { SelectMethodHeading } from "../../../reusable/selectMethod/selectMethodHeading";
import { DATA_TYPE_KEYS, STATUS_VALUES } from "utils/mappings/enums";
import { ApprovedOutputMapping } from "../ApprovedOutputMapping/ApprovedOutputMapping";
import { useTeamId } from "store/hooks/useTeamId";

const OUTPUT_COLUMNS_LIMIT = 401;

const AddOutputMappingColumns = observer(() => {
  const { outputMappingsStore } = useStore();
  const teamId = useTeamId();

  const { uuid: outputMappingUuid, status } = outputMappingsStore.getOutputMapping();
  const defaultState = {
    columnName: "",
    type: Object.keys(DATA_TYPE_KEYS)[0],
    required: true,
    warningBooleanName: false,
  };

  useLayoutEffect(() => {
    outputMappingsStore.getOutputMappingColumnsById({ teamId: teamId, outputMappingId: outputMappingUuid });
  }, []);

  const [state, setState] = useState(defaultState);

  const updateState = (value, key) => {
    setState({ ...state, ...{ [key]: value } });
  };

  /**
   * Checks if string length is within range
   * @returns {Boolean}
   */
  const inRange = (x, min, max) => {
    return x >= min && x <= max;
  };

  const addNewRow = () => {
    if (inRange(state.columnName.length, 1, 60)) {
      createOutputMappingColumn();
    } else {
      updateState(true, "warningBooleanName");
    }
  };

  const createOutputMappingColumn = async () => {
    const columns = await outputMappingsStore.getOutputMappingColumnsById({ teamId: teamId, outputMappingId: outputMappingUuid });

    let newColumn = { columnOrderNumber: columns.data.length + 1, required: state.required, type: state.type, columnName: state.columnName };
    newColumn.idValue = outputMappingsStore.generateColumnIdValue(newColumn);

    const requestObject = {
      teamId: teamId,
      outputMappingId: outputMappingUuid,
      ...newColumn,
    };

    const response = await outputMappingsStore.createOutputMappingColumn(requestObject);
    const { success } = response;
    if (success) {
      resetForm();
    }
  };

  const resetForm = () => {
    setState(defaultState);
  };

  const checkColumnsLimit = () => {
    if (outputMappingsStore.getColumns().length < OUTPUT_COLUMNS_LIMIT) {
      return false;
    } else {
      return true;
    }
  };

  const { type } = state;
  return (
    <>
      {status === STATUS_VALUES.DRAFT && (
        <div className="ml-72 mr-72">
          <SelectMethodHeading
            heading={"Add your output columns"}
            description={"Define the column headers for your output, in the order they should appear in the report."}
            align="left"
          />

          <div className="mt-32">
            <Card>
              <R props="light-grey-bg">
                <C5>
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Text weight="medium">Column name</Text>
                  </div>
                </C5>
                <C3>
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Text weight="medium">Data type</Text>
                  </div>
                </C3>
                <C2>
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Text weight="medium">Required</Text>
                  </div>
                </C2>
              </R>
              <R>
                <C5>
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Input
                      onChange={(event) => {
                        const { target } = event;
                        const { value } = target;
                        const warning = !state.warningBooleanName && false;
                        setState({ ...state, ...{ columnName: value.trimStart(), warningBooleanName: warning } });
                      }}
                      value={state.columnName}
                      placeholder="Add column name"
                      warning={state.warningBooleanName}
                      warningMessage="Column name must be between 1 and 60 characters in length"
                    />
                  </div>
                </C5>
                <C3>
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Dropdown>
                      <DropdownButton size="md" disabled={false}>
                        <DropdownButtonText>
                          <ItemBody>
                            <span>{DATA_TYPE_KEYS[type]}</span>
                          </ItemBody>
                          <IconDownArrow className="btn-md-svg" />
                        </DropdownButtonText>
                      </DropdownButton>
                      <DropdownList>
                        {Object.keys(DATA_TYPE_KEYS).map((item, index) => {
                          return (
                            <DropdownListItem
                              key={index}
                              onClick={() => {
                                updateState(Object.keys(DATA_TYPE_KEYS)[index], "type");
                              }}
                            >
                              <DropdownItem active={Object.keys(DATA_TYPE_KEYS)[index] === type && index} index={index}>
                                <ItemBody>{DATA_TYPE_KEYS[item]}</ItemBody>
                                <SelectedIcon active={Object.keys(DATA_TYPE_KEYS)[index] === type && index} index={index} />
                              </DropdownItem>
                            </DropdownListItem>
                          );
                        })}
                      </DropdownList>
                    </Dropdown>
                  </div>
                </C3>
                <C2>
                  <div className="pt-16 pl-16 pr-16 pb-16 d-flex align-items-center">
                    <Toggle
                      checked={state.required}
                      onChange={(event) => {
                        updateState(!state.required, "required");
                      }}
                    />
                    <span className="pl-12" />
                    {state.required ? "Required" : "Not required"}
                  </div>
                </C2>
                <C2 props="text-right">
                  <div className="pt-16 pl-16 pr-16 pb-16">
                    <Button
                      onClick={() => {
                        addNewRow();
                      }}
                      size="md"
                      disabled={checkColumnsLimit()}
                    >
                      <IconAdd className="btn-md-svg" />
                      <Text size="md">Add</Text>
                    </Button>
                  </div>
                </C2>
              </R>
            </Card>
          </div>
          <ColumnsTable />
        </div>
      )}
      {status === STATUS_VALUES.APPROVED && <ApprovedOutputMapping />}
    </>
  );
});

export { AddOutputMappingColumns };
