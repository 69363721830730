import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import { LumsContext } from "./LumsCommand";

import SelectOutputColumns from "./SelectOutputColumns";
import SelectReturnOrder from "./SelectReturnOrder";

export default function OutputSection() {
  const lumsContext = useContext(LumsContext);

  const { lookupType, lookupTable, selectedLookupTableColumns, mappedLookupTableColumns } = lumsContext;

  const emptyMappedLookupTableColumns = mappedLookupTableColumns.reduce((columns, column) => {
    const { columnIndex, lookupColumnName } = column;

    if (columnIndex === -1 || lookupColumnName === "") {
      columns.push(column);
    }

    return columns;
  }, []);

  if (
    !lookupTable ||
    (lookupType === "equals" && emptyMappedLookupTableColumns.length > 0) ||
    (lookupType === "search" && selectedLookupTableColumns.length === 0) ||
    (lookupType === "contains" && selectedLookupTableColumns.length === 0)
  ) {
    return null;
  }

  const InfoSection = ({ lookupType }) => {
    if (lookupType === "equals") {
      return (
        <>
          <b>Choose output.</b> Choose columns from the lookup table to enrich the original file
        </>
      );
    } else if (lookupType === "contains") {
      return (
        <>
          <b>Choose lookup columns.</b> Choose columns from the lookup table to enrich the original file
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className="section">
      <div style={{ marginBottom: "20px", color: "#4154AF" }}>
        <InfoSection lookupType={lookupType} />
      </div>

      <Grid container>
        <Grid item xs>
          <SelectOutputColumns />
        </Grid>

        <Grid item xs>
          {lookupType === "equals" && <SelectReturnOrder />}
        </Grid>
      </Grid>
    </div>
  );
}
