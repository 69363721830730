import { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Text,
  Button,
  Modal,
  ModalHeader,
  ModalText,
  ModalFooter,
  Input,
  modalInstance,
  LoadingButton,
  Dropdown,
  DropdownButtonText,
  DropdownButton,
  ItemBody,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  DropdownWarning,
  IconDownArrow,
  CheckableInput,
} from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { LOADING_STATES, FILE_SIZE } from "utils/enums";
import { useFusion20WorkflowState } from "components/workflows/ConfigureWorkflow/useFusion20WorkflowState";

// set delay time for closing modal once file is succesfully uploaded
const MODAL_CLOSE_DELAY = 2000;

// set delay time for resetting submit button state after an error
const ERROR_RESET_DELAY = 5000;

const getLoadingButtonText = (loadingState) => {
  switch (loadingState) {
    case LOADING_STATES.LOADING:
      return "Uploading...";

    case LOADING_STATES.LOADED:
      return "Uploaded";

    case LOADING_STATES.ERROR:
      return "Failed to upload!";

    default:
      return "Upload file";
  }
};

const UploadFileModal = observer(({ workflow, modalId, onSuccess, onError }) => {
  const { workflowsStore, outputMappingsStore, tbStore, templatesStore } = useStore();
  const fileInputRef = useRef();

  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const generateDisplayText = (selectedTemplates) => {
    let text = "";
    if (selectedTemplates.length > 0) {
      selectedTemplates.forEach((selectedTemplateUuid) => {
        const templateName = listOfTemplates.filter((template) => {
          const { templateUuid } = template;
          return selectedTemplateUuid === templateUuid;
        });

        const [{ name }] = templateName;

        text += `${name}, `;
      });
      //Remove final comma and space from the string
      text = text.slice(0, text.length - 2);
    }

    return text;
  };

  const [displayText, setDisplayText] = useState("");

  const [file, setFile] = useState();
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATES.IDLE);

  useEffect(() => {
    templatesStore.loadTemplates(workflow.teamId);
  }, []);

  const workflowEntity = workflowsStore.getWorkflowByUuid(workflow.uuid);

  const [fieldValues] = useFusion20WorkflowState(workflow, outputMappingsStore, tbStore);

  //We creates a unique list of template uuids
  const templateUuids = [...new Set(fieldValues.templates.map((template) => template.templateUuid))].map((uuid) => {
    return uuid;
  });

  // derived UI state
  const fileIsInvalid = file && file.size > FILE_SIZE.MAX_BYTES;
  const uploadEnabled = selectedTemplates.length > 0 && file && !fileIsInvalid;
  const inputsDisabled = loadingStatus !== LOADING_STATES.IDLE;
  const loadingButtonText = getLoadingButtonText(loadingStatus);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setFile(file);
  };

  const resetUploadForm = () => {
    // reset checkboxes
    listOfTemplates.forEach(({ templateUuid }) => {
      const checkbox = document.getElementById(`checkbox_${templateUuid}`);
      if (checkbox) {
        checkbox.checked = false;
      }
    });

    //reset display text
    setDisplayText("");

    //reset selected templates
    setSelectedTemplates([]);

    // reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    // reset file state
    setFile(null);
  };

  const handleClose = () => {
    // close modal
    modalInstance(modalId).toggle();

    // reset upload form fields
    resetUploadForm();

    // reset our local state
    setFile(null);
    setLoadingStatus(LOADING_STATES.IDLE);

    // reset uncontrolled input element
    fileInputRef.current.value = null;
  };

  const handleUpload = () => {
    //Adding the setting of state to a timeout lets the dropdown close now first, please refer to bugfix/FS-1486
    setTimeout(() => setLoadingStatus(LOADING_STATES.LOADING), 500);

    const json = JSON.stringify(selectedTemplates);
    const blob = new Blob([json], {
      type: "application/json",
    });

    workflowEntity
      .uploadFile(file, blob)
      .then(() => {
        setLoadingStatus(LOADING_STATES.LOADED);

        // callback to main view that upload has succeeded
        onSuccess();

        // reset display text
        // add short delay before closing modal
        // so user sees button state change to success
        setTimeout(handleClose, MODAL_CLOSE_DELAY);
      })
      .catch((error) => {
        setLoadingStatus(LOADING_STATES.ERROR);

        // callback to main view that upload has failed
        onError();

        // clear the error state after a short delay and enable re-submission
        setTimeout(() => setLoadingStatus(LOADING_STATES.IDLE), ERROR_RESET_DELAY);
      });
  };

  /**
   * We create a list of templates which match our template uuids from the templateUuids set above
   */
  const listOfTemplates = templatesStore.getTemplates().filter((template) => {
    return templateUuids.includes(template.templateUuid);
  });

  /**
   * Check if templateUuid is already in set, if so remove it
   * otherwise add it.
   */
  const handleSelectedTemplateUuid = (templateUuid) => (event) => {
    const isChecked = event.target.checked;
    const updatedSelectedTemplates = isChecked ? [...selectedTemplates, templateUuid] : selectedTemplates.filter((uuid) => uuid !== templateUuid);

    setSelectedTemplates(updatedSelectedTemplates);
    setDisplayText(generateDisplayText(updatedSelectedTemplates));
  };

  return (
    <Modal modalId={modalId}>
      <div className="d-flex flex-column modal-body">
        <ModalHeader heading="Upload a file (max. size 15 MB)" />
        <ModalText text="Your workflow will start a run as soon as this file has been uploaded if its source is “file upload” and it has an automated run trigger." />
      </div>
      <div className="pt-16 pl-32 pr-32">
        <Text size="sm" weight="medium" variant="secondary">
          Select file to upload
        </Text>
        <div className="pt-8">
          <Input
            onChange={handleFileChange}
            type="file"
            placeholderText="No file chosen"
            size="md"
            warningMessage={FILE_SIZE.MAX_WARNING}
            warning={fileIsInvalid}
            innerRef={fileInputRef}
            disabled={inputsDisabled}
          />
        </div>

        <div className="pt-20 mb-8 pb-32">
          <Text size="sm" weight="medium" variant="secondary">
            Select template(s) to apply to file
          </Text>
          <div className="pt-8">
            <Dropdown>
              <DropdownButton size="sm" disabled={inputsDisabled} data-bs-auto-close="*">
                <DropdownButtonText>
                  <ItemBody>{selectedTemplates.length === 0 ? <span style={{ color: "#838B9C" }}>Select</span> : <span>{displayText}</span>}</ItemBody>
                  <IconDownArrow className="btn-sm-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {listOfTemplates.map((template, index) => {
                  const { templateUuid, name } = template;
                  return (
                    <DropdownListItem key={index}>
                      <DropdownItem index={index}>
                        <CheckableInput
                          className="mr-12 mt-0"
                          type="checkbox"
                          onChange={handleSelectedTemplateUuid(templateUuid)}
                          id={`checkbox_${templateUuid}`}
                          name={name}
                          label={name}
                          checked={selectedTemplates.includes(templateUuid)}
                        />
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
              <DropdownWarning warningMessage="Bad things happened" />
            </Dropdown>
          </div>
        </div>
      </div>
      <ModalFooter>
        <Button color="tertiary" onClick={handleClose} disabled={inputsDisabled}>
          <Text size="sm">Cancel</Text>
        </Button>
        <LoadingButton status={loadingStatus} onClick={handleUpload} disabled={!uploadEnabled}>
          <Text size="sm">{loadingButtonText}</Text>
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
});

export { UploadFileModal };
