import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Panel, PanelBody, PanelHeader, PanelFooter, Button, modalInstance, IconCheck } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import _ from "lodash";
import styles from "./AddTemplatePanel.module.scss";

const AddTemplatePanel = observer(({ fieldValues, addTemplateRule, templates, importRuleUuid }) => {
  const [newTemplateUuid, changeTemplateUuid] = useState("");

  const handleClose = () => {
    changeTemplateUuid("");
    modalInstance(MODAL_IDS.ADD_TEMPLATE.concat(importRuleUuid)).hide();
  };

  const checkIfTemplateAlreadyInUse = (templateUuid) => {
    return fieldValues.templates.some((template) => template.templateUuid === templateUuid);
  };

  const addTemplate = (templateUuid) => {
    const defaultTemplateRuleObject = {
      type: "MATCH_ALL",
      templateUuid: templateUuid,
    };

    addTemplateRule(defaultTemplateRuleObject);
    changeTemplateUuid("");
  };

  const TemplateElement = ({ template, index }) => {
    const { name, templateUuid } = template;
    return (
      <div
        onClick={() => {
          if (!checkIfTemplateAlreadyInUse(templateUuid)) {
            changeTemplateUuid(templateUuid);
          }
        }}
        disabled={checkIfTemplateAlreadyInUse(templateUuid)}
        className={[
          `${!checkIfTemplateAlreadyInUse(templateUuid) && styles.cursorPointer}`,
          `${styles.templateElement}`,
          " d-flex mb-12 pb-8 pt-8 pl-12 pr-12",
          `${newTemplateUuid === templateUuid && styles.activeText}`,
          `${newTemplateUuid === templateUuid && styles.activeBackground}`,
          `${checkIfTemplateAlreadyInUse(templateUuid) && styles.disabledItem}`,
        ].join(" ")}
        key={index}
      >
        <div className="me-auto">{name}</div>
        <div>{newTemplateUuid === templateUuid && <IconCheck className={`${styles.checkIconSize}`} />}</div>
      </div>
    );
  };
  return (
    <Panel panelId={MODAL_IDS.ADD_TEMPLATE.concat(importRuleUuid)}>
      <PanelBody>
        <PanelHeader
          description=""
          header="Select template"
          onClick={() => {
            handleClose();
          }}
        />
        <div style={{ height: "70vh", overflowY: "auto" }} className="pt-0 pb-12 pl-0 pr-32">
          {_.uniqBy(templates, "templateUuid").map((template, index) => {
            return <TemplateElement template={template} index={index} />;
          })}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          onClick={() => {
            addTemplate(newTemplateUuid);
            modalInstance(MODAL_IDS.ADD_TEMPLATE.concat(importRuleUuid)).hide();
          }}
          size="md"
          disabled={newTemplateUuid === ""}
        >
          <Text size="sm">Add template</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { AddTemplatePanel };
