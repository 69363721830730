import { useContext } from "react";
import PropTypes from "prop-types";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { meStore, lookupsStore } from "store/Store";
import { LookupsTableContext } from "../LookupsTableType/LookupsTable";
import { redrawRowsToUpdateVerticalMenu } from "../LookupsTableType/utils/agGrid";

const ApproveLookupsModal = ({ modalId }) => {
  const { gridApi, lookupTable, selectedRowsCount, verticalMenuRow, setVerticalMenuRow } = useContext(LookupsTableContext);
  const { teamId, uuid, name } = lookupTable || {};

  const handleConfirmation = async () => {
    const selectedRows = gridApi.getSelectedRows();
    const canBeApproved = selectedRows.length > 0 ? selectedRows.filter((row) => canApproveRow(row)) : canApproveRow(verticalMenuRow) ? [verticalMenuRow] : [];

    if (canBeApproved.length > 0) {
      const lookupIds = canBeApproved.map((item) => item.id);
      const requestObject = { approvedBy: meStore.getEmail(), rowIdList: lookupIds };

      const response = await lookupsStore.approveTableRows(uuid, teamId, { ...requestObject });
      const { success } = response || {};

      if (success) {
        const approvedRow = canBeApproved.map((item) => {
          return { ...item, ...{ status: "Approved" } };
        });

        gridApi.applyTransactionAsync({ update: approvedRow }, () => {
          gridApi.deselectAll();
          redrawRowsToUpdateVerticalMenu({ gridApi, ids: lookupIds });
        });
      }
    }

    if (verticalMenuRow) {
      setVerticalMenuRow();
    }
  };

  // Content of modal changes if some rows can't be approved
  const canApproveRow = (row) => {
    // User can only approve rows submitted by other users
    const { email } = meStore.getMe();
    const { createdBy } = row;
    return email !== createdBy;
  };

  let heading = "";
  let text = "";
  let confirm = "";
  let body = "";
  if (selectedRowsCount > 1) {
    heading = `Are you sure you want to approve all the selected rows for ${name} ?`;
    text = "These rows will become available to use once approved.";
    confirm = "Approve Entries";

    const selectedRows = gridApi.getSelectedRows();
    const canBeApproved = selectedRows.filter((row) => canApproveRow(row));

    const cannotApproveCount = selectedRows.length - canBeApproved.length;
    if (cannotApproveCount > 0) {
      body = cannotApproveCount > 1 ? `You do not have permission to approve ${cannotApproveCount} rows.` : "You do not have permission to approve one row.";
      text = "The remaining rows will become available to use once approved.";
      confirm = `Approve ${canBeApproved.length} ${canBeApproved.length > 1 ? "Rows" : "Row"}`;
    }
  } else {
    heading = "Are you sure you want to approve the selected row?";
    text = "This row will become available to use one approved.";
    confirm = "Approve Entry";
  }

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading={heading}
      body={body}
      text={text}
      cancel="Cancel"
      confirm={confirm}
      onConfirm={() => handleConfirmation()}
      onCancel={() => {
        gridApi.deselectAll();
      }}
    />
  );
};

// Specifies the types for props:
ApproveLookupsModal.propTypes = {
  modalId: PropTypes.string,
};

// Specifies the default values for props:
ApproveLookupsModal.defaultProps = {
  modalId: "",
};

export { ApproveLookupsModal };
