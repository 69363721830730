import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Panel, PanelBody, PanelHeader, PanelFooter, Button, modalInstance, Radio, Note, Checkbox, LinkText, C, C8 } from "@fundrecs/ui-library";
import { MODAL_IDS, RULE_COMMAND_TYPES, RULE_GROUP_BY_COMMAND_INCLUDE_FIRST_CELL, RULE_COMMAND_ACTIONS } from "utils/workflows/enums";
import { ArrayOptionDropdown } from "components/workflows/ConfigureWorkflow/DropdownOptions/ArrayOptionDropdown";
import styles from "./AddRuleToApplyPanel.module.scss";

const RadioInput = ({ title, description, onClick, showNote, disabled, noteText, checked, children }) => {
  return (
    <div className={["d-flex mt-24", disabled && styles.inactiveInput, styles.ruleSectionBottomBorder].join(" ")}>
      <Radio onClick={onClick} checked={checked} disabled={disabled} key={Math.random()} />
      <div className="ml-8 w-100">
        <Text variant="secondary" size="sm" weight="medium" element="div">
          {title}
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          {description}
        </Text>
        {children}
        <div hidden={!showNote}>
          <Note>{noteText}</Note>
        </div>
      </div>
    </div>
  );
};

const AddRuleToApplyPanel = observer(({ ruleAction, applyRule, workflow, report, sectionIndex, columns }) => {
  let newOutputMappingUuid = workflow.getCurrentWorkflowReportOutputMapping(sectionIndex);
  const doesGroupByCommandExist = workflow.doesRuleExist(sectionIndex, RULE_COMMAND_TYPES.GroupByCommand);
  const doesIncludeColumnsCommandExist = workflow.doesRuleExist(sectionIndex, RULE_COMMAND_TYPES.IncludeColumnsCommand);
  const { action, type, params } = ruleAction || {};

  const [activeRule, setActiveRule] = useState();
  //Need to default this to what is already in the workflow I guess
  const [groupByCommandColumn, setGroupByCommandColumn] = useState();
  const [includeFirstCell, setIncludeFirstCell] = useState({ index: 1, option: "No", value: false });
  const [includeColumnsCommandIndexes, setIncludeColumnsCommandIndexes] = useState([]);

  const setExistingDataForSplitCommand = () => {
    if (workflow.getColumnSplitByIndex(sectionIndex) !== undefined) {
      const column = columns.filter((column) => {
        return column.index === workflow.getColumnSplitByIndex(sectionIndex);
      });

      const includeFirstCellOption = RULE_GROUP_BY_COMMAND_INCLUDE_FIRST_CELL.includeFirstCell.filter((option) => {
        return option.value === workflow.isFirstCellEnabled(sectionIndex);
      });

      if (column.length > 0) {
        const [columnToSplitBy] = column;
        const [{ index }] = includeFirstCellOption;
        setGroupByCommandColumn(columnToSplitBy);
        updateIncludeFirstCell(index);
      }
    }
  };

  useEffect(() => {
    if (action === RULE_COMMAND_ACTIONS.EDIT) {
      setActiveRule(RULE_COMMAND_TYPES[type]);
      if (type === RULE_COMMAND_TYPES.GroupByCommand) {
        setExistingDataForSplitCommand();
      } else if (type === RULE_COMMAND_TYPES.IncludeColumnsCommand) {
        setIncludeColumnsCommandIndexes(params?.includedColumnIndexArray ?? []);
      }
    } else if (action === RULE_COMMAND_ACTIONS.CREATE) {
      if (!doesGroupByCommandExist) {
        setActiveRule(RULE_COMMAND_TYPES.GroupByCommand);
      } else if (!doesIncludeColumnsCommandExist) {
        setActiveRule(RULE_COMMAND_TYPES.IncludeColumnsCommand);
        setIncludeColumnsCommandIndexes([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleAction, workflow]);

  useEffect(() => {
    if (columns.length > 0) {
      setGroupByCommandColumn(columns[0]);
    }
  }, [columns]);

  const handleClose = () => {
    modalInstance(MODAL_IDS.RULE_TO_APPLY.concat(sectionIndex)).hide();
  };

  const updateGroupByCommandColumn = (option) => {
    setGroupByCommandColumn(columns[option]);
  };

  const updateIncludeFirstCell = (option) => {
    setIncludeFirstCell(RULE_GROUP_BY_COMMAND_INCLUDE_FIRST_CELL.includeFirstCell[option]);
  };

  const toggleColumn = (index) => (evt) => {
    if (evt?.target?.checked) {
      setIncludeColumnsCommandIndexes([...includeColumnsCommandIndexes, index]);
    } else {
      setIncludeColumnsCommandIndexes(includeColumnsCommandIndexes?.filter((x) => x !== index));
    }
  };

  const handleSelectAllColumns = () => {
    setIncludeColumnsCommandIndexes(columns?.map((c) => c.columnOrderNumber - 1));
  };

  const handleDeselectAllColumns = () => {
    setIncludeColumnsCommandIndexes([]);
  };

  const handleSubmitApplyRule = () => {
    const params =
      activeRule === RULE_COMMAND_TYPES.GroupByCommand
        ? { includeFirstCell, groupByCommandColumn }
        : { includedColumnIndexArray: includeColumnsCommandIndexes };
    applyRule({ activeRule, params });
  };

  return (
    report !== undefined && (
      <Panel panelId={MODAL_IDS.RULE_TO_APPLY.concat(sectionIndex)}>
        <PanelBody>
          <PanelHeader description="" header="Select rule to apply" onClick={handleClose} />
          <RadioInput
            title="Split output based on column"
            description="Separates a consolidated transformed output (post template(s) application to one or multiple input files from one or multiple input locations) into separate output files of the same format containing a subset of the consolidated information as determined by values within a designated column."
            onClick={() => {
              setActiveRule(RULE_COMMAND_TYPES.GroupByCommand);
            }}
            showNote={false}
            noteText=""
            checked={activeRule === RULE_COMMAND_TYPES.GroupByCommand}
            disabled={(action !== "Edit" && doesGroupByCommandExist) || (action === "Edit" && activeRule !== RULE_COMMAND_TYPES.GroupByCommand)}
          >
            {activeRule === RULE_COMMAND_TYPES.GroupByCommand && (
              <>
                <div className="mb-8 mt-12">
                  <Text size="sm" weight="medium" variant="secondary">
                    Choose column to split output by
                  </Text>
                </div>
                <ArrayOptionDropdown options={columns} option={groupByCommandColumn} setOption={updateGroupByCommandColumn} objKey="columnName" />

                <div className="mb-8 mt-12">
                  <Text size="sm" weight="medium" variant="secondary">
                    Include first cell of this column in output name?
                  </Text>
                </div>
                <ArrayOptionDropdown
                  options={RULE_GROUP_BY_COMMAND_INCLUDE_FIRST_CELL.includeFirstCell}
                  option={includeFirstCell}
                  setOption={updateIncludeFirstCell}
                  objKey="option"
                />
              </>
            )}
          </RadioInput>

          <RadioInput
            title="Customise output columns"
            description="Select the columns from the assigned output mapping to include in generated output"
            onClick={() => {
              setActiveRule(RULE_COMMAND_TYPES.IncludeColumnsCommand);
            }}
            showNote={false}
            noteText=""
            checked={activeRule === RULE_COMMAND_TYPES.IncludeColumnsCommand}
            disabled={(action !== "Edit" && doesIncludeColumnsCommandExist) || (action === "Edit" && activeRule !== RULE_COMMAND_TYPES.IncludeColumnsCommand)}
          >
            {activeRule === RULE_COMMAND_TYPES.IncludeColumnsCommand && (
              <>
                <div className={[`${styles.columnsContainer}`, "w-100"].join(" ")}>
                  <div className={styles.containerHeader}>
                    <C8>
                      <Text variant="secondary" size="sm" weight="medium">
                        Selected: {includeColumnsCommandIndexes.length}
                      </Text>
                    </C8>
                    <C props="d-flex justify-content-center">
                      <LinkText size="sm" onClick={handleSelectAllColumns}>
                        <Text weight="regular" size="xs" variant="link">
                          Select all
                        </Text>
                      </LinkText>
                      <span className={styles.verticalDividerSpan}>|</span>
                      <LinkText size="sm" onClick={handleDeselectAllColumns}>
                        <Text weight="regular" size="xs" variant="link">
                          Deselect all
                        </Text>
                      </LinkText>
                    </C>
                  </div>

                  <div className="mt-12">
                    {columns?.map((col) => (
                      <div key={Math.random()} className="pr-12">
                        <Checkbox
                          spacers={["mr-12", "mt-0", "ml-12"]}
                          id={col.id}
                          value={col.columnOrderNumber - 1}
                          checked={includeColumnsCommandIndexes.includes(col.columnOrderNumber - 1)}
                          onClick={toggleColumn(col.columnOrderNumber - 1)}
                        />
                        <label htmlFor={col.id}>
                          <Text variant="secondary" size="sm" weight="regular">
                            {col.columnName}
                          </Text>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </RadioInput>
        </PanelBody>
        <PanelFooter>
          <Button color="tertiary" onClick={handleClose}>
            <Text size="sm">Cancel</Text>
          </Button>
          <Button onClick={handleSubmitApplyRule} size="md" disabled={newOutputMappingUuid === ""}>
            <Text size="sm">Apply rule</Text>
          </Button>
        </PanelFooter>
      </Panel>
    )
  );
});

export { AddRuleToApplyPanel };
