import { modalInstance, Button, Text, Modal, ModalBody, ModalSection, Error, ModalHeader, ModalText, ModalFooter } from "@fundrecs/ui-library";

const ErrorModal = ({ modalId, heading, text, confirm, onConfirm }) => {
  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalSection>
          <Error />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={heading} />
          <ModalSection />
          <ModalText text={text} />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          size="md"
          onClick={() => {
            onConfirm();
            modalInstance(modalId).toggle();
          }}
        >
          <Text size="sm">{confirm}</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ErrorModal };
