import { useEffect } from "react";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { Route, Routes, createSearchParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { WorkflowsList } from "./WorkflowsList/WorkflowsList";
import { WorkflowDetail } from "./WorkflowDetail/WorkflowDetail";
import { CreateWorkflow } from "./CreateWorkflow/CreateWorkflow";
import { useStore } from "../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { ConfigureWorkflow } from "./ConfigureWorkflow/ConfigureWorkflow";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { MODAL_IDS } from "utils/workflows/enums";
import { ROUTES } from "utils/enums";

const Workflows = observer(() => {
  let navigate = useNavigate();
  const teamId = useTeamId();
  const { workflowsStore } = useStore();

  useEffect(() => {
    if (teamId) workflowsStore.loadWorkflows(teamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const handleConfirmModal = () => {
    navigate({ pathname: ROUTES.WORKFLOWS, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <Routes>
        <ApmRoutes>
          <Route path="/" element={<WorkflowsList />} />
          <Route path=":workflowUuid" element={<WorkflowDetail />} />
          <Route path=":workflowUuid/configure" element={<ConfigureWorkflow />} />
        </ApmRoutes>
      </Routes>
      <CreateWorkflow />

      <ErrorModal
        modalId={MODAL_IDS.ERROR_WORKFLOW_NOT_FOUND}
        heading="Sorry, this workflow doesn't exist in your team workspace!"
        text="Please go to the workflows section and select from the list of all available workflows."
        confirm="Go to workflows"
        onConfirm={handleConfirmModal}
      />
    </>
  );
});

export { Workflows };
