import NameWrapper from "./NameWrapper";

/**
 * Represents the commands in the backend at the UI.
 * name => uiName,peName,uiDesc *
 * args => array of parameter for the command
 */

export default class UICommand {
  constructor(props) {
    this.name = NameWrapper.buildFromJson(props);

    this.argsSpec = props.argsSpec.map((argsJson) => {
      return NameWrapper.buildFromJson(argsJson);
    });

    this.dropDownKeyValuePair =
      props.dropDownKeyValuePair == undefined
        ? null
        : props.dropDownKeyValuePair.map((dropDownKeyValue) => {
            //If a uuid exists, this is a key value alias table object, otherwise it is a String for the Rate Command
            if (dropDownKeyValue.uuid != undefined) {
              //If lookupColumns exist this alias is for the LUMS service, where each table has multiple lookup cols
              if (dropDownKeyValue.lookupColumns != undefined) {
                // LUMS v2.0
                return {
                  uuid: dropDownKeyValue.uuid,
                  name: dropDownKeyValue.name,
                  deleted: dropDownKeyValue.deleted == "true",
                  lookupColumns: dropDownKeyValue.lookupColumns,
                };
              } else if (dropDownKeyValue.columns != undefined) {
                // LUMS v2.1
                return {
                  uuid: dropDownKeyValue.uuid,
                  name: dropDownKeyValue.name,
                  deleted: dropDownKeyValue.deleted,
                  columns: dropDownKeyValue.columns,
                  type: dropDownKeyValue.type,
                };
              } else {
                // AliasTables
                return {
                  uuid: dropDownKeyValue.uuid,
                  name: dropDownKeyValue.name,
                  deleted: dropDownKeyValue.deleted == "true",
                };
              }
            } else {
              return { name: dropDownKeyValue };
            }
          });
  }

  /**
   * Builds the list of commands available in backend from json response*
   * @param commandListJson
   * @returns {*}
   */
  static buildCommandListFromJson(commandListJson) {
    return commandListJson.map((commandJson) => {
      return new UICommand(commandJson);
    });
  }
}
