import { createSearchParams, Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  OUTPUT_DESTINATION_TYPES,
  RUN_RESULTS_STATUS,
  WORKFLOW_TRIGGER_DESCRIPTION,
  WORKFLOW_TRIGGER_TYPES,
  INPUT_SOURCE_TYPES,
  OUTPUT_BE_TYPES,
  WORKFLOW_RUN_DESTINATION,
} from "utils/workflows/enums";
import { ROW_STATUS_VALUES } from "utils/lookups/enums";
import { ROUTES, MIME_TYPE } from "utils/enums";
import { useStore } from "store/Store";
import { daysForInfoLabel } from "..";
import { createBrowserDownload } from "utils/file";
import { useTeamId } from "store/hooks/useTeamId";
import styles from "../../WorkflowRunResults/WorkflowRunResults.module.scss";

const OriginalFiles = (props) => {
  const { workflowsStore } = useStore();

  const {
    data: { metadata },
  } = props;

  return metadata.map((file) => {
    const { fileUploadedName, fileUploadedUuid } = file;
    return (
      <div
        className={[`${styles.downloadOriginalFile}`].join(" ")}
        onClick={() => {
          downloadRunResultFile(workflowsStore, fileUploadedName, fileUploadedUuid);
        }}
      >
        {fileUploadedName}
      </div>
    );
  });
};

const originalFilesFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    const {
      data: { metadata },
    } = value;

    switch (filter) {
      case "contains":
        return metadata.filter((data) => data.fileUploadedName.includes(filterText)).length > 0;
      case "notContains":
        return metadata.filter((data) => data.fileUploadedName.includes(filterText)).length === 0;
      case "equals":
        return (
          metadata.filter((data) => {
            return data.fileUploadedName === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          metadata.filter((data) => {
            return data.fileUploadedName !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          metadata.filter((data) => {
            return data.fileUploadedName.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          metadata.filter((data) => {
            return data.fileUploadedName.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const destinationTypes = (props) => {
  const {
    data: { metadata },
  } = props;

  return metadata.map((meta) => {
    const { destination } = meta;
    if (destination !== null) {
      const { type } = destination;
      return OUTPUT_DESTINATION_TYPES[type];
    }
  });
};

const RenderDestinationTypes = (props) => {
  return destinationTypes(props).map((destination) => {
    return <div>{destination}</div>;
  });
};

const destinationTypeComparator = (valueA, valueB, nodeA, nodeB) => {
  if (nodeA.data.metadata.length < nodeB.data.metadata.length) {
    return -1;
  } else if (nodeA.data.metadata.length > nodeB.data.metadata.length) {
    return 1;
  } else {
    return 0;
  }
};

const destinationTypeFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data.includes(filterText);
          }).length > 0
        );
      case "notContains":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data.includes(filterText);
          }).length === 0
        );
      case "equals":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          [...destinationTypes(value)].filter((data) => {
            return [null, undefined].includes(data) ? false : data.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const destinationFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return (
          destination(value).filter((data) => {
            return data.includes(filterText);
          }).length > 0
        );
      case "notContains":
        return (
          destination(value).filter((data) => {
            return data.includes(filterText);
          }).length === 0
        );

      case "equals":
        return (
          destination(value).filter((data) => {
            return data === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          destination(value).filter((data) => {
            return data !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          destination(value).filter((data) => {
            return data.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          destination(value).filter((data) => {
            return data.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const destination = (props) => {
  const {
    data: { metadata },
  } = props;

  return metadata.map((meta) => {
    const { destination } = meta;
    if (![null, undefined].includes(destination)) {
      const { type, destinationDisplayString } = destination;

      if (type === OUTPUT_BE_TYPES.FUSION_UI) {
        return WORKFLOW_RUN_DESTINATION[type];
      } else if (type === OUTPUT_BE_TYPES.RECONCILIATION) {
        return WORKFLOW_RUN_DESTINATION[type];
      } else {
        return destinationDisplayString;
      }
    }
  });
};

const RenderDestination = (props) => {
  return destination(props).map((destination) => {
    return <div>{destination}</div>;
  });
};

const sourceTypes = (props) => {
  const {
    data: { metadata = [] },
  } = props;

  return metadata.map((meta) => {
    const { source } = meta;
    if (![undefined, null].includes(source)) {
      const { type } = source;
      return INPUT_SOURCE_TYPES[type];
    }
  });
};

const RenderSourceTypes = (props) => {
  return sourceTypes(props).map((type) => {
    return <div>{type}</div>;
  });
};

const sourceTypeParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return (
          sourceTypes(value).filter((data) => {
            return data.includes(filterText);
          }).length > 0
        );
      case "notContains":
        return (
          sourceTypes(value).filter((data) => {
            return !data.includes(filterText);
          }).length === 0
        );
      case "equals":
        return (
          sourceTypes(value).filter((data) => {
            return data === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          sourceTypes(value).filter((data) => {
            return data !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          sourceTypes(value).filter((data) => {
            return data.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          sourceTypes(value).filter((data) => {
            return data.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const source = (props) => {
  const {
    data: { metadata },
  } = props;

  return metadata.map(({ source }) => {
    const {
      type,
      sourceDisplayString,
      uploadedByUser: { name },
    } = source ?? { uploadedByUser: { name: "" } };

    if (type === OUTPUT_BE_TYPES.FUSION_UI) {
      return "Uploaded by ".concat(name);
    } else {
      return sourceDisplayString;
    }
  });
};

const RenderSource = (props) => {
  return source(props).map((source) => {
    return <div>{source}</div>;
  });
};

const sourceParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return (
          source(value).filter((data) => {
            return data.includes(filterText);
          }).length > 0
        );
      case "notContains":
        return (
          source(value).filter((data) => {
            return data.includes(filterText);
          }).length === 0
        );

      case "equals":
        return (
          source(value).filter((data) => {
            return data === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          source(value).filter((data) => {
            return data !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          source(value).filter((data) => {
            return data.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          source(value).filter((data) => {
            return data.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const Templates = (props) => {
  const teamId = useTeamId();

  const {
    data: { metadata },
  } = props;

  const templates = metadata.map((meta) => {
    const { templateName, templateUuid } = meta;
    return {
      templateName,
      templateUuid,
    };
  });

  return templates.map((template) => {
    return (
      <div>
        <Link className={styles.link} to={{ pathname: `${ROUTES.TEMPLATES}/${template.templateUuid}`, search: `?${createSearchParams({ teamId: teamId })}` }}>
          {template.templateName}
        </Link>
      </div>
    );
  });
};

const templatesName = (props) => {
  const {
    data: { metadata },
  } = props;

  return metadata.map((meta) => {
    const { templateName } = meta;
    return {
      templateName,
    };
  });
};

const templatesFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return (
          templatesName(value).filter((data) => {
            return data.templateName.includes(filterText);
          }).length > 0
        );
      case "notContains":
        return (
          templatesName(value).filter((data) => {
            return data.templateName.includes(filterText);
          }).length === 0
        );
      case "equals":
        return (
          templatesName(value).filter((data) => {
            return data.templateName === filterText;
          }).length > 0
        );
      case "notEqual":
        return (
          templatesName(value).filter((data) => {
            return data.templateName !== filterText;
          }).length > 0
        );
      case "startsWith":
        return (
          templatesName(value).filter((data) => {
            return data.templateName.startsWith(filterText);
          }).length > 0
        );
      case "endsWith":
        return (
          templatesName(value).filter((data) => {
            return data.templateName.endsWith(filterText);
          }).length > 0
        );
      default:
        return false;
    }
  },
};

const TriggerType = (props) => {
  const {
    data: {
      metadata: [meta],
    },
  } = props;

  const { triggerType } = meta;

  return WORKFLOW_TRIGGER_TYPES[triggerType] ?? "";
};

const triggerTypeComparator = (valueA, valueB, nodeA, nodeB) => {
  if (TriggerType(nodeA) < TriggerType(nodeB)) {
    return -1;
  }
  if (TriggerType(nodeA) > TriggerType(nodeB)) {
    return 1;
  }
  return 0;
};

const triggerTypeParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return TriggerType(value).includes(filterText);
      case "notContains":
        return !TriggerType(value).includes(filterText);
      case "equals":
        return TriggerType(value) === filterText;
      case "notEqual":
        return TriggerType(value) !== filterText;
      case "startsWith":
        return TriggerType(value).startsWith(filterText);
      case "endsWith":
        return TriggerType(value).endsWith(filterText);
      default:
        return false;
    }
  },
};

const getRunContent = (trigger) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const { type, params = {} } = trigger;
  const { daysOfWeek = [] } = params || {};
  const runDays = daysOfWeek?.length > 0 ? (daysOfWeek?.length === 7 ? "every day" : `every ${daysForInfoLabel(daysOfWeek) ?? "day"}`) : "";
  switch (type) {
    case "AUTO":
      return WORKFLOW_TRIGGER_DESCRIPTION.AUTO;
    case "SCHEDULE":
      const { minuteOfHour, hourOfDay } = params;
      return `Run at ${("0" + hourOfDay).slice(-2)}:${("0" + minuteOfHour).slice(-2)} (${timeZone}) ${runDays + "."} `;
    case "INTERVAL":
      const { intervalUnit, interval, startTime = "", endTime = "" } = params;
      return `Run every ${
        interval === 1
          ? ` 1 ${_.startCase(_.camelCase(intervalUnit.toLowerCase()))}(s)`
          : `${interval} ${_.startCase(_.camelCase(intervalUnit.toLowerCase()))}(s)`
      } from  ${startTime.toString().concat(":00")} to ${endTime.toString().concat(":00")}  ${runDays}.`;
    case "MANUAL":
      return params?.triggerDisplayString ?? "";
    default:
      return "";
  }
};

const Trigger = (props) => {
  const {
    data: {
      metadata: [meta],
    },
  } = props;

  const { triggerType, triggerParams } = meta;

  const triggerDetails = { ...{ params: triggerParams }, ...{ type: triggerType } };

  return triggerType === WORKFLOW_TRIGGER_TYPES.AUTO ? WORKFLOW_TRIGGER_DESCRIPTION.AUTO : getRunContent(triggerDetails);
};

const triggerComparator = (valueA, valueB, nodeA, nodeB) => {
  if (Trigger(nodeA) < Trigger(nodeB)) {
    return -1;
  }
  if (Trigger(nodeA) > Trigger(nodeB)) {
    return 1;
  }
  return 0;
};

const triggerParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return Trigger(value).includes(filterText);
      case "notContains":
        return !Trigger(value).includes(filterText);
      case "equals":
        return Trigger(value) === filterText;
      case "notEqual":
        return Trigger(value) !== filterText;
      case "startsWith":
        return Trigger(value).startsWith(filterText);
      case "endsWith":
        return Trigger(value).endsWith(filterText);
      default:
        return false;
    }
  },
};

const statusParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    const {
      data: { state },
    } = value;

    const status = ROW_STATUS_VALUES[state];

    switch (filter) {
      case "contains":
        return status.includes(filterText);
      case "notContains":
        return !status.includes(filterText);
      case "equals":
        return status === filterText;
      case "notEqual":
        return status !== filterText;
      case "startsWith":
        return status.startsWith(filterText);
      case "endsWith":
        return status.endsWith(filterText);
      default:
        return false;
    }
  },
};

const downloadRunResultFile = async (workflowsStore, fileName, fileUuid) => {
  const fileData = await workflowsStore.getRunResultFileData(fileUuid);

  let fileExt = fileName.split(".").pop().toUpperCase();

  if (fileExt === "CSV") {
    createBrowserDownload(fileName, fileData, MIME_TYPE.CSV);
  } else if (fileExt === "XLSX") {
    createBrowserDownload(fileName, fileData, MIME_TYPE.XLSX);
  } else if (fileExt === "XML") {
    createBrowserDownload(fileName, fileData, MIME_TYPE.XML);
  } else {
    createBrowserDownload(fileName, fileData, MIME_TYPE.DEFAULT);
  }
};

const timeTakenFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return durationTimeFilterText(value) !== null ? durationTimeFilterText(value).includes(filterText) : false;
      case "notContains":
        return durationTimeFilterText(value) !== null ? !durationTimeFilterText(value).includes(filterText) : false;
      case "equals":
        return durationTimeFilterText(value) !== null ? durationTimeFilterText(value) === filterText : false;
      case "notEqual":
        return durationTimeFilterText(value) !== null ? durationTimeFilterText(value) !== filterText : false;
      case "startsWith":
        return durationTimeFilterText(value) !== null ? durationTimeFilterText(value).startsWith(filterText) : false;
      case "endsWith":
        return durationTimeFilterText(value) !== null ? durationTimeFilterText(value).endsWith(filterText) : false;
      default:
        return false;
    }
  },
};

const durationTimeFilterText = (params) => {
  const {
    data: { duration, state },
  } = params;
  if (state === RUN_RESULTS_STATUS.IN_PROGRESS) {
    return "-";
  } else {
    return `${moment.duration(duration).asSeconds()} sec`;
  }
};

const durationTime = (params) => {
  return `${moment.duration(params.value).asSeconds()} sec`;
};

const timeFilter = (filter, value, filterText, timeType) => {
  const {
    data: { [timeType]: resultTime },
  } = value;

  const time = moment(resultTime).format("D MMM YYYY, HH:mm:ss");

  switch (filter) {
    case "contains":
      return time.includes(filterText);
    case "notContains":
      return !time.includes(filterText);
    case "equals":
      return time === filterText;
    case "notEqual":
      return time !== filterText;
    case "startsWith":
      return time.startsWith(filterText);
    case "endsWith":
      return time.endsWith(filterText);
    default:
      return false;
  }
};

const timeFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    return timeFilter(filter, value, filterText, "startTimestamp");
  },
};

const reportName = (params) => {
  const {
    data: { metadata },
  } = params;

  return metadata
    ? metadata
        .map((m) => m.outputFileName ?? m.failureReasonMessage ?? "-")
        // Get unique values
        .filter((value, index, array) => array.indexOf(value) === index)
        .join("\n")
    : "-";
};

const resultFilterParams = {
  valueGetter: function (params) {
    return params;
  },
  textFormatter: function (r) {
    return r;
  },
  textCustomComparator: function (filter, value, filterText) {
    switch (filter) {
      case "contains":
        return reportName(value) !== null ? reportName(value).includes(filterText) : false;
      case "notContains":
        return reportName(value) !== null ? !reportName(value).includes(filterText) : false;
      case "equals":
        return reportName(value) !== null ? reportName(value) === filterText : false;
      case "notEqual":
        return reportName(value) !== null ? reportName(value) !== filterText : false;
      case "startsWith":
        return reportName(value) !== null ? reportName(value).startsWith(filterText) : false;
      case "endsWith":
        return reportName(value) !== null ? reportName(value).endsWith(filterText) : false;
      default:
        return false;
    }
  },
};

const Time = (params) => {
  return params.value !== null ? moment(params.value).format("D MMM YYYY, HH:mm:ss") : "-";
};

const WorkflowName = (props) => {
  const { meStore } = useStore();
  const teamId = useTeamId();

  const {
    data: { workflowName, workflowUuid, startTimestamp },
  } = props;

  return (
    <Link
      className={styles.link}
      to={{
        pathname: `${ROUTES.WORKFLOWS}/${workflowUuid}`,
        search: `?${createSearchParams({ teamId: teamId })}&date=${moment(startTimestamp).format(meStore.getUserDateFormat())}`,
      }}
    >
      {workflowName}
    </Link>
  );
};

const RenderRowsExtracted = (props, formatting = true) => {
  return props?.data?.metadata?.map((meta) => {
    const { rowsExtracted } = meta;
    return formatting ? <div>{rowsExtracted}</div> : rowsExtracted;
  });
};

const rowsExtractedFilterParams = {
  valueGetter: (params) => params,
  filterOptions: [
    {
      displayKey: "equals",
      displayName: "Equals",
      predicate: ([filterValue], cellValue) => {
        return cellValue === null ? false : RenderRowsExtracted(cellValue, false).filter((x) => x === filterValue).length > 0;
      },
    },
    {
      displayKey: "notEqual",
      displayName: "Not Equal",
      predicate: ([filterValue], cellValue) => {
        return cellValue === null ? false : RenderRowsExtracted(cellValue, false).filter((x) => x !== filterValue).length > 0;
      },
    },
    {
      displayKey: "lessThan",
      displayName: "Less Than",
      predicate: ([filterValue], cellValue) => {
        return cellValue === null ? false : RenderRowsExtracted(cellValue, false).filter((x) => x < filterValue).length > 0;
      },
    },
    {
      displayKey: "greaterThan",
      displayName: "Greater Than",
      predicate: ([filterValue], cellValue) => {
        return cellValue === null ? false : RenderRowsExtracted(cellValue, false).filter((x) => x > filterValue).length > 0;
      },
    },
  ],
};

export {
  WorkflowName,
  OriginalFiles,
  RenderDestinationTypes,
  Templates,
  downloadRunResultFile,
  durationTime,
  timeFilter,
  Time,
  TriggerType,
  Trigger,
  RenderSourceTypes,
  RenderSource,
  RenderDestination,
  sourceTypes,
  RenderRowsExtracted,
  originalFilesFilterParams,
  destinationTypeFilterParams,
  templatesFilterParams,
  timeFilterParams,
  resultFilterParams,
  destinationFilterParams,
  timeTakenFilterParams,
  triggerParams,
  statusParams,
  sourceTypeParams,
  sourceParams,
  triggerTypeParams,
  rowsExtractedFilterParams,
  destinationTypeComparator,
  triggerTypeComparator,
  triggerComparator,
  destinationTypes,
  destination,
  source,
};
