import { useState, useContext } from "react";
import { Text, Button } from "@fundrecs/ui-library";
import { FixedOptionDropdown } from "../../DropdownOptions/FixedOptionDropdown";
import { workflowsStore } from "store/Store";
import { ObjectOptionDropdown } from "../../DropdownOptions/ObjectOptionDropdown";
import { ScheduledTriggerView } from "./scheduledTrigger/ScheduledTriggerView";
import { observer } from "mobx-react-lite";
import {
  WORKFLOW_TRIGGER_TYPES,
  WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL,
  WORKFLOW_TRIGGER_SCHEDULE_TYPES,
  WORKFLOW_TRIGGER_DESCRIPTION,
} from "utils/workflows/enums";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";
import { useTeamId } from "store/hooks/useTeamId";
import styles from "./TriggerEditSection.module.scss";

const DEFAULT_DAYS_OF_WEEK = [1, 2, 3, 4, 5, 6, 7];
const DEFAULT_HOUR = "8";
const DEFAULT_END_HOUR = "9";
const DEFAULT_MINUTE = "00";
const DEFAULT_RECURRING_VALUE = "1";
const DEFAULT_RECURRING_INTERVAL = WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS;

const TriggerEditSection = observer(({ trigger, setEditable }) => {
  const teamId = useTeamId();

  const { workflow } = useContext(WorkflowConfigurationContext);

  const { triggerUuid, type, params } = trigger;

  const triggers = workflow.getCurrentWorkflowTriggers();

  const getTriggerIndex = () => {
    return triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid);
  };

  const getTriggerTypeIndex = (type) => {
    return Object.values(WORKFLOW_TRIGGER_TYPES).indexOf(WORKFLOW_TRIGGER_TYPES[type]);
  };

  const getTriggerType = (type) => {
    let triggerType = "";
    if (type === "AUTO") {
      triggerType = WORKFLOW_TRIGGER_TYPES.AUTO;
    }
    if (type === "SCHEDULE" || type === "INTERVAL") {
      triggerType = WORKFLOW_TRIGGER_TYPES.SCHEDULE;
    }
    return triggerType;
  };

  const getScheduleType = (type) => {
    let scheduleType = "";
    if (type === "SCHEDULE") {
      scheduleType = WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC;
    }
    if (type === "INTERVAL") {
      scheduleType = WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING;
    }
    return scheduleType;
  };

  const getScheduledHour = () => {
    if (type !== WORKFLOW_TRIGGER_TYPES.AUTO) {
      const hourOfDay = params && params.hourOfDay;
      if (hourOfDay == null) {
        return DEFAULT_HOUR;
      } else {
        return hourOfDay;
      }
    }
  };

  const getScheduledMinute = () => {
    const minuteOfHour = params && params.minuteOfHour;

    if (minuteOfHour == null || minuteOfHour === 0) {
      return DEFAULT_MINUTE;
    } else {
      return minuteOfHour;
    }
  };

  const getRecurringValue = () => {
    const interval = params && params.interval;

    if (interval == null) {
      return DEFAULT_RECURRING_VALUE;
    } else {
      return interval;
    }
  };

  const getRecurringInterval = () => {
    const intervalUnit = params && params.intervalUnit;

    if (intervalUnit == null) {
      return DEFAULT_RECURRING_INTERVAL;
    } else {
      if (intervalUnit === "HOUR") {
        return "Hour(s)";
      } else {
        return "Minute(s)";
      }
    }
  };

  const getDaysOfWeek = () => {
    const daysOfWeek = params && params.daysOfWeek;

    if ([null, undefined].includes(daysOfWeek) || daysOfWeek.length === 0) {
      return DEFAULT_DAYS_OF_WEEK;
    } else {
      return daysOfWeek;
    }
  };

  const getStartTime = () => {
    const startTime = params && params.startTime;

    if (startTime == null) {
      return DEFAULT_HOUR + ":00";
    } else {
      return startTime + ":00";
    }
  };

  const getEndTime = () => {
    const endTime = params && params.endTime;

    if (endTime == null) {
      return DEFAULT_END_HOUR + ":00";
    } else {
      return endTime + ":00";
    }
  };

  const [triggerTypeOption, setTriggerTypeOption] = useState({
    index: getTriggerTypeIndex(type),
    ...{ ...trigger, ...{ type: getTriggerType(type) } },
  });

  const [scheduleType, setScheduleType] = useState(getScheduleType(type));
  const [scheduledHour, setScheduledHour] = useState(getScheduledHour());
  const [scheduledMinute, setScheduledMinute] = useState(getScheduledMinute());
  const [recurringValue, setRecurringValue] = useState(getRecurringValue());
  const [recurringInterval, setRecurringInterval] = useState(getRecurringInterval());
  const [daysOfWeek, setDaysOfWeek] = useState(getDaysOfWeek());
  const [startTime, setStartTime] = useState(getStartTime());
  const [endTime, setEndTime] = useState(getEndTime());
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { timezone, setTimezone } = useState(timeZone);

  const updateTriggerTypeOption = (option) => {
    const { type: triggerType } = option;

    if (triggerType === WORKFLOW_TRIGGER_TYPES.SCHEDULE) {
      setScheduleType(WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC);
    }

    setTriggerTypeOption(option);
  };

  const saveTrigger = () => {
    const { type } = triggerTypeOption;
    const triggerTypeString =
      type === WORKFLOW_TRIGGER_TYPES.AUTO ? "AUTO" : scheduleType === WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC ? "SCHEDULE" : "INTERVAL";

    const updateTrigger = new Promise((resolve) => {
      let currentWorkflowTrigger = (workflow.configuration.triggers[getTriggerIndex()] = {
        ...(triggerTypeString === "AUTO" && {
          type: triggerTypeString,
          params: {},
        }),
        ...(triggerTypeString === "SCHEDULE" &&
          scheduleType === WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC && {
            type: triggerTypeString,
            params: { minuteOfHour: scheduledMinute, hourOfDay: scheduledHour, daysOfWeek: daysOfWeek, timezone: timeZone },
          }),
        ...(triggerTypeString === "INTERVAL" &&
          scheduleType === WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING && {
            type: triggerTypeString,
            params: {
              intervalUnit: recurringInterval === WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS ? "HOUR" : "MINUTE",
              interval: recurringValue,
              daysOfWeek: daysOfWeek,
              timezone: timeZone,
              startTime: startTime.substring(0, startTime.indexOf(":")),
              endTime: endTime.substring(0, endTime.indexOf(":")),
            },
          }),
        triggerUuid: workflow.configuration.triggers[getTriggerIndex()]["triggerUuid"],
        triggerName: workflow.configuration.triggers[getTriggerIndex()]["triggerName"],
      });

      resolve(workflowsStore.updateReadOnlyWorkflowTrigger(currentWorkflowTrigger, getTriggerIndex()));
    });
    updateTrigger.then(() => {
      trigger.saved = true;
      setEditable(false);
      workflowsStore.removeEditableTrigger(triggerUuid);
      workflowsStore.updateWorkflowTrigger(teamId, workflow.uuid, workflowsStore.getReadOnlyWorkflow());
    });
  };

  const cancelEditingTrigger = () => {
    if (triggers.length === 1 && triggers[getTriggerIndex()]["saved"] === false) {
      //We only have one trigger we reset trigger to null when we hit cancel
      triggers[getTriggerIndex()]["triggerUuid"] = null;
    } else if (triggers[getTriggerIndex()]["saved"] === false) {
      //This is a new trigger, we have more than 1 but this one hasn't been saved.
      triggers.splice(
        triggers.findIndex((trigger) => trigger.triggerUuid === triggerUuid),
        1
      );
      //We remove this trigger from the editable list
      workflowsStore.removeEditableTrigger(triggerUuid);
    } else if (triggers[getTriggerIndex()]["saved"] === undefined) {
      //We need to find existing trigger in read only workflow and reset it when we cancel
      const existingTrigger = workflowsStore.getReadOnlyWorkflowTriggers().filter((trigger) => {
        return trigger.triggerUuid === triggers[getTriggerIndex()]["triggerUuid"];
      });

      if (existingTrigger.length > 0) {
        const [oldTrigger] = existingTrigger;
        triggers[getTriggerIndex()] = oldTrigger;
      }

      setEditable(false);

      //This is for triggers which have already been saved.
      workflowsStore.removeEditableTrigger(triggerUuid);
    }
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { MANUAL, ...WORKFLOW_TRIGGER_TYPES_EXCLUDING_MANUAL } = WORKFLOW_TRIGGER_TYPES;

  return (
    <div className={styles.triggerContainer}>
      <ObjectOptionDropdown
        label="Choose trigger type"
        options={WORKFLOW_TRIGGER_TYPES_EXCLUDING_MANUAL}
        option={triggerTypeOption}
        setOption={updateTriggerTypeOption}
        disabled={false}
        objKey="type"
      />
      {WORKFLOW_TRIGGER_TYPES.AUTO === triggerTypeOption.type && (
        <>
          <FixedOptionDropdown label="Choose the event that triggers the workflow to start a run" value={WORKFLOW_TRIGGER_DESCRIPTION.AUTO} />
          <Text size="md" weight="medium">
            Summary: Trigger workflow to run when new data comes in from its source
          </Text>
        </>
      )}
      {WORKFLOW_TRIGGER_TYPES.SCHEDULE === triggerTypeOption.type && (
        <ScheduledTriggerView
          {...{
            scheduleType,
            setScheduleType,
            daysOfWeek,
            setDaysOfWeek,
            scheduledHour,
            setScheduledHour,
            scheduledMinute,
            setScheduledMinute,
            recurringValue,
            setRecurringValue,
            recurringInterval,
            setRecurringInterval,
            timezone,
            setTimezone,
            startTime,
            setStartTime,
            endTime,
            setEndTime,
          }}
        />
      )}

      <div className="mb-8 mt-24">
        <Button
          size="md"
          color="primary"
          onClick={() => {
            saveTrigger();
          }}
          disabled={daysOfWeek.length === 0}
        >
          <Text size="sm" weight="medium">
            Save trigger
          </Text>
        </Button>
        <span className="ml-12">
          <Button
            size="md"
            color="tertiary"
            onClick={() => {
              cancelEditingTrigger();
            }}
            disabled={false}
          >
            <Text size="sm" weight="medium">
              Cancel
            </Text>
          </Button>
        </span>
      </div>
    </div>
  );
});

export { TriggerEditSection };
