import { LinkText } from "@fundrecs/ui-library";

const DeleteRow = (params) => {
  const handleDelete = () => {
    const { api, node } = params;

    api.applyTransactionAsync({ remove: [node.data] }, () => {
      const newData = [];
      api.forEachNode((rowNode) => {
        newData.push(rowNode.data);
      });
      api.setRowData(newData);
      api.refreshCells();
    });
  };

  return (
    <span
      onClick={() => {
        handleDelete();
      }}
    >
      <LinkText>Delete</LinkText>
    </span>
  );
};

export { DeleteRow };
