/**
 * Represents the File parsers and their available properties with default arguments.
 */

export default class ParsersDesc {
  constructor(props) {
    this.uiName = props.uiName;
    this.parserName = props.parserName;
    this.defaultOptions = props.defaultOptions;
  }

  /**
   * Builds the list of parsers description as defined by backend json response*
   * @param parserDescList
   * @returns {*}
   */
  static buildParserDescFromJson(parserDescList) {
    return parserDescList.map((parserDesc) => {
      return new ParsersDesc(parserDesc);
    });
  }
}
