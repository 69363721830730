import { IconDownload, ToolTip } from "@fundrecs/ui-library";
import styles from "./DownloadTable.module.scss";

/**
 * DownloadTable Component
 * When passing a gridRef you must pass the api & column api. The rest of the props are optional.
 *
 * @param {Object} props - Component props
 * @param {Object} props.gridRef - Reference to the AG Grid instance
 * @param {string|function} [props.fileName] - Name of the CSV file to be created or a function that returns a string
 * @param {string} [props.direction] - Tooltip direction for the download button
 * @param {boolean} [props.excludeFirstColumn] - Exclude the first column from the export
 * @param {boolean} [props.excludeLastColumn] - Exclude the last column from the export
 * @param {Array} [props.includeColumns] - List of column IDs to include in the export
 * @param {boolean} [props.excludeEmpty] - Exclude columns with empty headers from the export
 * @param {function} [props.processCellCallback] - Optional custom cell processing callback function
 */
export const DownloadTable = ({
  gridRef,
  fileName = "exportCSV",
  direction = "top",
  excludeFirstColumn = false,
  excludeLastColumn = false,
  includeColumns = null,
  excludeEmpty = false,
  processCellCallback,
}) => (
  <button
    className={styles.downloadContainer}
    onClick={() => {
      if (!gridRef || !gridRef.api || !gridRef.columnApi) {
        console.error("Grid reference is not properly provided or initialised.");
        return;
      }

      const getFileName = () => (typeof fileName === "function" ? fileName() : fileName);

      let allColumns = gridRef.columnApi.getAllColumns();

      // Exclude columns with empty headers if excludeEmpty is true
      if (excludeEmpty) {
        allColumns = allColumns.filter((column) => column.getColDef().headerName);
      }

      const allColumnIds = allColumns.map((column) => column.getColId());
      let columnsToExport = allColumnIds;

      // If includeColumns is provided, filter the columns to export
      if (includeColumns) {
        columnsToExport = columnsToExport.filter((colId) => includeColumns.includes(colId));
      }

      // Exclude the first column if the prop is set to true
      if (excludeFirstColumn) {
        columnsToExport = columnsToExport.slice(1);
      }

      // Exclude the last column if the prop is set to true
      if (excludeLastColumn) {
        columnsToExport = columnsToExport.slice(0, -1);
      }

      const exportParams = {
        fileName: getFileName(),
        columnKeys: columnsToExport,
      };

      if (processCellCallback) {
        exportParams.processCellCallback = processCellCallback;
      }

      gridRef.api.exportDataAsCsv(exportParams);
    }}
  >
    <ToolTip text="Download Table" direction={direction} size="contain">
      <IconDownload className={styles.downloadIcon} />
    </ToolTip>
  </button>
);
