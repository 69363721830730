import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Text, Button, IconAdd, Input } from "@fundrecs/ui-library";
import { Tag } from "./Tag";

import styles from "./LookupTagsEditor.module.scss";

const LookupTagsEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [input, setInput] = useState("");
  const [inputError, setinputError] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const handleAddTag = () => {
    if (input.length === 0) {
      setinputError(true);
    } else {
      setValue((prevState) => [...prevState, input]);
      setInput("");
    }
  };

  const handleDeleteTag = (tag) => {
    setValue((prevState) => prevState.filter((t) => t !== tag));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setinputError(value.length === 0);
  };

  return (
    <div className={[styles.editor, "pt-12"].join(" ")}>
      <div className={[styles.scrollTags, "d-flex flex-wrap"].join(" ")}>
        {value && value.map((tag, index) => <Tag key={index} value={tag} canDelete={true} handleDelete={() => handleDeleteTag(tag)} />)}
      </div>

      <div className="d-flex pt-8 pb-12">
        <div className="flex-grow-1 pr-8">
          <Input onChange={onChange} value={input} placeholderText="Add a tag" size="md" warningMessage="Enter Tag value" warning={inputError} />
        </div>
        <div className="pr-8">
          <Button
            size="md"
            onClick={() => {
              handleAddTag();
            }}
          >
            <IconAdd className="btn-sm-svg" />
            <Text size="sm" weight="regular">
              Add tag
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
});

LookupTagsEditor.propTypes = {
  value: PropTypes.array,
};

LookupTagsEditor.defaultProps = {};

export { LookupTagsEditor };
