import { useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Text, Input, TextArea, IconRightArrow } from "@fundrecs/ui-library";
import { SelectMethodHeading } from "../../reusable/selectMethod/selectMethodHeading";
import { useStore } from "../../../store/Store";
import { FixedFooter } from "components/layout/FixedFooter";
import { useTeamId } from "store/hooks/useTeamId";

const CreateOutputMapping = observer(({ setActiveStep }) => {
  const { outputMappingsStore } = useStore();
  const { workflowUuid } = useParams();
  const teamId = useTeamId();

  const [outputMapping, setOutputMapping] = useState({ name: "", description: "" });

  const MAX_OUTPUT_MAPPING_NAME = 100;
  const MAX_OUTPUT_DESCRIPTION = 150;

  const checkStep02Completed = () => {
    const { name, description } = outputMapping;
    const outputMappingName = name.length > 0;
    const outputMappingDescription = description.length > 0;

    const stages = [outputMappingName, outputMappingDescription];

    if (stages.every((stage) => stage === true)) {
      return false;
    } else {
      return true;
    }
  };

  const requestCreateOutputMapping = async () => {
    const requestObject = {
      workflowUuid: workflowUuid,
      teamId: teamId,
      name: outputMapping.name,
      description: outputMapping.description,
    };

    const response = await outputMappingsStore.createOutputMapping(requestObject);
    const { success } = response;
    if (success) {
      setActiveStep({ name: "STEP 03", index: 3 });
    }
  };

  return (
    <>
      <div className="ml-72 mr-72">
        <div className="text-left">
          <SelectMethodHeading
            heading={"Create new output mapping"}
            description={
              "This is the template for the output file this workflow generates. It should contain all the columns you want your output file, in the order you need. Let's start with giving it a name and short description."
            }
            align="left"
          />

          <div className="mt-48">
            <div className="mb-24">
              <div className="mb-8 d-flex">
                <Text size="sm" weight="medium">
                  Output mapping name *
                </Text>
                <div className="ms-auto">
                  {outputMapping.name.length}
                  {MAX_OUTPUT_MAPPING_NAME && `/${MAX_OUTPUT_MAPPING_NAME}`}
                </div>
              </div>
              <Input
                value={outputMapping.name}
                onChange={(event) => {
                  if (event.target.value.length <= MAX_OUTPUT_MAPPING_NAME) {
                    setOutputMapping({ name: event.target.value.trimStart(), description: outputMapping.description });
                  }
                }}
                placeholderText="Add output mapping name"
              />
            </div>

            <div className="mb-24">
              <div className="mb-8 d-flex">
                <div>
                  <Text size="sm" weight="medium">
                    Description *
                  </Text>
                </div>
                <div className="ms-auto">
                  {outputMapping.description.length}
                  {MAX_OUTPUT_DESCRIPTION && `/${MAX_OUTPUT_DESCRIPTION}`}
                </div>
              </div>

              <TextArea
                onChange={(event) => {
                  if (event.target.value.length <= MAX_OUTPUT_DESCRIPTION) {
                    setOutputMapping({ description: event.target.value.trimStart(), name: outputMapping.name });
                  }
                }}
                value={outputMapping.description}
                placeholderText={outputMapping.description.length > 0 ? "" : "Add a description"}
              />
            </div>
          </div>
        </div>
      </div>
      <FixedFooter>
        <Button
          size="lg"
          color="primary"
          disabled={checkStep02Completed()}
          onClick={() => {
            requestCreateOutputMapping();
          }}
        >
          <Text size="md" weight="medium">
            Next - output columns
          </Text>
          <IconRightArrow />
        </Button>
      </FixedFooter>
    </>
  );
});

export { CreateOutputMapping };
