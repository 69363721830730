import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const API_PREFIX = "/teams";

const outputMappings = {
  getOutputMappings: ({ teamId }) => fusionRequest.get(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS_ADDITIONAL_DATA}`),
  getApprovedOutputMappings: ({ teamId }) => fusionRequest.get(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.APPROVED_OUTPUT_MAPPINGS}`),

  createOutputMapping: ({ teamId, requestBody }) => fusionRequest.post(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}`, requestBody),

  getOutputMappingsColumns: ({ teamId, outputMappingId }) =>
    fusionRequest.get(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.COLUMNS}`),
  createOutputMappingColumn: ({ teamId, outputMappingId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.COLUMNS}`,
      requestBody
    ),
  updateOutputMappingColumn: ({ teamId, outputMappingId, columnId, requestBody }) =>
    fusionRequest.patch(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.COLUMNS}/${columnId}`,
      requestBody
    ),

  // This endpoint expects ALL columns
  updateOutputMappingColumnsOrder: ({ teamId, outputMappingId, requestBody }) =>
    fusionRequest.patch(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.COLUMNS}/order`,
      requestBody
    ),

  deleteOutputMappingsColumns: ({ teamId, outputMappingId, columnId }) =>
    fusionRequest.delete(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.COLUMNS}/${columnId}`
    ),
  getOutputMappingById: ({ teamId, outputMappingId }) =>
    fusionRequest.get(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPING_ADDITIONAL_DATA}`
    ),

  updateMappingDetails: ({ teamId, outputMappingId, requestBody }) =>
    fusionRequest.patch(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}`, requestBody),

  deleteOutputMapping: ({ teamId, outputMappingId }) =>
    fusionRequest.delete(`${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}`),

  approveOutputMapping: ({ teamId, outputMappingId }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.APPROVE_OUTPUT_MAPPING}`
    ),

  cloneOutputMapping: ({ teamId, outputMappingId }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.CLONE_OUTPUT_MAPPING}`
    ),

  shareOutputMapping: ({ teamId, outputMappingId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.OUTPUT_MAPPINGS}/${outputMappingId}/${API_ENDPOINTS.OUTPUT_MAPPINGS.SHARE_OUTPUT_MAPPING}`,
      requestBody
    ),
};

export { outputMappings };
