/**
 * Created by Naman on 10/17/2016.
 */

import SelectOptions from "./SelectOptions";
import IndexedValue from "./IndexedValue";
import PropTypes from "prop-types";

export default class SelectLineItem {
  constructor(props) {
    this.subCommand = props.subCommand;
    this.indexedArg1 = props.indexedArg1;
    this.indexedArg2 = props.indexedArg2;
  }
}

SelectLineItem.propTypes = {
  subCommand: PropTypes.instanceOf(SelectOptions),
  indexedArg1: PropTypes.instanceOf(IndexedValue),
  indexedArg2: PropTypes.instanceOf(IndexedValue),
};
