/**
 * Created by Naman on 10/11/2016.
 */

import postal from "postal";

const tbAppChannel = postal.channel("tbApp");
const selectAndTransfromChannel = postal.channel("selectAndTransfrom");
const commandParamsChannel = postal.channel("commandParams");
const fundsDataTableChannel = postal.channel("datatableChannel");
const loadingChannel = postal.channel("loadingChannel");

export {
  tbAppChannel,
  selectAndTransfromChannel,
  commandParamsChannel,
  fundsDataTableChannel,
  loadingChannel,
};
