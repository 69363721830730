import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Input, Button, List, ListItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { appSingleton } from "../utils/AppSingleton";
import HttpMediator from "../utils/http/HttpMediator";

export default class FileChanger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileName: undefined, fileS3Urn: undefined };
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    let previousFiles = [];
    previousFiles = appSingleton.allFileS3Urns.map((fileAndUrn, index) => {
      let opacity = 0.5;
      let backgroundColor = "#e8e6e3";
      if (fileAndUrn[1] == this.state.fileS3Urn || (index == 0 && this.state.fileS3Urn == undefined)) {
        opacity = 1;
        backgroundColor = "white";
      }

      return (
        <ListItem
          button
          key={index}
          style={{ opacity: opacity, backgroundColor: backgroundColor }}
          onClick={(event) => this.handleFileMenuItemClick(event, index)}
        >
          {fileAndUrn[0]}
        </ListItem>
      );
    });

    return (
      <Dialog open={true} style={{ backgroundColor: "#e8e6e3" }}>
        <DialogTitle id="simple-dialog-title">Change sample File</DialogTitle>
        <DialogContent className="robotoFont">
          <List>
            {previousFiles}
            <ListItem>
              <Input id="changeSampleFileInput" type="file" inputRef={(e3) => (this.file = e3)} />
            </ListItem>
          </List>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.changeSampleFile.bind(this)} color="primary">
            Apply
          </Button>
          <Button onClick={this.props.onClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  //users clicked on previous file
  handleFileMenuItemClick(event, fileIndex) {
    this.setState({
      fileName: appSingleton.allFileS3Urns[fileIndex][0],
      fileS3Urn: appSingleton.allFileS3Urns[fileIndex][1],
    });
  }

  changeSampleFile() {
    let formData = new FormData();
    //Check if a file has been selected in the file input, it may be blank.
    if (document.getElementById("changeSampleFileInput").value) {
      //fileToUploadIsNotLargerThanFileSizeLimit is a function in application.js
      //Using typeof, check that the function can be referenced from here
      var canReferenceApplicationJs = typeof fileToUploadIsNotLargerThanFileSizeLimit == "function";
      //If the file size is too large, it returns false and displays an error message
      if (canReferenceApplicationJs && !fileToUploadIsNotLargerThanFileSizeLimit("changeSampleFileInput")) {
        //Close the file uploader popup
        this.props.onClose();
        return;
      }
    }
    if (this.file != undefined) {
      if (this.file != undefined) {
        formData.append("file", this.file.files[0]);
      }

      if (this.state.fileName != undefined) {
        formData.append("fileName", this.state.fileName);
        formData.append("fileS3Urn", this.state.fileS3Urn);
      }
    }
    formData.append("pos", appSingleton.step);
    formData.append("templateUuid", appSingleton.templateUuid);

    HttpMediator.changeSampleFile(formData).then(() => {
      this.handleFileMenuItemClick(null, 0);
    });

    this.props.onClose();
  }
}

FileChanger.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
};
