import { observer } from "mobx-react-lite";
import { VerticalMenuDropdown, VerticalMenuListItem, Button, IconCheck, Text, modalInstance } from "@fundrecs/ui-library";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { MODAL_IDS, STATUS_VALUES } from "utils/mappings/enums";
import { rolesStore } from "store/Store";
import { useStore } from "store/Store";
import styles from "./HeaderButtons.module.scss";

const HeaderButtons = observer(({ teamId, onClose, handleStepCompleted, setActiveStep, onComplete }) => {
  const { outputMappingsStore } = useStore();
  const { uuid: outputMappingUuid, status, name } = outputMappingsStore.getOutputMapping();

  const handleApproveAndExit = async () => {
    const { success } = await outputMappingsStore.approveOutputMapping(teamId, outputMappingUuid, name);
    if (success) {
      handleStepCompleted(onComplete, outputMappingUuid, setActiveStep);
      outputMappingsStore.setOutputMapping({});
      onClose();
    }
  };

  const handleClone = async () => {
    const { success } = await outputMappingsStore.cloneOutputMapping(teamId, outputMappingUuid, name);
    if (success) {
      outputMappingsStore.setOutputMapping({});
      onClose();
    }
  };

  const checkStepCompleted = () => {
    if (status === STATUS_VALUES.APPROVED) {
      return true;
    } else if (outputMappingsStore.getColumns().length > 0 && status === STATUS_VALUES.DRAFT) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="d-flex">
      <Button size="md" color="primary" onClick={handleApproveAndExit} disabled={checkStepCompleted()}>
        <IconCheck className="btn-md-svg" />
        <Text size="sm">Approve & exit</Text>
      </Button>
      <span className="pl-12" />
      <span className="pl-20" />
      <div className={styles.circle}>
        <VerticalMenuDropdown>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_EDIT])}>
            <VerticalMenuListItem
              text="Settings"
              onClick={() => {
                modalInstance(MODAL_IDS.EDIT_MAPPING_SETTINGS).toggle();
              }}
            />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_CREATE])}>
            <VerticalMenuListItem text="Clone" onClick={handleClone} />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_CREATE])}>
            <VerticalMenuListItem text="Export" disabled={true} />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_DELETE])}>
            <VerticalMenuListItem text="Delete" onClick={() => modalInstance(MODAL_IDS.DELETE_MAPPING).toggle()} disabled={status === STATUS_VALUES.APPROVED} />
          </AuthWrapper>
        </VerticalMenuDropdown>
      </div>
    </div>
  );
});
export { HeaderButtons };
