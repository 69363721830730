import React from "react";
import HttpMediator from "../utils/http/HttpMediator";
import { fundsDataTableChannel } from "../utils/communication/AppChannels";
import { List, ListItem, ListItemSecondaryAction, TextField, IconButton, Paper, Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import LockIcon from "@material-ui/icons/Lock";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import IndexedValue from "../utils/domain/selectandtransform/IndexedValue";
import { tbAppChannel } from "../utils/communication/AppChannels";
import { appSingleton } from "../utils/AppSingleton";
import { loadingChannel } from "../utils/communication/AppChannels";
import { tbStore, workflowsStore } from "../../../store/Store";
import "../TbApp.css";

export default class TemplateMapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateMappings: [],
      index: undefined,
      showApprovalOptions: false,
    };
    this.saveMappings = this.saveMappings.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.clearClicked = this.clearClicked.bind(this);
    this.deleteClicked = this.deleteClicked.bind(this);
    this.cancelSelected = this.cancelSelected.bind(this);
  }

  render() {
    if (this.props.disabled) return null;
    let currentIndex = this.state.index;
    let self = this;
    let keyIndex = 0;
    let mappingList = this.state.templateMappings.map((mapping, index) => {
      keyIndex = index;
      let selectedHeader = new IndexedValue({ value: "", index: "" });
      if (this.props.commandResult.fundsData) {
        selectedHeader = new IndexedValue({
          value: this.props.commandResult.fundsData.headers[mapping.columnIndex] || "",
          index: mapping.columnIndex === "" ? undefined : mapping.columnIndex,
        });
      }
      if (currentIndex != undefined && index == currentIndex) this.selectHeader.bind(self, mapping.columnName, self.state.index)();
      if (appSingleton.readOnly) {
        return (
          <ListItem key={index} className="padding-0">
            <Paper className="listItemPaper" elevation={3} square={false}>
              <Grid container alignItems="center" direction="row" spacing={0} className="padding-left-10">
                <Grid item xs={5}>
                  <div className="cellWithEllipsis mapper-item-text" title={mapping.columnName}>
                    {mapping.columnName}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <CompareArrowsIcon />
                </Grid>
                <Grid item xs={5}>
                  <div className="cellWithEllipsis mapper-item-text" title={selectedHeader.value}>
                    {selectedHeader.value}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </ListItem>
        );
      } else {
        let dataTypeIcon = "text_format";
        let dataTypeHint = ", can be any text";
        if (mapping.type == "custom") {
          dataTypeIcon = "border_color";
          dataTypeHint = " is a user defined mapping, can be any column type";
        }

        if (mapping.type == "DateType") {
          dataTypeIcon = "date_range";
          dataTypeHint = ", must be a date formatted as [yyyy/MM/dd]";
        }

        if (mapping.type == "Number") {
          dataTypeIcon = "filter_1";
          dataTypeHint = ", must be a numeric value";
        }

        if (mapping.type == "Currency") {
          dataTypeIcon = "local_atm";
          dataTypeHint = ", must be a currency code e.g EUR or USD";
        }

        let notSelectedOpacity = 1;
        if (currentIndex != undefined && currentIndex != index) {
          notSelectedOpacity = 0.5;
        }

        let TemplateMapperClientSpecific = () => {
          if (index == currentIndex && mapping.isClientSpecific) {
            return (
              <TextField
                value={mapping.columnName}
                onChange={this.onMappingNameChanged.bind(self, index)}
                autoFocus={true}
                className="mapper-text-input"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            );
          } else {
            return (
              <Grid container alignItems="center" direction="row" spacing={0} className="padding-left-10">
                <Grid item xs={5}>
                  <div className="cellWithEllipsis mapper-item-text" title={mapping.columnName + dataTypeHint}>
                    {mapping.columnName}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <CompareArrowsIcon />
                </Grid>
                <Grid item xs={4}>
                  <div className="cellWithEllipsis mapper-item-text" title={selectedHeader.value}>
                    {selectedHeader.value}
                  </div>
                </Grid>
              </Grid>
            );
          }
        };

        let TemplateMapperWarning = () => {
          if (selectedHeader.value == "" && mapping.required == true) {
            tbStore.templateBuilderDoneButtonDisabled(true);
            //If we get here we still have required mappings to map.
            //This template will always be the last to render
            return <WarningIcon title="Required field is not mapped" className="warning-icon-colour padding-right-12 vertical-align-middle" />;
          }
        };

        let TemplateMapperInfo = () => {
          if (selectedHeader.value == "" && mapping.required == false) {
            return <InfoIcon title="Optional field is not mapped" className="icon-colour vertical-align-middle"></InfoIcon>;
          }
        };

        let TemplateMapperClear = () => {
          if (selectedHeader.value != "") {
            return (
              <IconButton
                className="margin-right-minus-5"
                title="Click to clear field"
                onClick={(e) => {
                  e.stopPropagation();
                  this.clearClicked(index);
                }}
                //disabled={this.state.isLoading}
              >
                <ClearIcon />
              </IconButton>
            );
          }
        };

        let TemplateMapperDelete = () => {
          if (selectedHeader.value == "" && mapping.isClientSpecific == true) {
            return (
              <IconButton
                className="margin-right-minus-5"
                title="Delete optional field"
                onClick={(e) => {
                  e.stopPropagation();
                  this.deleteClicked(index);
                }}
                //disabled={this.state.isLoading}
              >
                <DeleteIcon />
              </IconButton>
            );
          }
        };

        return (
          <ListItem
            data-index={index}
            key={index}
            button={true}
            className="padding-0"
            style={{ opacity: notSelectedOpacity }}
            onClick={(event) => {
              if (currentIndex != index) {
                this.setState({ index: index });
              } else {
                this.cancelSelected();
              }
            }}
          >
            <Paper className="listItemPaper" elevation={3} square={false} style={{ marginRight: "0px" }}>
              {TemplateMapperClientSpecific()}
              <ListItemSecondaryAction>
                {TemplateMapperInfo()}
                {TemplateMapperClear()}
                {TemplateMapperDelete()}
                {TemplateMapperWarning()}
              </ListItemSecondaryAction>
            </Paper>
          </ListItem>
        );
      }
    });

    function lockAdd() {
      let createNewUserMappingModel = () => {
        return {
          columnName: "",
          idValue: "",
          required: false,
          type: "custom",
          desc: "No Description",
          columnIndex: "",
          isClientSpecific: true,
        };
      };

      let addNewUserMapping = () => {
        let blankTemplateMapping = createNewUserMappingModel();
        blankTemplateMapping.columnOrderNumber = self.state.templateMappings.length;
        blankTemplateMapping.columnIndex = -1; //be default new custom mappings are set to -1 as unmapped
        const updatedMappings = self.state.templateMappings;
        updatedMappings.unshift(blankTemplateMapping);
        self.selectHeader.bind(self, "", 0)();
        self.setState({ index: 0, templateMappings: updatedMappings });
      };

      if (appSingleton.readOnly) {
        return <LockIcon className="margin-right-5 margin-bottom-15 MuiIcon-root-100" title="Template is locked" style={{ fontSize: "20px" }}></LockIcon>;
      } else {
        return (
          <IconButton
            className="margin-right-minus-5  margin-bottom-15"
            title="Add a new mapping"
            onClick={() => {
              if (currentIndex == undefined) {
                addNewUserMapping();
              }
            }}
            style={{ fontSize: "20px" }}
            //disabled={this.state.isLoading}
          >
            <AddIcon />
          </IconButton>
        );
      }
    }

    //Add add new mapping button to the top of the list
    keyIndex++;
    mappingList.unshift(
      <ListItem key={keyIndex}>
        <ListItemSecondaryAction style={{ paddingTop: "20px", paddingBottom: "10px", right: "0px" }}>{lockAdd()}</ListItemSecondaryAction>
      </ListItem>
    );
    return (
      <List className="templateMapping-view large-panel" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
        {mappingList}
      </List>
    );
  }

  toggleModal() {
    this.setState({
      showApprovalOptions: !this.state.showApprovalOptions,
    });
  }
  onMappingNameChanged(index, event) {
    event.stopPropagation();
    const updatedMappings = this.state.templateMappings;
    updatedMappings[index].idValue = event.target.value;
    updatedMappings[index].columnName = event.target.value;
    this.setState({ index: index, templateMappings: updatedMappings });
  }

  saveMappings(event) {
    //validate all first
    var templateMapping = { templateColumns: this.state.templateMappings };

    HttpMediator.saveTemplateMappings(templateMapping).then((jsonResponse) => {
      let index = this.state.index;
      if (this.state.incrementIndex) {
        index++;
      }
      this.setState({ index: index, incrementIndex: false });
      //whats in .dataReturned ?
      // HttpMediator.fetchResultAndStack("-1")  //not needed as errors no longer displayed in fundDataView
    });
  }

  updateDataTableAll() {
    let mappingResponse = HttpMediator.fetchTemplateMappings();
    mappingResponse.then((jsonResponse) => {
      this.setState({ templateMappings: jsonResponse.data.sort((a, b) => a.columnOrderNumber - b.columnOrderNumber) });
      this.allRequiredMapped();
    });
  }

  selectHeader(mappingName, indexState) {
    try {
      this.onHeaderClickedSubscription.unsubscribe(); //needed as adding a new mapping calls this repeatedly
    } catch (err) {}
    try {
      this.onHeaderClickedSubscription = fundsDataTableChannel.subscribe("headerClicked", this.onHeaderClicked.bind(this, mappingName, indexState));
      document.querySelector(".structure-table").classList.add("header-clickable");
    } catch (err) {}
  }

  /**
   * Add subscription to handle header clicked notifications.*
   * @param mappingName - name of the mapping on which the header selection listener will be added
   * @param indexState - this.state.index. if it's not undefined, then on receiving header value,
   *        index must be set to undefined as next index will be selected by user.
   * @param data
   * @param envelope
   */

  onHeaderClicked(mappingName, indexState, data, envelope) {
    this.onHeaderClickedSubscription.unsubscribe();
    document.querySelector(".structure-table").classList.remove("header-clickable");
    let newMappings = this.state.templateMappings.map((mapping, index) => {
      const updatedMapping = mapping;
      /**
       * If this is user defined mapping and first row, then we are editing user defined mapping
       */
      if (mappingName === "" && indexState == index) {
        updatedMapping.columnIndex = data.event.target.cellIndex;
        updatedMapping.columnName = data.event.target.innerHTML;
        updatedMapping.idValue = data.event.target.innerHTML;
        if (updatedMapping.idValue == null || updatedMapping.idValue == "") {
          updatedMapping.idValue = "custom" + index;
        }
      } else if (mapping.columnName === mappingName) {
        updatedMapping.columnIndex = data.event.target.cellIndex;
      }
      return updatedMapping;
    });

    if (this.containsDuplicateIds(newMappings)) {
      //throw error
    } else {
      if (indexState != undefined && indexState + 1 < this.state.templateMappings.length && !(indexState == 0 && newMappings[0].isClientSpecific == true)) {
        //if custom and first, must be adding new, so dont move to next
        this.setState({ templateMappings: newMappings, incrementIndex: true });
      } else {
        this.setState({ templateMappings: newMappings });
        this.cancelSelected();
      }

      this.saveMappings();
      this.allRequiredMapped();
    }
  }

  /**
   * returns true if all the required mappings have been set
   * @returns {boolean}
   */
  allRequiredMapped() {
    let allMapped = true;
    this.state.templateMappings.find((mapping, index) => {
      if (mapping.required == true && (mapping.columnIndex === "" || mapping.columnIndex == -1)) {
        allMapped = false;
      }
    });

    appSingleton.allRequiredMapped = allMapped;
    if (allMapped) {
      tbStore.templateBuilderDoneButtonDisabled(false);
    }
    this.props.allRequiredMappedChange(allMapped);
    return allMapped;
  }

  componentWillReceiveProps(props) {
    if (props.disabled || this.props.commandResult == props.commandResult) return;
    this.updateDataTableAll();
  }

  /**
   * Calls logic to update appSingleton.allRequiredMapped when mounted, required for submit & approve buttons
   */
  componentDidMount() {
    this.allRequiredMapped();
  }

  componentWillMount() {
    loadingChannel.subscribe("loading", (object) => {
      this.setState({ isLoading: true });
    });
    loadingChannel.subscribe("finishedLoading", (object) => {
      this.setState({ isLoading: false });
    });
  }

  clearClicked(mappingIndex) {
    try {
      this.onHeaderClickedSubscription.unsubscribe(); //incase user is clearing  after selection
      document.querySelector(".structure-table").classList.remove("header-clickable");
    } catch (err) {}
    let newMappings = this.state.templateMappings.map((mapping, index) => {
      const updatedMapping = mapping;
      /**
       * If this is user defined mapping and first row, then we are editing user defined mapping
       */
      if (mappingIndex == index) {
        updatedMapping.columnIndex = -1;
      }
      return updatedMapping;
    });

    this.setState({ templateMappings: newMappings, index: undefined });

    this.saveMappings();
    this.allRequiredMapped();
  }

  deleteClicked(mappingIndex) {
    try {
      this.onHeaderClickedSubscription.unsubscribe(); //incase user is clearing  after selection
      document.querySelector(".structure-table").classList.remove("header-clickable");
    } catch (err) {}
    let newMappings = this.state.templateMappings;
    newMappings.splice(mappingIndex, 1);

    this.setState({ templateMappings: newMappings, index: undefined });

    this.saveMappings();
  }

  cancelSelected() {
    this.setState({ index: undefined });
    try {
      this.onHeaderClickedSubscription.unsubscribe(); //incase user is clearing  after selection
      document.querySelector(".structure-table").classList.remove("header-clickable");
    } catch (err) {}
  }

  //if an idValues are duplicated , throw error ,return false
  containsDuplicateIds(newMappings) {
    let idValues = [];
    newMappings.forEach((mapping) => {
      idValues.push(mapping.idValue);
    });

    for (let i = 0; i < idValues.length; i++) {
      for (let x = i + 1; x < idValues.length; x++) {
        if (idValues[i].toLowerCase() == idValues[x].toLowerCase()) {
          tbAppChannel.publish("error", "Duplicate column Name: " + idValues[i]);
          return true;
        }
      }
    }
    return false;
  }
}
