import { EmptyLookups, R, C, Heading, Text, Button, IconAdd } from "@fundrecs/ui-library";
import { AUTHORITIES } from "utils/enums";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { handleCreateLookupClick } from "../LookupsList";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";

/**
 * Default page when no lookups have yet been added for the selected team
 */
const NoLookups = () => {
  const { rolesStore } = useStore();
  const teamId = useTeamId();

  return (
    <>
      <R>
        <span className="emptyState-icon">{<EmptyLookups />}</span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No lookups
          </Heading>
        </C>
      </R>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_CREATE])}>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                Click Create New Lookup below
              </Text>
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                to add a lookup
              </Text>
            </div>
          </C>
        </R>
        <R>
          <C>
            <div className="pt-32 pb-32" style={{ pointerEvents: "auto" }}>
              <Button onClick={handleCreateLookupClick} size="md">
                <IconAdd className="btn-md-svg" />
                <Text size="sm">Create New Lookup</Text>
              </Button>
            </div>
          </C>
        </R>
      </AuthWrapper>
    </>
  );
};

export { NoLookups };
