import { observer } from "mobx-react-lite";
import { IconAdd, Text, Radio, Button } from "@fundrecs/ui-library";
import { Condition } from "./Condition";
const defaultCondition = {
  paramName: "filename",
  operator: "string_contains",
  paramValue: "",
};
const ConditionsList = observer(({ state, dispatch }) => {
  const { criteria, type } = state || {};

  const handleChangeOperator = (selectedOperator) => {
    dispatch({ type: selectedOperator });
  };
  const handleAddCondition = () => {
    dispatch({ criteria: [...criteria, defaultCondition] });
  };
  const handleChangeCondition = (index, newValues) => {
    const newState = [...criteria];
    newState[index] = newValues;
    dispatch({ criteria: newState });
  };
  const handleRemoveCondition = (index) => {
    const newState = [...criteria];
    newState.splice(index, 1);
    dispatch({ criteria: newState });
  };
  return (
    <>
      <div className="pt-20 pb-20 d-flex">
        <Radio
          checked={type === "OR"}
          onClick={() => {
            handleChangeOperator("OR");
          }}
        />
        <div className="pl-12 pr-32">
          <div className="pt-4">
            <Text variant="primary" size="sm" weight="regular" element="div">
              <b>ANY</b> of the following conditions
            </Text>
          </div>
        </div>
        <Radio
          checked={type === "AND"}
          onClick={() => {
            handleChangeOperator("AND");
          }}
        />
        <div className="pl-12">
          <div className="pt-4">
            <Text variant="primary" size="sm" weight="regular" element="div">
              <b>ALL</b> of the following conditions
            </Text>
          </div>
        </div>
      </div>
      {criteria.map((item, index) => {
        return (
          <>
            {index === 0 ? (
              <Condition
                index={index}
                condition={item}
                canDelete={false}
                handleChangeCondition={handleChangeCondition}
                handleRemoveCondition={handleRemoveCondition}
              />
            ) : (
              <>
                <div className="pt-8 pl-0 pr-0 pb-8">
                  <Text size="xs">{type}</Text>
                </div>
                <Condition
                  index={index}
                  condition={item}
                  canDelete={true}
                  handleChangeCondition={handleChangeCondition}
                  handleRemoveCondition={handleRemoveCondition}
                />
              </>
            )}
          </>
        );
      })}
      <div className="pt-16 pl-0 pr-16 pb-16">
        <Button color="primary-secondary" onClick={() => handleAddCondition()} size="md">
          <IconAdd className="btn-sm-svg" />
          <Text size="md">Add a condition</Text>
        </Button>
      </div>
    </>
  );
});
export { ConditionsList };
