import { useContext } from "react";
import { Button, Text, modalInstance, IconDelete } from "@fundrecs/ui-library";
import { rolesStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { LookupsTableContext } from "../../LookupsTable";
import { MODAL_IDS } from "utils/lookups/enums";

const DeleteRowsButtons = () => {
  const { lookupTable } = useContext(LookupsTableContext);
  const { teamId } = lookupTable || {};

  return (
    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_DELETE])}>
      <span className="pl-8" />
      <Button
        size="md"
        color="danger-secondary"
        onClick={() => {
          modalInstance(MODAL_IDS.DELETE_LOOKUPS_MODAL_ID).toggle();
        }}
      >
        <IconDelete className="btn-md-svg" />
        <Text size="sm">Delete Rows</Text>
      </Button>
    </AuthWrapper>
  );
};

export { DeleteRowsButtons };
