const redrawRowsToUpdateVerticalMenu = ({ gridApi, ids }) => {
  const rows = [];
  ids.forEach((id) => {
    const rowNode = gridApi.getRowNode(id);
    rows.push(rowNode);
  });
  gridApi.redrawRows({ rowNodes: rows });
};

export { redrawRowsToUpdateVerticalMenu };
