export default class CommandResult {
  constructor(props) {
    this.fundsData = props.fundsData;
    this.infoArray = props.infoArray;
    this.structure = props.structure;
    this.textData = props.data;
  }

  static buildResultFromJson(commandResultJson) {
    return new CommandResult(commandResultJson);
  }
}
