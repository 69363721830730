import React from "react";
import CloneDeep from "lodash.clonedeep";
import "../selectandtransform/select-transform.css";
import IndexedValue from "../utils/domain/selectandtransform/IndexedValue";
import HttpMediator from "../utils/http/HttpMediator";
import { tbAppChannel } from "../utils/communication/AppChannels";
import { appSingleton } from "../utils/AppSingleton";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SelectAndTransformView from "../utils/domain/selectandtransform/SelectAndTransformView";
import SelectAndTransformListView from "./SelectAndTransformListView";
import SelectAndTransformInputsView from "./SelectAndTransformInputsView";

export default class SelectAndTransformPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getFreshState(props);
    this.columnToColumnSelectOptions = [];
    this.columnToValueSelectOptions = [];
    this.tableHeaders = null;
    this.modifyTransformOptions = [];
    this.mergeTransformOptions = [];
    this.createSelectAndTransformRequest = this.createSelectAndTransformRequest.bind(this);
    this.getListOptions = this.getListOptions.bind(this);
    this.listOptionSelected = this.listOptionSelected.bind(this);
    this.submitUserInputs = this.submitUserInputs.bind(this);
  }

  /**
   * If component to receive props reset state
   * If props contains an existing rule
   * @param props
   */
  componentWillReceiveProps(props) {
    //The S&T panel relies on the select and transform options being loaded to the appSingleton
    //Therefore if not yet loaded, ignore
    if (appSingleton.selectOptions !== undefined && appSingleton.selectOptions !== undefined) {
      //Check that pe selection and transformation options are initialized from appSingleton,
      //if they have been initialized, the S&T panel is open, do not update state from props
      if (
        this.columnToColumnSelectOptions.length == 0 &&
        this.columnToValueSelectOptions.length == 0 &&
        this.modifyTransformOptions.length == 0 &&
        this.mergeTransformOptions.length == 0
      ) {
        this.getSelectOptions();
        this.getSingleTransformOptions();
      }

      let newState = this.getFreshState(props);
      if (props.isVisible) {
        //Always reset the table headers, in case the table has been updated
        this.tableHeaders = props.tableHeaders;
        //If there are previousViews existing, do not reset state (This is for when views change while editing a rule)
        if (this.state.previousViews.length > 0) {
          return;
        }
        //Set the S&T mode from props
        newState.mode = props.mode;
        //If there is an existing rule (complete or partially complete) load its parameters (This is for when opening an existing rule to edit it)
        if (props.selectAndTransformCommands.transform != undefined) {
          let existingRule = props.selectAndTransformCommands;
          let newView = newState.currentView;
          newView.viewName = "editMode";
          newView.descriptor = this.createDescriptorText(
            existingRule.selectExpr,
            existingRule.andSelect,
            existingRule.orSelect,
            existingRule.transform,
            existingRule.mode
          );
          newView.andSelect = typeof existingRule.andSelect !== "undefined" ? existingRule.andSelect : [];
          newView.orSelect = typeof existingRule.orSelect !== "undefined" ? existingRule.orSelect : [];
          newView.selectAll = existingRule.selectExpr == "ALL";
          newView.currentTransformation = existingRule.transform;
          //If mode specified in the rule, set the mode from the rule
          if (existingRule.mode) {
            newState.mode = existingRule.mode;
          }
        }

        //If the template status does not permit rules to be edited, disable listView,
        //inputsView is disabled by default, so neither will display
        if (!appSingleton.approvalOptions.submit.enable) {
          newState.currentView.listView = false;
        }
      }
      this.setState(newState);
    }
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }

    //For both multiple transformation rules the string returned from the PE is not suitable for the UI
    let mode;
    if (this.state.mode == "CopyAndModifyRows") {
      mode = "Copy and modify rows";
    } else if (this.state.mode == "SwapColumns") {
      mode = "Swap columns";
    } else if (this.state.mode == "findDuplicateAndCopyLargerValue") {
      mode = "Find duplicate and copy larger value";
    } else {
      mode = this.state.mode;
    }

    return (
      <Paper className="command-params-root">
        <Grid container spacing={0}>
          <Grid item sm={1}></Grid>
          <Grid item sm={9}>
            <Typography variant="headline" className="padding-top-10">
              {mode}
            </Typography>
          </Grid>
          <Grid item sm={1} className="align-right">
            <IconButton title="Back" onClick={this.stepBack.bind(this)}>
              <KeyboardReturnIcon />
            </IconButton>
            <IconButton name="Close" title="Close" onClick={this.closePanel.bind(this, "hideCommandParams")}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div title={this.state.currentView.descriptor} className="selectAndTransformDescriptionText">
              {this.state.currentView.descriptor}
            </div>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <SelectAndTransformListView
              isVisible={this.state.currentView.listView}
              listOptions={this.getListOptions()}
              listOptionSelected={this.listOptionSelected}
            />
            <SelectAndTransformInputsView
              isVisible={this.state.currentView.inputsView}
              selectOption={this.state.currentView.currentSelection}
              mode={this.state.mode}
              transformOptionArgsType={this.state.currentView.currentTransformation.type}
              submitUserInputs={this.submitUserInputs}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Paper>
    );
  }

  /*####################################################################################
     ########################## Description String Logic #################################
     ######################################################################################*/

  /**
   * Used when existing rule opened to recreate the descriptor string for the rule
   * Returns String "Where ... selection options ... then ... transformation rules"
   * 1) Add selection criteria to the string
   * 2) Add transformation criteria to the string
   * @param selectAll True if all rows selected
   * @param andSelect Array of 'and' selection options
   * @param orSelect Array of 'or' selection options
   * @param transformation Object containing transformation data
   * @param mode
   */
  createDescriptorText(selectAll, andSelect, orSelect, transformation, mode) {
    let descriptor = "";
    //1) Add Selection criteria
    //Add selection text for either 'all selected', 'and selects' or 'or selects'
    if (selectAll === "ALL") {
      descriptor = "Where all rows selected";
    } else if (andSelect && andSelect.length > 0) {
      //Iterate through all AND selections and append their details to the descriptor string
      for (let i = 0; i < andSelect.length; i++) {
        //Get the string description for this selection rule
        let andSelectionString = this.createDescriptionStringForSelection(andSelect[i]);
        //Append ' and ' to each selection except the first
        if (i === 0) {
          andSelectionString = "Where " + andSelectionString;
        } else {
          andSelectionString = " and " + andSelectionString;
        }
        //Set this selections description key to the constructed string
        andSelect[i].description = andSelectionString;
        //Add the string to the overall descriptor
        descriptor += andSelectionString;
      }
    } else if (orSelect && orSelect.length > 0) {
      //Iterate through all OR selections and append their details to the descriptor string
      for (let i = 0; i < orSelect.length; i++) {
        //Get the string description for this selection rule
        let orSelectionString = this.createDescriptionStringForSelection(orSelect[i]);
        if (i === 0) {
          orSelectionString = "Where " + orSelectionString;
        } else {
          orSelectionString = " or " + orSelectionString;
        }
        //Set this selections description key to the constructed string
        orSelect[i].description = orSelectionString;
        //Add the string to the overall descriptor
        descriptor += orSelectionString;
      }
    }

    //2) Add transformation criteria
    //Check if this is an incomplete rule - or a 'Filter' rule - with no transformation
    if (transformation.transformOperation === "NOP" && (!transformation.transformChain || transformation.transformChain.length == 0)) {
      return descriptor;
    } else if (transformation.transformOperation !== "NOP") {
      //If transformOption != "NOP" it is not a multiple transformation, it is a single Merge or Modify transformation
      transformation.description = this.createDescriptionStringForSingleTransformation(transformation);
      return descriptor + ", then " + transformation.description;
    } else if (transformation.transformChain.length > 0) {
      //If transformOperation === "NOP" and there is a transformChain, it is a multiple column transformation
      let descriptionStringAppendage = ", then ";
      if (mode == "CopyAndModifyRows") {
        descriptionStringAppendage = ", then copy the rows and ";
      }
      return descriptor + descriptionStringAppendage + this.createDescriptionStringForMultipleTransformations(transformation.transformChain);
    }
  }

  /**
   * For an existing selection, creates a string to describe the rule and its inputs
   * @param selection
   * @param tableHeaders
   * @returns {string}
   */
  createDescriptionStringForSelection(selection) {
    //Get the pe data for this selection rule
    let peSelectRule = appSingleton.selectOptions.find((selectRule) => selectRule.peName === selection.peName);
    //If not the first iteration set the beginning of the String with 'and' or 'or'
    let descriptionString = peSelectRule.uiDesc;
    let inputA, inputB;
    //Set input values to header name if index exists, otherwise set to the received parameter
    inputA = (peSelectRule.isArg1Index ? this.tableHeaders[selection.arg1] : selection.arg1).toString();
    inputB = (peSelectRule.isArg2Index ? this.tableHeaders[selection.arg2] : selection.arg2).toString();
    //Replace placeholder text from pe description with the applied inputs
    descriptionString = descriptionString
      .replace("COLUMN A", inputA)
      .replace("COLUMN B", inputB)
      .replace(" X", ' "' + inputB + '"');
    return descriptionString;
  }

  /**
   * For an existing single transformation, creates a string to describe the rule and its inputs
   * @param transformation
   * @param tableHeaders
   * @returns {string|*}
   */
  createDescriptionStringForSingleTransformation(transformation) {
    let transformationString, inputA;
    let inputB = "";
    let peTransformRule = appSingleton.transformOptions.find((transformRule) => transformRule.peName === transformation.transformOperation);
    transformationString = peTransformRule.uiDesc;
    inputA = transformation.transformColumn.value.toString();
    //Only if there is a transformValue or transformChain, add inputB, otherwise it's a unary transformation with only one parameter
    if (transformation.transformValue != "" || (transformation.transformChain && transformation.transformChain.length > 0)) {
      //If transformation contains a transformValue it is a col->val transformation (Modify), otherwise it is a col->col transformation (Merge)
      inputB = (transformation.transformValue != "" ? transformation.transformValue : this.tableHeaders[transformation.transformChain[0].arg1]).toString();
    }
    //Replace placeholder text from pe description with the applied inputs
    transformationString = transformationString
      .replace("COLUMN A", inputA)
      .replace("COLUMN B", inputB)
      .replace(" X", ' "' + inputB + '"');
    return transformationString;
  }

  /**
   * For an existing transformation chain, creates a string to describe the multiple transformations
   * @param peName
   * @param transformChain
   * @param tableHeaders
   */
  createDescriptionStringForMultipleTransformations(transformChain) {
    //Two multiple transformation rules exist: 'Copy and modify rows' and 'Find duplicate and copy larger value'
    //'Copy and modify rows' - the first item in the transformChain contains the rule ({peName:String}),
    //      each subsequent object contains the indexes of the selected columns ({arg1: String, arg2: String})
    //'Find duplicate and copy larger value' - each object in the array has the same attributes ({arg1: String, arg2: String, peName: String})
    let peName;
    if (transformChain.length > 1) {
      //This is necessary as the CopyAndModifyRows rule has 'copy row' as the first transformation
      peName = transformChain[1].peName;
    } else {
      peName = transformChain[0].peName;
    }
    //If the first object in the array doesn't contain args, do not include in the loop
    let i = transformChain[0].arg1 && transformChain[0].arg2 ? 0 : 1;
    const firstIndex = i;
    let descriptionString = "";
    let peTransformRule = appSingleton.transformOptions.find((transformRule) => transformRule.peName === peName);
    let transformationString = peTransformRule.uiDesc;
    //Iterate through all objects in the transformChain
    for (i; i < transformChain.length; i++) {
      let inputA = this.tableHeaders[transformChain[i].arg1];
      let inputB = this.tableHeaders[transformChain[i].arg2];
      let transformationDescription = transformationString.replace("COLUMN A", inputA).replace("COLUMN B", inputB);
      if (i > firstIndex) {
        transformationDescription = ", " + transformationDescription;
      }
      //Add description to the transform chain
      transformChain[i].description = transformationDescription;
      descriptionString += transformationDescription;
    }
    return descriptionString;
  }

  /**
   * Update the description string for the selection/ transformation with user input
   * @param descriptorString Existing description string including placeholder text
   * @param arg1
   * @param arg2
   * @returns {*}
   */
  addArgsToDescriptorString(descriptorString, arg1, arg2) {
    descriptorString = descriptorString.replace("COLUMN A", arg1);
    descriptorString = descriptorString.replace("COLUMN B", arg2);
    descriptorString = descriptorString.replace(" X", ' "' + arg2 + '"');
    return descriptorString;
  }

  /**
   * Return copy of current description string with the passed string removed
   * @param str String to remove from the current description
   * @returns {string}
   */
  removeStringFromDescriptor(str) {
    return this.state.currentView.descriptor.slice(0).replace(str, "");
  }

  /**
   * Only called when selection process is complete, returns data to display the next view
   * Returns different data if    a) Single transformation rule,
   *                              b) Multiple transformation CopyAndModifyRows rule
   *                              c) Multiple transformation findDuplicateAndCopyLargerValue rule
   * @param viewObj
   * @returns {*}
   */
  updateViewIfMultipleTransformationRule(viewObj) {
    if (this.state.mode == "CopyAndModifyRows") {
      let swapColumnsDesc = appSingleton.transformOptions.find((transformOption) => {
        return transformOption.peName === "swapColumns";
      }).uiDesc;
      viewObj.descriptor = viewObj.descriptor + "copy the rows and " + swapColumnsDesc;
      viewObj.data = {
        isArg2Index: true,
        argsType: "binary",
        data: { peName: "swapColumns" },
        description: swapColumnsDesc,
      };
      viewObj.viewName = "transformParams";
    } else if (this.state.mode == "findDuplicateAndCopyLargerValue") {
      let findDuplicateAndCopyLargerValueDesc = appSingleton.transformOptions.find((transformOption) => {
        return transformOption.peName === "findDuplicateAndCopyLargerValue";
      }).uiDesc;
      viewObj.descriptor = viewObj.descriptor + findDuplicateAndCopyLargerValueDesc;
      viewObj.data = {
        isArg2Index: true,
        argsType: "binary",
        data: { peName: "findDuplicateAndCopyLargerValue" },
        description: findDuplicateAndCopyLargerValueDesc,
      };
      viewObj.viewName = "transformParams";
    } else if (this.state.mode == "SwapColumns") {
      let swapColumnsDesc = appSingleton.transformOptions.find((transformOption) => {
        return transformOption.peName === "swapColumnsInOriginalRows";
      }).uiDesc;
      viewObj.descriptor = viewObj.descriptor + swapColumnsDesc;
      viewObj.data = {
        isArg2Index: true,
        argsType: "binary",
        data: { peName: "swapColumnsInOriginalRows" },
        description: swapColumnsDesc,
      };
      viewObj.viewName = "transformParams";
    }
    return viewObj;
  }

  descriptorForAllSelectionsDeleted() {
    if (this.props.isNew) {
      return "";
    }
    if (!this.props.isNew) {
      let descriptionString = this.state.currentView.descriptor.slice(0);
      descriptionString = descriptionString.slice(descriptionString.indexOf(", then"));
      return "Where (no selection criteria entered)" + descriptionString;
    }
  }

  /*#####################################################################################
     ############################### View logic ###########################################
     ####################################################################################*/

  /**
   * Move current view on to the next view object
   * Clones current view object, then makes modifications to next view object as necessary
   */
  nextView(viewName, descriptor, data) {
    let listView = true;
    let inputsView = "";
    //Use the loadash cloneDeep method to create a clone of currentView state
    //This is necessary to ensure a deep clone is created for any nested values
    let newView = CloneDeep(this.state.currentView);

    //If select all option has been clicked, update selection fields
    if (data && data.allSelected) {
      newView.andSelect = [];
      newView.orSeelect = [];
      newView.currentSelection = {};
      newView.selectAll = true;
    }

    //If select or transform input view is returned, set to params view
    //assign data to either currentSelection or currentTransformation
    if (["selectParams", "transformParams"].includes(viewName)) {
      listView = false;
      //Set inputView to 'select' or 'transform'
      inputsView = viewName.replace("Params", "");
      if (viewName === "selectParams") {
        //If a selection has been entered, remove select all
        descriptor = descriptor.replace("Where all rows selected", "");
        newView.selectAll = false;
        newView.currentSelection = data;
      }
      if (viewName === "transformParams") {
        if (this.state.mode == "CopyAndModifyRows" && newView.currentTransformation.transformChain.length == 0) {
          newView.currentTransformation.transformChain[0] = {
            peName: "copyRow",
            arg1: undefined,
            arg2: undefined,
          };
        }
        //If this is a multiple transformation rule, update transformChain
        if (["CopyAndModifyRows", "findDuplicateAndCopyLargerValue", "SwapColumns"].includes(this.state.mode)) {
          newView.currentTransformation.transformChain.push({
            peName: data.data.peName,
            arg1: undefined,
            arg2: undefined,
            description: data.description,
          });
        } else {
          newView.currentTransformation.transformOperation = data.data.peName;
        }
        //Not necessary to update the following if appending transformation to existing multiple transformation rule
        if (data && data.argsType) {
          newView.currentTransformation.isArg2Index = data.isArg2Index;
          newView.currentTransformation.type = data.argsType;
        }
        //If opening the transformation params having deleted the final multiple transformation, remove this transformation from the transformChain
        if (data && data.deleteTransformation) {
          newView.currentTransformation.transformChain = newView.currentTransformation.transformChain.filter(function (transformation) {
            return transformation.description !== data.deleteTransformation;
          });
        }
      }
    }

    //If complete selection view, either (creating a new selection) update inputs or (deleting a selection) update existing selections
    if (viewName === "completeSelection") {
      //If user has entered inputs, update current selection and descriptor
      if (data && data.arg1 && data.arg2) {
        newView.currentSelection.arg1 = data.arg1;
        newView.currentSelection.arg2 = data.arg2;
        newView.currentSelection.description = this.addArgsToDescriptorString(newView.currentSelection.description, data.arg1.value, data.arg2.value);
        descriptor = this.addArgsToDescriptorString(newView.descriptor, data.arg1.value, data.arg2.value);

        //If user has chosen to delete a selection, update andSelect/ orSelect array
      } else if (data && data.deleteArray) {
        newView[data.deleteArray] = newView[data.deleteArray].filter(function (selection) {
          return selection.description !== data.description;
        });
        //If deleting this selection has led to the 'Where' being deleted from the beginning of the descriptor,
        if (descriptor.slice(0, 5) !== "Where") {
          //Start of descriptor string updated
          descriptor = descriptor.replace(data.replaceText, "Where");
          //update the string for either the first in the array, or the currentSelection
          if (newView[data.deleteArray].length == 0) {
            newView.currentSelection.description = newView.currentSelection.description.replace(data.replaceText, "Where");
          } else {
            newView[data.deleteArray][0].description = newView[data.deleteArray][0].description.replace(data.replaceText, "Where");
          }
        }
      }
    }

    //If user has deleted currentSelection
    if (data && data.deleteCurrentSelection) {
      //newView.descriptor = newView.descriptor.replace(newView.currentSelection.description, "");
      newView.currentSelection = {};

      //If all selections have been deleted
    } else if (data && data.allSelectionsDeleted) {
      newView.andSelect = [];
      newView.orSelect = [];
      newView.currentSelection = {};
    }

    //If 'and selection' or 'or selection' has been clicked, append the currentSelection to the relevant array
    if (data && data.selectionType) {
      //If state.currentView.currentSelection contains a selection, append to the appropriate array
      if (Object.keys(this.state.currentView.currentSelection).length > 0) {
        if (data.selectionType == "and") {
          newView.andSelect.push(this.getDataFromCurrentSelection());
        } else if (data.selectionType == "or") {
          newView.orSelect.push(this.getDataFromCurrentSelection());
        }
      }
      newView.currentSelection = {};
    }

    //If the user inputs have been submitted for a multiple transformation rule
    //Updates the args and description on the last item in the transformationChain
    if (viewName == "completeTransformation") {
      if (data && data.arg1 && data.arg2) {
        let lastObjectOnTransformChain = newView.currentTransformation.transformChain[newView.currentTransformation.transformChain.length - 1];
        lastObjectOnTransformChain.arg1 = data.arg1.index;
        lastObjectOnTransformChain.arg2 = data.arg2.index;
        lastObjectOnTransformChain.description = this.addArgsToDescriptorString(lastObjectOnTransformChain.description, data.arg1.value, data.arg2.value);
        //If the user has deleted a transformation
      } else if (data && data.deleteTransformation) {
        newView.currentTransformation.transformChain = newView.currentTransformation.transformChain.filter(function (transformation) {
          return transformation.description !== data.deleteTransformation;
        });
        //If the first transformation in the list has been removed, update the descriptor and the description of the first transformation in the transformChain
        if (this.state.mode == "CopyAndModifyRows" && descriptor.indexOf(" and , ") != -1) {
          descriptor = descriptor.replace(" and , ", " and ");
          newView.currentTransformation.transformChain[1].description = newView.currentTransformation.transformChain[1].description.slice(2);
        } else if (this.state.mode == "CopyAndModifyRows" && descriptor.indexOf(" then, ") != -1) {
          descriptor = descriptor.replace(" then, ", " then ");
          newView.currentTransformation.transformChain[0].description = newView.currentTransformation.transformChain[1].description.slice(2);
        }
      }
    }

    newView.viewName = viewName;
    newView.descriptor = descriptor;
    newView.listView = listView;
    newView.inputsView = inputsView;
    return newView;
  }

  /**
   * Appends the current view to the previous view array
   * @returns {Array}
   */
  appendToPreviousViews() {
    let previousViews = this.state.previousViews;
    previousViews.push(this.state.currentView);
    return previousViews;
  }

  /**
   * Moves previous state to the current state
   * Called when back button is clicked
   */
  stepBack() {
    //If there are no previous views, if editing a rule, return to rule list, otherwise close panel
    if (this.state.previousViews.length === 0) {
      let subscriptionString = this.props.isNew ? "hideCommandParamsBackToCommandList" : "hideCommandParams";
      this.closePanel(subscriptionString);
    }
    //Retrieves the last state object pushed onto state.previousStates
    let previousView = this.state.previousViews.slice(-1)[0];
    //Creates new array of states minus the last pushed state
    let newPreviousViews = this.state.previousViews.slice(0, -1);
    this.setState({
      previousViews: newPreviousViews,
      currentView: previousView,
    });
  }

  /**
   * Called when the close button is clicked
   * Resets state for the panel
   * Returns user to applied rules list view
   */
  closePanel(subscriptionString) {
    this.resetSelectAndTransformPanel(subscriptionString);
    //Delete if this is a new rule which has already been partially saved
    if (this.props.isNew && this.state.ruleSavedByPE) {
      HttpMediator.updateCommand({
        updateType: "deleteCommand",
        commandIndex: appSingleton.step,
      });
    }
  }

  /*#####################################################################################
     ############################### Selection logic ######################################
     ####################################################################################*/

  /**
   * Return 2 args and the PE rule name for serialization for the PE
   * @returns {{arg1: *, arg2: *, peName: *}}
   */
  getDataFromCurrentSelection() {
    const currentSelection = this.state.currentView.currentSelection;
    return {
      arg1: currentSelection.isArg1Index ? currentSelection.arg1.index : currentSelection.arg1.value,
      arg2: currentSelection.isArg2Index ? currentSelection.arg2.index : currentSelection.arg2.value,
      peName: currentSelection.data.peName,
      description: currentSelection.description,
    };
  }

  /*#####################################################################################
     ############################### List view logic ######################################
     ####################################################################################*/

  /**
   * Gets data to be displayed as a list based on the current view defined in state
   * Used to populate select and transform list view
   * 1) - New rule, or edit existing rule options
   * 2) - Selection options
   * 3) - Selection completion
   * 4) - Transformation options
   * 5) - Transformation completion
   * @returns Array of objects: {uiValue:"", type:"", data: {}}
   */
  getListOptions() {
    let view = this.state.currentView.viewName;
    let listOptions = [];
    if (view === "selectionType") {
      //1) new rule - Choose selection type
      listOptions = [
        {
          uiValue: "Create selection based on column to column comparison",
          type: "columnToColumnSelection",
          data: null,
        },
        {
          uiValue: "Create selection based on column to value comparison",
          type: "columnToValueSelection",
          data: null,
        },
      ];
      //If the S&T rule is not 'Filter', add option to select all rows
      if (this.state.mode !== "Filter") {
        listOptions.push({
          uiValue: "Select all rows",
          type: "selectAll",
          data: null,
        });
      }
    } else if (view === "editMode") {
      //1) edit rule - Choose to edit selection or transformation
      listOptions = [{ uiValue: "Edit selection", type: "editSelection", data: null }];
      //If the S&T rule is not 'Filter', add option to edit the transformation
      if (this.state.mode !== "Filter") {
        listOptions.push({
          uiValue: "Edit transformation",
          type: "editTransformation",
          data: null,
        });
      }
    } else if (view == "columnToColumnSelection") {
      //2) - Return list of col->col options
      listOptions = this.columnToColumnSelectOptions;
    } else if (view == "columnToValueSelection") {
      //2) - Return list of col->val options
      listOptions = this.columnToValueSelectOptions;
    } else if (view == "completeSelection") {
      //3) - Return list of options to complete, continue, or delete the current selection criteria
      //For each rule, user can append 'and' selections or 'or' selections, but not a combination of the two
      //For the first selection they can choose to 'and' or 'or another selection'
      if (this.state.currentView.andSelect.length === 0 && this.state.currentView.orSelect.length === 0) {
        listOptions = this.getSelectionOptions("none");
      } else if (this.state.currentView.andSelect.length > 0) {
        listOptions = this.getSelectionOptions("and");
      } else if (this.state.currentView.orSelect.length > 0) {
        listOptions = this.getSelectionOptions("or");
      }

      if (!this.props.isNew) {
        listOptions.push({
          uiValue: "Update Selection",
          type: "completeSelection",
          data: null,
        });
      } else {
        listOptions.push({
          uiValue: this.state.mode == "Filter" ? "Complete and save rule" : "Next",
          type: "completeSelection",
          data: null,
        });
      }
    } else if (view == "transformationCondition") {
      //4) - Return list of transformation conditions
      if (this.state.mode == "Merge") {
        listOptions = this.mergeTransformOptions;
      } else if (this.state.mode == "Modify") {
        listOptions = this.modifyTransformOptions;
      }
    } else if (view == "completeTransformation") {
      //5) - Return list of options to continue, or delete the current transformation criteria
      listOptions = this.getTransformationOptions();
    }
    return listOptions;
  }

  /**
   * Returns an array of options for deleting existing commands
   * @param selectionType
   * @returns {*}
   */
  getSelectionOptions(selectionType) {
    let listOptions = [];
    let selectionDescription = "";
    //If there are no previous selections add only the option to delete the current selection
    if (selectionType === "none") {
      listOptions = [
        {
          uiValue: "And (Another selection)",
          type: "addAndSelection",
          data: {},
        },
        { uiValue: "Or (Another selection)", type: "addOrSelection", data: {} },
      ];

      //If in edit mode, there may not be a currently selected option
      if (Object.keys(this.state.currentView.currentSelection).length > 0) {
        listOptions.push({
          uiValue: this.state.currentView.currentSelection.description.slice(0).replace("Where", "Remove selection: "),
          type: "deleteCurrentSelection",
          data: {
            description: this.state.currentView.currentSelection.description.slice(0),
          },
        });
      }

      //Otherwise, if there are 'and' selections, add the options to delete these selections and the current selection
    } else if (selectionType === "and") {
      listOptions.push({
        uiValue: "And (Another selection)",
        type: "addAndSelection",
        data: {},
      });
      for (let selection of this.state.currentView.andSelect) {
        //Replace 'Where' or 'and' with 'Delete' for the UI
        if (selection.description.slice(0, 5) == "Where") {
          selectionDescription = selection.description.slice(0).replace("Where", "Remove selection: ");
        } else {
          selectionDescription = selection.description.slice(0).replace(" and", "Remove selection: ");
        }
        listOptions.push({
          uiValue: selectionDescription,
          type: "deleteAndSelection",
          data: { description: selection.description.slice(0) },
        });
      }
      //If there is a currentSelection add to the list
      if (Object.keys(this.state.currentView.currentSelection).length != 0) {
        listOptions.push({
          uiValue: this.state.currentView.currentSelection.description.slice(0).replace(" and", "Remove selection: "),
          type: "deleteCurrentSelection",
          data: {
            description: this.state.currentView.currentSelection.description.slice(0),
          },
        });
      }

      //Otherwise, if there are 'or' selections, add the options to delete these selections and the current selection
    } else if (selectionType === "or") {
      listOptions.push({
        uiValue: "Or (Another selection)",
        type: "addOrSelection",
        data: {},
      });
      for (let selection of this.state.currentView.orSelect) {
        if (selection.description.slice(0, 5) == "Where") {
          selectionDescription = selection.description.slice(0).replace("Where", "Remove selection: ");
        } else {
          selectionDescription = selection.description.slice(0).replace(" or", "Remove selection: ");
        }
        listOptions.push({
          uiValue: selectionDescription,
          type: "deleteOrSelection",
          data: { description: selection.description.slice(0) },
        });
      }
      //If there is a currentSelection add to the list
      if (Object.keys(this.state.currentView.currentSelection).length != 0) {
        listOptions.push({
          uiValue: this.state.currentView.currentSelection.description.slice(0).replace(" or", "Remove selection: "),
          type: "deleteCurrentSelection",
          data: {
            description: this.state.currentView.currentSelection.description.slice(0),
          },
        });
      }
    }
    return listOptions;
  }

  /**
   * Returns an array of options to modify/complete a transformation in a multiple transformation rule
   * @returns {[]}
   */
  getTransformationOptions() {
    let listOptions = [
      {
        uiValue: "Append another transformation",
        type: "appendTransformation",
        data: {},
      },
    ];
    let i = 0;
    //If CopyAndModify rows, the first item in the transformChain is the copy rows rule
    if (this.state.mode == "CopyAndModifyRows") {
      i = 1;
    }
    for (i; i < this.state.currentView.currentTransformation.transformChain.length; i++) {
      listOptions.push({
        uiValue: "Remove transformation: " + this.state.currentView.currentTransformation.transformChain[i].description,
        type: "deleteTransformation",
        data: {
          description: this.state.currentView.currentTransformation.transformChain[i].description,
        },
      });
    }
    listOptions.push({
      uiValue: "Complete and save rule",
      type: "completeTransformation",
      data: null,
    });
    return listOptions;
  }

  /**
   * Called when a list option is clicked
   * @param type String which describes the clicked list option, used to identify the logic to be called
   * @param data Object containing data relevant to the specified logic
   */
  listOptionSelected(type, data) {
    if (type == "selectAll") {
      let viewObj = { viewName: "", descriptor: "", data: {} };
      if (this.props.isNew) {
        //Display list of transformation options if simple transform rule
        //Otherwise if multiple transformation rule, display input view for transformation
        this.finishSelectionAndUpdateOrCreateRule(true);
        viewObj = this.updateViewIfMultipleTransformationRule({
          viewName: "transformationCondition",
          descriptor: "Where all rows selected, then ",
          data: {},
        });
      } else {
        let currentDescription = this.state.currentView.descriptor.slice(0);
        viewObj = {
          viewName: "completeSelection",
          descriptor: "Where all rows selected" + currentDescription.slice(currentDescription.indexOf(", then")),
          data: {},
        };
      }
      //Set the allSelected flag, this is used in nextView()
      viewObj.data.allSelected = true;
      this.setState({
        currentView: this.nextView(viewObj.viewName, viewObj.descriptor, viewObj.data),
        previousViews: this.appendToPreviousViews(),
        ruleSavedByPE: true,
      });
    } else if (type == "columnToColumnSelection") {
      //Display column to column selection options
      this.setState({
        currentView: this.nextView(type, this.state.currentView.descriptor.slice(0)),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "columnToValueSelection") {
      //Display column to value selection options
      this.setState({
        currentView: this.nextView(type, this.state.currentView.descriptor.slice(0)),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "selectionOption") {
      //Save selection condition and display inputs view
      if (data.peName != "" && data.peName != undefined) {
        //Get the selection object from the one of the lists returned from appSingleton
        let selectedCondition;
        if (this.state.currentView.viewName == "columnToColumnSelection") {
          selectedCondition = this.columnToColumnSelectOptions.find((selectOption) => {
            return selectOption.data.peName === data.peName;
          });
        } else if (this.state.currentView.viewName == "columnToValueSelection") {
          selectedCondition = this.columnToValueSelectOptions.find((selectOption) => {
            return selectOption.data.peName === data.peName;
          });
        }

        //Create description string for the selection append String depending on whether
        // a) additional 'and' selection
        // b) an additional 'or' selection
        // c) the first selection condition
        if (this.state.currentView.andSelect.length > 0) {
          selectedCondition.description = " and " + selectedCondition.uiValue;
        } else if (this.state.currentView.orSelect.length > 0) {
          selectedCondition.description = " or " + selectedCondition.uiValue;
        } else {
          selectedCondition.description = "Where " + selectedCondition.uiValue;
        }

        let completeDescriptionString;
        if (this.props.isNew || this.state.mode == "Filter") {
          completeDescriptionString = this.state.currentView.descriptor.slice(0) + selectedCondition.description;
        } else {
          //If editing a rule which is not filter, insert the new selection just before the transformation in the descriptor string
          //If through editing, all selections were removed, remove it's description string before appending the selection
          let originalCompleteDescriptor = this.state.currentView.descriptor.slice(0).replace("Where (no selection criteria entered)", "");
          let firstPartOfDescriptor = originalCompleteDescriptor.slice(0, originalCompleteDescriptor.indexOf(", then"));
          let lastPartOfDescriptor = originalCompleteDescriptor.slice(originalCompleteDescriptor.indexOf(", then"));
          completeDescriptionString = firstPartOfDescriptor + selectedCondition.description + lastPartOfDescriptor;
        }
        this.setState({
          currentView: this.nextView("selectParams", completeDescriptionString, selectedCondition),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "addAndSelection") {
      //'And' the current selection, display options for new selection
      this.setState({
        currentView: this.nextView("selectionType", this.state.currentView.descriptor.slice(0), {
          selectionType: "and",
        }),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "addOrSelection") {
      //'Or' the current selection, display options for new selection
      this.setState({
        currentView: this.nextView("selectionType", this.state.currentView.descriptor.slice(0), {
          selectionType: "or",
        }),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "deleteAndSelection") {
      //Delete clicked 'and' selection, if last remaining selection, change view to create new selection
      if (this.state.currentView.andSelect.length == 1 && Object.keys(this.state.currentView.currentSelection).length == 0) {
        this.setState({
          currentView: this.nextView("selectionType", this.descriptorForAllSelectionsDeleted(), {
            allSelectionsDeleted: true,
          }),
          previousViews: this.appendToPreviousViews(),
        });
      } else {
        this.setState({
          currentView: this.nextView("completeSelection", this.removeStringFromDescriptor(data.description), {
            deleteArray: "andSelect",
            description: data.description,
            replaceText: " and",
          }),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "deleteOrSelection") {
      //Delete clicked 'or' selection, if last remaining selection, change view to create new selection
      if (this.state.currentView.orSelect.length == 1 && Object.keys(this.state.currentView.currentSelection).length == 0) {
        this.setState({
          currentView: this.nextView("selectionType", this.descriptorForAllSelectionsDeleted(), {
            allSelectionsDeleted: true,
          }),
          previousViews: this.appendToPreviousViews(),
        });
      } else {
        this.setState({
          currentView: this.nextView("completeSelection", this.removeStringFromDescriptor(data.description), {
            deleteArray: "orSelect",
            description: data.description,
            replaceText: " or",
          }),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "deleteCurrentSelection") {
      //Delete the current selection, if last remaining selection, change view to create new selection
      if (this.state.currentView.andSelect.length == 0 && this.state.currentView.orSelect.length == 0) {
        this.setState({
          currentView: this.nextView("selectionType", this.descriptorForAllSelectionsDeleted(), {
            allSelectionsDeleted: true,
          }),
          previousViews: this.appendToPreviousViews(),
        });
      } else {
        this.setState({
          currentView: this.nextView("completeSelection", this.removeStringFromDescriptor(data.description), {
            deleteCurrentSelection: true,
          }),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "completeSelection") {
      //Finish selection process, if state.mode is for a single transformation, display list of transformation options
      //Otherwise show input view for this multiple transformation mode
      if (!this.props.isNew || this.state.mode == "Filter") {
        this.submitCompletedSelectAndTransformRule();
        return;
      }
      this.finishSelectionAndUpdateOrCreateRule(false);
      let viewObj = this.updateViewIfMultipleTransformationRule({
        viewName: "transformationCondition",
        descriptor: this.state.currentView.descriptor.slice(0) + ", then ",
        data: {},
      });
      this.setState({
        currentView: this.nextView(viewObj.viewName, viewObj.descriptor, viewObj.data),
        previousViews: this.appendToPreviousViews(),
        ruleSavedByPE: true,
      });
    } else if (type == "transformationOption") {
      //Save transformation option and display inputs view (This logic should only be called for a single transformation rule)
      if (data.peName != "" && data.peName != undefined) {
        //Get the transformation object from the one of the lists returned from appSingleton
        let transformOptions = [];
        if (this.state.mode == "Modify") {
          transformOptions = this.modifyTransformOptions;
        } else if (this.state.mode == "Merge") {
          transformOptions = this.mergeTransformOptions;
        }
        let selectedTransformation = transformOptions.find((transformOption) => {
          return transformOption.data.peName === data.peName;
        });
        //Append ', then' to the description, this is important if it needs to be removed from the overall descriptor
        selectedTransformation.description = ", then " + selectedTransformation.uiValue;
        this.setState({
          currentView: this.nextView("transformParams", this.state.currentView.descriptor + selectedTransformation.description, selectedTransformation),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "appendTransformation") {
      let transformRule;
      if (this.state.mode == "CopyAndModifyRows") {
        transformRule = appSingleton.transformOptions.find((transformOption) => {
          return transformOption.peName === "swapColumns";
        });
      } else if (this.state.mode == "SwapColumns") {
        transformRule = appSingleton.transformOptions.find((transformOption) => {
          return transformOption.peName === "swapColumnsInOriginalRows";
        });
      } else if (this.state.mode == "findDuplicateAndCopyLargerValue") {
        transformRule = appSingleton.transformOptions.find((transformOption) => {
          return transformOption.peName === "findDuplicateAndCopyLargerValue";
        });
      }
      this.setState({
        currentView: this.nextView("transformParams", this.state.currentView.descriptor + ", " + transformRule.uiDesc, {
          data: { peName: transformRule.peName },
          description: transformRule.uiDesc,
        }),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "deleteTransformation") {
      //If deleting a transformation which is not the last, show completeTransformation view
      let viewObj = {
        viewName: "completeTransformation",
        descriptor: this.removeStringFromDescriptor(data.description),
        data: { description: data.description },
      };
      if (this.state.mode == "CopyAndModifyRows" && this.state.currentView.currentTransformation.transformChain.length <= 2) {
        //If the last CopyAndModifyRows transformation, display the params view
        viewObj.descriptor = viewObj.descriptor.replace("copy the rows and", "");
        viewObj = this.updateViewIfMultipleTransformationRule(viewObj);
      } else if (this.state.mode == "findDuplicateAndCopyLargerValue" && this.state.currentView.currentTransformation.transformChain.length <= 1) {
        //If the last findDuplicateAndCopyLargerValue transformation, display the params view
        viewObj = this.updateViewIfMultipleTransformationRule(viewObj);
      }

      //Add the description string for the transformation to delete
      viewObj.data.deleteTransformation = data.description;
      this.setState({
        currentView: this.nextView(viewObj.viewName, viewObj.descriptor, viewObj.data),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "editSelection") {
      this.setState({
        currentView: this.nextView("completeSelection", this.state.currentView.descriptor.slice(0)),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "editTransformation") {
      if (["CopyAndModifyRows", "findDuplicateAndCopyLargerValue", "SwapColumns"].includes(this.state.mode)) {
        this.setState({
          currentView: this.nextView("completeTransformation", this.state.currentView.descriptor.slice(0)),
          previousViews: this.appendToPreviousViews(),
        });
      } else {
        var descriptor = this.removeStringFromDescriptor(this.state.currentView.currentTransformation.description);
        this.setState({
          currentView: this.nextView("transformationCondition", descriptor),
          previousViews: this.appendToPreviousViews(),
        });
      }
    } else if (type == "completeTransformation" && ["CopyAndModifyRows", "findDuplicateAndCopyLargerValue", "SwapColumns"].includes(this.state.mode)) {
      this.submitCompletedSelectAndTransformRule();
    }
  }

  /*#####################################################################################
     ############################## Input view logic ######################################
     ####################################################################################*/

  /**
   * Called when selection or transformation inputs are submitted
   */
  submitUserInputs(data, type) {
    if (type == "select") {
      this.setState({
        currentView: this.nextView("completeSelection", "", data),
        previousViews: this.appendToPreviousViews(),
      });
    } else if (type == "transform") {
      //If user is currently making a transformation
      if (["CopyAndModifyRows", "findDuplicateAndCopyLargerValue", "SwapColumns"].includes(this.state.mode)) {
        let descriptionUpdatedWithArgs = this.addArgsToDescriptorString(this.state.currentView.descriptor.slice(0), data.arg1.value, data.arg2.value);
        this.setState({
          currentView: this.nextView("completeTransformation", descriptionUpdatedWithArgs, {
            arg1: data.arg1,
            arg2: data.arg2,
          }),
          previousViews: this.appendToPreviousViews(),
        });
      } else {
        this.state.currentView.currentTransformation.transformColumn = data.arg1;
        this.state.currentView.currentTransformation.transformValue = this.state.mode != "Modify" ? "" : data.arg2.value;
        if (this.state.mode == "Merge") {
          this.state.currentView.currentTransformation.transformChain[0] = {
            arg1: data.arg2.index,
            arg2: undefined,
            peName: "getColumn",
          };
        }
        this.submitCompletedSelectAndTransformRule();
      }
    }
  }

  /*#####################################################################################
     ################### Retrieve data from AppSingleton logic ############################
     ######################################################################################*/

  /**
   * Gets PE select options from appSingleton, used to create array of option for col->col / col->val comparisons
   * @param boolean True for column to column select comparisons, false for column to value comparisons
   */
  getSelectOptions() {
    for (let selectOption of appSingleton.selectOptions) {
      if (selectOption.isArg2Index) {
        this.columnToColumnSelectOptions.push({
          uiValue: selectOption.uiDesc,
          type: "selectionOption",
          data: { peName: selectOption.peName },
          isArg1Index: selectOption.isArg1Index,
          isArg2Index: selectOption.isArg2Index,
        });
      } else {
        this.columnToValueSelectOptions.push({
          uiValue: selectOption.uiDesc,
          type: "selectionOption",
          data: { peName: selectOption.peName },
          isArg1Index: selectOption.isArg1Index,
          isArg2Index: selectOption.isArg2Index,
        });
      }
    }
  }

  /**
   * Gets PE single column transformations transformation options from appSingleton
   * @returns {*}
   */
  getSingleTransformOptions() {
    for (let transformOption of appSingleton.transformOptions) {
      if (!transformOption.isTransformChainExclusive && transformOption.uiDesc !== "Delete row") {
        this.modifyTransformOptions.push({
          uiValue: transformOption.uiDesc,
          type: "transformationOption",
          data: { peName: transformOption.peName },
          isArg1Index: transformOption.isArg1Index,
          isArg2Index: transformOption.isArg2Index,
          argsType: transformOption.type,
        });

        if (transformOption.type == "binary") {
          this.mergeTransformOptions.push({
            uiValue: transformOption.uiDesc,
            type: "transformationOption",
            data: { peName: transformOption.peName },
            isArg1Index: transformOption.isArg1Index,
            isArg2Index: transformOption.isArg2Index,
            argsType: transformOption.type,
          });
        }
      }
    }
  }

  /*#####################################################################################
     ############################### General S&T logic ####################################
     ######################################################################################*/

  /**
   * Checks if entire select and transform rule is in valid state, if true, submit button is enabled
   * @returns {boolean}
   */
  stateValid() {
    //If in filter mode and either currentSelection contains both args and peName, or andSelect/ orSelect contain one selection, return true
    if (
      this.state.mode === "Filter" &&
      this.state.currentView.currentSelection.data &&
      this.state.currentView.currentSelection.arg1 &&
      this.state.currentView.currentSelection.arg2 &&
      (![
        this.state.currentView.currentSelection.data.peName,
        this.state.currentView.currentSelection.arg1.value,
        this.state.currentView.currentSelection.arg2.value,
      ].includes("") ||
        this.state.currentView.andSelect.length > 0 ||
        this.state.currentView.orSelect.length > 0)
    ) {
      return true;
    }

    //Ensure there is a selection made, if not return false
    if (
      this.state.currentView.andSelect.length == 0 &&
      this.state.currentView.orSelect.length == 0 &&
      !this.state.currentView.selectAll &&
      !this.state.currentView.currentSelection.data.peName
    ) {
      return false;
    }
    //If a valid transformation applied, return true
    if (
      this.state.currentView.currentTransformation.transformOperation &&
      this.state.currentView.currentTransformation.transformOperation !== "NOP" &&
      this.state.currentView.currentTransformation.transformOperation !== ""
    ) {
      return true;
    }
    //If a CopyAndModifyRows rule, the transformChain must contain at least 2 entries, else if findDuplicateAndCopyLargerValue must have at least one entry
    if (this.state.mode == "CopyAndModifyRows" && this.state.currentView.currentTransformation.transformChain.length > 1) {
      return true;
    }
    if (
      ["SwapColumns", "findDuplicateAndCopyLargerValue"].includes(this.state.mode) &&
      this.state.currentView.currentTransformation.transformChain.length > 0
    ) {
      return true;
    }
    return false;
  }

  /**
   * Return fresh state for beginning of select and transform process
   * Called in componentWillReceiveProps
   */
  getFreshState(props) {
    return {
      currentView: new SelectAndTransformView({
        viewName: props.isNew ? "selectionType" : "editMode", //Used to identify the stage of the S&T process
        descriptor: "", //Displayed to the user to describe the current state of the S&T rule
        listView: true, //True if the current view is a list view
        inputsView: "", //'select', or 'transform' if current view is a params view for selection or transformation
        andSelect: [], //Array of current AND selections
        orSelect: [], //Array of current OR selections
        selectAll: false, //True if select all rows selected
        currentTransformation: this.getFreshTransformationObject(),
        mode: "", //String to describe the S&T mode, eg. 'Modify', 'Filter' etc.
        currentSelection: {}, //Currently selected Selection rule
      }),
      mode: "", //String to describe the S&T mode, eg. 'Modify', 'Filter' etc.
      previousViews: [], //Array of views previously opened by he viewer
      ruleSavedByPE: props.isNew ? false : true, //Boolean true if this rule has been created and saved by the parser engine
    };
  }

  /**
   * Returns a blanks representation of the transformation object
   */
  getFreshTransformationObject() {
    return {
      transformOperation: "", //Name of the transform operation
      transformColumn: new IndexedValue({ value: "" }), //Column selected for single transformation
      transformValue: "", //Value entered for single transformation
      transformChain: [],
    }; //Array used for multiple column transformations
  }

  /**
   * If rule exists, updates rule, otherwise creates a new one
   * Called when selection completed for every rule except 'Filter'
   * nb. this.state.ruleSavedByPE must be set to true after calling this method
   */
  finishSelectionAndUpdateOrCreateRule(selectAll) {
    let selectAndTransformRequest = this.getSelectAndTransformRequest(selectAll);
    //If a new rule, save selection to db, otherwise update existing rule
    if (!this.state.ruleSavedByPE) {
      HttpMediator.updateCommand(selectAndTransformRequest);
    } else {
      HttpMediator.updateCommandTemp(selectAndTransformRequest);
    }
  }

  /**
   * Returns representation of current state to send to the PE
   * @param selectAll Boolean true if all rows selected
   * @returns {{Object}}
   */
  getSelectAndTransformRequest(selectAll) {
    let selectAndTransformRequest = this.createSelectAndTransformRequest();
    let currentSelection = null;
    if (selectAll) {
      selectAndTransformRequest.selectExpr = "ALL";
    } else if (Object.keys(this.state.currentView.currentSelection).length > 0) {
      currentSelection = this.getDataFromCurrentSelection();
    }

    //Add the current selection to selectAndTransformRequest andSelect or orSelect arrays
    if (currentSelection != null) {
      //'and' the current selection if it is the only one, or if 'and' selections exist
      //if 'or' selections exist, 'or' the current selection
      let operandString = " AND ";
      let requestArray = "andSelect";
      if (this.state.currentView.orSelect.length > 0) {
        operandString = " OR ";
        requestArray = "orSelect";
      }
      selectAndTransformRequest[requestArray] =
        selectAndTransformRequest[requestArray] == ""
          ? currentSelection.arg1 + " " + currentSelection.peName + " " + currentSelection.arg2
          : selectAndTransformRequest[requestArray] + operandString + currentSelection.arg1 + " " + currentSelection.peName + " " + currentSelection.arg2;
    }
    return selectAndTransformRequest;
  }

  /**
   * Translate the user input stored in local state into an object containing strings to describe the inputs
   * @returns {{ Object }}
   *      selectExpr: Set to 'ALL' if select all chosen,
   *      andSelect: String representing all 'and' select options,
   *      orSelect: String representing all 'or' select options,
   *      transformValue: ,
   *      transformChain: ,
   *      transformColumn: ,
   *      mode: String representing the S&T mode
   */
  createSelectAndTransformRequest() {
    let selectAndTransformRequest = {};
    //Add the selection criteria to the selectAndTransformRequest
    if (this.state.currentView.selectAll) {
      selectAndTransformRequest.selectExpr = "ALL";
    } else {
      selectAndTransformRequest.andSelect = this.state.currentView.andSelect
        .map((andSelectItem) => {
          if (andSelectItem != null) {
            return andSelectItem.arg1 + " " + andSelectItem.peName + " " + andSelectItem.arg2;
          }
        })
        .join(" AND ");
      selectAndTransformRequest.orSelect = this.state.currentView.orSelect
        .map((orSelectItem) => {
          return orSelectItem.arg1 + " " + orSelectItem.peName + " " + orSelectItem.arg2;
        })
        .join(" OR ");
    }
    //Add the transformation criteria to the selectAndTransformRequest
    if (this.state.currentView.currentTransformation.transformValue != "") {
      selectAndTransformRequest.transformValue = this.state.currentView.currentTransformation.transformValue;
    } else if (this.state.currentView.currentTransformation.transformChain && this.state.currentView.currentTransformation.transformChain.length > 0) {
      selectAndTransformRequest.transformChain = this.state.currentView.currentTransformation.transformChain
        .map((transformLine) => {
          let command = appSingleton.transformOptions.find((transformOption) => {
            return transformOption.peName == transformLine.peName;
          });
          if (command.type === "noArgs") {
            return command.peName;
          }
          if (command.type === "unary") {
            return command.peName + " " + transformLine.arg1;
          }
          if (command.type === "binarySeparated") {
            return command.peName + " " + transformLine.arg1 + command.argSeperator + transformLine.arg2;
          }
        })
        .join(" THEN ");
    }
    selectAndTransformRequest.transformColumn = this.state.currentView.currentTransformation.transformColumn.index;
    selectAndTransformRequest.transformOperation =
      this.state.currentView.currentTransformation.transformOperation == "" ? "NOP" : this.state.currentView.currentTransformation.transformOperation;
    selectAndTransformRequest.commandName = "selectAndTransform";
    selectAndTransformRequest.mode = this.state.mode;
    if (!this.state.ruleSavedByPE) {
      selectAndTransformRequest["commandIndex"] = appSingleton.step + 1;
      selectAndTransformRequest["updateType"] = "insertCommand";
    } else {
      selectAndTransformRequest["commandIndex"] = appSingleton.step;
      selectAndTransformRequest["updateType"] = "updateCommand";
    }
    return selectAndTransformRequest;
  }

  /**
   * Called when the final submit button for the completed rule is clicked
   * Serializes state, appends PE required fields and sends the data to the PE
   */
  submitCompletedSelectAndTransformRule() {
    if (!this.stateValid()) {
      tbAppChannel.publish("error", "This rule is incomplete, please review the rule and try again");
      return;
    }
    let selectAndTransformRequest = this.getSelectAndTransformRequest(this.state.currentView.selectAll);
    HttpMediator.updateCommand(selectAndTransformRequest);
    this.resetSelectAndTransformPanel("hideCommandParams");
  }

  /**
   * Resets this.state and closes the S&T panel
   * @param subscriptionString Defines whether rule list pop-up should be displayed or not
   */
  resetSelectAndTransformPanel(subscriptionString) {
    tbAppChannel.publish(subscriptionString);
    this.setState(this.getFreshState(this.props));
  }
}
