import { Text } from "@fundrecs/ui-library";

import styles from "./SummaryItem.module.scss";

const SummaryItemList = ({ name, list = [] }) => {
  return (
    <div className={styles.container}>
      <Text element="dt" size="xs" variant="secondary">
        {name}:
      </Text>
      <div className="d-flex flex-column">
        {list.map(({ name }) => {
          return (
            <div className="d-flex justify-content-start">
              <Text element="d" size="xs" weight="regular" variant="secondary">
                {name || "–"}
              </Text>
            </div>
          );
        })}
        {list.length === 0 && "-"}
      </div>
    </div>
  );
};

export { SummaryItemList };
