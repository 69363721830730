import moment from "moment";
import {
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  SelectedIcon,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  R,
  C8,
  C6,
  C4,
  C3,
  C2,
  Input,
} from "@fundrecs/ui-library";
import { WORKFLOW_TRIGGER_SCHEDULE_TYPES, WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL } from "utils/workflows/enums";
import styles from "./ScheduledTriggerView.module.scss";

const TriggerTimesView = ({
  scheduleType,
  scheduledHour,
  setScheduledHour,
  scheduledMinute,
  setScheduledMinute,
  recurringValue,
  setRecurringValue,
  recurringInterval,
  setRecurringInterval,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const hours = [...Array(24)].map((_, i) => Math.floor(i).toString().padStart(2, "0"));
  const hoursWithMins = [...Array(24)].map((_, i) => Math.floor(i).toString()).map((i) => i + ":00");
  const minutes = [...Array(60)].map((_, i) => Math.floor(i).toString().padStart(2, "0"));
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const isEndTimeLessThanStartTime = (time) => {
    if (moment(time, "HH:mm").isSame(moment("00:00", "HH:mm")) && moment(startTime, "HH:mm").isSame(moment("23:00", "HH:mm"))) {
      return false;
    } else {
      return moment(time, "HH:mm").isSameOrBefore(moment(startTime, "HH:mm"));
    }
  };

  const isEndTimeGreaterThanStartTime = (time) => {
    return moment(time, "HH:mm").isAfter(moment(startTime, "HH:mm"));
  };

  const changeStartTime = (time) => {
    if (moment(time, "HH:mm").isSame(moment("23:00", "HH:mm"))) {
      setStartTime(time);
      setEndTime(moment("0:00", "HH:mm").format("H:mm").toString());
    } else if (moment(time, "HH:mm").isSameOrAfter(moment(endTime, "HH:mm"))) {
      setStartTime(time);
      const end = moment(time, "H:mm").add(1, "hours");
      setEndTime(end.format("H:mm").toString());
    } else {
      setStartTime(time);
    }
  };

  const disabledOptions = (timeComparison, item, index, time) => {
    if (timeComparison(item)) {
      return (
        <ItemBody>
          <Text size="sm" weight="regular" variant="muted">
            {item}
          </Text>
        </ItemBody>
      );
    } else {
      return (
        <>
          <ItemBody>{item}</ItemBody>
          <SelectedIcon active={hoursWithMins.indexOf(time)} index={index} size="sm" />
        </>
      );
    }
  };
  return (
    <>
      {WORKFLOW_TRIGGER_SCHEDULE_TYPES.SPECIFIC === scheduleType && (
        <>
          <R props="d-flex pr-0 pl-0">
            <C4 props="pr-0 pl-0">
              <div className="mb-12">
                <Text size="sm" weight="medium" variant="secondary">
                  Run at
                </Text>
              </div>
            </C4>
            <C4 props="pr-0 pl-20">
              <div className="mb-12 pl-100">
                <Text size="sm" weight="medium" variant="secondary">
                  Time Zone
                </Text>
              </div>
            </C4>
          </R>
          <R props="d-flex pr-0 pl-0">
            <C2 props="pr-0 pl-0">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{scheduledHour}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {hours.map((item, index) => {
                    return (
                      <DropdownListItem
                        key={index}
                        onClick={() => {
                          setScheduledHour(item);
                        }}
                      >
                        <DropdownItem active={hours.indexOf(scheduledHour)} index={index}>
                          <ItemBody>{item}</ItemBody>
                          <SelectedIcon active={hours.indexOf(scheduledHour)} index={index} size="sm" />
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C2>
            <C2 props="pr-0 pl-8">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{scheduledMinute}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {minutes.map((item, index) => {
                    return (
                      <DropdownListItem
                        key={index}
                        onClick={() => {
                          setScheduledMinute(item);
                        }}
                      >
                        <DropdownItem active={minutes.indexOf(scheduledMinute)} index={index}>
                          <ItemBody>{item}</ItemBody>
                          <SelectedIcon active={minutes.indexOf(scheduledMinute)} index={index} size="sm" />
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C2>
            <C8 props="pr-0 pl-20">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>{timeZone}</ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {[timeZone].map((item, index) => {
                    return (
                      <DropdownListItem key={0} onClick={() => {}}>
                        <DropdownItem active={0} index={0}>
                          <ItemBody>{item}</ItemBody>
                          <SelectedIcon active={0} index={0} size="sm" />
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C8>
          </R>
        </>
      )}
      {WORKFLOW_TRIGGER_SCHEDULE_TYPES.RECURRING === scheduleType && (
        <>
          <R props="d-flex pr-0 pl-0">
            <C6 props="pr-0 pl-0">
              <div className="mb-12">
                <Text size="sm" weight="medium" variant="secondary">
                  Run every
                </Text>
              </div>
            </C6>
            <C3 props="pr-0 pl-20">
              <div className="mb-12">
                <Text size="sm" weight="medium" variant="secondary">
                  Start time
                </Text>
              </div>
            </C3>
            <C3 props="pr-0 pl-20">
              <div className="mb-12">
                <Text size="sm" weight="medium" variant="secondary">
                  End time
                </Text>
              </div>
            </C3>
          </R>
          <R props="d-flex pr-0 pl-0">
            <C2 props="pr-0 pl-0">
              <Input
                type="number"
                name="recurringValue"
                value={recurringValue}
                onChange={(event) => {
                  setRecurringValue(event.target.value);
                }}
                warning={!recurringValue}
                warningMessage="Number has to be provided"
              />
            </C2>
            <C4 props="pr-0 pl-8">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{recurringInterval}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  <DropdownListItem
                    onClick={() => {
                      setRecurringInterval(WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS);
                    }}
                  >
                    <DropdownItem active={recurringInterval === WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS ? 0 : 1} index={0}>
                      <ItemBody>{WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS}</ItemBody>
                      <SelectedIcon active={recurringInterval === WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS ? 0 : 1} index={0} size="sm" />
                    </DropdownItem>
                  </DropdownListItem>
                  <DropdownListItem
                    onClick={() => {
                      setRecurringInterval(WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.MINUTES);
                    }}
                  >
                    <DropdownItem active={recurringInterval === WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS ? 0 : 1} index={1}>
                      <ItemBody>{WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.MINUTES}</ItemBody>
                      <SelectedIcon active={recurringInterval === WORKFLOW_TRIGGER_SCHEDULED_RECURRING_INTERVAL.HOURS ? 0 : 1} index={1} size="sm" />
                    </DropdownItem>
                  </DropdownListItem>
                </DropdownList>
              </Dropdown>
            </C4>
            <C3 props="pr-0 pl-20">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{startTime}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {hoursWithMins.map((item, index) => {
                    return (
                      <DropdownListItem
                        key={index}
                        onClick={() => {
                          changeStartTime(item);
                        }}
                      >
                        <DropdownItem active={hoursWithMins.indexOf(startTime)} index={index}>
                          <ItemBody>{item}</ItemBody>
                          <SelectedIcon active={hoursWithMins.indexOf(startTime)} index={index} size="sm" />
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C3>
            <C3 props="pr-0 pl-20">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{endTime}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {hoursWithMins.map((item, index) => {
                    return (
                      <DropdownListItem
                        key={index}
                        onClick={() => {
                          isEndTimeGreaterThanStartTime(item) && setEndTime(item);
                        }}
                      >
                        <DropdownItem active={hoursWithMins.indexOf(endTime)} index={index}>
                          {disabledOptions(isEndTimeLessThanStartTime, item, index, endTime)}
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </C3>
          </R>
        </>
      )}
    </>
  );
};
export { TriggerTimesView };
