const ACTIONS = Object.freeze({
  CELL_EDIT_SINGLE_CLICK: "CELL_EDIT_SINGLE_CLICK",
  CELL_EDIT_DOUBLE_CLICK: "CELL_EDIT_DOUBLE_CLICK",
  INLINE_EDIT: "INLINE_EDIT",
  FULL_ROW_EDIT: "FULL_ROW_EDIT",
  DELETE_ROW: "DELETE_ROW",
  DRAG_ROW: "DRAG_ROW",
  UNDO_EDIT: "UNDO_EDIT",
  UNDO_EDIT_WITH_BUTTON: "UNDO_EDIT_WITH_BUTTON",
  REDO_EDIT: "REDO_EDIT",
  REDO_EDIT_WITH_BUTTON: "REDO_EDIT_WITH_BUTTON",
  SUPPRESS_ROW_CLICK_SELECTION: "SUPPRESS_ROW_CLICK_SELECTION",
  EXPAND_ROW: "EXPAND_ROW",
});

const RowIndex = {
  headerName: "#",
  valueGetter: "node.rowIndex + 1",
  width: 50,
  pinned: "left",
  colId: "rowIndex",
  suppressMenu: true,
};

const CONFIG_VALUES = Object.freeze({
  UNDO_REDO_EDIT_LIMIT: 5,
});

const CUSTOM_COMPONENTS = {
  NO_ROWS_OVERLAY_COMPONENT: "NO_ROWS_OVERLAY_COMPONENT",
};

export { ACTIONS, RowIndex, CONFIG_VALUES, CUSTOM_COMPONENTS };
