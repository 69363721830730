import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { useNavigate, createSearchParams } from "react-router-dom";
import moment from "moment";
import { R, IconAdd, Text, Button, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { ManageLayout, PageTitleArea } from "../../layout/Layout";
import { Table } from "../../ag-grid/Ag-grid";
import { StatusCell } from "../../ag-grid/StatusCell/StatusCell";
import { VerticalMenu } from "../../ag-grid/verticalMenu/VerticalMenu";
import { NoOutputMappings } from "../NoOutputMappings";
import { AuditCell, auditCellText } from "./AuditCell/AuditCell";
import { AUTHORITIES } from "utils/enums";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { DropdownPanelCell } from "./dropdownPanel/DropdownPanelCell";
import { getMenuItems } from "./verticalMenu/verticalMenu";
import { MODAL_IDS, STATUS_VALUES } from "utils/mappings/enums";
import { DeleteMapping } from "components/workflows/OutputMapping/Modals/DeleteMapping";
import { ShareOutputMappingModal } from "../ShareOutputMappingModal/ShareOutputMappingModal";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

const OutputMappingsList = observer(() => {
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const { outputMappingsStore, rolesStore, meStore } = useStore();
  const [outputMappings, setOutputMappings] = useState(null);
  const [shareOutputMappingUuid, setSharedOutputMappingUuid] = useState(null);
  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All output mappings for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  useEffect(() => {
    // Show ag-grid spinner until reaction updates state with data from store
    gridApi?.api?.showLoadingOverlay();

    if (teamId) outputMappingsStore.loadOutputMappings({ teamId });

    /**
     * Mobx Reaction to keep useState in sync with store
     * Whenever the observable data changes, it triggers the effect
     * The effect checks if the component is mounted and the store already finished to fetch the data
     *
     * For more details refer to:
     * https://mobx.js.org/reactions.html#reaction
     *
     * @param {Function} ObservableData - Tracks the observable.
     * @param {Function} Effect - Reacts to changes in the observable.
     */
    const disposer = reaction(
      () => outputMappingsStore.getOutputMappings(), // Observable data
      (outputMappingsListForSelectedTeam) => {
        // Effect
        setOutputMappings(outputMappingsListForSelectedTeam?.length > 0 ? outputMappingsListForSelectedTeam : []);
      }
    );
    // Cleanup reaction
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const navigateEdit = (id, props, teamId) => {
    outputMappingsStore.setOutputMapping(props.data);
    navigate({ pathname: `/mappings/edit/${id}`, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  const navigateView = (id, props, teamId) => {
    outputMappingsStore.setOutputMapping(props.data);
    navigate({ pathname: `/mappings/view/${id}`, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  const onCellClick = (event) => {
    if (event?.column?.colId !== "rowEndSettings") {
      const status = event?.data?.status;
      const uuid = event?.data?.uuid;
      const teamId = event?.data?.teamId;

      if (status === STATUS_VALUES.DRAFT) {
        navigateEdit(uuid, event, teamId);
      } else if (status === STATUS_VALUES.APPROVED) {
        navigateView(uuid, event, teamId);
      }
    }
  };

  const cloneRow = async (event) => {
    const { uuid, name } = event.data;
    const { success } = await outputMappingsStore.cloneOutputMapping(teamId, uuid, name);
    if (success) {
      outputMappingsStore.loadOutputMappings({ teamId });
    }
  };

  const shareRow = (event) => {
    const { uuid } = event.data;
    setSharedOutputMappingUuid(uuid);
    modalInstance(MODAL_IDS.SHARE_OUTPUT_MAPPING).toggle();
  };

  const onItemClick = (option, props) => {
    const { uuid } = props.data;
    switch (option) {
      case "viewRow":
        navigateView(uuid, props, teamId);
        break;
      case "cloneRow":
        cloneRow(props);
        break;
      case "shareRow":
        shareRow(props);
        break;
      case "editRow":
        navigateEdit(uuid, props, teamId);
        break;
      case "deleteRow":
        outputMappingsStore.setOutputMapping(props.data);
        modalInstance(MODAL_IDS.DELETE_MAPPING).toggle();
        break;
      default:
        break;
    }
  };

  const handleCreateMapping = () => {
    outputMappingsStore.setOutputMapping({});
    navigate({ pathname: "/mappings/create", search: `?${createSearchParams({ teamId: teamId })}` });
  };

  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: true,
    detailRowAutoHeight: true,
    suppressContextMenu: true,
    suppressRowClickSelection: true,
    onGridReady: onGridReady,
    noRowsOverlayComponent: NoOutputMappings,
    onCellClicked: onCellClick,
    detailCellRenderer: DropdownPanelCell,
  };

  const columns = [
    {
      headerName: "",
      field: "dropdown_trigger",
      cellRenderer: "agGroupCellRenderer",
      width: 60,
      suppressMenu: true,
      sortable: false,
    },
    { headerName: "Name", field: "name", flex: 2, resizable: true },
    { headerName: "Description", field: "description", flex: 2, resizable: true },
    { headerName: "Status", field: "status", cellRenderer: StatusCell, maxWidth: 100, resizable: true },
    {
      headerName: "Audit log",
      field: "createdAt",
      cellRenderer: AuditCell,
      sort: "desc",
      flex: 2,
      filter: "agTextColumnFilter",
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const { lastModifiedDate: lastModifiedDateA, createdDate: createdDateA } = nodeA.data;
        const { lastModifiedDate: lastModifiedDateB, createdDate: createdDateB } = nodeB.data;

        const timestampA = lastModifiedDateA || createdDateA;
        const timestampB = lastModifiedDateB || createdDateB;

        const date1 = moment(new Date(timestampA));
        const date2 = moment(new Date(timestampB));

        if (date1.isSame(date2)) return 0;
        return date1.isAfter(date2) ? 1 : -1;
      },
      filterParams: {
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          switch (filter) {
            case "contains":
              return auditCellText(value).includes(filterText);
            case "notContains":
              return !auditCellText(value).includes(filterText);
            case "equals":
              return auditCellText(value) === filterText;
            case "notEqual":
              return auditCellText(value) !== filterText;
            case "startsWith":
              return auditCellText(value).startsWith(filterText);
            case "endsWith":
              return auditCellText(value).endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
    {
      headerName: "",
      field: "rowEndSettings",
      suppressMenu: true,
      sortable: false,
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: { menuItems: [], onItemClick: onItemClick, getMenuItemsFromRowData: getMenuItems },
    },
  ];

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} param.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if it isn't available
    const cellValue = node.data?.[columnId] ?? "";

    if (columnId === "createdAt") {
      // custom cell processing for "createdAt" column
      return auditCellText(node);
    }

    return cellValue;
  };

  return (
    <>
      <ShareOutputMappingModal shareOutputMappingUuid={shareOutputMappingUuid} />
      <DeleteMapping teamId={teamId} outputMapping={outputMappingsStore.getOutputMapping()} />
      <PageTitleArea
        title="Output mappings"
        description={<div className="text-medium pb-4">View your team's output mappings</div>}
        additionalChildClasses="pr-20"
      >
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_CREATE])}>
          <Button size="md" color="primary" onClick={handleCreateMapping}>
            <IconAdd className={"btn-md-svg"} />
            <Text size="sm">New output mapping</Text>
          </Button>
          <DownloadTable gridRef={gridApi} processCellCallback={processCellCallback} fileName={fileName} excludeFirstColumn={true} excludeLastColumn={true} />
        </AuthWrapper>
      </PageTitleArea>
      <ManageLayout>
        <R props="d-flex pt-32 pl-0 pb-20" />
        <Table columns={columns} rowData={outputMappings} agGridOptions={gridOptions} />
      </ManageLayout>
    </>
  );
});

export { OutputMappingsList };
