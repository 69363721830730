import { makeAutoObservable } from "mobx";
import { fusionDataApi } from "api";

const importRulesAPI = fusionDataApi.importRules;

class ImportsStore {
  constructor() {
    makeAutoObservable(this);
  }

  getImportRules = async (request) => {
    const { teamId, type } = request;
    return importRulesAPI
      .getImportRules({ teamId, type })
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {});
  };
}
export { ImportsStore };
