import React from "react";
import PropTypes from "prop-types";
import { Text } from "@fundrecs/ui-library";
import { TABLE_TYPES } from "utils/lookups/enums";
import { Table } from "components/ag-grid/Ag-grid";
import { RowIndex } from "components/ag-grid/config";
import { ToggleRenderer } from "components/ag-grid/toggle/ToggleRenderer";
import { ToggleEditor } from "components/ag-grid/toggle/ToggleEditor";
import { DeleteRow } from "components/ag-grid/deleteRow/DeleteRow";
/**
 * The LookupTable component renders the ag-grid table.
 * It sets up the ag-grid table with specific column headers based off the Lookup Table Type.
 * It will only re-render the table if the data changes.
 *
 * The prop rowData is passed down from the parent. This is an array of objects
 * @param {rowData} rowData
 *
 * The prop setRowData is a hook which is passed into ag-grid allowing it to update the parent's state if a function within ag-grid is called.
 * So for example the drag & drop functionality would affect the rowData in the parent because the order changes and this allows ag-gid to tell us
 * this interal method was called.
 * @param {setRowData}
 *
 * The prop tableType is used to distinguish between the two table types "Equals" & "Contains".
 * If the tableType is contains we want to remove the "Unique" column from rendering in the table
 * @param {tableType}
 */
const LookupTable = ({ rowData, tableType }) => {
  const { LOOKUP_TABLE_TYPE_CONTAINS } = TABLE_TYPES;

  const columns = [
    RowIndex,
    { headerName: "Column Headers (Max 60)", field: "header", flex: 2, rowDrag: true, editable: true },
    { headerName: "Unique", field: "unique", editable: true, flex: 2, cellRenderer: ToggleRenderer, cellEditor: ToggleEditor },
    { headerName: "Action", field: "delete", editable: false, cellRenderer: DeleteRow },
  ];

  //Remove the column header unique from the table before we render it. Contains table does not require a unique flag!
  const tableColumns = tableType === LOOKUP_TABLE_TYPE_CONTAINS ? columns.filter((col) => col.headerName !== "Unique") : columns;

  const onRowDragEnd = (params) => {
    const { api } = params;
    api.forEachNode((rowNode, index) => {
      rowNode.id = index++;
    });
    api.refreshCells();
  };

  const gridOptions = {
    rowDragManaged: true,
    rowDragEntireRow: true,
    rowDragMultiRow: true,
    onRowDragEnd: onRowDragEnd,
  };

  return (
    <div className="mt-32 mb-32">
      {rowData.length > 0 && (
        <>
          <div className="mb-16">
            <Text weight="medium">{rowData.length} columns added</Text>
          </div>
          <Table columns={tableColumns} rowData={rowData} agGridOptions={gridOptions} />
        </>
      )}
    </div>
  );
};

//Proptypes for LookupTable
LookupTable.propTypes = {
  rowData: PropTypes.array,
  setRowData: PropTypes.func,
  tableType: PropTypes.oneOf([TABLE_TYPES.LOOKUP_TABLE_TYPE_CONTAINS, TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS]),
};

// Specifies the default values for props:
LookupTable.defaultProps = {
  rowData: [],
  setRowData: () => {},
  //Not specifying default proptype for tableType as "Select Type" needs to show before user chooses type.
};

export { LookupTable };
