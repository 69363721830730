import { useState, createContext } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { BorderBottom } from "@fundrecs/ui-library";
import { ManageLayout, PageTitleArea } from "../../layout/Layout";
import { ResultsTab } from "./tabs/ResultsTab";

const RunResultsContext = createContext();

const WorkflowRunResults = observer(() => {
  const [searchParams] = useSearchParams();
  const [gridApi, setGridApi] = useState(null);
  const [date, setDate] = useState(searchParams.get("date") ? new Date(searchParams.get("date")) : new Date());

  const runResultsContext = {
    gridApi,
    setGridApi,
    date,
    setDate,
  };
  return (
    <>
      <PageTitleArea
        leftArrow={false}
        title={"Workflows run results"}
        navigate={() => {}}
        description={<div className="text-medium pb-4"> View the run results of your team's active workflows</div>}
        borderBottom={false}
      />
      <BorderBottom />
      <ManageLayout>
        <RunResultsContext.Provider value={runResultsContext}>
          <ResultsTab gridApi={gridApi} setGridApi={setGridApi} />
        </RunResultsContext.Provider>
      </ManageLayout>
    </>
  );
});

export { WorkflowRunResults, RunResultsContext };
