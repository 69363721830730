import { Text } from "@fundrecs/ui-library";

const defaultTextTrue = "Enabled";
const defaultTextFalse = "Disabled";

const ToggleRenderer = (props) => {
  const { value, textTrue = defaultTextTrue, textFalse = defaultTextFalse } = props;

  return (
    <Text size="sm" weight="regular" variant={value ? "success" : "primary"}>
      {value ? textTrue : textFalse}
    </Text>
  );
};

export { ToggleRenderer };
