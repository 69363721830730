import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import MapColumn from "./MapColumn";

import { LumsContext } from "./LumsCommand";

export default function MapColumns() {
  const lumsContext = useContext(LumsContext);

  const { inputFile, mappedLookupTableColumns } = lumsContext;

  const usedLookupTableColumns = mappedLookupTableColumns.map((mapping) => mapping.lookupColumnName);

  const { columns: inputFileColumns } = inputFile;

  return (
    <div className="section">
      <div className="section-info blue">
        <b>Map input file to lookup.</b> Choose columns from the input file that map to columns in the lookup table
      </div>

      <div className="input-combo">
        <Grid container>
          <Grid item xs>
            <div className="input-label-top">Select column from the input file preview below</div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs>
            <div className="input-label-top">Select matching column in lookup table</div>
          </Grid>
        </Grid>
      </div>
      <div className="input-combo">
        {mappedLookupTableColumns.map((mapping, mappingIndex) => (
          <MapColumn
            key={mappingIndex}
            usedLookupTableColumns={usedLookupTableColumns}
            inputFileColumns={inputFileColumns}
            mapping={mapping}
            mappingIndex={mappingIndex}
          />
        ))}
      </div>
    </div>
  );
}
