import { useContext } from "react";
import { Button, Text, modalInstance, IconCheck } from "@fundrecs/ui-library";
import { rolesStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { LookupsTableContext } from "../../LookupsTable";
import { MODAL_IDS } from "utils/lookups/enums";

const ApproveRowsButton = () => {
  const { lookupTable } = useContext(LookupsTableContext);
  const { teamId } = lookupTable || {};

  return (
    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_APPROVE])}>
      <Button
        size="md"
        color="success-secondary"
        onClick={() => {
          modalInstance(MODAL_IDS.APPROVE_LOOKUPS_MODAL_ID).toggle();
        }}
      >
        <IconCheck className="btn-md-svg" />
        <Text size="sm">Approve Rows</Text>
      </Button>
    </AuthWrapper>
  );
};

export { ApproveRowsButton };
