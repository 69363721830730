import { useState, useEffect } from "react";
import Intercom from "react-intercom";
import { observer } from "mobx-react-lite";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import {
  SideNavBar,
  SideNavColumn,
  HoverDropdown,
  HoverDropdownItem,
  SideNavDropdownButton,
  OneProductSideNavFooter,
  TeamAvatar,
  ToolTip,
  modalInstance,
  OneProductSideNavButton,
  OneProductLookupsDataIcon,
  OneProductRunResultsDataIcon,
  OneProductTemplatesDataIcon,
  OneProductWorkflowsDataIcon,
  OneProductLaunchDataIcon,
  OneProductHelpDataIcon,
  SideNavHeading,
  OneProductOutputMappingDataIcon,
  OneProductReconciliationsDataIcon,
  OneProductFundsDataIcon,
  OneProductSubAccountsDataIcon,
  OneProductDashboardDataIcon,
  OneProductSourcesAdminIcon,
  OneProductReportingDataIcon,
  OneProductUploadsIcon,
  OneProductGlobalMappingsIcon,
  SideMenuChevron,
  HyperLink,
} from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { ROUTES, ROUTES_NAMES } from "utils/enums";
import { MODAL_IDS } from "utils/workflows/enums";
import styles from "./Sidebar.module.scss";

const Sidebar = observer(({ expanded }) => {
  const { meStore, tenantConfigStore, recTypeStore } = useStore();

  const location = useLocation();
  const { pathname } = { ...location };

  const [searchParams, setSearchParams] = useSearchParams();
  const [initialised, setInitialised] = useState(false);

  const [active, setActive] = useState("");

  const fusionAdminUrl = tenantConfigStore.getFusionAdminUrl();
  const fusionRecsUrl = tenantConfigStore.getFusionRecsUrl();
  const reportingUrl = tenantConfigStore.getFusionReportingUrl();
  const userInfo = {
    email: meStore.getEmail(),
    name: meStore.getName(),
  };

  // Retrieve the currently selected team from the meStore.
  const selectedTeam = meStore.getSelectedTeam();

  useEffect(() => {
    /*
     * Updates the active menu item based on the current path.
     */
    const updateActiveMenu = (path) => {
      const routeKey = Object.keys(ROUTES).find((key) => ROUTES[key] === path) || "/";
      setActive(ROUTES_NAMES[routeKey]);
    };

    /*
     * Sets the selected team and updates the URL search parameters.
     */
    const selectTeam = (team) => {
      meStore.setSelectedTeam(team);
      setSearchParams({ teamId: team.id });
    };

    /*
     * Selects the default team or shows an error modal if no team is available.
     */
    const selectDefaultTeam = () => {
      const defaultTeam = meStore.getDefaultTeamOrFirstInList();
      if (defaultTeam) {
        selectTeam(defaultTeam);
      } else {
        meStore.setSelectedTeam(null);
        modalInstance(MODAL_IDS.ERROR_TEAM_NOT_FOUND).show();
      }
    };

    /*
     * Handles the selection of a team based on the provided teamId.
     * If teamId is not provided or invalid, selects a default team.
     */
    const handleTeamSelection = (teamId) => {
      if (teamId) {
        // If a teamId is provided, try to find the team by ID
        const team = meStore.getTeamById(teamId);

        if (team) {
          meStore.setSelectedTeam(team);
        } else {
          selectDefaultTeam();
        }
      } else {
        // If no teamId is provided, check if a team is already selected in the store
        const storeSelectedTeam = meStore.getSelectedTeam();

        if (storeSelectedTeam) {
          setSearchParams({ teamId: storeSelectedTeam.id });
        } else {
          selectDefaultTeam();
        }
      }
    };

    const currentTeamId = searchParams.get("teamId");
    const initialPath = `/${pathname.split("/")[1]}`;

    updateActiveMenu(initialPath);
    handleTeamSelection(currentTeamId);
  }, [pathname, searchParams, setSearchParams, meStore, setActive]);

  if (selectedTeam && selectedTeam.id && !initialised) {
    recTypeStore.getRecTypes(selectedTeam.id);
    setInitialised(true);
  }

  const renderSupport = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-data.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (!localENV) {
      return <Intercom appID="2a73body" {...userInfo} />;
    }
  };

  const launchIntercom = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-data.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (localENV) {
      let emailTo = "mailto:2a73body@incoming.intercom.io";
      window.open("mailto:" + emailTo);
    } else {
      window.Intercom("show", {
        hideDefaultLauncher: true,
      });
    }
  };

  return (
    <>
      <SideNavBar visible={expanded}>
        <SideNavColumn>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.REC_TYPES}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductDashboardDataIcon />
                ) : (
                  <ToolTip text={ROUTES_NAMES.DASHBOARDS} direction="right">
                    <OneProductDashboardDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.DASHBOARDS}
              onClick={() => {}}
            />
          </HyperLink>
          <HyperLink href={reportingUrl} target="_blank">
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductReportingDataIcon />
                ) : (
                  <ToolTip text="Reporting" direction="right">
                    <OneProductReportingDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.REPORTING}
              onClick={() => {}}
            />
          </HyperLink>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.UPLOADS}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              minimised={!expanded}
              buttonName={ROUTES_NAMES.UPLOADS}
              ButtonIcon={<OneProductUploadsIcon />}
              onClick={() => {}}
              active={active}
            />
          </HyperLink>
          <Link className={styles.sideBarLink} to={`${ROUTES.HOME}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductRunResultsDataIcon />
                ) : (
                  <ToolTip text="Workflows run results" direction="right">
                    <OneProductRunResultsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.HOME}
              onClick={() => {
                setActive(ROUTES_NAMES.HOME);
              }}
            />
          </Link>
        </SideNavColumn>
        {expanded && <SideNavHeading HeadingText="Manage" />}

        <SideNavColumn>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.REC_TYPE_CONFIG_DASH}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductReconciliationsDataIcon />
                ) : (
                  <ToolTip text={ROUTES_NAMES.RECONCILIATIONS} direction="right">
                    <OneProductReconciliationsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.RECONCILIATIONS}
              onClick={() => {}}
            />
          </HyperLink>
          <Link className={styles.sideBarLink} to={`${ROUTES.WORKFLOWS}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductWorkflowsDataIcon />
                ) : (
                  <ToolTip text="Workflows" direction="right" size="medium">
                    <OneProductWorkflowsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.WORKFLOWS}
              onClick={() => {
                setActive(ROUTES_NAMES.WORKFLOWS);
              }}
            />
          </Link>
          <Link className={styles.sideBarLink} to={`${ROUTES.SFTP_IMPORT}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductSourcesAdminIcon />
                ) : (
                  <ToolTip text="Imports" direction="right">
                    <OneProductSourcesAdminIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.IMPORTS}
              onClick={() => {
                setActive(ROUTES_NAMES.SFTP_IMPORT);
              }}
            />
          </Link>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.FUNDS}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductFundsDataIcon />
                ) : (
                  <ToolTip text="Funds" direction="right" size="medium">
                    <OneProductFundsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.FUNDS}
              onClick={() => {}}
            />
          </HyperLink>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.SUB_ACCOUNTS}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductSubAccountsDataIcon />
                ) : (
                  <ToolTip text="Sub accounts" direction="right" size="medium">
                    <OneProductSubAccountsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.SUB_ACCOUNTS}
              onClick={() => {}}
            />
          </HyperLink>
        </SideNavColumn>

        {expanded && <SideNavHeading HeadingText="Resources" />}

        <SideNavColumn>
          <Link className={styles.sideBarLink} to={`${ROUTES.LOOKUPS}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductLookupsDataIcon />
                ) : (
                  <ToolTip text="Lookups" direction="right">
                    <OneProductLookupsDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.LOOKUPS}
              onClick={() => {
                setActive(ROUTES_NAMES.LOOKUPS);
              }}
            />
          </Link>
        </SideNavColumn>

        <SideNavColumn>
          <Link className={styles.sideBarLink} to={`${ROUTES.OUTPUT_MAPPINGS}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductOutputMappingDataIcon />
                ) : (
                  <ToolTip text="Output mappings" direction="right">
                    <OneProductOutputMappingDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.OUTPUT_MAPPINGS}
              onClick={() => {
                setActive(ROUTES_NAMES.OUTPUT_MAPPINGS);
              }}
            />
          </Link>
        </SideNavColumn>

        <SideNavColumn>
          <Link className={styles.sideBarLink} to={`${ROUTES.TEMPLATES}?teamId=${selectedTeam?.id}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductTemplatesDataIcon />
                ) : (
                  <ToolTip text="Templates" direction="right">
                    <OneProductTemplatesDataIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.TEMPLATES}
              onClick={() => {
                setActive(ROUTES_NAMES.TEMPLATES);
              }}
            />
          </Link>
          <HyperLink href={`${fusionRecsUrl}${ROUTES.GLOBAL_MAPPINGS}?teamId=${selectedTeam ? selectedTeam.id : ""}`}>
            <OneProductSideNavButton
              ButtonIcon={
                expanded ? (
                  <OneProductGlobalMappingsIcon />
                ) : (
                  <ToolTip text={ROUTES_NAMES.GLOBAL_MAPPINGS} direction="right">
                    <OneProductGlobalMappingsIcon />
                  </ToolTip>
                )
              }
              minimised={!expanded}
              active={active}
              buttonName={ROUTES_NAMES.GLOBAL_MAPPINGS}
              onClick={() => {}}
            />
          </HyperLink>
          <SideNavHeading />
        </SideNavColumn>
      </SideNavBar>
      <OneProductSideNavFooter
        expanded={expanded}
        topSection={
          selectedTeam && (
            <>
              <OneProductSideNavButton
                ButtonIcon={
                  expanded ? (
                    <OneProductHelpDataIcon />
                  ) : (
                    <ToolTip text="Help" direction="right">
                      <OneProductHelpDataIcon />
                    </ToolTip>
                  )
                }
                minimised={!expanded}
                active={active}
                buttonName={ROUTES_NAMES.HELP}
                onClick={() => {
                  setActive(ROUTES_NAMES.HELP);
                  launchIntercom();
                }}
                disabled={false}
              />
              <HyperLink href={fusionAdminUrl} target="_blank">
                <OneProductSideNavButton
                  ButtonIcon={
                    expanded ? (
                      <OneProductLaunchDataIcon />
                    ) : (
                      <ToolTip text="Launch admin" direction="right">
                        <OneProductLaunchDataIcon />
                      </ToolTip>
                    )
                  }
                  minimised={!expanded}
                  active={active}
                  buttonName={ROUTES_NAMES.LAUNCH_ADMIN}
                  onClick={() => {
                    setActive(ROUTES_NAMES.LAUNCH_ADMIN);
                  }}
                />
              </HyperLink>
            </>
          )
        }
        bottomSection={
          selectedTeam && (
            <SideNavColumn>
              <li className="dropstart">
                <SideNavDropdownButton
                  minimised={!expanded}
                  body="Team workspace"
                  icon={<TeamAvatar text={selectedTeam.name} size="md" />}
                  arrowIcon={<SideMenuChevron width="20" height="20" />}
                  title={selectedTeam.name}
                />
                <HoverDropdown width="300px" title="Switch teams">
                  {meStore.getTeams().map((team, index) => {
                    return (
                      <HoverDropdownItem
                        key={index}
                        avatarText={team.name}
                        selected={selectedTeam.id === team.id}
                        title={team.name}
                        onClick={() => {
                          meStore.setSelectedTeam(team);
                          setSearchParams({ teamId: team.id });
                        }}
                      />
                    );
                  })}
                </HoverDropdown>
              </li>
            </SideNavColumn>
          )
        }
      />
      {renderSupport()}
    </>
  );
});

export { Sidebar };
