import React from "react";
import { ListItem } from "@material-ui/core";

export default class SelectAndTransformListView extends React.Component {
  constructor(props) {
    super(props);
    this.listOptionClicked = this.listOptionClicked.bind(this);
  }

  render() {
    //Use charAt() and slice() to capitalise the first character of the uiValue
    const uiValue =
      this.props.listOption.uiValue.charAt(0).toUpperCase() +
      this.props.listOption.uiValue.slice(1);
    return (
      <ListItem
        className="font-100 cellWithEllipsis padding-2 background-white"
        title={uiValue}
        data-index={this.props.index}
        key={this.props.index}
        button
        onClick={this.listOptionClicked}
      >
        {uiValue}
      </ListItem>
    );
  }

  /**
   * Pass the click event back to listOptionSelected() in SelectAndTransformPanel.jsx
   * @param type String to identify the clicked option
   * @param data
   */
  listOptionClicked() {
    this.props.listOptionSelected(
      this.props.listOption.type,
      this.props.listOption.data
    );
  }
}
