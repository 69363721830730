import { Route, Routes, createSearchParams, useNavigate } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { observer } from "mobx-react-lite";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { MODAL_IDS } from "utils/templates/enums";
import { ROUTES } from "utils/enums";
import { TemplatesList } from "./TemplatesList/TemplatesList";
import { CreateTemplateStageWithoutWorkflow } from "./CreateTemplate/CreateTemplateWithoutWorkflow";
import { Template } from "./EditTemplate/Template";
import { useTeamId } from "store/hooks/useTeamId";

const Templates = observer(() => {
  let navigate = useNavigate();
  const teamId = useTeamId();

  const handleConfirmModal = () => {
    navigate({ pathname: ROUTES.TEMPLATES, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <Routes>
        <ApmRoutes>
          <Route path="/" element={<TemplatesList />} />
          <Route path="/create" element={<CreateTemplateStageWithoutWorkflow />} />
          <Route path=":templateUuid" element={<Template />} />
        </ApmRoutes>
      </Routes>

      <ErrorModal
        modalId={MODAL_IDS.ERROR_TEMPLATE_NOT_FOUND}
        heading="Sorry, this template doesn't exist in your team workspace!"
        text="Please go to the templates section and select from the list of all available templates."
        confirm="Go to templates"
        onConfirm={handleConfirmModal}
      />
    </>
  );
});

export { Templates };
