import { useState, createContext } from "react";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";
import { modalInstance, R } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { ManageLayout } from "components/layout/Layout";
import { MODAL_IDS, TABLE_TYPES } from "utils/lookups/enums";
import { AUTHORITIES } from "utils/enums";
import { isUserAuthorized } from "components/AuthorizationWrapper";
import { autoSizeAll } from "components/ag-grid/Events/autoSizeAll";
import { LookupsTableHeader } from "./header/Header";
import { NoLookupsRows } from "./noLookups/NoLookups";
import { useTeamId } from "store/hooks/useTeamId";
import { ContainsTable } from "./tableType/ContainsTable";
import { EqualsTable } from "./tableType/EqualsTable";
import { getMenuItems, handleSubmitSingleRow } from "./utils/verticalMenu";
import { VerticalMenu } from "components/ag-grid/verticalMenu/VerticalMenu";
import { LookupsTableSubheader } from "./subHeader/Subheader";
import { RejectLookupsModal } from "../LookupModals/RejectLookupsModal";
import { ApproveLookupsModal } from "../LookupModals/ApproveLookupsModal";
import { DeleteLookupsModal } from "../LookupModals/DeleteLookupsModal";
import { createColumnMappingsObject, assembleData } from "./utils/transformData";

const LookupsTableContext = createContext();

const LookupsTable = observer(() => {
  const [searchParams] = useSearchParams();
  const { lookupsStore, rolesStore, meStore } = useStore();

  const teamId = useTeamId();
  const lookupTableUuid = searchParams.get("tableId");

  const lookupTable = lookupsStore.getLookupTableByUuid(lookupTableUuid);

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isAddRow, setIsAddRow] = useState(false);
  // This is only used to when vertical menu triggers modals
  const [verticalMenuRow, setVerticalMenuRow] = useState();

  // Grid Events
  const onRowSelected = (params) => {
    if (params.api.getSelectedRows().length > 0) {
      setSelectedRowsCount(params.api.getSelectedRows().length);
    } else {
      setSelectedRowsCount(0);
    }
  };

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params.api);
      setColumnApi(params.columnApi);
    }
    autoSizeAll(true, params);
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onCellEditingStopped = (params) => {
    const { api, data } = params;

    const editRow = async () => {
      const { teamId, uuid, columns: tableColumns } = lookupTable;
      if (data) {
        api.deselectAll();

        const columnMappings = createColumnMappingsObject(data, tableColumns);
        const { priority, status, tags, id, createdAt, createdBy } = data;

        const requestObject = {
          id: id,
          columnMappings: columnMappings,
          tags: tags,
          priority: priority,
          status: status,
          createdAt: createdAt,
          createdBy: createdBy,
          editedBy: meStore.getEmail(),
        };

        const response = await lookupsStore.updateTableRow(uuid, teamId, data.id, { ...requestObject });
        const { success, data: responseData } = response || {};
        if (success) {
          api.applyTransactionAsync({ update: assembleData([responseData]) });
        } else {
          //Undo changes if fails
          api.undoCellEditing();
        }
      }
    };
    const canEdit = isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_EDIT]) });

    if (!api.addRow && canEdit) {
      editRow();
    } else if (data.id !== -1) {
      // Users are not supposed to edit different rows other than the one being added
      params.api.undoCellEditing();
    }
  };

  const gridOptions = {
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    getRowId: (params) => params.data.id,
    onRowSelected: onRowSelected,
    onGridReady: onGridReady,
    onGridSizeChanged: onGridSizeChanged,
    onCellEditingStopped: onCellEditingStopped,
    noRowsOverlayComponent: NoLookupsRows,
    noRowsOverlayComponentParams: { lookupTable },
    undoRedoCellEditing: true,
  };

  // Row Vertical Menu
  const onItemClick = (option, props) => {
    const { data } = props || {};
    switch (option) {
      case "deleteRow":
        setVerticalMenuRow(data);
        modalInstance(MODAL_IDS.DELETE_LOOKUPS_MODAL_ID).toggle();
        break;
      case "rejectRow":
        setVerticalMenuRow(data);
        modalInstance(MODAL_IDS.REJECT_LOOKUPS_MODAL_ID).toggle();
        break;
      case "approveRow":
        setVerticalMenuRow(data);
        modalInstance(MODAL_IDS.APPROVE_LOOKUPS_MODAL_ID).toggle();
        break;
      case "submitRow":
        handleSubmitSingleRow({ gridApi, data, lookupTable });
        break;
      default:
        break;
    }
  };
  const verticalMenu = {
    headerName: "",
    field: "rowEndSettings",
    rowDrag: false,
    editable: false,
    sortable: false, // [FS-1511] Sorting disabled for all columns as add/edit expects index 0
    suppressSizeToFit: true,
    pinned: "right",
    width: 60,
    cellRenderer: VerticalMenu,
    cellRendererParams: { menuItems: [], onItemClick: onItemClick, getMenuItemsFromRowData: getMenuItems, disableMenu: isAddRow },
  };

  const lookupsTableContext = {
    lookupTable,
    gridApi,
    setGridApi,
    columnApi,
    selectedRowsCount,
    setSelectedRowsCount,
    isAddRow,
    setIsAddRow,
    verticalMenuRow,
    setVerticalMenuRow,
  };

  const { type } = lookupTable || {};

  return (
    <LookupsTableContext.Provider value={lookupsTableContext}>
      <ApproveLookupsModal modalId={MODAL_IDS.APPROVE_LOOKUPS_MODAL_ID} />
      <RejectLookupsModal modalId={MODAL_IDS.REJECT_LOOKUPS_MODAL_ID} />
      <DeleteLookupsModal modalId={MODAL_IDS.DELETE_LOOKUPS_MODAL_ID} />

      <LookupsTableHeader lookupTable={lookupTable} />
      <ManageLayout>
        <R props="d-flex pt-32 pl-0 pb-20">
          <LookupsTableSubheader />
        </R>
        {type === TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS ? (
          <EqualsTable gridOptions={gridOptions} verticalMenu={verticalMenu} />
        ) : (
          <ContainsTable gridOptions={gridOptions} verticalMenu={verticalMenu} />
        )}
      </ManageLayout>
    </LookupsTableContext.Provider>
  );
});

export { LookupsTable, LookupsTableContext };
