import { useContext } from "react";
import { Button, Text, IconAdd } from "@fundrecs/ui-library";
import { TABLE_TYPES } from "utils/lookups/enums";
import { AUTHORITIES } from "utils/enums";
import { LookupsTableContext } from "../../LookupsTable";
import { rolesStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";

const AddRowButton = () => {
  const { gridApi, lookupTable, setIsAddRow } = useContext(LookupsTableContext);
  const { columns, type, teamId } = lookupTable || {};

  const handleAddRow = () => {
    setIsAddRow(true);
    let newRow = {};
    columns.forEach((header) => {
      newRow[header.name] = "";
    });
    newRow.status = "Draft";
    newRow.id = -1;
    /* 
     [FS-1511] Need to set createdAt to ensure row is sorted to top of grid.
     Backend will overwrite this on save. 
    */
    newRow.createdAt = new Date().toISOString();
    // Add tags value to contains table
    if (type === TABLE_TYPES.LOOKUP_TABLE_TYPE_CONTAINS) {
      newRow.tags = [];
    }

    gridApi.applyTransactionAsync({ add: [newRow], addIndex: 0 }, () => {
      gridApi.deselectAll();
      gridApi.addRow = true;

      const colKey = columns[0].name;
      gridApi.setFocusedCell(0, colKey);
      gridApi.startEditingCell({
        rowIndex: 0,
        colKey: colKey,
      });
    });
  };

  return (
    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_CREATE])}>
      <Button
        size="md"
        color="primary"
        disabled={false}
        onClick={() => {
          handleAddRow();
        }}
      >
        <IconAdd className="btn-md-svg" />
        <Text size="sm">Add row</Text>
      </Button>
    </AuthWrapper>
  );
};

export { AddRowButton };
