import { useEffect, useState } from "react";
import { Text, LinkText } from "@fundrecs/ui-library";
import { AuditItem } from "./AuditItem";
import { SummaryItem } from "./SummaryItem";
import styles from "./DropdownPanelCell.module.scss";
import {
  getDestinationType,
  getExportConnectionName,
  getDestinationConnectionUuid,
  getReconciliationTypeName,
  getDestinationRecTypeId,
} from "components/workflows/reusable";
import summaryStyles from "./SummaryItem.module.scss";
import { useExportSftpConnectionData } from "components/workflows/hooks/useExportSftpConnectionData";
import { OUTPUT_DESTINATION_TYPES, WORKFLOW_TRIGGER_DESCRIPTION } from "utils/workflows/enums";
import { ROUTES } from "utils/enums";
import { useExportEmailConnectionData } from "components/workflows/hooks/useExportEmailConnectionData";
import { useExportReconciliationData } from "components/workflows/hooks/useExportReconciliationData";
import { useStore } from "store/Store";

const DropdownPanelCell = (rowNode) => {
  const { description, createdAt, createdBy, teamId, lastModifiedAt, lastModifiedBy } = rowNode.data;
  const [templates, setTemplates] = useState([]);
  const { tbStore, tenantConfigStore } = useStore();
  const fusionRecsUrl = tenantConfigStore.getFusionRecsUrl();

  const exportSftpConnectionData = useExportSftpConnectionData();
  const exportEmailConnectionData = useExportEmailConnectionData();
  const exportRecData = useExportReconciliationData(teamId);
  const {
    configuration: {
      steps: {
        "APPLY_TEMPLATE_V1-1": {
          params: { templateImportRules },
        },
      },
    },
  } = rowNode.data;

  useEffect(async () => {
    if (templateImportRules.length > 0) {
      const {
        0: { inclusionRules },
      } = templateImportRules;

      await getTemplates(inclusionRules);
    }
  }, []);

  const getTemplates = async (inclusionRules) => {
    const templateUuids = [...new Set(inclusionRules.map((template) => template.templateUuid))];
    templateUuids.map(async (templateUuid) => {
      await tbStore.getRequiredTemplate({ templateUuid, teamId }).then(({ data: { tbTemplate } }) => {
        setTemplates((prevState) => [...prevState, ...[tbTemplate]]);
      });
    });
  };

  const getDestinationName = () => {
    const type = getDestinationType(rowNode.data);

    if (!["FUSION_UI", OUTPUT_DESTINATION_TYPES.FUSION_UI].includes(type)) {
      if (type === OUTPUT_DESTINATION_TYPES.SFTP) {
        return (
          <SummaryItem
            name="Destination to send reports to"
            value={getExportConnectionName(exportSftpConnectionData, getDestinationConnectionUuid(rowNode.data))}
          />
        );
      }
      if (type === OUTPUT_DESTINATION_TYPES.EMAIL) {
        return (
          <SummaryItem
            name="Destination to send reports to"
            value={getExportConnectionName(exportEmailConnectionData, getDestinationConnectionUuid(rowNode.data))}
          />
        );
      }
      if (type === "RECONCILIATION") {
        return (
          <SummaryItem
            name="Destination to send reports to"
            value={
              <LinkText
                onClick={() => {
                  window.open(`${fusionRecsUrl}${ROUTES.REC_TYPE_DASH}?teamId=${teamId}&recType=${getDestinationRecTypeId(rowNode.data)}&client=all`, "_self");
                }}
                size="sm"
              >
                <Text weight="regular" size="sm" element="div">
                  {getRecLink(getReconciliationTypeName(exportRecData, getDestinationRecTypeId(rowNode.data)))}
                </Text>
              </LinkText>
            }
          />
        );
      }
    } else {
      return <SummaryItem name="Destination to send reports to" value="View in Fusion only" />;
    }
  };

  const getRecLink = (recName) => {
    const recDetails = recName.split(":");
    return recDetails[0] + " -" + recDetails[1];
  };

  const renderDestinationType = () => {
    const type = getDestinationType(rowNode.data);
    if (!["FUSION_UI", OUTPUT_DESTINATION_TYPES.FUSION_UI].includes(type) && !["RECONCILIATION", OUTPUT_DESTINATION_TYPES.RECONCILIATION].includes(type)) {
      return <SummaryItem name="Destination type" value={getDestinationType(rowNode.data)} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <Text size="xs" weight="medium" element="p" variant="primary">
          Description
        </Text>
        <Text size="xs" weight="regular" element="p" variant="secondary">
          {description}
        </Text>
        <div>
          <SummaryItem name="Run trigger" value={"Automated trigger - ".concat(WORKFLOW_TRIGGER_DESCRIPTION.AUTO)} />
          {renderDestinationType()}
          {getDestinationName()}
          <div className={summaryStyles.container}>
            <Text element="dt" size="xs" variant="secondary">
              {`${"Template".concat(templates.length > 1 ? `${"'s"}` : "").concat(" applied: ")}`}
            </Text>
            <div className="d-flex flex-column">
              {templates.map((template) => {
                const { name } = template;
                return (
                  <Text size="xs" weight="regular" variant="secondary">
                    {name}
                  </Text>
                );
              })}
              {templates.length === 0 && " - "}
            </div>
          </div>
        </div>
      </div>
      <div>
        {lastModifiedAt !== null ? (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Last edited by
            </Text>
            <AuditItem user={lastModifiedBy.name} timestamp={lastModifiedAt} />
          </>
        ) : (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Created by
            </Text>
            <AuditItem user={createdBy.name} timestamp={createdAt} />
          </>
        )}
      </div>
    </div>
  );
};

export { DropdownPanelCell };
