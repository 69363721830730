const MODAL_IDS = Object.freeze({
  CREATE_NEW_LOOKUP_MODAL_ID: "createNewLookupModal",
  DELETE_LOOKUP_TABLE_MODAL_ID: "deleteLookupTableModal",
  EDIT_LOOKUP_TABLE_MODAL_ID: "editLookupTableModalId",
  APPROVE_LOOKUPS_MODAL_ID: "approveLookupsModalId",
  REJECT_LOOKUPS_MODAL_ID: "rejectLookupsModalId",
  ARCHIVE_LOOKUPS_MODAL_ID: "archiveLookupsModalId",
  DELETE_LOOKUPS_MODAL_ID: "deleteLookupsModalId",
  ERROR_LOOKUP_NOT_FOUND: "errorLookupNotFound",
});

const TABLE_TYPES = Object.freeze({
  LOOKUP_TABLE_TYPE_CONTAINS: "contains",
  LOOKUP_TABLE_TYPE_EQUALS: "equals",
});

const LOOKUP_PROPERTIES = Object.freeze({
  LOOKUP_MAX_COLUMNS: 60,
});

const PRIORITY_VALUES = Object.freeze({
  1: "Very High",
  2: "High",
  3: "Medium",
  4: "Low",
  5: "Very Low",
});

const ROW_STATUS_VALUES = Object.freeze({
  DRAFT: "Draft",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PUBLISHED: "Published",
  COMPLETED: "Completed",
  FAILED: "Failed",
  IN_PROGRESS: "In Progress",
  FAILURE: "Failed",
  SUCCESS: "Completed",
});

const HEADER_BUTTON_TYPES = Object.freeze({
  ADD_ROW: "ADD_ROW",
  APPROVE_REJECT_ROWS: "APPROVE_REJECT_ROWS",
  ARCHIVE_ROWS: "ARCHIVE_ROWS",
  SUBMIT_FOR_APPROVAL_OR_DELETE: "SUBMIT_FOR_APPROVAL_OR_DELETE",
  SAVE_ROW: "SAVE_ROW",
});

export { MODAL_IDS, TABLE_TYPES, LOOKUP_PROPERTIES, PRIORITY_VALUES, ROW_STATUS_VALUES, HEADER_BUTTON_TYPES };
