import { useContext } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { C, C2 } from "@fundrecs/ui-library";
import { LookupsTableContext } from "../LookupsTable";
import { LookupRowsFilter, DateFilter } from "components/lookups/LookupFilters/Filters";
import { SubheaderButtons } from "./SubheaderButtons";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { LookupPriorityRendererText } from "../lookupPriority/LookupPriorityRenderer";
import { useStore } from "store/Store";

const LookupsTableSubheader = observer(() => {
  const { gridApi: api, columnApi, lookupTable } = useContext(LookupsTableContext);
  const { meStore } = useStore();

  const { name: teamName } = meStore.getTeamById(lookupTable.teamId);
  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All entries for lookup ${lookupTable.name} for ${teamName}-${today}`;

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} param.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();

    // retrieve the cell value, defaulting to an empty string if undefined
    const cellValue = node.data?.[columnId] ?? "";

    if (columnId === "priority") {
      // Custom processing for "priority" column
      return LookupPriorityRendererText(cellValue);
    }

    // return the processed value for other columns
    return cellValue;
  };

  return (
    <>
      <C2>
        <LookupRowsFilter />
      </C2>
      <C2 props={"pl-0"}>
        <div className="d-flex">
          <DateFilter />
          <span className="pl-16 align-self-center">
            <DownloadTable
              fileName={fileName}
              excludeFirstColumn={true}
              processCellCallback={processCellCallback}
              excludeLastColumn={true}
              gridRef={{ api, columnApi }}
            />
          </span>
        </div>
      </C2>
      <C props="d-flex justify-content-end pr-0">
        <SubheaderButtons />
      </C>
    </>
  );
});

export { LookupsTableSubheader };
