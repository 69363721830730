import React from "react";
import styles from "./step.module.scss";
import { Text, IconCheck } from "@fundrecs/ui-library";

const Step = ({ steps, activeStep }) => {
  //expects steps = [{name:"Step name"}]
  //expects activeStep = "Step name"
  return (
    <div className={["d-flex justify-content-center"].join(" ")}>
      {steps.map((step, index) => {
        return (
          <React.Fragment key={index}>
            <StepStage step={step} activeStep={activeStep} />
            {index !== steps.length - 1 && <Line />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Line = () => {
  return <div className={styles.line} />;
};

const StepStage = ({ activeStep, step }) => {
  let stage = "active";

  const { index } = activeStep;

  if (index > step.index) {
    //If the active step's index is great than mapped step, it must be a previous step. So it's probably completed.
    stage = "completed";
  } else if (index === step.index) {
    //If the active step's index is equal to the current step's index it must be the active step.
    stage = "active";
  } else {
    //For anything else set the default step
    stage = "default";
  }

  switch (stage) {
    case "completed":
      return <Circle stage={stage} step={<IconCheck className="icon-size" />} />;
    case "active":
      return <Circle stage={stage} step={<Text>{step.index}</Text>} />;
    default:
      return <Circle stage={stage} step={<Text>{step.index}</Text>} />;
  }
};
const Circle = ({ step, stage }) => {
  return <div className={`${styles[stage]}`}>{step}</div>;
};
export { Step };
