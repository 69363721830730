import { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance, WarningConfirmationModal, R } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { MODAL_IDS } from "../../../utils/templates/enums";
import styles from "./CreateTemplateStage.module.scss";
import { ROUTES } from "utils/enums";
import { SelectTemplateMethod } from "./SelectTemplateMethod";
import { CreateTemplate } from "./CreateTemplate";
import { useTeamId } from "store/hooks/useTeamId";

const SelectComponent = ({ activeStep, setActiveStep, stepParams, template, setTemplate }) => {
  const { name } = activeStep;
  switch (name) {
    case "STEP 01":
      return <SelectTemplateMethod setActiveStep={setActiveStep} />;
    case "STEP 02":
      return <CreateTemplate setActiveStep={setActiveStep} stepParams={stepParams} isWorkflow={false} setTemplate={setTemplate} template={template} />;
    default:
      return <SelectTemplateMethod setActiveStep={setActiveStep} />;
  }
};

const CreateTemplateStageWithoutWorkflow = observer(() => {
  const navigate = useNavigate();
  const teamId = useTeamId();
  const [template, setTemplate] = useState({ name: "", description: "" });

  const closeModal = () => {
    modalInstance(MODAL_IDS.CONFIRM_CLOSE_CREATE_TEMPLATE).toggle();
  };

  const confirmCloseModalAndResetFields = () => {
    setActiveStep({ name: "STEP 01", index: 1 });
    navigate({ pathname: ROUTES.TEMPLATES, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  const [activeStep, setActiveStep] = useState({ name: "STEP 01", index: 1 });

  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
  ];

  return (
    <>
      <WarningConfirmationModal
        modalId={MODAL_IDS.CONFIRM_CLOSE_CREATE_TEMPLATE}
        heading="Are you sure you want to quit?"
        text="All progress in this session will be lost"
        cancel="Cancel"
        confirm="Quit"
        onConfirm={confirmCloseModalAndResetFields}
      />
      <div className={styles.headerWrapper}>
        <ModalHeader onCloseClick={closeModal} title="New template" classes="mb-20 pl-32 mb-72" />

        <R props="ml-72 mr-72 mt-32">
          <div className="mb-72">
            <Step steps={steps} activeStep={activeStep} />
          </div>
        </R>

        <SelectComponent activeStep={activeStep} setActiveStep={setActiveStep} stepParams={{}} setTemplate={setTemplate} template={template} />
      </div>
    </>
  );
});

export { CreateTemplateStageWithoutWorkflow };
