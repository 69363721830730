import { isUserAuthorized } from "components/AuthorizationWrapper";
import { rolesStore, meStore, lookupsStore } from "store/Store";
import { AUTHORITIES } from "utils/enums";
import { ROW_STATUS_VALUES } from "utils/lookups/enums";
import { createColumnMappingsObject } from "./transformData";
import { redrawRowsToUpdateVerticalMenu } from "./agGrid";

const getMenuItems = ({ teamId, data }) => {
  const { status } = data;

  let menuOptions = [];

  switch (status) {
    case ROW_STATUS_VALUES.PENDING:
      menuOptions.push(rejectOption(teamId));
      menuOptions.push(deleteOption(teamId, data));
      menuOptions.push(approveOption(teamId, data));
      break;
    case ROW_STATUS_VALUES.DRAFT:
      menuOptions.push(submitRow(teamId));
      menuOptions.push(deleteOption(teamId, data));
      break;
    case ROW_STATUS_VALUES.REJECTED:
      menuOptions.push(deleteOption(teamId, data));
      break;
    case ROW_STATUS_VALUES.APPROVED:
      menuOptions.push(deleteOption(teamId, data));
      break;
    default:
      break;
  }

  return menuOptions;
};

const deleteOption = (teamId, data) => {
  const { status } = data;

  // Only Super Admin can delete Approved rows
  if (status === ROW_STATUS_VALUES.APPROVED) {
    return {
      key: "deleteRow",
      label: "Delete Row",
      visible: meStore.isSuperAdmin(),
    };
  }

  return {
    key: "deleteRow",
    label: "Delete Row",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_DELETE]) }),
  };
};

const rejectOption = (teamId) => {
  return {
    key: "rejectRow",
    label: "Reject Row",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_REJECT]) }),
  };
};

const approveOption = (teamId, data) => {
  // User can only approve rows submitted by other users
  const { email } = meStore.getMe();
  const { createdBy } = data;

  return {
    key: "approveRow",
    label: "Approve Row",
    visible: email !== createdBy && isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_APPROVE]) }),
  };
};

const submitRow = (teamId) => {
  return {
    key: "submitRow",
    label: "Submit Row",
    visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_EDIT]) }),
  };
};

const handleSubmitSingleRow = async ({ gridApi, data, lookupTable }) => {
  const {
    uuid,
    teamId,
    columns: tableColumns,
    settings: { fourEyesEnabled },
  } = lookupTable || {};

  const newStatus = fourEyesEnabled ? ROW_STATUS_VALUES.PENDING : ROW_STATUS_VALUES.APPROVED;
  const columnMappings = createColumnMappingsObject(data, tableColumns);
  const { priority, tags, id, createdAt, createdBy } = data;

  const requestObject = {
    id: id,
    columnMappings: columnMappings,
    tags: tags,
    priority: priority,
    status: newStatus,
    createdAt: createdAt,
    createdBy: createdBy,
    editedBy: meStore.getEmail(),
  };

  const response = await lookupsStore.updateTableRow(uuid, teamId, data.id, { ...requestObject });

  const { success } = response || {};

  if (success) {
    let updatedRow = { ...data };
    updatedRow.status = newStatus;

    gridApi.applyTransactionAsync({ update: [updatedRow] }, () => {
      gridApi.deselectAll();

      redrawRowsToUpdateVerticalMenu({ gridApi, ids: [id] });
    });
  }
};

export { getMenuItems, handleSubmitSingleRow };
