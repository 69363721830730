import moment from "moment";
import { STATUS_VALUES } from "utils/templates/enums";
import { isToday } from "../../../../utils/datetime";

/**
 * AuditCell
 *
 * Renders a string with a date-time representing the Workflow's
 * audit trail, using the following display conditions:
 *
 * - If PUBLISHED, show the published date
 * - If modified after creation, then show the last modified date
 * - If neither of above are true, then show the creation date
 *
 * @param {RowNode} rowNode - instance of AG Grid Row object https://www.ag-grid.com/react-data-grid/row-object/
 */
const AuditCell = (rowNode) => {
  const timestamp = getDateTimeStamp(rowNode);
  const datePrefix = getDatePrefix(rowNode);
  const date = new Date(timestamp);
  const dateString = isToday(date) ? "today" : moment(date).format("D MMM YYYY");
  const timeString = moment(date).format("HH:mm");

  const displayText = `${datePrefix} ${dateString}, ${timeString}`;

  return <div>{displayText}</div>;
};

const getDateTimeStamp = ({ data }) => {
  const { status, rejectedDate, createdDate, lastModifiedDate, approvedDate, submittedDate } = data;
  const timestamp = lastModifiedDate || createdDate;

  if (status === STATUS_VALUES.REJECTED) {
    return rejectedDate ?? timestamp;
  } else if (status === STATUS_VALUES.APPROVED) {
    return approvedDate ?? timestamp;
  } else if (status === STATUS_VALUES.PENDING) {
    return submittedDate ?? timestamp;
  } else {
    return timestamp;
  }
};

const getDatePrefix = ({ data }) => {
  const { status, lastModifiedDate } = data;

  if (status === STATUS_VALUES.REJECTED) {
    return STATUS_VALUES.REJECTED;
  } else if (status === STATUS_VALUES.APPROVED) {
    return STATUS_VALUES.APPROVED;
  } else if (status === STATUS_VALUES.PUBLISHED) {
    return STATUS_VALUES.PUBLISHED;
  } else if (status === STATUS_VALUES.PENDING) {
    return "Submitted";
  } else {
    const datePrefix = lastModifiedDate ? "Last edited" : "Created";

    return datePrefix;
  }
};

const auditCellText = (rowNode) => {
  const timestamp = getDateTimeStamp(rowNode);
  const datePrefix = getDatePrefix(rowNode);

  const date = new Date(timestamp);
  const dateString = isToday(date) ? "today" : moment(date).format("D MMM YYYY");
  const timeString = moment(date).format("HH:mm");

  const displayText = `${datePrefix} ${dateString}, ${timeString}`;

  return displayText;
};

export { AuditCell, auditCellText, getDateTimeStamp };
