const chipLabels = {
  "-": "-",
  ".": ".",
  "/": "/",
  _: "_",
  customText: "Custom text",
  date: "Date",
  "t-1Date": "T-1 Date",
  "t-NDate": "T-n Date",
  year: "Year",
  month: "Month",
  day: "Day",
  hour: "Hour",
  minute: "Minutes",
  second: "Seconds",
  millisecond: "Milliseconds",
  originalFileName: "File Name",
  originalFileExtension: "File Extension",
  originalFolder: "Output Folder",
  workflow: "Workflow Name",
  team: "Team Name",
  template: "Template Name",
  uuid: "UUID",
  randomDigits: "Random Digits",
  trimAfter: "Trim After",
  firstCellOfColumn: "First cell of output column",
};

const tagLabels = {
  "-": "-",
  ".": ".",
  "/": "/",
  _: "_",
  customText: "Custom text",
  date: "Date",
  "t-1Date": "T-1 Date",
  "t-NDate": "T-n Date",
  year: "Year",
  month: "Month",
  day: "Day",
  hour: "Hour",
  minute: "Minutes",
  second: "Seconds",
  millisecond: "Milliseconds",
  originalFileName: "Original File Name",
  originalFileExtension: "Original File Extension",
  originalFolder: "Original Output Folder",
  workflow: "Workflow Name",
  team: "Team Name",
  template: "Template Name",
  uuid: "UUID",
  randomDigits: "Random Digits",
  trimAfter: "Trim After",
  firstCellOfColumn: "First cell of output column",
};

const folderExpressions = [
  {
    type: "originalFolder",
  },
  {
    type: "customText",
    value: "",
  },
  {
    type: "template",
  },
  {
    type: "date",
    format: "yyyyMMdd",
  },
  {
    type: "t-1Date",
    format: "yyyyMMdd",
  },
  {
    type: "t-NDate",
    format: "yyyyMMdd",
    n: 1,
  },
  {
    type: "day",
  },
  {
    type: "team",
  },
  {
    type: "year",
  },
  {
    type: "month",
  },
  {
    type: "-",
  },
  {
    type: ".",
  },
  {
    type: "/",
  },
  {
    type: "_",
  },
  {
    type: "firstCellOfColumn",
  },
];

const fileNameExpressions = [
  {
    type: "originalFileName",
  },
  // {
  //   type: "originalFileExtension",
  // },
  {
    type: "customText",
    value: "",
  },
  {
    type: "workflow",
  },
  {
    type: "template",
  },
  {
    type: "date",
    format: "yyyyMMdd",
  },
  {
    type: "t-1Date",
    format: "yyyyMMdd",
  },
  {
    type: "t-NDate",
    format: "yyyyMMdd",
    n: 1,
  },
  {
    type: "year",
  },
  {
    type: "month",
  },
  {
    type: "day",
  },
  {
    type: "hour",
  },
  {
    type: "minute",
  },
  {
    type: "second",
  },
  {
    type: "millisecond",
  },
  {
    type: "randomDigits",
    count: "8",
  },
  {
    type: "uuid",
  },
  {
    type: "-",
  },
  {
    type: "_",
  },
  {
    type: ".",
  },
  {
    type: "firstCellOfColumn",
  },
  /*   {
    type: "trimAfter",
    pattern: "###END###",
  }, */
];

export { chipLabels, tagLabels, folderExpressions, fileNameExpressions };
