const MODAL_IDS = Object.freeze({
  ERROR_TEMPLATE_NOT_FOUND: "errorTemplateNotFound",
  CONFIRM_CLOSE_CREATE_TEMPLATE: "confirmCloseCreateTemplate",
  CONFIRM_CLOSE_EDIT_TEMPLATE: "confirmCloseEditTemplate",
  EDIT_TEMPLATE_SETTINGS: "editTemplateSettings",
  DELETE_TEMPLATE: "deleteTemplateModal",
  EXPLAIN_TEMPLATE: "explainTemplateModal",
  CANNOT_DELETE_TEMPLATE: "cannotDeleteTemplateModal",
  DRAFT_TEMPLATE_SUBMIT_AND_EXIT: "draftTemplateSubmitAndExitModal",
  REJECTED_TEMPLATE_SUBMIT_AND_EXIT: "rejectedTemplateSubmitAndExitModal",
  FOUR_EYES_DISABLED_PUBLISH_AND_EXIT: "fourEyesDisabledPublishAndExitModal",
  PENDING_TEMPLATE_APPROVE_AND_EXIT: "pendingTemplateApproveAndExitModal",
  PENDING_TEMPLATE_REJECT_AND_EXIT: "pendingTemplateRejectAndExitModal",
  SHARE_TEMPLATE: "shareTemplateModal",
});

const STATUS_VALUES = Object.freeze({
  DRAFT: "Draft",
  PENDING: "Pending",
  REJECTED: "Rejected",
  SUBMITTED: "Submitted",
  PUBLISHED: "Published",
  DELETED: "Deleted",
  APPROVED: "Approved",
});

export { MODAL_IDS, STATUS_VALUES };
