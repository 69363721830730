import React from "react";
import Preview from "./Preview";
import { chipLabels, tagLabels } from "./builderConfig";
import { Heading, Text, Tag, LinkText, IconDelete, IconAdd, IconCloseRemove } from "@fundrecs/ui-library";
import styles from "./CustomNameBuilder.Module.scss";
import _ from "lodash";

const NEVER_DISABLED = ["customText", "-", "/", "_", "."];

class CustomNameBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragged: null,
      draggedItemIndex: null,
      selectedIndex: null,
      editableObject: null,
      rendered: true,
    };
  }
  handleDelete = (tag, index) => {
    const { expressions, onChange, workflow, reportIndex } = this.props;
    const { type } = tag;
    if (type === "firstCellOfColumn" && workflow.isFirstCellEnabled(reportIndex)) {
      return;
    }

    expressions.splice(index, 1);

    onChange([...expressions]);

    //This is to cause a re-render when we delete
    this.setState({
      rendered: !this.state.rendered,
    });
  };

  handleChangeCustomText = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { value } = newValues;

    const newExpression = { ...expressions[index], value };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeDate = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { format } = newValues;

    const newExpression = { ...expressions[index], format };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeT1Date = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { format } = newValues;

    const newExpression = { ...expressions[index], format };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeTNDate = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { n, format } = newValues;

    const newExpression = { ...expressions[index], n, format };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeRandomDigits = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { count } = newValues;

    const newExpression = { ...expressions[index], count };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeFirstOutputCell = (index, value, columns) => {
    const { expressions, onChange } = this.props;

    const { index: columnIndex, columnName } = columns[value];

    const firstCellRule = {
      n: columnIndex,
      type: "firstCellOfColumn",
      value: columnName,
    };

    expressions[index] = firstCellRule;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleChangeTrimAfter = (index, newValues) => {
    const { expressions, onChange } = this.props;

    const { pattern } = newValues;

    const newExpression = { ...expressions[index], pattern };

    expressions[index] = newExpression;

    onChange([...expressions]);

    this.setState({
      selectedIndex: null,
    });
  };

  handleDrop = () => {
    const { dragged } = this.state;

    if (dragged) {
      const { expressions, onChange } = this.props;

      // Set default value to firstCellOfColumn
      if (dragged?.type === "firstCellOfColumn" && !dragged?.value) {
        expressions.push(this.getFirstCellOfColumnDefaultValue());
      } else {
        expressions.push(dragged);
      }

      onChange([...expressions]);

      this.setState({
        dragged: null,
      });
    }
  };

  handleDropChip = (index) => {
    const { expressions, onChange } = this.props;

    let from = this.state.draggedItemIndex;
    let to = index;

    expressions.splice(to, 0, expressions.splice(from, 1)[0]);

    onChange([...expressions]);

    this.setState({
      draggedItemIndex: null,
    });
  };

  checkDisabled = (targetExpression) => {
    const { expressions = [] } = this.props;

    if (NEVER_DISABLED.includes(targetExpression.type)) {
      return false;
    }

    return expressions.find((expression) => expression.type === targetExpression.type);
  };

  getFirstCellOfColumnDefaultValue = () => {
    const { columns } = this.props || {};
    return {
      n: 0,
      type: "firstCellOfColumn",
      value: columns[0]?.columnName,
    };
  };

  renderLabel = (expression, index) => {
    const { type } = expression;

    if (type === "customText") {
      return this.renderCustomText(expression, index);
    } else if (type === "date") {
      return this.renderDate(expression, index);
    } else if (type === "t-1Date") {
      return this.renderT1Date(expression, index);
    } else if (type === "t-NDate") {
      return this.renderTNDate(expression, index);
    } else if (type === "randomDigits") {
      return this.renderRandomDigits(expression, index);
    } else if (type === "trimAfter") {
      return this.renderTrimAfter(expression, index);
    } else if (type === "firstCellOfColumn") {
      return this.renderFirstOutputCell(index);
    } else {
      return this.renderGeneric(expression);
    }
  };

  renderFirstOutputCell = (index) => {
    const { columns, workflow, reportIndex } = this.props;

    return (
      <>
        <Text size="xs" weight="regular">
          First cell of output column
        </Text>
        <select
          name="column"
          className={[`${styles.customTagInput}`, `${styles.firstCellSelect}`, "ml-12"].join(" ")}
          disabled={workflow.isFirstCellEnabled(reportIndex)}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            this.handleChangeFirstOutputCell(index, value, columns);
          }}
          defaultValue={workflow.getFirstOutputCellSelectedValue(reportIndex) ?? 0}
        >
          {columns.map((column) => {
            const { index, columnName } = column;

            if (index === workflow.getFirstOutputCellSelectedValue(reportIndex)) {
              return (
                <option value={index} selected={index}>
                  {columnName}
                </option>
              );
            } else {
              return <option value={index}>{columnName}</option>;
            }
          })}
        </select>
      </>
    );
  };

  renderGeneric = (expression) => {
    const { type } = expression;

    const label = chipLabels[type] || "";

    return (
      <Text size="xs" weight="regular">
        {label}
      </Text>
    );
  };

  renderCustomText = (expression, index) => {
    const { value } = expression;
    return (
      <>
        <Text size="xs" weight="regular">
          Custom text
        </Text>
        <input
          id="outlined-basic"
          className={[`${styles.customTagInput}`, "ml-12"].join(" ")}
          value={value}
          placeholder="Click to edit"
          onChange={(event) => {
            const {
              target: { value: newValue },
            } = event;

            this.handleChangeCustomText(index, { value: newValue });
          }}
        />
      </>
    );
  };

  renderDate = (expression, index) => {
    const { format } = expression;

    return (
      <>
        <Text size="xs" weight="regular">
          Date
        </Text>
        <span className={["base-text text-sm text-regular", `${styles.customTagText}`].join(" ")}>format</span>
        <>
          <input
            id="outlined-basic"
            className={styles.customTagInput}
            value={format}
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeDate(index, { format: newValue });
            }}
          />
        </>
      </>
    );
  };

  renderT1Date = (expression, index) => {
    const { format } = expression;

    return (
      <>
        <Text size="xs" weight="regular">
          T-1 date
        </Text>
        <span className={["base-text text-sm text-regular", `${styles.customTagText}`].join(" ")}>format</span>
        <>
          <input
            id="outlined-basic"
            className={styles.customTagInput}
            value={format}
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeT1Date(index, { format: newValue });
            }}
          />
        </>
      </>
    );
  };

  renderTNDate = (expression, index) => {
    const { n, format } = expression;

    return (
      <>
        <Text size="xs" weight="regular">
          T-n date
        </Text>
        <span className={["base-text text-sm text-regular", `${styles.customTagText}`].join(" ")}>n</span>

        <>
          <input
            id="outlined-basic"
            type="number"
            className={[`${styles.customTagInput}`].join(" ")}
            value={n}
            placeholder="Click to edit"
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeTNDate(index, { n: newValue, format });
            }}
          />
        </>
        <>
          <span className={["base-text text-sm text-regular", `${styles.customTagText}`, "ml-0"].join(" ")}>format</span>

          <input
            id="outlined-basic"
            className={styles.customTagInput}
            value={format}
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeTNDate(index, { n, format: newValue });
            }}
          />
        </>
      </>
    );
  };

  renderRandomDigits = (expression, index) => {
    const { count } = expression;

    return (
      <>
        <Text size="xs" weight="regular">
          Random digits
        </Text>
        <span className={["base-text text-sm text-regular", `${styles.customTagText}`].join(" ")}>count</span>

        <>
          <input
            id="outlined-basic"
            type="number"
            className={styles.customTagInput}
            value={count}
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeRandomDigits(index, { count: newValue });
            }}
          />
        </>
      </>
    );
  };

  renderTrimAfter = (expression, index) => {
    const { pattern } = expression;

    return (
      <>
        <Text size="xs" weight="regular">
          Trim after
        </Text>
        <span className={["base-text text-sm text-regular", `${styles.customTagText}`].join(" ")}>pattern</span>
        <>
          <input
            id="outlined-basic"
            className={styles.customTagInput}
            value={pattern}
            onChange={(event) => {
              const {
                target: { value: newValue },
              } = event;

              this.handleChangeTrimAfter(index, { pattern: newValue });
            }}
          />
        </>
      </>
    );
  };

  renderChips = (expressions) => {
    const { belowTagsText } = this.props;

    if (expressions && expressions.length > 0) {
      return expressions.map((expression, index) => {
        return (
          <span className={styles.tagsButton}>
            <Tag
              color="secondary-light-blue-background-blue-text"
              onDragOver={(event) => {
                event.preventDefault();
              }}
              onClick={() => {
                if (expression.type === "customText") {
                  this.setState({
                    selectedIndex: index,
                    editableObject: expression,
                  });
                }
              }}
              onDrop={() => {
                this.handleDropChip(index);
              }}
              onDragStart={() => {
                this.setState({
                  draggedItemIndex: index,
                });
              }}
              draggable={true}
              key={index}
              size="sm"
            >
              {this.renderLabel(expression, index)}
              <IconCloseRemove
                className="btn-sm-svg"
                onClick={() => {
                  this.handleDelete(expression, index);
                }}
              />
            </Tag>
          </span>
        );
      });
    } else {
      return (
        <div style={{ marginTop: "75px", textAlign: "center" }}>
          <Heading variant="h6" align="center">
            Drag and drop
          </Heading>
          <Text size="sm" variant="secondary" weight="regular">
            {belowTagsText}
          </Text>
        </div>
      );
    }
  };

  render() {
    const { expressions = [], tags, aboveTagsText, preview, onChange, format } = this.props;
    return (
      <div>
        <div className="pb-12">
          <div className="pr-4">
            <Text size="sm" variant="secondary" weight="medium">
              {aboveTagsText}
            </Text>
          </div>
          <div>
            <Text size="sm" variant="secondary" weight="medium">
              {preview && expressions.length > 0 && <Preview expressions={expressions} format={format} />}
            </Text>
          </div>
        </div>
        <div
          className={styles.rectangle}
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onDrop={(event) => {
            this.handleDrop(event);
          }}
          style={{ padding: "20px" }}
        >
          {this.renderChips(expressions)}
        </div>
        <div className={styles.tags}>
          {tags.map((tag, index) => {
            const { type } = tag;

            const label = tagLabels[type] || "";

            return (
              <span className={styles.tagsButton}>
                <Tag
                  id={type}
                  color="primary-white-background-blue-text"
                  key={index}
                  draggable="true"
                  onDragStart={() => {
                    this.setState({
                      dragged: _.clone(tag),
                    });
                  }}
                  size="sm"
                  disabled={this.checkDisabled(tag)}
                >
                  <Text size="xs" weight="regular">
                    {label}
                  </Text>
                </Tag>
              </span>
            );
          })}
        </div>
        <div className={styles.actions}>
          <LinkText
            size="md"
            onClick={() => {
              // Set default value to firstCellOfColumn
              const index = tags.findIndex((t) => t.type === "firstCellOfColumn");
              if (index !== -1) {
                tags[index] = { ...tags[index], ...this.getFirstCellOfColumnDefaultValue() };
              }
              onChange("ADD", [...tags]);
            }}
            disabled={JSON.stringify(expressions) === JSON.stringify(tags)}
          >
            <IconAdd className="btn-md-svg" />
            <Text size="sm">Add all</Text>
          </LinkText>
          <LinkText
            size="md"
            onClick={() => {
              onChange("REMOVE");
            }}
            className={styles.tagActionButtons}
          >
            <IconDelete className="btn-md-svg" />
            <Text size="sm">Remove all</Text>
          </LinkText>
        </div>
      </div>
    );
  }
}

export default CustomNameBuilder;
