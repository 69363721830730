import { useContext, useEffect } from "react";
import { Text, Button } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { TriggerEditSection } from "./TriggerEditSection";
import { TriggerSection } from "./TriggerSection";
import { DEFAULT_TRIGGER_OBJECT, WORKFLOW_STAGES } from "utils/workflows/enums";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const TriggerEditView = observer(({ workflow, onSave }) => {
  const { teamId, editingStage, onStageComplete } = useContext(WorkflowConfigurationContext);

  const triggers = workflow.getCurrentWorkflowTriggers();
  const triggerUuid = uuidv4();
  const { workflowsStore } = useStore();

  useEffect(() => {
    if (triggers.length === 0) {
      workflow.addNewCurrentWorkflowTrigger({
        ...DEFAULT_TRIGGER_OBJECT,
        ...{ triggerName: "Trigger_".concat(workflow.getCurrentWorkflowTriggers().length + 1), triggerUuid: triggerUuid },
      });
      workflowsStore.addEditableTrigger(triggerUuid);
    }
  }, []);

  const checkIfSaveAndExitSetupDisabled = () => {
    if (workflowsStore.getReadOnlyWorkflow()) {
      return workflowsStore.getReadOnlyWorkflowTriggers().length === 0;
    } else {
      return true;
    }
  };

  return (
    <>
      {triggers.map((trigger) => {
        return (
          <TriggerSection trigger={trigger} workflow={workflow} editingStage={editingStage}>
            <TriggerEditSection trigger={trigger} />
          </TriggerSection>
        );
      })}
      <div className="d-flex flex-row-reverse mt-32 mb-24">
        {/* MVP hack: there are no changes the user can make so Save button is default enabled  */}
        <Button
          size="lg"
          color="primary"
          onClick={() => {
            workflowsStore.clearEditableTriggers();
            onStageComplete(WORKFLOW_STAGES.TRIGGER);
          }}
          disabled={checkIfSaveAndExitSetupDisabled()}
        >
          <Text size="md" weight="medium">
            Close step
          </Text>
        </Button>
      </div>
    </>
  );
});

export { TriggerEditView };
