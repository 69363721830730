import React, { useContext } from "react";

import { Chip, OutlinedInput, InputAdornment, IconButton, List, ListItem, ListItemText } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useSelect } from "downshift";

import "./styles.css";

import { LumsContext } from "./LumsCommand";

function Chips(props) {
  const { outputColumns, setOutputColumns } = props;

  return (
    <>
      {outputColumns.map((name) => {
        const index = outputColumns.indexOf(name);

        return (
          <Chip
            className="chip"
            key={name}
            label={name}
            onDelete={() => {
              outputColumns.splice(index, 1);

              setOutputColumns([...outputColumns]);
            }}
          />
        );
      })}
    </>
  );
}

export default function SelectOutputColumns() {
  const lumsContext = useContext(LumsContext);

  const { lookupTable, outputColumns, setOutputColumns, lookupType } = lumsContext;

  const allLookupTableColumnNames = lookupTable.columns.map((column) => column.name);

  const allLookupTableColumnsSelected = outputColumns.length === allLookupTableColumnNames.length;

  const initialAvailableLookupTableColumns = outputColumns.length >= allLookupTableColumnNames.length - 1 ? [] : [{ name: "Select All", selectAll: true }];

  const availableLookupTableColumns = lookupTable.columns.reduce((result, column) => {
    const { name } = column;

    if (outputColumns.indexOf(name) === -1) {
      result.push(column);
    }

    return result;
  }, initialAvailableLookupTableColumns);

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: availableLookupTableColumns,
    onSelectedItemChange: (changes) => {
      const { selectedItem: column } = changes;

      const { selectAll, name } = column;

      if (selectAll) {
        setOutputColumns(allLookupTableColumnNames);
      } else {
        outputColumns.push(name);

        setOutputColumns([...outputColumns]);
      }
    },
  });

  return (
    <>
      <div>
        <span className="input-label">Select Columns to add to input file</span>

        <span className="chips-container">
          <OutlinedInput
            className="chips-input"
            readOnly
            inputComponent={Chips}
            inputProps={{
              outputColumns,
              setOutputColumns,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton disabled={allLookupTableColumnsSelected} {...getToggleButtonProps()}>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            }
          />

          <span className="chips-list">
            <List disablePadding {...getMenuProps()}>
              {isOpen &&
                !allLookupTableColumnsSelected &&
                availableLookupTableColumns.map((column, index) => {
                  const { name } = column;

                  return (
                    <ListItem button key={name} {...getItemProps({ item: column, index })}>
                      <ListItemText primary={name} />
                    </ListItem>
                  );
                })}
            </List>
          </span>
        </span>
      </div>
    </>
  );
}
