const DATA_TYPE_KEYS = Object.freeze({
  stringValue: "Text",
  dateValue: "Date",
  datetimeValue: "Date and Time",
  numericValue: "Number",
});

const MODAL_IDS = Object.freeze({
  ERROR_OUTPUT_MAPPING_NOT_FOUND: "errorOutputMappingNotFound",
  CONFIRM_CLOSE_EDIT_MAPPING: "confirmCloseEditMapping",
  EDIT_MAPPING_SETTINGS: "editMappingSettings",
  DELETE_MAPPING: "deleteMappingModal",
  SHARE_OUTPUT_MAPPING: "shareOutputMappingModal",
});

const STATUS_VALUES = Object.freeze({
  APPROVED: "Approved",
  DRAFT: "Draft",
});

export { DATA_TYPE_KEYS, MODAL_IDS, STATUS_VALUES };
