import { Text, ToolTip, IconPencil, Button, Heading } from "@fundrecs/ui-library";
import { STAGE_ACTIONS } from "../../../../utils/workflows/enums";
import { ReactComponent as IconTick } from "../icons/icon-tick.svg";
import { ReactComponent as IconPadlock } from "../icons/icon-padlock.svg";
import { AuthWrapper } from "../../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../../utils/enums";
import { useStore } from "../../../../store/Store";
import styles from "./WorkflowStage.module.scss";
import { useTeamId } from "store/hooks/useTeamId";

/**
 * WorkflowStage
 *
 * A presentational component to represent an editable stage in a Workflow
 */
const WorkflowStage = ({ stageNumber, title, description, disabledTooltipText, editView, summaryView, onActionClick, enabled, complete, editing }) => {
  const { rolesStore } = useStore();
  const teamId = useTeamId();

  // only apply "completed" style when NOT editing
  const containerClass = editing ? styles.containerEditing : complete ? styles.containerComplete : styles.container;
  // show action menu when Stage is enabled and is not in edit mode
  const showActionMenu = enabled && !editing;

  // switch subviews depending on edit state
  const subview = editing ? editView : summaryView;

  const actionMenuItems = [
    {
      key: STAGE_ACTIONS.EDIT,
      label: "Edit",
    },
  ];

  return (
    <div className={containerClass}>
      <div className={styles.indicator}>
        <Text size="sm" weight="bold">
          {stageNumber}
        </Text>
        <IconTick />
      </div>
      <div className={styles.header}>
        <Heading variant="h5">{title}</Heading>
        <Text size="sm" weight="regular">
          {description}
        </Text>
        {enabled ? (
          <div className={styles.content}>{subview}</div>
        ) : (
          <div className={styles.disabledIcon}>
            <ToolTip direction="top" text={disabledTooltipText}>
              <IconPadlock />
            </ToolTip>
          </div>
        )}
        {showActionMenu && (
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_CREATE])}>
            <div className={styles.actionMenu}>
              <Button
                color="primary-secondary"
                size="sm"
                onClick={() => {
                  onActionClick();
                }}
              >
                <IconPencil className={styles.editIcon} />
                <Text size="xs">Edit step</Text>
              </Button>
            </div>
          </AuthWrapper>
        )}
      </div>
    </div>
  );
};

export { WorkflowStage };
