import PropTypes from "prop-types";
import styles from "./PendingRowsIcon.module.scss";

const PendingRowsIcon = (params) => {
  const { data } = params;
  const { metadata: { tableRowStatusCounts } = {} } = data || {};
  const { Pending: pendingCount = 0 } = tableRowStatusCounts || {};

  return (
    <>
      {pendingCount > 0 && (
        <div className={styles.renderCell}>
          <div className={[styles.statusIcon, styles.pending].join(" ")}> </div>
        </div>
      )}
    </>
  );
};

PendingRowsIcon.propTypes = {
  params: PropTypes.shape({
    data: PropTypes.shape({
      metadata: PropTypes.shape({
        tableRowStatusCounts: PropTypes.shape({
          Pending: PropTypes.number,
        }),
      }),
    }),
  }),
};

PendingRowsIcon.defaultProps = {
  params: { data: { metadata: { tableRowStatusCounts: { Pending: 0 } } } },
};

export { PendingRowsIcon };
