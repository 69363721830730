import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TabsSection } from "@fundrecs/ui-library";
import styles from "./Tabs.module.scss";

/**
 * Configuration for all tabs, including their display names, keys, and disabled status.
 */
const tabConfig = [
  { name: "Results", key: "results", disabled: false },
  { name: "Queue", key: "queue", disabled: false },
  { name: "Overview", key: "overview", disabled: false },
  { name: "Audit log", key: "audit-log", disabled: true },
  { name: "Uploads", key: "uploaded-files", disabled: true },
];

/**
 * Tabs component
 *
 * @param {number} activeTabIndex - The currently active tab index.
 * @param {function} setActiveTabIndex - Function to set the active tab index.
 */
const Tabs = ({ activeTabIndex, setActiveTabIndex }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Filter enabled tabs for use in URL-based tab selection
  const enabledTabs = tabConfig.filter((tab) => !tab.disabled);

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      // Find the index of the tab in the enabled tabs list
      const tabIndex = enabledTabs.findIndex((t) => t.key === tab.toLowerCase());

      // Set active tab index if a valid, enabled tab is found
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      }
    }
  }, [searchParams, enabledTabs, setActiveTabIndex]);

  const handleTabClick = (index, tabKey) => {
    setActiveTabIndex(index);
    searchParams.set("tab", tabKey);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="pl-32 pr-32 ml-32">
      <TabsSection>
        <ul className="nav">
          {tabConfig.map((tab, index) => {
            // is the tab disabled or active?
            const disabledClass = tab.disabled && styles.disabled;
            const activeClass = activeTabIndex === index ? `tab-link-active ${styles.active}` : styles.inactive;

            // Combine all class names into an array
            const tabClassName = ["tab-link text-sm text-medium", disabledClass || activeClass].join(" ");

            return (
              <li key={tab.key} onClick={() => !tab.disabled && handleTabClick(index, tab.key)}>
                <span className={tabClassName}>{tab.name}</span>
              </li>
            );
          })}
        </ul>
      </TabsSection>
    </div>
  );
};

export { Tabs };
