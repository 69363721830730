import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { tbStore } from "../../../store/Store";

const TransformAndMap = observer(({ activeStep, stepParams }) => {
  const { onComplete } = stepParams;

  useEffect(() => {
    if (activeStep.name === "STEP 03") {
      tbStore.setDoneButtonOnClick(onComplete);
      tbStore.mountTemplateBuilder();
    }
  }, [activeStep]);

  return <></>;
});

export { TransformAndMap };
