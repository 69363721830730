import { checkAllRequired, checkOneRequired, checkProhibited } from "../utils/authorization";
import { useStore, meStore } from "../store/Store";

/**
 * Functional Component wrapper encapsulating Authorization logic
 * 3 modes in which is can be used:
 *  - Authorizing using a custom validation method - uses prop customValidateMethod
 *  - Authorizing for all actions in a list - uses props allRequiredList & prohibitedList
 *  - Authorizing for any one action in a list - uses props oneRequiredList & prohibitedList
 *
 * If any of these return true, render the wrapped children components
 *
 * nb. When implementing: the role names are defined in Enums.js, these can be used to get the required role from the store
 * eg.  <AuthWrapper allRequiredList={RoleStore.getActionsByRoleName([ROLE.WORKFLOW_DOER])} />
 * @param {*} param0
 * @returns
 */
const AuthWrapper = ({ teamId, allRequired, oneRequired, prohibited, customValidateMethod, children }) => {
  const { meStore } = useStore();
  const loggedInUser = meStore.getMe();
  const userActions = teamId ? meStore.getActionsByTeamId(teamId) : meStore.getAllActions();

  let displayWrappedComponents = false;
  //1. Custom authorization
  if (customValidateMethod) {
    displayWrappedComponents = customValidateMethod();

    //2. All actions in a list must be authorized
  } else if (allRequired && allRequired.length) {
    displayWrappedComponents = checkAllRequired(loggedInUser, userActions, allRequired) && checkProhibited(loggedInUser, userActions, prohibited);
    //3. Any one of the actions in the list must be authorized
  } else if (oneRequired && oneRequired.length) {
    displayWrappedComponents = checkOneRequired(loggedInUser, userActions, oneRequired) && checkProhibited(loggedInUser, userActions, prohibited);
  }

  return displayWrappedComponents ? children : "";
};

const isUserAuthorized = ({ teamId, allRequired, oneRequired, prohibited, customValidateMethod }) => {
  const loggedInUser = meStore.getMe();
  const userActions = teamId ? meStore.getActionsByTeamId(teamId) : meStore.getAllActions();

  //1. Custom authorization
  if (customValidateMethod) {
    return customValidateMethod();

    //2. All actions in a list must be authorized
  } else if (allRequired && allRequired.length) {
    return checkAllRequired(loggedInUser, userActions, allRequired) && checkProhibited(loggedInUser, userActions, prohibited);
    //3. Any one of the actions in the list must be authorized
  } else if (oneRequired && oneRequired.length) {
    return checkOneRequired(loggedInUser, userActions, oneRequired) && checkProhibited(loggedInUser, userActions, prohibited);
  }

  return false;
};

const RouteAuthWrapper = ({ validActions, children }) => {
  const { meStore } = useStore();
  const loggedInUser = meStore.getMe();
  const userActions = meStore.getAllActions();

  const check = checkAllRequired(loggedInUser, userActions, validActions);
  if (!check) {
    //Implement Toast
    console.log("Unable to route to selected URL: Incorrect permissions");
  }
  return check ? children : console.log("redirect user");
};

export { AuthWrapper, isUserAuthorized, RouteAuthWrapper };
