import { Button, Text, R, Heading, C6, ModalError } from "@fundrecs/ui-library";
import { tbStore } from "store/Store";

const ReloadTemplateBuilderModal = ({ teamId }) => {
  return (
    <R props="pt-48 justify-content-center">
      <C6 props="text-center pt-32 pb-32 pl-48 pr-48">
        <ModalError />

        <div className="pt-16">
          <Heading variant="h4" align="center">
            Widget failed to load
          </Heading>
        </div>
        <div className="pt-16 pb-16">
          <Text variant="tertiary" size="sm" align="center" weight="regular" element="p">
            Please try again
          </Text>
        </div>
        <Button
          onClick={() => {
            tbStore.reloadTemplateBuilder({ teamId });
          }}
        >
          <Text size="sm" weight="medium">
            Reload
          </Text>
        </Button>
      </C6>
    </R>
  );
};

export { ReloadTemplateBuilderModal };
