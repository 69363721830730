import React from "react";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

export default class TextDataView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper className="unstructured-table">
        <Paper className="infoArray">
          {this.props.commandResult.infoArray.map((info, index) => {
            return <Chip label={info} />;
          })}
        </Paper>
        <Paper>
          <div style={{ whiteSpace: "pre-wrap" }}>{this.props.commandResult.textData}</div>
        </Paper>
      </Paper>
    );
  }
}
