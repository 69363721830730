import { useNavigate, createSearchParams } from "react-router-dom";

import { Heading, R, C, Text, Button, IconAdd, EmptyWorkflow } from "@fundrecs/ui-library";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { useStore } from "../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const NoOutputMappings = () => {
  const pointerStyle = {
    pointerEvents: "auto",
  };

  const navigate = useNavigate();
  const { rolesStore, outputMappingsStore } = useStore();
  const teamId = useTeamId();

  const handleCreateMapping = () => {
    outputMappingsStore.setOutputMapping({});
    navigate({ pathname: "/mappings/create", search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyWorkflow />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No output mappings
          </Heading>
        </C>
      </R>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.OUTPUT_MAPPING_CREATE])}>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                Click the '+ New output mapping' button below to add your
              </Text>
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                first output mapping to your team's resources
              </Text>
            </div>
          </C>
        </R>
        <R>
          <C>
            <div className="pt-32 pb-32" style={pointerStyle}>
              <Button className={pointerStyle} onClick={handleCreateMapping} size="md">
                <IconAdd className={"btn-md-svg"} />
                <Text size="sm">New output mapping</Text>
              </Button>
            </div>
          </C>
        </R>
      </AuthWrapper>
    </>
  );
};

export { NoOutputMappings };
