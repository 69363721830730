
  const autoSizeAll = (skipHeader, params) => {
    //params is gridOptions
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };


  export {autoSizeAll}