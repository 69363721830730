/**
 * Created by Naman on 10/17/2016.
 */
export default class IndexedValue {
  constructor(props) {
    this.index = props.index;
    this.value = props.value;
  }

  containsIndex() {
    if (this.index != undefined) return true;
    return false;
  }
}
