import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { LookupsList } from "./LookupsList/LookupsList";
import { LookupsTable } from "./LookupsTableType/LookupsTable";
import { useTeamId } from "store/hooks/useTeamId";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { MODAL_IDS } from "utils/lookups/enums";

const Lookups = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = useTeamId();
  const tableId = searchParams.get("tableId");
  const { lookupsStore, meStore } = useStore();

  const [lookupTable, setLookupTable] = useState();

  useEffect(() => {
    if (teamId) {
      lookupsStore.getTables(teamId, {}, true).then(() => {
        if (tableId) {
          const lookupTable = lookupsStore.getLookupTableByUuid(tableId);
          if (lookupTable) {
            setLookupTable(lookupTable);
          } else {
            setLookupTable();

            const team = meStore.getTeamById(teamId);
            if (team !== undefined) {
              modalInstance(MODAL_IDS.ERROR_LOOKUP_NOT_FOUND).show();
            }
          }
        } else {
          setLookupTable();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, tableId]);

  const handleConfirmModal = () => {
    searchParams.delete("tableId");
    setSearchParams(searchParams);
  };

  return (
    <>
      <ErrorModal
        modalId={MODAL_IDS.ERROR_LOOKUP_NOT_FOUND}
        heading="Sorry, this lookup doesn't exist in your team workspace!"
        text="Please go to the lookups section and select from the list of all available lookups."
        confirm="Go to lookups"
        onConfirm={handleConfirmModal}
      />
      {lookupTable && tableId ? <LookupsTable /> : <LookupsList teamId={teamId} />}
    </>
  );
});

export { Lookups };
