const agMultiColumnFilter = {
  filter: "agMultiColumnFilter",
  filterParams: {
    filters: [
      {
        filter: "agTextColumnFilter",
        display: "subMenu",
      },
      {
        filter: "agSetColumnFilter",
      },
    ],
  },
};

export { agMultiColumnFilter };
