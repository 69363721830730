import { Text, IconCloseRemove } from "@fundrecs/ui-library";
import styles from "./ModalHeader.module.scss";

/**
 * The ModalHeader component renders a close button and a title for the modal.
 *
 * Clicking the close button calls the `onCloseClick` callback which is passed in as a prop.
 *
 * @param {function} onCloseClick - function to be called when the close button is triggered
 * @param {string} title - title for the modal
 */
const ModalHeader = ({ onCloseClick, title, contentLeft, contentRight, contentCenter, classes }) => (
  <div className={["d-flex", `${classes}`].join(" ")}>
    <IconCloseRemove
      className={["btn-lg-svg", styles.iconCloseRemove].join(" ")}
      onClick={() => {
        onCloseClick();
      }}
    />
    <span className={styles.verticalDividerSpan}>|</span>
    <Text>{title}</Text>
    <div className={styles.contentAreaLeft}>{contentLeft}</div>
    <div className={styles.contentCenter}>{contentCenter}</div>
    <div className={styles.contentAreaRight}>{contentRight}</div>
  </div>
);

const ModalFooter = ({ children }) => (
  <div className="pt-48 ">
    <div className={styles.footer}>
      <div className="d-flex flex-row-reverse">{children}</div>
    </div>
  </div>
);

export { ModalHeader, ModalFooter };
