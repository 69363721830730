import React from "react";
import { Text, C10, Radio } from "@fundrecs/ui-library";
import styles from "./method.module.scss";

const Method = ({ name, description, disabled, value, selected, setSelected, props }) => {
  const {selectedMethod} = styles;
  const {unSelectedMethod} = styles;
  const {disabledMethod} = styles;

  //Check if method is selected and set the highlighted colors
  let classes = selected === value ? selectedMethod : unSelectedMethod;

  //Check if method is disabled and set the disabled colors
  classes = disabled === true ? disabledMethod : classes;
  return (
    <div className={["d-flex pl-32 pt-24 pr-32 pb-24 mt-20 mb-20", `${classes}`].join(" ")}>
      <div>
        <Radio
          onClick={() => {
            setSelected(value);
          }}
          checked={selected === value}
          disabled={disabled}
          style={{ width: "30px", height: "30px" }}
        />
      </div>
      <C10 props="mt-4 mb-0 pl-20 pr-20">
        <div className="d-flex align-items-center">
          <Text element="div" size="lg" weight="bold" variant="primary">
            {name}
          </Text>
        </div>
        <div className="d-flex pb-8">{description}</div>
        {props}
      </C10>
    </div>
  );
};

export { Method };
