import React from "react";
import { appSingleton } from "./utils/AppSingleton";
import HttpMediator from "./utils/http/HttpMediator";
import { tbAppChannel } from "./utils/communication/AppChannels";
import { Icon, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, Grid } from "@material-ui/core";
import validateCurrencyCode from "validate-currency-code";
import { tbStore, meStore, templatesStore } from "../../store/Store";
import { checkAllRequired } from "../../utils/authorization";
import { Button, Text, IconCheck } from "@fundrecs/ui-library";

export default class SubmitApproveButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorDialogDisplay: false };
  }

  validateThenSubmitTemplate() {
    HttpMediator.fetchResultAndStack().then((jsonResponse) => {
      let dataIsValid = this.validateMappings();

      if (!dataIsValid) {
        this.setState({ errorDialogDisplay: true });
      } else {
        HttpMediator.submitTemplate().then((jsonResponse) => {
          tbStore.remountTemplateBuilder(appSingleton);
        });
      }
    });
  }

  submitTemplate() {
    HttpMediator.submitTemplate().then((jsonResponse) => {
      tbStore.remountTemplateBuilder(appSingleton);
    });
  }

  approveTemplate() {
    HttpMediator.approveTemplate().then((jsonResponse) => {
      tbStore.remountTemplateBuilder(appSingleton);
    });
  }

  rejectTemplate() {
    HttpMediator.rejectTemplate().then((jsonResponse) => {
      tbStore.remountTemplateBuilder(appSingleton);
    });
  }

  /**
   * If button disabled when clicked, Display an error message
   * If user is not in the tamplste mapping page, direct
   * @param message Messahe to be returned
   * @param buttonType 'submit', 'approve' or 'reject'
   */
  displayErrorMessage(message, buttonType) {
    //If the user is not on the mappings page, direct them to it
    if (!this.props.applyMappings) {
      message = "To " + buttonType + " template, please go to Template Mappings view";
    }
    tbAppChannel.publish("error", message);
  }

  closeErrorDialog() {
    this.setState({ errorDialogDisplay: false });
  }

  render() {
    // Currently the submit and approve buttons are not required, but they may be reintroduced in the future.
    // So the logic above remains and they are disabled here
    const submitApproveButtons = false;
    return (
      /*   <div id="submitApproveButtonsContainer">
      All this logic will need to be moved for 4 eyes
        <div className="d-flex flex-row-reverse">
          {!submitApproveButtons ? (
            <Button
              size="md"
              color="primary"
              onClick={async (e) => {
                const { success } = await templatesStore.approveTemplate(appSingleton.teamId, appSingleton.templateUuid);
                if (success) {
                  appSingleton.onComplete(appSingleton.templateUuid);
                }
              }}
              disabled={tbStore.doneButtonDisabled}
            >
              <IconCheck className="btn-md-svg" />
              <Text size="sm">Approve & exit</Text>
            </Button>
          ) : checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:submit"]) && appSingleton.approvalOptions.submit.show ? (
            <Button
              className="submit-approve-reject-buttons right-5pc"
              aria-label="submit"
              title={appSingleton.approvalOptions.submit.message}
              onClick={(e) => {
                !appSingleton.approvalOptions.submit.enable || !this.props.allRequiredMappedState || !this.props.applyMappings
                  ? this.displayErrorMessage("Error: " + appSingleton.approvalOptions.submit.message, "submit")
                  : this.validateThenSubmitTemplate();
              }}
            >
              <Text size="md" weight="medium">
                Submit
              </Text>
            </Button>
          ) : checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:approve"]) && appSingleton.approvalOptions.approve.show ? (
            <Button
              className="submit-approve-reject-buttons right-10pc"
              aria-label="approve"
              title={appSingleton.approvalOptions.approve.message}
              onClick={(e) => {
                !appSingleton.approvalOptions.approve.enable || !this.props.applyMappings
                  ? this.displayErrorMessage("Error: " + appSingleton.approvalOptions.approve.message, "approve")
                  : this.approveTemplate();
              }}
            >
              <Text size="md" weight="medium">
                Approve
              </Text>
            </Button>
          ) : checkAllRequired(meStore.getMe(), meStore.getAllActions(), ["template:reject"]) && appSingleton.approvalOptions.reject.show ? (
            <Button
              className="submit-approve-reject-buttons right-5pc"
              aria-label="reject"
              title={appSingleton.approvalOptions.reject.message}
              onClick={(e) => {
                !appSingleton.approvalOptions.reject.enable || !this.props.applyMappings
                  ? this.displayErrorMessage("Error: " + appSingleton.approvalOptions.reject.message, "reject")
                  : this.rejectTemplate();
              }}
            >
              <Text size="md" weight="medium">
                Reject
              </Text>
            </Button>
          ) : (
            ""
          )}
          <Dialog
            maxWidth="lg"
            classes={{ paperWidthLg: "width-800" }}
            open={this.state.errorDialogDisplay}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Column mapping errors</DialogTitle>
            <DialogContent>{this.errorList}</DialogContent>
            <DialogActions>
              <Button title="Accept these errors and submit the template" onClick={this.submitTemplate} autoFocus>
                Submit
              </Button>

              <Button title="Cancel and return to editing the template" onClick={this.closeErrorDialog.bind(this)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div> */
      <></>
    );
  }

  validateMappings() {
    let rowsToShow = this.props.commandResult.fundsData.rows;
    let error = "";
    let errorCounts = new Array(this.props.templateColumns.length).fill(0);

    rowsToShow.map((row, index) => {
      if (row.parsedRow.length > 1) {
        //dont validate row summary (XXXX more rows)
        for (let i = 0; i < this.props.templateColumns.length; i++) {
          let col = this.props.templateColumns[i];

          if (col.type == "Number" && col.columnIndex >= 0) {
            if (!this.validateNumber(row.parsedRow[col.columnIndex])) {
              errorCounts[i]++;
            }
          } else if (col.type == "DateType" && col.columnIndex >= 0) {
            if (!this.validateDate(row.parsedRow[col.columnIndex])) {
              errorCounts[i]++;
            }
          } else if (col.type == "DateTime" && col.columnIndex >= 0) {
            if (!this.validateDateTime(row.parsedRow[col.columnIndex])) {
              errorCounts[i]++;
            }
          } else if (col.type == "Currency" && col.columnIndex >= 0) {
            if (!this.validateCurrency(row.parsedRow[col.columnIndex].toUpperCase())) {
              errorCounts[i]++;
            }
          }
          // any mapped required column, should not be blank
          else if (col.columnIndex >= 0 && col.required) {
            if (row.parsedRow[col.columnIndex].trim() == "") {
              errorCounts[i]++;
            }
          }
        }
      }
    });

    let keyIndex = 0;
    let errorList = errorCounts.map((count, index) => {
      if (count > 0) {
        let error = "";
        if (this.props.templateColumns[index].type == "Number") {
          error = "Not a valid number";
        } else if (this.props.templateColumns[index].type == "DateType") {
          error = "Not a valid date (yyyy-MM-dd)";
        } else if (this.props.templateColumns[index].type == "DateTime") {
          error = "Not a valid date and time (yyyy-MM-dd'T'HH:mm'Z')";
        } else if (this.props.templateColumns[index].type == "Currency") {
          error = "Not a currency code e.g. EUR, USD";
        } else if (this.props.templateColumns[index].required) {
          error = "Is required, but blank";
        }

        return (
          <ListItem key={keyIndex}>
            <Grid container alignItems="center" direction="row" spacing={0}>
              <Grid item xs={2}>
                <div className="cellWithEllipsis" title={this.props.templateColumns[index].columnName}>
                  {this.props.templateColumns[index].columnName}
                </div>
              </Grid>
              <Grid item xs={1}>
                <Icon className="icon-colour">compare_arrows</Icon>
              </Grid>
              <Grid item xs={2}>
                <div className="cellWithEllipsis" title={this.props.commandResult.fundsData.headers[this.props.templateColumns[index].columnIndex]}>
                  {this.props.commandResult.fundsData.headers[this.props.templateColumns[index].columnIndex]}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="cellWithEllipsis" title={error}>
                  {error}
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>{count + "/" + rowsToShow.length + " rows"}</div>
              </Grid>
            </Grid>
          </ListItem>
        );
      }
      keyIndex++;
    });

    errorList.unshift(
      <ListItem key={keyIndex} className="border-bottom-grey">
        <Grid container alignItems="center" direction="row" spacing={0}>
          <Grid item xs={5} className="font-600">
            Column mapping
          </Grid>
          <Grid item xs={5} className="font-600">
            Error
          </Grid>
          <Grid item xs={2} className="font-600">
            Rows affected
          </Grid>
        </Grid>
      </ListItem>
    );

    this.errorList = <List>{errorList}</List>;

    //if any errors found fail and show error dialog
    for (let i = 0; i < errorCounts.length; i++) {
      if (errorCounts[i] > 0) {
        return false;
      }
    }
    return true; //if no errors found return true
  }

  //supports yyyy/MM/dd only  e.g '2017/09/15'
  validateDate(dateString) {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) return false;

    return true;
  }

  //supports yyyy/MM/dd'T'hh:mm'Z' only  e.g '2016-08-31T00:00Z'
  validateDateTime(dateString) {
    if (!/^(\d{4})-0?(\d+)-0?(\d+)[T ]0?(\d+):0?(\d+):0?(\d+)$/.test(dateString)) return false;

    return true;
  }

  //return true if string is a number
  validateNumber(numStr) {
    let num = numStr.toString().replace(/,/g, ""); //coerce num to be a string, without commas
    return !isNaN(num) && !isNaN(parseFloat(num));
  }

  validateCurrency(curStr) {
    return validateCurrencyCode(curStr);
  }
}
