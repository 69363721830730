import { WorkflowStage } from "./WorkflowStage/WorkflowStage";

/**
 * Fusion20WorkflowStage
 *
 * Wrapper component which translates stage state from a Fusion20WorkflowStore
 * into props for a WorkflowStage component
 */
const Fusion20WorkflowStage = ({ stageState, ...regularProps }) => {
  const stateProps = {
    editing: stageState.isEditing,
    complete: stageState.isComplete,
    enabled: stageState.isEnabled,
  };

  return <WorkflowStage {...stateProps} {...regularProps} />;
};

export { Fusion20WorkflowStage };
