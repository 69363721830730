/**
 * Represents the Options to show in selectandtransform's select operations.
 */

export default class SelectOptions {
  constructor(props) {
    this.peName = props.peName;
    this.uiName = props.uiName;
    this.uiDesc = props.uiDesc;
    this.isArg1Index = props.isArg1Index;
    this.isArg2Index = props.isArg2Index;
  }

  /**
   * Builds the list of commands available in backend from json response*
   * @param commandListJson
   * @returns {*}
   */
  static buildSelectOptionsFromJson(selectOptionsJson) {
    return selectOptionsJson.map((selectJson) => {
      return new SelectOptions(selectJson);
    });
  }
}
