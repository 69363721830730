/**
 * This function is used on the input summary page and the input edit view.
 * It displays the rules in a readable format which matches the designs.
 *
 * It returns a text string.
 *
 * @param criteria
 * @param type
 * @return
 */
const generateText = (criteria, type) => {
  let textConditions = "Apply this template if filename";
  criteria.forEach((condition, index) => {
    textConditions += ` ${condition.operator === "string_contains" ? "contains" : "does not contain"} ${condition.paramValue} ${
      criteria.length - 1 !== index ? type : ""
    }`;
  });

  return textConditions;
};

export { generateText };
