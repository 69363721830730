import PropTypes from "prop-types";
import { PRIORITY_VALUES } from "utils/lookups/enums";

// Default value shared between components
const defaultValue = 3;

// Component to render the priority value as JSX
const LookupPriorityRenderer = ({ value = defaultValue }) => {
  return <>{PRIORITY_VALUES[value]}</>;
};

LookupPriorityRenderer.propTypes = {
  value: PropTypes.number,
};

// Component to render the priority value as a plain string
const LookupPriorityRendererText = ({ value }) => {
  const resolvedValue = value ?? defaultValue;
  return PRIORITY_VALUES[resolvedValue];
};

LookupPriorityRendererText.propTypes = {
  value: PropTypes.number,
};

export { LookupPriorityRenderer, LookupPriorityRendererText };
