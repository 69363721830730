import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { modalInstance, Button, Text, IconAdd, Heading, R, C6, C3, C4, C8, C9 } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { PageTitleArea, ManageLayout } from "components/layout/Layout";
import { MODAL_IDS } from "utils/workflows/enums";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { ROUTES, FE_SOURCE_TYPES, RULE_TYPE } from "utils/enums";
import { AddTemplate } from "../addTemplate/AddTemplate";
import { TemplateTable } from "../templateTable/TemplateTable";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { generateText } from "components/workflows/ConfigureWorkflow/stages/input/reusable/GenerateText";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

const ImportDetail = ({ importType }) => {
  const navigate = useNavigate();
  const { connectionUuid, inputMappingId } = useParams();
  const { importsStore, meStore, tenantConfigStore } = useStore();
  const [gridApi, setGridApi] = useState(null);
  const [connectionData, setConnectionData] = useState({
    connectionName: "",
    sftpDirectoryName: "",
    sftpDirectoryPath: "",
    templatesAssigned: "",
    inclusionRules: [],
  });

  const { superAdmin } = meStore.getMe();
  const fusionAdminUrl = tenantConfigStore.getFusionAdminUrl();

  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const getFileName = () => {
    const sourceType = importType === FE_SOURCE_TYPES.SFTP ? "SFTP directory" : "email address";
    const sourceName = importType === FE_SOURCE_TYPES.SFTP ? connectionData.sftpDirectoryName : connectionData.connectionName;
    const today = moment().format(meStore.getUserDateFormat());

    return `All templates assigned to ${sourceType} source for ${sourceName} ${teamName}-${today}`;
  };

  const getImportRules = async () => {
    const { success, data } = await importsStore.getImportRules({ teamId: teamId, type: importType });
    if (success) {
      const validConnection = data.filter((connection) => {
        if (importType === FE_SOURCE_TYPES.SFTP) {
          if (connection.connectionUuid === connectionUuid && connection.inputMappingId === Number(inputMappingId)) {
            return connection;
          }
        } else {
          if (connection.connectionUuid === connectionUuid) {
            return connection;
          }
        }
      });

      if (validConnection.length > 0) {
        const [importData] = validConnection;
        setConnectionData(importData);
      } else {
        modalInstance(MODAL_IDS.ERROR_IMPORT_NOT_FOUND).show();
      }
    } else {
      modalInstance(MODAL_IDS.ERROR_IMPORT_NOT_FOUND).show();
    }
  };

  const handleSourceConnectionCreate = () => {
    if (superAdmin) {
      if (importType === FE_SOURCE_TYPES.SFTP) {
        window.open(fusionAdminUrl.concat("/import/sftpmapping"), "_blank");
      } else if (importType === FE_SOURCE_TYPES.EMAIL) {
        window.open(fusionAdminUrl.concat("/import/email"), "_blank");
      }
    } else {
      handleConfirmCreateModal();
    }
  };

  const handleConfirmCreateModal = () => {
    modalInstance(MODAL_IDS.ERROR_TEMPLATE_IMPORT).toggle();
  };

  useEffect(() => {
    if (teamId) {
      getImportRules();
    }
  }, [connectionUuid, teamId]);

  const getRule = (params) => {
    const { criteria, type } = params.data.rule;
    if (type === RULE_TYPE.MATCH_ALL) {
      return "Apply this template to all incoming data";
    } else {
      return generateText(criteria, type);
    }
  };

  /**
   * Custom cell processing callback for AG Grid export to CSV.
   * Processes cell values based on the column ID for export.
   *
   * For more details refer to:
   * https://ag-grid.com/archive/26.1.0/javascript-data-grid/csv-export/#reference-csvExportParams-processCellCallback
   *
   * @param {Object} params - Parameters provided by AG Grid.
   * @param {Object} params.column - The column object containing column metadata.
   * @param {Function} params.column.getColId - Function to get the column ID.
   * @param {Object} params.node - The row node object containing row data.
   * @param {Object} param.node.data - The data of the row node.
   * @returns {string} - The processed cell value.
   */
  const processCellCallback = ({ column, node }) => {
    const columnId = column.getColId();
    // retrieve the cell value, defaulting to an empty string if it isn't available
    const cellValue = node.data?.[columnId] ?? "";

    if (columnId === "rules") {
      // custom processing for the "rules" column
      return getRule(node);
    }

    return cellValue;
  };

  const handleConfirmModal = () => {
    const type = importType === FE_SOURCE_TYPES.SFTP ? ROUTES.SFTP_IMPORT : ROUTES.EMAIL_IMPORT;
    const location = window.location.origin + "/" + type + "?teamId=" + teamId;
    window.location.href = location;
  };

  return (
    <>
      <AddTemplate importType={importType} rowData={connectionData} getImportRules={getImportRules} setRowData={() => {}} performReset={false} />
      <ErrorModal
        modalId={MODAL_IDS.ERROR_IMPORT_NOT_FOUND}
        heading={`${"Sorry, this " + " source doesn't exist!"}`}
        text={`${"Please go to the " + " imports section and select from the list of all available " + " sources."}`}
        confirm={`${"Go to " + " imports "}`}
        onConfirm={handleConfirmModal}
      />
      <ErrorModal
        modalId={MODAL_IDS.ERROR_TEMPLATE_IMPORT}
        heading={`${"Sorry, you do not have permissions to create a new " + `${importType}` + " connection!"}`}
        text="Please contact your organisation administrator or the Fusion team for support."
        confirm="Ok"
        onConfirm={handleConfirmCreateModal}
      />
      <PageTitleArea
        leftArrow={true}
        title={importType === FE_SOURCE_TYPES.SFTP ? connectionData.sftpDirectoryName : connectionData.connectionName}
        dividerActive={false}
        description={""}
        navigate={() => {
          navigate(`${(importType === FE_SOURCE_TYPES.SFTP ? ROUTES.SFTP_IMPORT : ROUTES.EMAIL_IMPORT).concat(`?teamId=${connectionData.teamId}`)}`);
        }}
      >
        <span className="mr-12">
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.ADD_TEMPLATE_IMPORT).toggle();
            }}
          >
            <IconAdd className="btn-md-svg" />
            <Text size="sm">Assign template</Text>
          </Button>
        </span>
        <Button
          size="md"
          color="primary-secondary"
          onClick={() => {
            handleSourceConnectionCreate();
          }}
        >
          <Text size="sm">Manage source</Text>
        </Button>
      </PageTitleArea>
      <ManageLayout>
        <div className="pt-32 pb-48">
          <div className="mb-20">
            <Heading variant="h5" element="span">
              Source details
            </Heading>
          </div>
          <R>
            <C6 props="pl-0 pr-0">
              <div className="d-flex">
                <C3>
                  <Text size="sm" weight="medium">
                    {importType === FE_SOURCE_TYPES.SFTP ? "SFTP directory name:" : "Email address name:"}
                  </Text>
                </C3>
                <C9>
                  <Text size="sm" weight="regular">
                    {importType === FE_SOURCE_TYPES.SFTP ? connectionData.sftpDirectoryName : connectionData.connectionName}
                  </Text>
                </C9>
              </div>
            </C6>
            <C6 props="pl-0 pr-0">
              {importType === FE_SOURCE_TYPES.SFTP && (
                <div className="d-flex">
                  <C4>
                    <Text size="sm" weight="medium">
                      Associated SFTP connection:
                    </Text>
                  </C4>
                  <C8>
                    <Text size="sm" weight="regular">
                      {connectionData.connectionName}
                    </Text>
                  </C8>
                </div>
              )}
            </C6>
          </R>
          <R>
            <C6 props="pl-0 pr-0">
              <div className="d-flex">
                <C3>
                  <Text size="sm" weight="medium">
                    {importType === FE_SOURCE_TYPES.SFTP ? " SFTP directory path:" : "Email address:"}
                  </Text>
                </C3>
                <C9>
                  <Text size="sm" weight="regular">
                    {importType === FE_SOURCE_TYPES.SFTP ? connectionData.sftpDirectoryPath : connectionData.emailAddress}
                  </Text>
                </C9>
              </div>
            </C6>
            <C6 props="pl-0 pr-0">
              <div className="d-flex">
                <C3>
                  <Text size="sm" weight="medium">
                    Templates assigned:
                  </Text>
                </C3>
                <C9>
                  <Text size="sm" weight="regular">
                    {connectionData.templatesAssigned}
                  </Text>
                </C9>
              </div>
            </C6>
          </R>
        </div>
        <div>
          <div className="d-flex mb-20 justify-content-between align-items-center">
            <div>
              <Heading variant="h5" element="span">
                Templates assigned
              </Heading>
              <Text size="sm" weight="regular" variant="muted" element="div">
                Assign templates to transform data coming in to Fusion from this source. Apply import rules as part of template assignment to target specific
                source data for transformation.
              </Text>
            </div>
            <div className="ms-auto pr-12 align-self-start">
              <DownloadTable gridRef={gridApi} processCellCallback={processCellCallback} fileName={getFileName} excludeLastColumn={true} />
            </div>
          </div>
          <div className="mt-20">
            <TemplateTable
              connectionData={connectionData}
              importType={importType}
              getImportRules={getImportRules}
              gridApi={gridApi}
              setGridApi={setGridApi}
              teamId={teamId}
            />
          </div>
        </div>
      </ManageLayout>
    </>
  );
};

export { ImportDetail };
