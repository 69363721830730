import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, Icon, DialogContent, Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";


export default class TypeChanger extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    let typeOptionsButtons = [];
    if (this.props.typeOptions != null) {
      Object.entries(this.props.typeOptions).forEach(([key, value]) => {
        let boundItemClick = this.props.onSelectType.bind(this, key);
        typeOptionsButtons.push(
          <ListItem key={key} button onClick={boundItemClick}>
            {value}
          </ListItem>
        );
      });
    }
    return (
      <Dialog open={true}>
        <DialogContent className="robotoFont">
          <List>
            <ListItem button onClick={this.props.onClose}>
              <CloseIcon/>
            </ListItem>
            {typeOptionsButtons}
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

TypeChanger.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectType: PropTypes.func.isRequired,
  typeOptions: PropTypes.object.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
};
