import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Toggle, C, C2, R } from "@fundrecs/ui-library";

const defaultTextTrue = "Enabled";
const defaultTextFalse = "Disabled";

const ToggleEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const { textTrue = defaultTextTrue, textFalse = defaultTextFalse } = props;

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  return (
    <R>
      <C2>
        <Toggle
          checked={value}
          onChange={(event) => {
            const {
              target: { checked },
            } = event;
            setValue(checked);
          }}
        />
      </C2>
      <C>{value ? textTrue : textFalse}</C>
    </R>
  );
});

export { ToggleEditor };
