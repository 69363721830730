import { makeAutoObservable, toJS } from "mobx";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const { reconciliations } = fusionDataApi;


class RecTypeStore {
    clientsAccountsPerRecType = [];
    enabledRecTypes = [];
    recTypeTags = [];
    selectedRecType = null;
    recTypeTmos = [];
    recTypeColumnMap = {};
    groupTmoColumns = [];
    matchingRulesForRecType = [];
    matchingRuleDetails = [];
    draftRecType = {
      name: "Test RecType",
      description: "this is a test rectype hardcoded in the store",
      type: "Two sided",
      defaultDateFormat: "yyyy-MM-dd",
      defaultDecimal: 4,
      status: "DRAFT",
      columns: { Internal: [], External: [], Results: [] },
    };
    defaultSettings = [];

    constructor() {
        makeAutoObservable(this);
    }

    setRecTypes(value) {
        this.enabledRecTypes = value;
    }

    getEnabledRecTypes = () => {
        return toJS(this.enabledRecTypes);
      }

    /**
   * This method sends a request to get reconciliation types.
   *
   * If the request is successful we returns the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
    getRecTypes = (teamId) => {
        return reconciliations
        .getRecTypes(teamId)
        .then((response) => {
            const { status } = response;
            if (status === 200) {
            this.setRecTypes(response.data);
            return { ...response, ...{ success: true } };
            } else {
            return { ...response, ...{ success: false } };
            }
        })
        .catch((response) => {
            uiStore.addNotification("error", "Unable to get Reconciliation types! Please try again. ");
        });
    }
    

    getSelectedRecType = () => {
    return toJS(this.selectedRecType);
    };

    getRecTypeColumnMap = () => {
    return toJS(this.recTypeColumnMap);
    };
}

export { RecTypeStore };