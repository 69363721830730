import PropTypes from "prop-types";
import moment from "moment";
import { Text, R, C, C6, C3, UserItem } from "@fundrecs/ui-library";
import { TABLE_TYPES } from "utils/lookups/enums";
import styles from "./DropdownPanelCell.module.scss";

const DropdownPanelCell = (rowNode) => {
  const dateFormat = "MMM DD, YYYY [at] HH:mm";

  const {
    data: {
      description,
      type,
      createdBy,
      createdAt,
      settings: { caseSensitivity, fourEyesEnabled, overwrite },
      metadata: { tableRowStatusCounts } = {},
    },
  } = rowNode;

  const { Pending: pendingCount = 0, Draft: draftCount = 0, Approved: approvedCount = 0 } = tableRowStatusCounts || {};

  return (
    <>
      <R props="pl-32 pt-32 pb-32">
        <C6 props="pl-0">
          <R props="pb-12">
            <C props="pl-0">
              <Text size="xs" weight="medium" element="div" variant="primary">
                Description
              </Text>
            </C>
          </R>
          <R props="pb-12">
            <C props="pl-0">
              <Text size="xs" weight="regular" element="div" variant="secondary">
                {description}
              </Text>
            </C>
          </R>
          <R>
            <C props="pl-0">
              <R>
                <C props="pl-0">
                  <Text size="xs" weight="medium" element="div" variant="primary">
                    Type:
                  </Text>
                </C>
                <C props="pl-0">
                  <Text size="xs" weight="regular" element="div" variant="secondary">
                    {type}
                  </Text>
                </C>
              </R>
              {type === TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS && (
                <R>
                  <C props="pl-0">
                    <Text size="xs" weight="medium" element="div" variant="primary">
                      Overwrite:
                    </Text>
                  </C>
                  <C props="pl-0">
                    <Text size="xs" weight="regular" element="div" variant="secondary">
                      {overwrite ? "Enabled" : "Disabled"}
                    </Text>
                  </C>
                </R>
              )}
            </C>
            <C props="pl-0">
              {type === TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS && (
                <R>
                  <C props="pl-0">
                    <Text size="xs" weight="medium" element="div" variant="primary">
                      Case-Sensitive:
                    </Text>
                  </C>
                  <C props="pl-0">
                    <Text size="xs" weight="regular" element="div" variant="secondary">
                      {caseSensitivity ? "Enabled" : "Disabled"}
                    </Text>
                  </C>
                </R>
              )}
              <R>
                <C props="pl-0">
                  <Text size="xs" weight="medium" element="div" variant="primary">
                    Approval:
                  </Text>
                </C>
                <C props="pl-0">
                  <Text size="xs" weight="regular" element="div" variant="secondary">
                    {fourEyesEnabled ? "Required" : "Not Required"}
                  </Text>
                </C>
              </R>
            </C>
          </R>
        </C6>
        <C3>
          <R props="pb-12">
            <Text size="xs" weight="medium" element="div" variant="primary">
              Row Status
            </Text>
          </R>
          <R>
            <C props="pl-0">
              <Text size="xs" weight="regular" element="div" variant="secondary">
                <div className={[styles.statusIcon, styles.pending].join(" ")} /> {pendingCount} pending rows
              </Text>
            </C>
          </R>
          <R>
            <C props="pl-0">
              <Text size="xs" weight="regular" element="div" variant="secondary">
                <div className={[styles.statusIcon, styles.approved].join(" ")} /> {approvedCount} approved rows
              </Text>
            </C>
          </R>
          <R>
            <C props="pl-0">
              <Text size="xs" weight="regular" element="div" variant="secondary">
                <div className={[styles.statusIcon, styles.draft].join(" ")} /> {draftCount} draft rows
              </Text>
            </C>
          </R>
        </C3>
        <C3>
          <R props="pb-12">
            <Text size="xs" weight="medium" element="div" variant="primary">
              Created by
            </Text>
          </R>
          <R>
            <li className={["d-flex custom-item", styles.breakSpace].join(" ")}>
              <UserItem avatar={""} header={createdBy.name} body={moment(createdAt).format(dateFormat)} />
            </li>
          </R>
        </C3>
      </R>
    </>
  );
};

DropdownPanelCell.propTypes = {
  rowNode: PropTypes.shape({
    data: PropTypes.shape({
      description: PropTypes.string,
      type: PropTypes.oneOf([TABLE_TYPES.LOOKUP_TABLE_TYPE_CONTAINS, TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS]),
      createdBy: PropTypes.shape({ name: PropTypes.string }),
      createdAt: PropTypes.instanceOf(Date),
      settings: PropTypes.shape({
        caseSensitivity: PropTypes.bool,
        fourEyesEnabled: PropTypes.bool,
        overwrite: PropTypes.bool,
      }),
      metadata: PropTypes.shape({
        tableRowStatusCounts: PropTypes.shape({
          Pending: PropTypes.number,
          Draft: PropTypes.number,
          Approved: PropTypes.number,
        }),
      }),
    }),
  }),
};

DropdownPanelCell.defaultProps = {
  //Leaving this blank intentionally.
};

export { DropdownPanelCell };
