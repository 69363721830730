import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import { LumsContext } from "./LumsCommand";

export default function SelectSearchType({ info, description, all, any }) {
  const lumsContext = useContext(LumsContext);

  const { lookupTable, searchType, setSearchType } = lumsContext;

  if (!lookupTable) {
    return null;
  }

  return (
    <div className="marginLeft12">
      <div className="marginBottom9">{info}</div>
      <Grid container>
        <Grid item xs>
          <span className="input-label">
            <b>{description}</b>
          </span>

          <span>
            <span className="option">
              <input
                type="radio"
                id="match-all"
                name="drone"
                value="all"
                checked={searchType === "all"}
                onChange={() => {
                  setSearchType("all");
                }}
              />
              <label htmlFor="match-all" style={{ marginLeft: "8px" }}>
                <b>{all}</b>
              </label>
            </span>

            <span className="option">
              <input
                type="radio"
                id="match-any"
                name="drone"
                value="any"
                checked={searchType === "any"}
                onChange={() => {
                  setSearchType("any");
                }}
              />
              <label htmlFor="match-any" style={{ marginLeft: "8px" }}>
                <b>{any}</b>
              </label>
            </span>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}
