export default class NameWrapper {
  constructor(props) {
    this.uiName = props.uiName;
    this.peName = props.peName;
    this.uiDesc = props.uiDesc;
    this.type = props.type;
    this.reqStructure = props.reqStructure;
    this.outputStructure = props.outputStructure;
    this.required = props.required; //|| true
    this.defaultValue = props.defaultValue;
    this.suggestions = props.suggestions;
    this.order = props.order;
    this.multiSelectAllowed = props.multiSelectAllowed;
  }

  static buildFromJson(propAsjson) {
    return new NameWrapper(propAsjson);
  }
}
