import { useState, useEffect } from "react";
import { useNavigate, createSearchParams, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  Button,
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownItem,
  DropdownList,
  DropdownListItem,
  Input,
  TextArea,
  IconRightArrow,
  R,
} from "@fundrecs/ui-library";
import { SelectMethodHeading } from "components/reusable/selectMethod/selectMethodHeading";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { ModalFooter } from "components/layout/ModalHeader";
import { ROUTES, RULE_TYPE } from "utils/enums";
import styles from "./CreateTemplate.module.scss";

const CreateTemplate = observer(({ setActiveStep, template = { name: "", description: "" }, setTemplate, isWorkflow = true, workflow, importRuleIndex }) => {
  const navigate = useNavigate();
  const { tbStore, outputMappingsStore, workflowsStore, templatesStore } = useStore();
  const { workflowUuid } = useParams();
  const [outputMappingUuid, setOutputMappingUuid] = useState("");
  const [outputMappingName, setOutputMappingName] = useState("");
  const [reports, setReports] = useState([]);
  const [outputMappings, setOutputMappings] = useState([]);

  const teamId = useTeamId();

  const [selectedFile, setSelectedFile] = useState("");

  const getOutputMapping = async () => {
    // With LUMS and Fusion Recs we won't have the outputMappingUuid in the frontend since it's created in the backend
    // We need to have it available for the summary output view and to prefill the next stage for input edit view
    if (workflowUuid) {
      //It must be a lookup or Rec created output mapping if the name is null at this point
      const currentWorkflow = async () => {
        return await workflowsStore.getWorkflowByUuidAndTeamId(workflowUuid, teamId);
      };
      currentWorkflow().then(({ data }) => {
        const {
          configuration: {
            steps: {
              "DELIVER_FILES_V1-1": {
                params: { reports },
              },
            },
          },
        } = data;
        if (reports.length > 0) {
          setReports(reports);

          outputMappingsStore.loadOutputMappings({ teamId: teamId }).then(({ data }) => {
            const outputUuids = reports.map((output) => {
              return output.outputMappingUuid;
            });

            const uniqueOutputList = new Set(outputUuids);

            const outputMappingsList = data.filter((output) => {
              return uniqueOutputList.has(output.uuid);
            });

            if (outputMappingsList.length > 0) {
              setOutputMappingUuid(outputMappingsList[0]["uuid"]);
              setOutputMappingName(outputMappingsList[0]["name"]);
            }
            setOutputMappings(outputMappingsList);
          });
        }
      });
    }
  };

  useEffect(() => {
    if (isWorkflow) {
      getOutputMapping();
    } else {
      if (teamId) {
        outputMappingsStore.loadApprovedOutputMappings({ teamId }).then(() => {
          setOutputMappings(outputMappingsStore.getOutputMappings());
        });
      }
    }
  }, []);

  const MAX_TEMPLATE_NAME = 100;
  const MAX_TEMPLATE_DESCRIPTION = 150;

  const checkStep02Completed = () => {
    const templateName = template.name.length > 0;
    const templateDescription = template.description.length > 0;
    const templateInputFile = selectedFile && selectedFile !== "";

    const stages = [templateName, templateDescription, templateInputFile];

    if (stages.every((stage) => stage === true)) {
      return false;
    } else {
      return true;
    }
  };

  const handleCreateTemplate = async () => {
    if (isWorkflow) {
      const requestObject = {
        workflowUuid: workflowUuid,
        teamId: teamId,
        tmoId: outputMappingUuid,
        file: selectedFile,
        name: template.name,
        description: template.description,
      };
      const response = await tbStore.createNewTemplate(requestObject);

      const { success, data } = response || {};

      if (success) {
        templatesStore.getTemplateByUuidAndTeamId(data.templateUuid, teamId).then((response) => {
          const { data: templateData, success } = response || {};
          if (success) {
            const templates = workflow.getTemplateImportRules(importRuleIndex);
            const template = {
              priority: templates.length + 1,
              type: RULE_TYPE.MATCH_ALL,
              templateUuid: data.templateUuid,
            };

            workflow.addTemplateImportRule(template, importRuleIndex);
            templatesStore.templates.push(templateData.tbTemplate);
            templatesStore.templatesLinkedtoOutputMapping.push(templateData.tbTemplate);
            setActiveStep({ name: "STEP 03", index: 3 });
            setTemplate(templateData.tbTemplate);
          }
        });
      }
    } else {
      const requestObject = {
        teamId: teamId,
        tmoId: outputMappingUuid,
        file: selectedFile,
        name: template.name,
        description: template.description,
      };

      const response = await tbStore.createNewTemplate(requestObject);

      const { success } = response;
      if (success) {
        const {
          data: { templateUuid },
        } = response;

        // Redirect to view/edit
        navigate({ pathname: `${ROUTES.TEMPLATES}/${templateUuid}`, search: `?${createSearchParams({ teamId: teamId })}` });
      }
    }
  };

  return (
    <>
      <R props="ml-72 mr-72 mb-72">
        <SelectMethodHeading
          heading={"Create new template"}
          description={"This template enables you to transform your input data to match the format you've specified for your output file."}
          align="left"
        />

        <div className="mt-48">
          <div className="mb-24">
            <div className="mb-8 d-flex">
              <Text size="sm" weight="medium">
                Template name *
              </Text>
              <div className="ms-auto">
                {template.name.length}
                {MAX_TEMPLATE_NAME && `/${MAX_TEMPLATE_NAME}`}
              </div>
            </div>
            <Input
              value={template.name}
              onChange={(event) => {
                if (event.target.value.length <= MAX_TEMPLATE_NAME) {
                  setTemplate({ name: event.target.value.trimStart(), description: template.description });
                }
              }}
              placeholderText="Add template name"
            />
          </div>

          <div className="mb-24">
            <div className="mb-8 d-flex">
              <div>
                <Text size="sm" weight="medium">
                  Description *
                </Text>
              </div>
              <div className="ms-auto">
                {template.description.length}
                {MAX_TEMPLATE_DESCRIPTION && `/${MAX_TEMPLATE_DESCRIPTION}`}
              </div>
            </div>

            <TextArea
              onChange={(event) => {
                if (event.target.value.length <= MAX_TEMPLATE_DESCRIPTION) {
                  setTemplate({ description: event.target.value.trimStart(), name: template.name });
                }
              }}
              value={template.description}
              placeholderText={template.description.length > 0 ? "" : "Add a description"}
            />
          </div>

          <div className="mb-24">
            <div className="mb-8">
              <Text size="sm" weight="medium">
                Output mapping to map sample input data to *
              </Text>
            </div>
            <div className="d-flex">
              <Dropdown>
                <DropdownButton size="md">
                  <DropdownButtonText>
                    <ItemBody>
                      <>{outputMappingName === "" ? "Select Output Mapping" : outputMappingName}</>
                    </ItemBody>
                    <IconDownArrow className="btn-md-svg" />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList classes={styles.dropdownList}>
                  {outputMappings.map((item, index) => {
                    return (
                      <DropdownListItem
                        key={index}
                        onClick={() => {
                          setOutputMappingUuid(item.uuid);
                          setOutputMappingName(item.name);
                        }}
                      >
                        <DropdownItem active={null}>
                          <ItemBody>{item.name}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              </Dropdown>
            </div>
          </div>
          <div className="mb-24">
            <div className="mb-8">
              <Text size="sm" weight="medium">
                Source input data to transform *
              </Text>
            </div>
            <FileUpload setSelectedFile={setSelectedFile} />
          </div>
        </div>
      </R>
      <ModalFooter>
        <Button
          size="lg"
          color="primary"
          disabled={checkStep02Completed()}
          onClick={() => {
            handleCreateTemplate();
          }}
        >
          <Text size="md" weight="medium">
            Create template
          </Text>
          <IconRightArrow style={{ width: "18px", height: "18px", verticalAlign: "baseline" }} />
        </Button>
      </ModalFooter>
    </>
  );
});

const FileUpload = ({ setSelectedFile }) => {
  // Only accept csv
  // FS-1153 - remove validation
  // commented code will be left here in case validation is needed in future

  //const FILE_TYPE_ACCEPTED = ["text/csv"];
  //const FILE_TYPE_WARNING = "File type is not valid";

  //const [fileInvalid, setFileInvalid] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    //setFileInvalid(false);
    setSelectedFile(file);

    /*if (file && FILE_TYPE_ACCEPTED.includes(file.type)) {
      setFileInvalid(false);
      setSelectedFile(file);
    } else {
      setFileInvalid(true);
      setSelectedFile();
    }*/
  };

  return (
    <Input
      type="file"
      onChange={handleFileChange}
      /*warningMessage={FILE_TYPE_WARNING} warning={fileInvalid}*/
    />
  );
};

export { CreateTemplate };
