import { IconRefresh } from "@fundrecs/ui-library";

import styles from "./RefreshControl.module.scss";

const RefreshControl = ({ refreshing, onClick }) => (
  <button className={styles.container}>
    <IconRefresh className={refreshing ? styles.refreshIconAnimated : styles.refreshIcon} onClick={onClick} />
  </button>
);

export { RefreshControl };

