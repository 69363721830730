import { useContext } from "react";
import { WarningConfirmationModal, Button, Text } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { ModalHeader } from "../../layout/ModalHeader";
import { MODAL_IDS, WORKFLOW_STAGES, WORKFLOW_STATUS } from "../../../utils/workflows/enums";
import { StatusCell } from "../../ag-grid/StatusCell/StatusCell";
import { Fusion20WorkflowStage } from "./Fusion20WorkflowStage";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../utils/enums";
import { WorkflowConfigurationContext } from "./ConfigureWorkflow";
/**
 * sub-views for each stage
 */
import { OutputEditView } from "./stages/output/OutputEditView";
import { OutputSummaryView } from "./stages/output/OutputSummaryView";
import { TriggerEditView } from "./stages/trigger/TriggerEditView";
import { TriggerSummaryView } from "./stages/trigger/TriggerSummaryView";
import { OutputEditSectionHeader } from "./stages/output/OutputEditSectionHeader";
import { InputEditSectionHeader } from "./stages/input/InputEditSectionHeader";
import { InputEditView } from "./stages/input/InputEditView";
import { InputSummaryView } from "./stages/input/InputSummaryView";

import styles from "./ConfigureWorkflow.module.scss";
import { TriggerEditSectionHeader } from "./stages/trigger/TriggerEditSectionHeader";

const { OUTPUT, INPUT, TRIGGER } = WORKFLOW_STAGES;

const ConfigureWorkflowStages = () => {
  const { rolesStore } = useStore();

  const {
    fieldValues,
    stageState,
    setFieldValue,
    saveStage,
    setEditingStage,
    onStageEdit,
    teamId,
    workflow,
    handlePublishButtonClick,
    handleCloseButtonClick,
    handleClose,
    enablePublishButton,
    setWorkflowStage,
  } = useContext(WorkflowConfigurationContext);
  return (
    <>
      <div className={styles.headerWrapper}>
        <WarningConfirmationModal
          modalId={MODAL_IDS.CONFIRM_CLOSE_CONFIGURE_WORKFLOW}
          heading="Are you sure you want to leave the workflow builder area?"
          text="If you have made changes to any steps in your workflow please save them before proceeding. "
          cancel="Cancel"
          confirm="Exit workflow"
          onConfirm={handleClose}
        />
        <ModalHeader
          title={workflow.name}
          onCloseClick={handleCloseButtonClick}
          contentLeft={<StatusCell value={workflow.status} />}
          contentRight={
            workflow.status !== WORKFLOW_STATUS.PUBLISHED && (
              <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_CREATE])}>
                <Button disabled={!enablePublishButton} onClick={handlePublishButtonClick}>
                  <Text size="md">Publish &amp; exit</Text>
                </Button>
              </AuthWrapper>
            )
          }
        />
      </div>
      <div className={styles.stages}>
        <Fusion20WorkflowStage
          stageState={stageState[OUTPUT]}
          stageNumber={1}
          title="Configure outputs"
          description="Each output tells the workflow which destination to send transformed data to, in the specified output structure."
          disabledTooltipText=""
          editView={
            <>
              <OutputEditSectionHeader />
              <OutputEditView
                fieldValues={fieldValues}
                setFieldValue={setFieldValue}
                workflow={workflow}
                onSave={() => saveStage(OUTPUT)}
                stageState={stageState[OUTPUT]}
                loadNextStageCreation={() => {
                  setEditingStage(INPUT);
                }}
              />
            </>
          }
          summaryView={
            <>
              <OutputEditSectionHeader />
              <OutputSummaryView workflow={workflow} />
            </>
          }
          onActionClick={() => onStageEdit(OUTPUT)}
        />
        <Fusion20WorkflowStage
          stageState={stageState[INPUT]}
          stageNumber={2}
          title="Configure inputs"
          description="Each input tells the workflow which source to ingest data from and which templates to apply to transform it."
          disabledTooltipText="Complete step 01 to unlock this step"
          editView={
            <>
              <InputEditSectionHeader />
              <InputEditView
                fieldValues={fieldValues}
                setFieldValue={setFieldValue}
                workflow={workflow}
                onSave={() => saveStage(INPUT)}
                stageState={stageState[INPUT]}
                loadNextStageCreation={() => {
                  setEditingStage(TRIGGER);
                }}
              />
            </>
          }
          summaryView={
            <>
              <InputEditSectionHeader />
              <InputSummaryView workflow={workflow} />
            </>
          }
          onActionClick={() => onStageEdit(INPUT)}
        />
        <Fusion20WorkflowStage
          stageState={stageState[TRIGGER]}
          stageNumber={3}
          title="Configure triggers"
          description="Each trigger tells the workflow how and when to start a run to transform input data into output data and send onwards."
          disabledTooltipText="Complete step 02 to unlock this step"
          editView={
            <>
              <TriggerEditSectionHeader />
              <TriggerEditView
                onSave={() => saveStage(TRIGGER)}
                workflow={workflow}
                loadStageCreation={() => {
                  setEditingStage(TRIGGER);
                }}
              />
            </>
          }
          summaryView={
            <>
              <TriggerEditSectionHeader />
              <TriggerSummaryView fieldValues={fieldValues} workflow={workflow} stageState={stageState} />
            </>
          }
          onActionClick={() => onStageEdit(TRIGGER)}
        />
      </div>
    </>
  );
};

export { ConfigureWorkflowStages };
