import { useEffect, useContext } from "react";
import { Button, Text } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { DEFAULT_REPORT_OBJECT, WORKFLOW_STAGES } from "utils/workflows/enums";
import { OutputEditSection } from "./OutputEditSection";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";

import { useExportSftpConnectionData } from "components/workflows/hooks/useExportSftpConnectionData";
import { useExportLookupTableData } from "components/workflows/hooks/useExportLookupTableData";
import { useExportEmailConnectionData } from "components/workflows/hooks/useExportEmailConnectionData";
import { useExportReconciliationData } from "components/workflows/hooks/useExportReconciliationData";
import { OutputSection } from "./OutputSection";
import { WorkflowConfigurationContext } from "../../ConfigureWorkflow";

const OutputEditView = observer(({ workflow, loadNextStageCreation }) => {
  const { outputMappingsStore, workflowsStore } = useStore();

  const { teamId, editingStage, onStageComplete } = useContext(WorkflowConfigurationContext);

  const reports = workflow.getCurrentWorkflowReports();
  const reportUuid = uuidv4();

  useEffect(() => {
    if (reports.length === 0) {
      workflow.addNewCurrentWorkflowReport({
        ...DEFAULT_REPORT_OBJECT,
        ...{ reportName: "Output_".concat(workflow.getCurrentWorkflowReports().length + 1), reportUuid: reportUuid },
      });
      workflowsStore.addEditableOutput(reportUuid);
    }
  }, []);

  useEffect(() => {
    loadOutputMappings();
  }, []);

  const sftpData = useExportSftpConnectionData();
  const emailData = useExportEmailConnectionData();
  const lookupData = useExportLookupTableData();
  const recData = useExportReconciliationData(teamId);

  const loadOutputMappings = async () => {
    outputMappingsStore.loadApprovedOutputMappings({ teamId });
  };

  const checkIfSaveAndExitSetupDisabled = () => {
    if (workflowsStore.getReadOnlyWorkflow()) {
      return workflowsStore.getReadOnlyWorkflowReports().length === 0;
    } else {
      return true;
    }
  };

  return (
    <>
      {reports.map((report) => {
        return (
          <OutputSection report={report} workflow={workflow} editingStage={editingStage}>
            <OutputEditSection
              report={report}
              emailData={emailData}
              lookupData={lookupData}
              loadOutputMappings={loadOutputMappings}
              sftpData={sftpData}
              recData={recData}
            />
          </OutputSection>
        );
      })}
      <div className="d-flex flex-row-reverse mb-24 mt-48">
        <Button
          size="md"
          color="primary"
          onClick={() => {
            workflowsStore.clearEditableOutputs();
            onStageComplete(WORKFLOW_STAGES.OUTPUT);
            loadNextStageCreation();
          }}
          disabled={checkIfSaveAndExitSetupDisabled()}
        >
          <Text size="sm" weight="medium">
            Close step
          </Text>
        </Button>
      </div>
    </>
  );
});

export { OutputEditView };
