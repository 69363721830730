import React, { useContext } from "react";

import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useSelect } from "downshift";

import "./styles.css";

import HeaderSelectorButton from "./HeaderSelectorButton.js";

import { LumsContext } from "./LumsCommand";

export default function MapColumn(props) {
  const { usedLookupTableColumns, inputFileColumns, mapping, mappingIndex } =
    props;

  const lumsContext = useContext(LumsContext);

  const {
    initialState,
    lookupTable,
    mappedLookupTableColumns,
    setMappedLookupTableColumns,
  } = lumsContext;

  const { columnIndex, lookupColumnName } = mapping;

  const inputFileColumn = columnIndex == -1 || inputFileColumns[columnIndex];

  const inputFileColumnName =
    columnIndex == -1 ? "Select Column" : inputFileColumn.name;

  const availableLookupTableColumns = lookupTable.columns.reduce(
    (columns, column) => {
      const { name } = column;

      if (
        name === lookupColumnName ||
        usedLookupTableColumns.indexOf(name) === -1
      ) {
        columns.push(column);
      }

      return columns;
    },
    []
  );

  const allMapped =
    mappedLookupTableColumns.length === lookupTable.columns.length;

  const last = mappingIndex === mappedLookupTableColumns.length - 1;

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items: availableLookupTableColumns,
      onSelectedItemChange: (changes) => {
        const { selectedItem: lookupColumn } = changes;

        const { name } = lookupColumn;

        const newMapping = { ...mapping, lookupColumnName: name };

        mappedLookupTableColumns[mappingIndex] = newMapping;

        setMappedLookupTableColumns([...mappedLookupTableColumns]);
      },
    });

  return (
    <Grid container key={mappingIndex}>
      <Grid item xs>
        <TextField
          className="input long"
          value={inputFileColumnName || ""}
          placeholder="Select Column"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />

        <HeaderSelectorButton
          onSelect={(indexedValue) => {
            const { index: columnIndex } = indexedValue;

            const columnIndexWithoutOutputColumns =
              columnIndex - initialState.outputColumns.length;

            if (columnIndexWithoutOutputColumns < 0) {
              return;
            }

            const newMapping = {
              ...mapping,
              columnIndex: columnIndexWithoutOutputColumns,
            };

            mappedLookupTableColumns[mappingIndex] = newMapping;

            setMappedLookupTableColumns([...mappedLookupTableColumns]);
          }}
        />
      </Grid>

      <Grid item xs={1}>
        <span className="icon">
          <ArrowForwardIcon />
        </span>
      </Grid>

      <Grid item xs>
        <Grid container>
          <Grid item xs={8}>
            <span className="select-container">
              <TextField
                className="select-input long"
                value={lookupColumnName || ""}
                placeholder="Select Column"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...getToggleButtonProps()}
              />

              <span className="select-list-2">
                <List disablePadding {...getMenuProps()}>
                  {isOpen &&
                    availableLookupTableColumns.map((column, index) => {
                      const { name } = column;

                      return (
                        <ListItem
                          button
                          key={index}
                          {...getItemProps({ item: column, index })}
                        >
                          <ListItemText primary={name} />
                        </ListItem>
                      );
                    })}
                </List>
              </span>
            </span>
          </Grid>

          <Grid item xs={1}>
            <span className="icon">
              <IconButton
                onClick={() => {
                  mappedLookupTableColumns.splice(mappingIndex, 1);
                  setMappedLookupTableColumns([...mappedLookupTableColumns]);
                }}
                disabled={mappedLookupTableColumns.length <= 1}
              >
                <ClearIcon />
              </IconButton>
            </span>
          </Grid>

          <Grid item xs={1}>
            {last && !allMapped && (
              <span className="icon">
                <IconButton
                  color="primary"
                  onClick={() => {
                    mappedLookupTableColumns.push({
                      columnIndex: -1,
                      lookupColumnName: "",
                    });
                    setMappedLookupTableColumns([...mappedLookupTableColumns]);
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
