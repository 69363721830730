import React from "react";
import { Text } from "@fundrecs/ui-library";

import styles from "./ActionButton.module.scss";

const ActionButton = ({ title, subtitle, onClick, IconComponent, disabled }) => (
  <button className={[`${styles.container}`, "flex-fill"].join(" ")} disabled={disabled} onClick={onClick}>
    <IconComponent className={styles.icon} />
    <span className={styles.text}>
      <Text size="sm" variant="secondary">
        {title}
      </Text>
      <Text size="xs" variant="tertiary" weight="regular">
        {subtitle}
      </Text>
    </span>
  </button>
);

export { ActionButton };
