import { observer } from "mobx-react-lite";
import { Button, Text, modalInstance } from "@fundrecs/ui-library";
import { rolesStore } from "store/Store";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { MODAL_IDS } from "utils/workflows/enums";
import { useTeamId } from "store/hooks/useTeamId";

const RemoveSelectedFilesButton = observer(({ checkDisabled }) => {
  const teamId = useTeamId();

  return (
    <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_EDIT])}>
      <Button
        size="md"
        color="tertiary"
        disabled={checkDisabled}
        onClick={() => {
          modalInstance(MODAL_IDS.REMOVE_WORKFLOW_PENDING_FILE).toggle();
        }}
      >
        <Text size="sm">Remove selected files</Text>
      </Button>
    </AuthWrapper>
  );
});

export { RemoveSelectedFilesButton };
