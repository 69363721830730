import { WarningConfirmationModal } from "@fundrecs/ui-library";

const DeleteWorkflowModal = ({ modalId, workflow, handleDeleteWorkflow }) => {
  const { name = "", uuid = "", teamId = "" } = workflow;

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading={`Are you sure you want to delete workflow ${name} ?`}
      text="By deleting this workflow you will not receive generated reports. This action cannot be undone."
      cancel="Cancel"
      confirm="Delete workflow"
      onConfirm={() => {
        handleDeleteWorkflow(teamId, uuid, name);
      }}
    />
  );
};

export { DeleteWorkflowModal };
