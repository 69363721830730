import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Text,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  TeamAvatar,
  ItemBody,
  Input,
  SelectedIcon,
} from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { MODAL_IDS } from "utils/templates/enums";
import { ROUTES, FILE_SIZE } from "utils/enums";
import styles from "./ShareTemplateModal.module.scss";

const ShareTemplateModal = ({ shareTemplateUuid }) => {
  const { meStore, uiStore, outputMappingsStore, tbStore, templatesStore } = useStore();
  const teamId = useTeamId();

  const defaultState = {
    selectedTeam: null,
    selectedTeamIndex: null,
    shareWithTeam: false,
    requesting: false,
    outputMappings: [],
    selectedOutputMapping: null,
    selectedOutputMappingIndex: null,
    fileS3Urns: null,
    file: null,
  };

  const [stateObject, setStateObject] = useState(defaultState);

  const { selectedTeam, selectedTeamIndex, shareWithTeam, requesting, outputMappings, selectedOutputMapping, selectedOutputMappingIndex, fileS3Urns, file } =
    stateObject;

  const inputRef = useRef(null);

  const dataTransfer = new DataTransfer();

  useEffect(() => {
    const fetchData = async () => {
      const { success, data } = await tbStore.getResultAndStack({ teamId, pos: "-1", appliedFileName: "", templateUuid: shareTemplateUuid });
      if (success) {
        const { allFileS3Urns } = data;
        setStateObject({ ...stateObject, fileS3Urns: allFileS3Urns });

        addSampleInputFile(allFileS3Urns);
      }
    };

    shareTemplateUuid && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareTemplateUuid, teamId, tbStore]);

  useEffect(() => {
    const fetchData = async () => {
      const { success, data } = await outputMappingsStore.loadApprovedOutputMappings({ teamId: selectedTeam?.id });
      if (success) {
        setStateObject({ ...stateObject, outputMappings: data });
      }
    };

    selectedTeam && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  const addSampleInputFile = (allFileS3Urns) => {
    const [[fileName]] = allFileS3Urns;
    const myFile = new File([""], fileName, {
      type: "text/plain",
      lastModified: new Date(),
    });

    dataTransfer.items.add(myFile);
    inputRef.current.files = dataTransfer.files;
  };

  const handleFileChange = (event) => {
    const {
      target: {
        files: [uploadedFile],
      },
    } = event;

    if (uploadedFile) {
      setStateObject({ ...stateObject, file: uploadedFile });
    } else {
      addSampleInputFile(fileS3Urns);
      setStateObject({ ...stateObject, file: null });
    }
  };

  const successToastWithTeamLink = (team) => {
    const { name, id } = team;
    return (
      <>
        {`Template successfully shared with ${name} team workspace`}
        <br />
        <Link className={styles.linkStyle} to={`${ROUTES.TEMPLATES}?teamId=${id}`}>
          Go to team workspace
        </Link>
      </>
    );
  };

  const handleShare = () => {
    setStateObject({ ...stateObject, requesting: true });

    const notificationId = uiStore.addNotification("status", `Sharing template with ${selectedTeam.name} team workspace...`);

    setTimeout(async () => {
      uiStore.removeNotification(notificationId);
      const { uuid } = selectedOutputMapping;
      const { id } = selectedTeam;
      const [[fileName, fileS3Urn]] = fileS3Urns;

      const requestBody = { tmoUuid: uuid, targetTeamId: id, ...(file ? { file: file } : { fileName: fileName, fileS3Urn: fileS3Urn }) };

      const response = await templatesStore?.shareTemplate(teamId, shareTemplateUuid, selectedTeam, requestBody, successToastWithTeamLink(selectedTeam));
      const { success } = response;
      if (success) {
        !shareWithTeam && modalInstance(MODAL_IDS.SHARE_TEMPLATE).toggle();
        setStateObject({ ...defaultState, file: file, fileS3Urns: fileS3Urns });
      } else {
        setStateObject({ ...stateObject, requesting: false });
      }
    }, 2000);
  };

  const fileIsInvalid = file && file.size > FILE_SIZE.MAX_BYTES;

  return (
    <Modal modalId={MODAL_IDS.SHARE_TEMPLATE} size="lg">
      <ModalBody>
        <div className="w-100 pb-24">
          <div className="d-flex">
            <ModalHeader heading="Share template" />
            <button
              type="button"
              className="btn-close ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setStateObject(defaultState);
                modalInstance(MODAL_IDS.SHARE_TEMPLATE).hide();
              }}
            />
          </div>
          <div className="pt-12 pb-8">
            <Text variant="primary" weight="medium" size="sm">
              Select team workspace to share template with
            </Text>
          </div>
          <div>
            <Dropdown>
              <DropdownButton size="lg">
                <DropdownButtonText>
                  <ItemBody>
                    <>
                      {selectedTeam === null ? (
                        <Text size="sm" weight="regular">
                          Select
                        </Text>
                      ) : (
                        <div className="d-flex">
                          <TeamAvatar text={selectedTeam?.name} size="xs" />
                          <div className="ml-12">
                            <Text size="sm" weight="medium">
                              {selectedTeam?.name}
                            </Text>
                          </div>
                        </div>
                      )}
                    </>
                  </ItemBody>

                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                {meStore?.getTeams()?.map((team, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setStateObject({
                          ...stateObject,
                          selectedTeam: team,
                          selectedTeamIndex: index,
                          outputMappings: [],
                          selectedOutputMapping: null,
                          selectedOutputMappingIndex: null,
                        });
                      }}
                    >
                      <DropdownItem active={selectedTeamIndex} index={index}>
                        <TeamAvatar text={team?.name} size="xs" />
                        <div className="align-self-center ml-12 width-95-pc">
                          <Text size="sm" weight="medium">
                            {team?.name}
                          </Text>
                        </div>
                        <SelectedIcon active={selectedTeamIndex} index={index} size="sm" />
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
          <div className="pt-24 pb-8">
            <Text variant="primary" weight="medium" size="sm">
              Select output mapping from team workspace for template
            </Text>
          </div>
          <div>
            <Dropdown>
              <DropdownButton size="lg">
                <DropdownButtonText>
                  <ItemBody>
                    <>
                      {selectedOutputMapping === null ? (
                        <Text size="sm" weight="regular">
                          Select
                        </Text>
                      ) : (
                        <div className="d-flex">
                          <div>
                            <Text size="sm" weight="medium">
                              {selectedOutputMapping?.name}
                            </Text>
                          </div>
                        </div>
                      )}
                    </>
                  </ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                {outputMappings.map((outputMapping, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setStateObject({ ...stateObject, selectedOutputMapping: outputMapping, selectedOutputMappingIndex: index });
                      }}
                    >
                      <DropdownItem active={selectedOutputMappingIndex} index={index}>
                        <div className="align-self-center width-95-pc">
                          <Text size="sm" weight="medium">
                            {outputMapping?.name}
                          </Text>
                        </div>
                        <SelectedIcon active={selectedOutputMappingIndex} index={index} size="sm" />
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
          <div className="pt-24">
            <Text size="sm" weight="medium" variant="secondary">
              Upload sample input file for template
            </Text>
          </div>
          <div className="pt-8">
            <Input onChange={handleFileChange} type="file" size="md" innerRef={inputRef} warningMessage={FILE_SIZE.MAX_WARNING} warning={fileIsInvalid} />
          </div>
        </div>
      </ModalBody>
      <div className={["d-flex", `${styles.modalFooter}`].join(" ")}>
        <div className="d-flex w-100">
          <input
            className="form-check-input mt-8"
            type="checkbox"
            checked={shareWithTeam}
            onChange={() => setStateObject({ ...stateObject, shareWithTeam: !shareWithTeam })}
          />
          <div className="align-self-center ml-8">
            <Text size="sm" weight="regular">
              Share with another team workspace
            </Text>
          </div>
        </div>
        <div className="d-flex flex-shrink-1 bd-highlight">
          <Button
            color="tertiary"
            onClick={() => {
              setStateObject(defaultState);
              modalInstance(MODAL_IDS.SHARE_TEMPLATE).toggle();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <span className="ml-12">
            <Button
              color="primary"
              onClick={() => {
                handleShare();
              }}
              disabled={[selectedOutputMapping, selectedTeam].includes(null) || requesting === true}
            >
              <Text size="sm">Share</Text>
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export { ShareTemplateModal };
