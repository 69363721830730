import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  Text,
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter,
  Button,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownList,
  DropdownItem,
  DropdownListItem,
  Radio,
} from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import { useStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import { getWorkflowReportsOutputMappingUuids, replaceTemplateRule } from "components/workflows/reusable";
import { ConditionsList } from "components/workflows/ConfigureWorkflow/stages/input/conditions/ConditionsList";
import styles from "./editTemplate.module.scss";

const EditTemplate = observer(({ propsData, getImportRules, clearData, importType }) => {
  const { data = {} } = propsData || {};
  const { workflowName = "", ruleIndex, templateUuid, workflowUuid = null, rule = {}, importRuleName, importRuleUuid } = data;
  const { criteria = [], type = "OR" } = rule;

  const initialState = {
    criteria: [
      {
        paramName: "filename",
        operator: "string_contains",
        paramValue: "",
      },
    ],
    type: "OR",
  };

  const checkExistingRules = () => {
    if (criteria.length > 0) {
      showRules(true);
      return {
        criteria: criteria,
        type: type,
      };
    } else {
      return initialState;
    }
  };

  const [workflow, setWorkflow] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [templateIndex, setTemplateIndex] = useState(null);

  const [rules, showRules] = useState(false);
  const { workflowsStore, templatesStore } = useStore();

  const teamId = useTeamId();

  const [state, setState] = useState({ ...initialState });

  const dispatch = (object) => {
    setState({ ...state, ...object });
  };

  useEffect(() => {
    const fetchWorkflow = async () => {
      if (workflowUuid) {
        const { data, success } = await workflowsStore.getWorkflowByUuidAndTeamId(workflowUuid, teamId);
        if (success) {
          setWorkflow({ ...data });
        }
      }
    };

    fetchWorkflow();
  }, [data]);

  useEffect(() => {
    if (workflow !== null) {
      const outputMappingsUuids = getWorkflowReportsOutputMappingUuids(workflow);
      if (outputMappingsUuids.length > 0) {
        loadTemplatesLinkedToOutputMappings(teamId, outputMappingsUuids);
      } else {
        setTemplateIndex(null);
        setTemplate(null);
        setTemplates([]);
      }
    }
  }, [workflow]);

  useEffect(() => {
    if (criteria.length > 0) {
      document.getElementById("meet_edit").checked = true;
      showRules(true);
    } else {
      document.getElementById("all_edit").checked = true;
    }
  }, [criteria]);

  useEffect(() => {
    if (rules) {
      setState({ ...checkExistingRules() });
    }
  }, [rules]);

  useEffect(() => {
    if (templateUuid !== null && templates.length > 0) {
      const templateIndex = templates.findIndex((template) => {
        return template.templateUuid === templateUuid;
      });

      const template = templates.filter((template) => {
        return template.templateUuid === templateUuid;
      });

      template.length > 0 && setTemplate(template[0]);
      templateIndex && setTemplateIndex(templateIndex);
    }
  }, [templates, templateUuid]);

  const loadTemplatesLinkedToOutputMappings = async (teamId, outputMappingsUuids) => {
    const { success, data } = await templatesStore.loadTemplatesLinkedToOutputMappings(teamId, outputMappingsUuids);
    if (success) {
      setTemplates(data);
    }
  };

  const handleClose = () => {
    reset();
    getImportRules();
    modalInstance(MODAL_IDS.EDIT_TEMPLATE_IMPORT.concat(importType)).hide();
  };

  const handleAddRule = async () => {
    if (rules) {
      const newTemplate = {
        priority: null,
        ...state,
        templateUuid: template.templateUuid,
        type: "OR",
      };

      const modifiedWorkflow = replaceTemplateRule(workflow, ruleIndex, importRuleUuid, newTemplate);
      const { success } = await workflowsStore.addWorkflowTemplate(modifiedWorkflow, teamId, workflowUuid);

      if (success) {
        handleClose();
      }
    } else {
      const newTemplate = {
        priority: null,
        templateUuid: template.templateUuid,
        type: "MATCH_ALL",
      };

      const modifiedWorkflow = replaceTemplateRule(workflow, ruleIndex, importRuleUuid, newTemplate);

      const { success } = await workflowsStore.addWorkflowTemplate(modifiedWorkflow, teamId, workflowUuid);

      if (success) {
        handleClose();
      }
    }
  };

  const reset = () => {
    setWorkflow(null);
    setTemplate(null);
    setTemplates([]);
    setTemplateIndex(null);
    showRules(false);
    setState(initialState);
    clearData({});
    document.getElementById("all").checked = true;
  };

  const returnConditionsList = () => {
    if (rules) {
      return <ConditionsList state={state} dispatch={dispatch} />;
    }
  };

  return (
    <Panel panelId={MODAL_IDS.EDIT_TEMPLATE_IMPORT.concat(importType)}>
      <PanelBody>
        <PanelHeader
          description={
            <span>
              Map the template you want applied to the <b>{workflowName}</b> source
            </span>
          }
          header="Edit template"
          onClick={() => {
            reset();
            modalInstance(MODAL_IDS.EDIT_TEMPLATE_IMPORT.concat(importType)).hide();
          }}
        />

        <div className="pb-24 pt-12">
          <div className="mb-8">
            <Text size="sm" weight="medium">
              Choose workflow the template should belong to
            </Text>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md" disabled={true}>
                <DropdownButtonText>
                  <ItemBody>{<>{workflowName}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
            </Dropdown>
          </div>
        </div>

        <div className="pb-12">
          <div className="mb-8">
            <Text size="sm" weight="medium">
              Choose input the template should belong to
            </Text>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md" disabled={true}>
                <DropdownButtonText>
                  <ItemBody>{<>{importRuleName}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                <DropdownListItem key={0} onClick={() => {}}>
                  <DropdownItem active={0} index={0}>
                    <ItemBody>{importRuleName}</ItemBody>
                  </DropdownItem>
                </DropdownListItem>
              </DropdownList>
            </Dropdown>
          </div>
        </div>

        <div className="pb-12">
          <div className="mb-8">
            <Text size="sm" weight="medium">
              Choose template to apply to incoming data
            </Text>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md">
                <DropdownButtonText>
                  <ItemBody>{<>{template === null ? "Select" : template.name}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {templates.map((templateEntity, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setTemplate(templateEntity);
                        setTemplateIndex(index);
                      }}
                    >
                      <DropdownItem active={templateIndex} index={index}>
                        <ItemBody>{templateEntity.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>

        <div className="pt-0 pb-12 pl-0 pr-32">
          <div className="pt-12">
            <Text size="sm" weight="medium" variant="secondary">
              Input data to apply this template to:
            </Text>
          </div>

          <div className="pt-20 d-flex">
            <Radio
              name="apply"
              value="all"
              id="all_edit"
              onClick={() => {
                document.getElementById("all_edit").checked = true;
                showRules(false);
              }}
            />
            <div className="pl-12">
              <div className="pt-4">
                <Text variant="primary" size="sm" weight="regular" element="div">
                  Apply this template to <b>all</b> incoming files
                </Text>
              </div>
            </div>
          </div>
          <div className="pt-20 d-flex">
            <Radio
              name="apply"
              value="meet"
              id="meet_edit"
              onClick={() => {
                showRules(true);
              }}
            />
            <div className="pl-12">
              <div className="pt-4">
                <Text variant="primary" size="sm" weight="regular" element="div">
                  Apply this template to files that <b>meet</b> certain conditions
                </Text>
              </div>
            </div>
          </div>
          {returnConditionsList()}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={handleAddRule} size="md" disabled={template === null}>
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});
export { EditTemplate };
