import React, { Component } from "react";

class Preview extends Component {
  example({ type, value }) {
    if (type === "-") {
      return "-";
    } else if (type === ".") {
      return ".";
    } else if (type === "/") {
      return "/";
    } else if (type === "_") {
      return "_";
    } else if (type === "customText") {
      return value;
    } else if (type === "date") {
      return "20200928";
    } else if (type === "t-1Date") {
      return "20200927";
    } else if (type === "t-NDate") {
      return "20200922";
    } else if (type === "year") {
      return "2020";
    } else if (type === "month") {
      return "09";
    } else if (type === "day") {
      return "28";
    } else if (type === "hour") {
      return "16";
    } else if (type === "minute") {
      return "42";
    } else if (type === "second") {
      return "59";
    } else if (type === "millisecond") {
      return "862";
    } else if (type === "originalFileName") {
      return "OriginalFileName_2020-09-09-15-48-02-473_8225561767697838385";
    } else if (type === "originalFileExtension") {
      return ".xlsx";
    } else if (type === "originalFolder") {
      return "MyFolder";
    } else if (type === "workflow") {
      return "MyWorkflow";
    } else if (type === "team") {
      return "MyTeam";
    } else if (type === "template") {
      return "MyTemplate";
    } else if (type === "randomDigits") {
      return "83964341";
    } else if (type === "firstCellOfColumn") {
      return value;
    } else if (type === "uuid") {
      return "4f551251-fd72-4e70-9b76-6f3f7469f9a5ran";
    } else {
      return "";
    }
  }

  render() {
    const { expressions, format } = this.props;

    return <span>{expressions.map((expression) => this.example(expression)).join("") + (format ? format : "")}</span>;
  }
}

export default Preview;
