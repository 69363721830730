import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Text, WarningConfirmationModal } from "@fundrecs/ui-library";
import { lookupsStore } from "store/Store";

const DeleteLookupTableModal = ({ modalId, lookup }) => {
  const { name, description, teamId } = lookup || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const tableId = searchParams.get("tableId");

  const handleConfirmation = async () => {
    const { success } = await lookupsStore.deleteTable(teamId, lookup);
    if (success) {
      if (tableId) {
        // Remove tableId to go back to Lookups List
        searchParams.delete("tableId");
        setSearchParams(searchParams);
      }

      lookupsStore.removeLookupTable(lookup);
    }
  };

  return (
    <WarningConfirmationModal
      modalId={modalId}
      heading="Are you sure you want to delete this Lookup?"
      text="This action will affect all workflows using this lookup table and cannot be undone."
      body={
        <div className="pb-24">
          <Text element="div" size="sm" variant="primary" weight="bold">
            {name}
          </Text>
          <Text element="div" size="xs" variant="secondary" weight="regular">
            {description}
          </Text>
        </div>
      }
      cancel="Cancel"
      confirm="Delete lookup"
      onConfirm={() => handleConfirmation()}
    />
  );
};

// Specifies the types for props:
DeleteLookupTableModal.propTypes = {
  modalId: PropTypes.string,
  lookup: PropTypes.object,
};

// Specifies the default values for props:
DeleteLookupTableModal.defaultProps = {
  modalId: "",
  lookup: {},
};

export { DeleteLookupTableModal };
