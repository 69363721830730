import React from "react";
import "./structured-table.css";
import "../../TbApp.css";
import { fundsDataTableChannel } from "../../utils/communication/AppChannels";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
export default class FundsDataView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.tableHeaders = this.tableHeaders.bind(this);
    this.tableBody = this.tableBody.bind(this);
    this.tableInfoArray = this.tableInfoArray.bind(this);
    this.handleHeadersClick = this.handleHeadersClick.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
  }

  render() {
    return (
      <div className="structure-table">
        <Paper className="infoArray">{this.tableInfoArray()}</Paper>
        <table id="structured-data-table">
          <thead id="structured-data-table-header">
            <tr>{this.tableHeaders()}</tr>
          </thead>
          <tbody onClick={this.handleBodyClick}>{this.tableBody()}</tbody>
        </table>
      </div>
    );
  }

  tableHeaders() {
    let headers = this.props.commandResult.fundsData.headers;

    if (headers == null) <th key={0}>No Headers Found</th>;
    let headersHtml = headers.map((header, indx) => {
      return (
        <th className="structured-data-table-header-cell" title={header} key={indx} onClick={this.handleHeadersClick}>
          {header}
        </th>
      );
    });
    return headersHtml;
  }

  tableBody() {
    let rowsToShow = this.props.commandResult.fundsData.rows;

    let colTypeList = [this.props.commandResult.fundsData.headers.length];
    for (let k = 0; k < this.props.commandResult.fundsData.headers.length; k++) {
      colTypeList[k] = "StringType";
    }

    if (this.props.templateColumns != null) {
      for (let c = 0; c < this.props.templateColumns.length; c++) {
        let pos = this.props.templateColumns[c].columnIndex;
        if (pos >= 0) {
          colTypeList[pos] = this.props.templateColumns[c].type;
        }
      }
    }
    let error = "";
    let bodyHtml = rowsToShow.map((row, index) => {
      let cells = row.parsedRow.map((cell, i) => {
        return (
          <td title={cell} className="structured-table-cell font-100 font-small" key={i}>
            {cell}
          </td>
        );
      });

      return <tr key={index}>{cells}</tr>;
    });

    return bodyHtml;
  }

  tableInfoArray() {
    let infoArray = this.props.commandResult.infoArray;
    let infoHtml = infoArray.map((infoStringValue, index) => {
      return (
        <Button primary key={index}>
          {infoStringValue}
        </Button>
      );
    });
    return infoHtml;
  }

  handleBodyClick(event) {
    fundsDataTableChannel.publish("bodyClicked", event.target.textContent);
  }

  handleHeadersClick(event) {
    fundsDataTableChannel.publish("headerClicked", {
      event: event,
      parentId: this.props.parentId,
    });
  }
}
