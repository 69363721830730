import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Text, Panel, PanelBody, PanelHeader, PanelFooter, Button, modalInstance } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import { ConditionsList } from "../conditions/ConditionsList";

const AddInclusionRulePanel = observer(({ conditionObject, setInclusionRules, setEditableRuleIndex, importRuleUuid }) => {
  const initialState = {
    criteria: [
      {
        paramName: "filename",
        operator: "string_contains",
        paramValue: "",
      },
    ],
    type: "OR",
  };

  useEffect(() => {
    if (Object.keys(conditionObject).length > 0) {
      setState(conditionObject);
    }
  }, [conditionObject]);

  const [state, setState] = useState({ ...initialState });
  const dispatch = (object) => {
    setState({ ...state, ...object });
  };
  const handleClose = () => {
    dispatch(initialState);
    setEditableRuleIndex(null);
    modalInstance(MODAL_IDS.ADD_INCLUSION_RULE.concat(importRuleUuid)).hide();
  };
  const handleAddRule = () => {
    setInclusionRules(state);
    handleClose();
  };

  const checkMultipleDisabledConditions = (state) => {
    if (state.criteria) {
      if (!state.criteria.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <Panel panelId={MODAL_IDS.ADD_INCLUSION_RULE.concat(importRuleUuid)}>
      <PanelBody>
        <PanelHeader
          description="You can apply this template to input files that matches certain conditions by defining them here"
          header="Add import rule"
          onClick={() => {
            dispatch(initialState);
          }}
        />
        <div className="pt-0 pb-12 pl-0 pr-32">
          <div className="pt-12">
            <Text size="sm" weight="medium" variant="secondary">
              Apply this template to files that match
            </Text>
          </div>
          <ConditionsList state={state} dispatch={dispatch} />
        </div>
      </PanelBody>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={handleAddRule} size="md" disabled={checkMultipleDisabledConditions(state)}>
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});
export { AddInclusionRulePanel };
