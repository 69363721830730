import { Heading, R, C, Text, Button, EmptySftpConnections, modalInstance } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { MODAL_IDS } from "utils/workflows/enums";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";

const NoSftpDirectories = () => {
  const { tenantConfigStore, meStore } = useStore();
  const fusionAdminUrl = tenantConfigStore.getFusionAdminUrl();
  const loggedInUser = meStore.getMe();
  const { superAdmin = false } = loggedInUser;

  const pointerStyle = {
    pointerEvents: "auto",
  };

  const handleSourceConnectionCreate = () => {
    if (superAdmin) {
      window.open(fusionAdminUrl.concat("/import/sftpmapping"), "_blank");
    } else {
      handleConfirmModal();
    }
  };

  const handleConfirmModal = () => {
    modalInstance(MODAL_IDS.ERROR_TEMPLATE_IMPORT).toggle();
  };

  return (
    <>
      <ErrorModal
        modalId={MODAL_IDS.ERROR_TEMPLATE_IMPORT}
        heading="Sorry, you do not have permissions to create a new SFTP connection!"
        text="Please contact your organisation administrator or the Fusion team for support."
        confirm="Ok"
        onConfirm={() => {}}
      />
      <R>
        <span className="emptyState-icon">
          <EmptySftpConnections />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No sources available
          </Heading>
        </C>
      </R>
      <>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                You can create and manage sources from the Admin Console in Fusion. Sources are responsible for importing data into Fusion that is then
                transformed by workflows and their assigned templates.
              </Text>
            </div>
          </C>
        </R>
        <R>
          <C>
            <div className="pt-32 pb-32" style={pointerStyle}>
              <Button
                className={pointerStyle}
                onClick={() => {
                  handleSourceConnectionCreate();
                }}
                size="md"
              >
                <Text size="sm">Manage sources</Text>
              </Button>
            </div>
          </C>
        </R>
      </>
    </>
  );
};

export { NoSftpDirectories };
