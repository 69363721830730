import { useStore } from "../../../../../store/Store";
import { OutputSection } from "./OutputSection";
import { useFusion20WorkflowState } from "../../useFusion20WorkflowState";

const OutputSummaryView = ({ workflow }) => {
  const { workflowsStore } = useStore();
  const reports = workflowsStore.getReadOnlyWorkflowReports();
  const [editingStage] = useFusion20WorkflowState(workflow);

  return (
    <>
      {reports.map((report) => {
        return <OutputSection report={report} workflow={workflow} editingStage={editingStage} />;
      })}
    </>
  );
};

export { OutputSummaryView };
