import { Text, Heading } from "@fundrecs/ui-library";

const SelectMethodHeading = ({ heading, description, align }) => {
  return (
    <>
      <div style={{ textAlign: align }}>
        <Heading element="span" variant="h3">
          {heading}
        </Heading>
        <div>
          <Text weight="regular">{description}</Text>
        </div>
      </div>
    </>
  );
};

export { SelectMethodHeading };
