import _ from "lodash";

import { createContext, useState, useMemo } from "react";
import { tbAppChannel } from "../../utils/communication/AppChannels";
import CommandArgsModel from "../../utils/domain/CommandArgsModel";
import { Paper, Grid } from "@material-ui/core";

import SelectLookupType from "./SelectLookupType";
import SelectLookupTable from "./SelectLookupTable";
import MapColumns from "./MapColumns";
import SelectLookupTableColumns from "./SelectLookupTableColumns";
import SelectSearchType from "./SelectSearchType";
import OutputSection from "./OutputSection";
import Actions from "./Actions";

const LumsContext = createContext();

const emptyState = {
  lookupType: "equals",
  lookupTable: null,
  selectedLookupTableColumns: [],
  searchType: "all",
  mappedLookupTableColumns: [{ columnIndex: -1, lookupColumnName: "" }],
  outputColumns: [],
  returnOrder: "first",
};

function LumsCommand(props) {
  const {
    insertRule,
    updateRule,

    commandParams: {
      uiCommand,
      uiCommand: { dropDownKeyValuePair: lookupTables },
      userInputargs,
      isNew,
    },

    commandResultArray,
  } = props;

  const commandResult = commandResultArray[0];

  const {
    fundsData: { headers },
  } = commandResult;

  let inputState;

  if (!isNew) {
    const { lookupTableUuid } = userInputargs;

    const lookupTable = lookupTables.find((lookupTable) => lookupTable.uuid === lookupTableUuid);
    inputState = { ...userInputargs, lookupTable };
  }

  const initialState = useMemo(() => (isNew ? emptyState : inputState), [userInputargs]);

  const [lookupType, setLookupType] = useState(initialState.lookupType);
  const [lookupTable, setLookupTable] = useState(initialState.lookupTable);
  const [selectedLookupTableColumns, setSelectedLookupTableColumns] = useState(
    initialState.selectedLookupTableColumns && initialState.selectedLookupTableColumns.length ? [...initialState.selectedLookupTableColumns] : []
  );
  const [searchType, setSearchType] = useState(initialState.searchType);
  const [mappedLookupTableColumns, setMappedLookupTableColumns] = useState([...initialState.mappedLookupTableColumns]);
  const [outputColumns, setOutputColumns] = useState([...initialState.outputColumns]);
  const [returnOrder, setReturnOrder] = useState(initialState.returnOrder);

  const [lookupTypeMenu, setLookupTypeMenu] = useState(false);
  const [lookupTableMenu, setLookupTableMenu] = useState(false);

  const headersWithoutOutputColumns = headers.slice(initialState.outputColumns.length);

  const inputFile = {
    columns: headersWithoutOutputColumns.map((header) => ({
      name: header,
    })),
  };

  const lumsContext = {
    initialState,
    inputFile,
    lookupTables,
    lookupType,
    lookupTable,
    selectedLookupTableColumns,
    searchType,
    mappedLookupTableColumns,
    outputColumns,
    returnOrder,
    setLookupType,
    setLookupTable,
    setSelectedLookupTableColumns,
    setSearchType,
    setMappedLookupTableColumns,
    setOutputColumns,
    setReturnOrder,
    lookupTypeMenu,
    lookupTableMenu,
    setLookupTypeMenu,
    setLookupTableMenu,
  };

  return (
    <LumsContext.Provider value={lumsContext}>
      <Paper className="command-params-root lums-view">
        <div className="section">
          <div className="section-info">
            <b>Create new columns from a lookup table</b>
          </div>
          <Grid container>
            <Grid item xs>
              <SelectLookupType
                onChange={() => {
                  setLookupTable(emptyState.lookupTable);
                  setSelectedLookupTableColumns([...emptyState.selectedLookupTableColumns]);
                  setSearchType(emptyState.searchType);
                  setMappedLookupTableColumns([...emptyState.mappedLookupTableColumns]);
                  setOutputColumns([...emptyState.outputColumns]);
                  setReturnOrder(emptyState.returnOrder);
                }}
              />
            </Grid>

            <Grid item xs>
              <SelectLookupTable
                onChange={() => {
                  setSelectedLookupTableColumns([...emptyState.selectedLookupTableColumns]);
                  setSearchType(emptyState.searchType);
                  setMappedLookupTableColumns([...emptyState.mappedLookupTableColumns]);
                  setOutputColumns([...emptyState.outputColumns]);
                  setReturnOrder(emptyState.returnOrder);
                }}
              />
            </Grid>
          </Grid>
        </div>

        {lookupTable && (
          <>
            {lookupType === "equals" && <MapColumns />}

            {lookupType === "search" && (
              <div className="section">
                <SelectLookupTableColumns />
                <Grid container>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    <SelectSearchType description="Match: " all="All" any="Any" info="" />
                  </Grid>
                </Grid>
              </div>
            )}

            {lookupType === "contains" && (
              <>
                <div className="section">
                  <SelectLookupTableColumns />
                </div>
                <div className="section">
                  <SelectSearchType
                    description="Select how a match should be made"
                    all="Match All Tags"
                    any="Match Any Tags"
                    info={
                      <span style={{ color: "#4154AF" }}>
                        <b>Choose match. </b>Choose whether a match should be made when input data matches either all or any of the tags within a row of the
                        lookup table
                      </span>
                    }
                  />
                </div>
              </>
            )}

            <OutputSection />
          </>
        )}

        <div className="section">
          <Actions
            onClear={() => {
              setLookupTypeMenu(false);
              setLookupType(emptyState.lookupType);
              setLookupTable(emptyState.lookupTable);
              setSelectedLookupTableColumns([...emptyState.selectedLookupTableColumns]);
              setSearchType(emptyState.searchType);
              setMappedLookupTableColumns([...emptyState.mappedLookupTableColumns]);
              setOutputColumns([...emptyState.outputColumns]);
              setReturnOrder(emptyState.returnOrder);
            }}
            onSaveLookup={() => {
              const newUserInputargs = {
                lookupTableUuid: lookupTable.uuid,
                lookupType,
                selectedLookupTableColumns,
                searchType,
                mappedLookupTableColumns,
                outputColumns,
                returnOrder,
              };

              const commandArgs = new CommandArgsModel({
                uiCommand,
                userInputargs: newUserInputargs,
                isNew,
              });

              tbAppChannel.publish("onCommandParamsChange", commandArgs);

              // the state in TbApp is updated via Postal.js and not via React, so when updateRule() is called,
              // the setState() in TbApp which would update CommandParams props has not been executed yet, so
              // CommandProps has stale state
              //
              // this kind of props manipulation is a huge no-no, but it is used in all onValueChange() functions,
              // so we just follow the pattern rather than rewriting the entire codebase
              //
              // an alternative would be wrapping the updateRule() into a setTimeout()

              props.commandParams.userInputargs = newUserInputargs;

              if (isNew) {
                insertRule();
              } else {
                updateRule();
              }
            }}
          />
        </div>
      </Paper>
    </LumsContext.Provider>
  );
}

export { LumsCommand, LumsContext };
