import { useState, useLayoutEffect } from "react";
import { observer } from "mobx-react-lite";
import { createSearchParams, useNavigate, useParams, useLocation } from "react-router-dom";
import { modalInstance, WarningConfirmationModal, R } from "@fundrecs/ui-library";
import { ModalHeader } from "../../layout/ModalHeader";
import { Step } from "../../reusable/step/step";
import { SelectOutputMappingMethod } from "./SelectOutputMappingMethod";
import { CreateOutputMapping } from "./CreateOutputMapping";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";
import { AddOutputMappingColumns } from "./AddOutputMappingColumns/AddOutputMappingColumns";
import { MODAL_IDS } from "../../../utils/workflows/enums";
import { HeaderButtons } from "./Header/HeaderButtons";
import { ROUTES } from "utils/enums";
import { useStore } from "store/Store";
import styles from "./CreateOutputMappingStage.module.scss";
import { MappingSettings } from "./Modals/MappingSettings";
import { DeleteMapping } from "./Modals/DeleteMapping";
import { useTeamId } from "store/hooks/useTeamId";

const SelectComponent = ({ activeStep, setActiveStep }) => {
  const { name } = activeStep;
  switch (name) {
    case "STEP 01":
      return <SelectOutputMappingMethod setActiveStep={setActiveStep} />;
    case "STEP 02":
      return <CreateOutputMapping setActiveStep={setActiveStep} />;
    case "STEP 03":
      return <AddOutputMappingColumns />;
    default:
      return <SelectOutputMappingMethod setActiveStep={setActiveStep} />;
  }
};

const CreateOutputMappingStage = observer(({ modalId, handleOutputMappingCreated, onConfirm, handleStepCompleted }) => {
  const { outputMappingsStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [render, setRender] = useState(false);

  const [activeStep, setActiveStep] = useState({ name: "STEP 01", index: 1 });

  const teamId = useTeamId();

  let { outputMappingUuid } = useParams();

  useLayoutEffect(() => {
    if (outputMappingUuid) {
      outputMappingsStore.getOutputMappingById({ teamId: teamId, id: outputMappingUuid }).then(({ data }) => {
        outputMappingsStore.setOutputMapping(data);
        setActiveStep({ name: "STEP 03", index: 3 });
        setRender(true);
      });
    } else {
      setActiveStep({ name: "STEP 01", index: 1 });
      setRender(true);
    }
  }, []);

  const closeModalAndResetFields = () => {
    modalInstance(MODAL_IDS.CONFIRM_CLOSE_CREATE_OUTPUT_MAPPING).toggle();
  };

  const getTitle = () => {
    const { name } = outputMappingsStore.getOutputMapping();

    if (name) {
      return name;
    } else return "New output mapping";
  };

  const getStatus = () => {
    const { status } = outputMappingsStore.getOutputMapping();
    if (status) {
      return status;
    }
  };
  const { name } = activeStep;
  const steps = [
    { name: "STEP 01", index: 1 },
    { name: "STEP 02", index: 2 },
  ];

  const resetOutputMapping = () => {
    outputMappingsStore.setColumns([]);
    outputMappingsStore.setOutputMapping({});
    onConfirm(setActiveStep({ name: "STEP 01", index: 1 }));
  };

  const confirmCloseModal = () => {
    const { pathname } = location;
    //Need to check if we are using the create output mapping from workflows or create output mapping screen.

    if (pathname.includes("mappings")) {
      resetOutputMapping();
      navigate({ pathname: ROUTES.OUTPUT_MAPPINGS, search: `?${createSearchParams({ teamId: teamId })}` });
    } else if (pathname.includes("workflows")) {
      resetOutputMapping();
    }
  };

  return (
    <>
      {render && (
        <>
          <DeleteMapping teamId={teamId} outputMapping={render && outputMappingsStore.getOutputMapping()} />
          <MappingSettings teamId={teamId} />
          <div className={styles.headerWrapper}>
            <WarningConfirmationModal
              modalId={MODAL_IDS.CONFIRM_CLOSE_CREATE_OUTPUT_MAPPING}
              heading="Are you sure you want to quit?"
              text="All progress in this session will be lost"
              cancel="Cancel"
              confirm="Quit"
              onConfirm={confirmCloseModal}
            />
            <ModalHeader
              onCloseClick={closeModalAndResetFields}
              CREATE_NEW_LOOKUP_MODAL_ID={modalId}
              title={render && getTitle()}
              contentLeft={render && <StatusCell value={getStatus()} />}
              contentRight={
                name === "STEP 03" && (
                  <HeaderButtons
                    teamId={teamId}
                    template={outputMappingsStore.getOutputMapping()}
                    onClose={confirmCloseModal}
                    handleStepCompleted={handleStepCompleted}
                    setActiveStep={setActiveStep}
                    onComplete={handleOutputMappingCreated}
                  />
                )
              }
            />
          </div>
          <R props={"ml-72 mr-72 ".concat(`${name === "STEP 03" ? "mt-16" : "mb-72"}`)}>
            {render && <div>{name !== "STEP 03" && <Step steps={steps} activeStep={activeStep} />}</div>}
          </R>

          <SelectComponent
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepParams={handleOutputMappingCreated}
            handleStepCompleted={handleStepCompleted}
          />
        </>
      )}
    </>
  );
});

export { CreateOutputMappingStage };
