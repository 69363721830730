import { Modal, ModalBody, ModalSection, ModalHeader, ModalText, Button, Text, ModalFooter, modalInstance, ModalAlert } from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/templates/enums";

const DraftAndRejectedTemplateSubmitAndExitModal = ({ name, onConfirm }) => {
  return (
    <Modal modalId={MODAL_IDS.DRAFT_TEMPLATE_SUBMIT_AND_EXIT}>
      <ModalBody>
        <ModalSection>
          <ModalAlert />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={`Are you sure you want to submit template '${name}' for approval?`} />
          <ModalText text="Pending templates cannot be edited or deleted while awaiting review. " />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          color="tertiary"
          onClick={() => {
            modalInstance(MODAL_IDS.DRAFT_TEMPLATE_SUBMIT_AND_EXIT).toggle();
          }}
        >
          <Text size="sm">Cancel</Text>
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          <Text size="sm">Submit & exit</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { DraftAndRejectedTemplateSubmitAndExitModal };
