import NameWrapper from "./NameWrapper";
import CommandUtils from "../CommandUtils";
/**
 * name= *
 */
export default class CommandStackModel {
  constructor(props) {
    this.name = props.name;
    this.argsUser = props.argsUser;
  }
  /**
   * *
   * @param commandStackJson contains array: [name:String,args:Map<String,String>
   *
   */
  static buildCommandStackFromJson(commandStackJson) {
    let uiCommand = CommandUtils.findUiCommand(commandStackJson.name);
    let properties = new Object();
    properties["name"] = new NameWrapper(uiCommand.name);
    properties["argsUser"] = commandStackJson.args;
    return new CommandStackModel(properties);
  }
}
