import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import moment from "moment";
import { modalInstance, Text, Button } from "@fundrecs/ui-library";
import { Table } from "components/ag-grid/Ag-grid";
import { VerticalMenu } from "components/ag-grid/verticalMenu/VerticalMenu";
import { NoSftpDirectories } from "./NoSftpDirectories";
import { MODAL_IDS } from "utils/workflows/enums";
import { AddTemplate } from "../addTemplate/AddTemplate";
import { EditTemplate } from "../editTemplate/EditTemplate";
import { useStore } from "store/Store";
import { PageTitleArea, ManageLayout } from "components/layout/Layout";
import { Tabs } from "../tabs/tabs";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { BE_SOURCE_TYPES, FE_SOURCE_TYPES } from "utils/enums";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";
import { useTeamId } from "store/hooks/useTeamId";

const SftpDirectories = () => {
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [importSftpData, setSftpData] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { importsStore, meStore, tenantConfigStore } = useStore();
  const fusionAdminUrl = tenantConfigStore.getFusionAdminUrl();
  const { superAdmin } = meStore.getMe();
  const { teamName } = useSelectedTeam();
  const teamId = useTeamId();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All SFTP imports for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  const getImportRules = async () => {
    const { success, data } = await importsStore.getImportRules({ teamId: teamId, type: BE_SOURCE_TYPES.SFTP });
    if (success) {
      setSftpData(data);
    }
  };

  useEffect(() => {
    // Show Ag-Grid spinner until data is loaded
    gridApi?.api?.showLoadingOverlay();

    setActiveTabIndex(0);
    if (teamId) {
      getImportRules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, teamId]);

  const [sftpDirectoryRow, setSftpDirectoryRow] = useState({});

  const onItemClick = (option, props) => {
    switch (option) {
      case "add":
        setSftpDirectoryRow(props.data);
        modalInstance(MODAL_IDS.ADD_TEMPLATE_IMPORT).toggle();
        break;
      case "edit":
        setSftpDirectoryRow(props);
        modalInstance(MODAL_IDS.EDIT_TEMPLATE_IMPORT.concat(FE_SOURCE_TYPES.SFTP)).toggle();
        break;
      default:
        break;
    }
  };

  const returnMenuItems = () => {
    const menuItems = [
      {
        key: "add",
        label: "Add template to source",
        visible: true,
      },
    ];

    return menuItems;
  };

  const columns = [
    {
      headerName: "SFTP directory name",
      field: "sftpDirectoryName",
      flex: 2,
      sort: "asc",
    },
    {
      headerName: "SFTP directory path",
      field: "sftpDirectoryPath",
      flex: 2,
    },
    {
      headerName: "Associated SFTP connection",
      field: "connectionName",
      flex: 2,
    },
    {
      headerName: "Templates assigned",
      field: "templatesAssigned",
      flex: 2,
    },
    {
      headerName: "",
      suppressMenu: true,
      sortable: false,
      field: "rowEndSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: (params) => {
        return { menuItems: returnMenuItems(params), onItemClick: onItemClick };
      },
    },
  ];

  const handleSourceConnectionCreate = () => {
    if (superAdmin) {
      window.open(fusionAdminUrl.concat("/import/stfpmapping"), "_blank");
    } else {
      handleConfirmModal();
    }
  };

  const handleConfirmModal = () => {
    modalInstance(MODAL_IDS.ERROR_TEMPLATE_IMPORT).toggle();
  };

  const updateRoute = (value) => {
    if (value === 1) {
      navigate("/imports/email", { replace: true });
    }
  };

  const updateActiveTab = (value) => {
    updateRoute(value);
  };

  const onCellClick = (event) => {
    if (event?.column?.colId !== "rowEndSettings") {
      const {
        data: { connectionUuid, inputMappingId },
      } = event;

      navigate({
        pathname: `${"/imports/sftp/connectionUuid/" + connectionUuid + "/inputMappingId/" + inputMappingId}`,
        search: `?${createSearchParams({ teamId: event.data.teamId })}`,
      });
    }
  };
  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: true,
    pagination: true,
    suppressRowClickSelection: true,
    onCellClicked: onCellClick,
    onGridReady: onGridReady,
    noRowsOverlayComponent: NoSftpDirectories,
  };

  return (
    <>
      <ErrorModal
        modalId={MODAL_IDS.ERROR_TEMPLATE_IMPORT}
        heading="Sorry, you do not have permissions to create a new SFTP connection!"
        text="Please contact your organisation administrator or the Fusion team for support."
        confirm="Ok"
        onConfirm={() => {}}
      />
      <PageTitleArea
        title="Imports"
        description={<div className="text-medium pb-4">Manage data coming in from your organisation’s active sources</div>}
        borderBottom={false}
        additionalChildClasses="pr-20"
      >
        <Button size="md" color="primary" onClick={handleSourceConnectionCreate}>
          <Text size="sm">Manage sources</Text>
        </Button>
        <DownloadTable gridRef={gridApi} direction="left" fileName={fileName} excludeLastColumn={true} />
      </PageTitleArea>
      <Tabs activeTabIndex={activeTabIndex} setActiveTabIndex={updateActiveTab} />
      <AddTemplate importType={FE_SOURCE_TYPES.SFTP} rowData={sftpDirectoryRow} getImportRules={getImportRules} setRowData={setSftpDirectoryRow} />
      <EditTemplate rowData={sftpDirectoryRow} getImportRules={getImportRules} clearData={setSftpDirectoryRow} importType={FE_SOURCE_TYPES.SFTP} />
      <ManageLayout>
        <div className="pt-32 pb-32">
          <Table columns={columns} rowData={importSftpData} agGridOptions={gridOptions} />
        </div>
      </ManageLayout>
    </>
  );
};

export { SftpDirectories };
