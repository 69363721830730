import PropTypes from "prop-types";
import { NumberedInputPanelWithTitleDescription } from "../reusable/NumberedInputPanelWithTitleDescription";
import { TextInput } from "../reusable/TextInput";

/**
 * This component returns the Lookup Type Name & Description section within tab 1 of the Setup New Lookup Section.
 */
const SetupNameAndDescription = ({ lookupNameWarning, lookupName, lookupDescription, lookupDescriptionWarning, dispatch }) => {
  return (
    <div className="mt-32">
      <NumberedInputPanelWithTitleDescription
        index={0}
        title="Lookup Name + Description"
        description="Choose a unique name for your lookup table. Add a description that best outlines the purpose and content of the lookup table."
      >
        <TextInput
          title="Lookup name *"
          update={(value) => {
            //Reset the warning when the user is interacting with the input again.
            const warning = !lookupNameWarning && false;
            dispatch({ lookupName: value, lookupNameWarning: warning });
          }}
          value={lookupName}
          warningBoolean={lookupNameWarning}
          warningMessage="Lookup name is required"
          classes="mb-20"
          maxCharacter={100}
        />
        <TextInput
          title="Description *"
          update={(value) => {
            //Reset the warning when the user is interacting with the input again.
            const warning = !lookupDescriptionWarning && false;
            dispatch({ lookupDescription: value, lookupDescriptionWarning: warning });
          }}
          warningBoolean={lookupDescriptionWarning}
          warningMessage="Description must be between 1 and 60 characters for a new table"
          value={lookupDescription}
          maxCharacter={150}
        />
      </NumberedInputPanelWithTitleDescription>
    </div>
  );
};

//Proptypes for SetupNameAndDescription
SetupNameAndDescription.propTypes = {
  lookupNameWarning: PropTypes.bool,
  lookupName: PropTypes.string,
  lookupDescription: PropTypes.string,
  lookupDescriptionWarning: PropTypes.bool,
  dispatch: PropTypes.func,
};

// Specifies the default values for props:
SetupNameAndDescription.defaultProps = {
  lookupNameWarning: false,
  lookupName: "",
  lookupDescription: "",
  lookupDescriptionWarning: false,
  dispatch: () => {},
};

export { SetupNameAndDescription };
