import { makeAutoObservable, toJS } from "mobx";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const { lookups } = fusionDataApi;

class LookupsStore {
  isLoading = false;
  lookups = [];

  constructor() {
    makeAutoObservable(this);
  }

  getLookupTables() {
    return toJS(this.lookups);
  }

  setLookupTables(value) {
    this.lookups = value;
  }

  removeLookupTable(lookupTable) {
    const nonDeletedTables = this.lookups.filter((lookup) => lookup.uuid !== lookupTable.uuid);
    this.setLookupTables(nonDeletedTables);
  }

  updateLookupTable(lookupTable) {
    const index = this.lookups.findIndex((index) => index.uuid === lookupTable.uuid);
    this.lookups[index] = lookupTable;
  }

  /**
   * Currently only used on Manage Lookup.
   * Could be moved directly to Manage Lookup if it isn't more reuable.
   * @param {*} uuid
   * @returns lookupTable which matches uuid
   */
  getLookupTableByUuid(uuid) {
    return toJS(this.lookups.find((table) => table.uuid === uuid));
  }

  /**
   * This method sends a request to create a new lookup table.
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  createTable = (request) => {
    this.isLoading = true;
    const { name, teamId } = request;

    return lookups
      .createTable({ teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", `Lookup "${name}" successfully added`);
          this.lookups.push(response.data);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add lookup "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to add lookup "${name}" ! Please try again`);
      });
  };

  /**
   * This method sends a request to delete a lookup table.
   * It has one param which is a request body. This request body contains
   * data needed on the backed.
   *
   * If the request is successful we returns the response data.
   * If the request is unsuccessful we return false signifying that request has failed.
   * @param request
   * @returns
   */
  deleteTable = (teamId, request) => {
    this.isLoading = true;
    const { name, uuid } = request;

    return lookups
      .deleteTable({ teamId, tableId: uuid })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 204) {
          uiStore.addNotification("success", `Lookup "${name}" successfully deleted!`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete Lookup "${name}"! Please try again `);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", `Unable to delete Lookup "${name}"! Please try again `);
      });
  };
  /**
   * This method sends a request to get lookup tables.
   *
   * If the request is successful we returns the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  getTables = (teamId, request, withMetadata = false) => {
    this.isLoading = true;
    if (withMetadata) {
      return lookups
        .getTablesWithMetadata({ teamId, requestBody: request })
        .then((response) => {
          this.isLoading = false;
          const { status } = response;
          if (status === 200) {
            this.setLookupTables(response.data);
            return { ...response, ...{ success: true } };
          } else {
            return { ...response, ...{ success: false } };
          }
        })
        .catch(() => {
          this.isLoading = false;
          uiStore.addNotification("error", "Unable to get tables! Please try again. ");
        });
    } else {
      return lookups
        .getTables({ teamId, requestBody: request })
        .then((response) => {
          this.isLoading = false;
          const { status } = response;
          if (status === 200) {
            this.setLookupTables(response.data);
            return { ...response, ...{ success: true } };
          } else {
            return { ...response, ...{ success: false } };
          }
        })
        .catch(() => {
          this.isLoading = false;
          uiStore.addNotification("error", "Unable to get tables! Please try again. ");
        });
    }
  };

  /**
   * This method sends a request to get rows for lookup table.
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  getTableRows = (request) => {
    this.isLoading = true;
    return lookups
      .getTableRows(request)
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200) {
          return { ...response, ...{ success: true } };
        } else {
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        //uiStore.addNotification("error", `Unable to get tables! Please try again `);
      });
  };

  /**
   * This method sends a request to update an existing lookup table details.
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  updateTable = (teamId, request) => {
    this.isLoading = true;
    const { uuid: tableId } = request;
    return lookups
      .updateTable({ teamId, requestBody: request, tableId })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Lookup Settings successfully updated!");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to change Lookup Settings! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to change Lookup Settings! Please try again");
      });
  };

  /**
   * This method sends a request to edit an existing lookup table row.
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  createTableRow = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .createTableRow(tableId, teamId, { requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success new row added!");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry a new row could not be added at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry a new row could not be added at this time");
      });
  };

  /**
   * This method sends a request to update an existing lookup table row.
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return null signifying that request has failed.

  * @param tableUuid  
  * @param rowId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  updateTableRow = (tableId, teamId, rowId, request) => {
    this.isLoading = true;
    return lookups
      .updateTableRow({ tableId, teamId, rowId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Lookup Row successfully changed");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Unable to change Lookup Row! Please try again");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Unable to change Lookup Row! Please try again");
      });
  };

  /**
   * This method sends a request to delete a row
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  deleteTableRows = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .deleteTableRows({ tableId, teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success row deleted");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry row could not be deleted at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry  row could not be deleted at this time");
      });
  };

  /**
   * This method sends a request to reject row
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  rejectTableRows = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .rejectTableRows({ tableId, teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success row rejected");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry row could not be rejected at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry  row could not be rejected at this time");
      });
  };

  /**
   * This method sends a request to approve a row
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param teamId
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  approveTableRows = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .approveTableRows({ tableId, teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success row approved");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry row could not be approved at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry row could not be approved at this time");
        return { ...{ success: false } };
      });
  };

  /**
   * This method sends a request to submit a row for approval
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param tableUuid
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  submitForApprovalTableRows = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .submitForApprovalTableRows({ tableId, teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success, rows has been submitted for approval");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry, rows could not be submittted for approval at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry, rows could not be submittted for approval at this time");
      });
  };

  /**
   * This method sends a request to submit a row without approval
   *
   * If the request is successful we return the response data.
   * If the request is unsuccessful we return false signifying that request has failed.

  * @param tableUuid
  * @param request - request body contains that contains data needed on the backend
  * @returns
  */
  submitWithoutApprovalTableRows = (tableId, teamId, request) => {
    this.isLoading = true;
    return lookups
      .submitWithoutApprovalTableRows({ tableId, teamId, requestBody: request })
      .then((response) => {
        this.isLoading = false;
        const { status } = response;
        if (status === 200 || status === 201) {
          uiStore.addNotification("success", "Success, rows has been submitted");
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", "Sorry, rows could not be submittted at this time");
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        this.isLoading = false;
        uiStore.addNotification("error", "Sorry, rows could not be submittted at this time");
      });
  };
}

export { LookupsStore };
