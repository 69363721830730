import { useStore } from "store/Store";

const useSelectedTeam = () => {
  const { meStore } = useStore();
  const selectedTeam = meStore.getSelectedTeam() ?? {};

  return {
    teamId: selectedTeam?.id ?? null,
    teamName: selectedTeam?.name ?? "",
  };
};

export { useSelectedTeam };
