import React from "react";
import PropTypes from "prop-types";
import { CO, R, C, C6, Heading, VerticalDivider, Text, Button, BorderBottom, IconAdd, Card, IconLeftArrow, LinkText } from "@fundrecs/ui-library";

const PageTitleArea = ({ leftArrow, title, description, classes, borderBottom, navigate, children, dividerActive, additionalChildClasses = "" }) => (
  <>
    <div className={["page-header d-flex align-items-center", `${classes}`].join(" ")}>
      {leftArrow && (
        <LinkText
          classes="pr-16"
          onClick={() => {
            navigate();
          }}
        >
          <IconLeftArrow className="btn-lg-svg" color="#1e5eff" />
        </LinkText>
      )}
      <Heading variant="h4">{title}</Heading>
      {dividerActive && <VerticalDivider />}
      <Text variant="muted" size="md" element="span">
        {description}
      </Text>
      <div className={["d-flex ms-auto", `${additionalChildClasses}`].join(" ")}>{children}</div>
    </div>
    {borderBottom && <BorderBottom />}
  </>
);

/**
 * @additionalChildClasses
 * This prop lets us pass additional css classes.
 * We need this so that we can control the padding on the right of
 * the PageTitleArea.
 */
PageTitleArea.propTypes = {
  borderBottom: PropTypes.bool,
  classes: PropTypes.string,
  dividerActive: PropTypes.bool,
  additionalChildClasses: PropTypes.string,
};

PageTitleArea.defaultProps = {
  borderBottom: true,
  dividerActive: true,
  classes: "",
  additionalChildClasses: "",
};

/**
 * This is used on the manage Lookups page
 */
const ManageLayout = ({ pageTitleArea, children }) => {
  return (
    <>
      {pageTitleArea}
      <CO props="pr-0 pl-0 fill">
        <R props="full-height">
          <C props="section-border-left pr-0 pl-0">
            <div className="light-grey-bg section-border-left " style={{ height: "100%" }}>
              <R props={"pl-20 pr-20 pb-32"}>{children}</R>
            </div>
          </C>
        </R>
      </CO>
    </>
  );
};

/**
 * This is used on empty state pages.
 */
const EmptyStateLayout = ({ icon, title, description, buttonText, customButtonIcon, onClick, showDescriptionAndButton = true }) => {
  return (
    <>
      <CO props="pr-0 pl-0 fill">
        <R props="pt-48 justify-content-center">
          <C6 props="text-center pt-32 pb-32 pl-48 pr-48">
            <span className="emptyState-icon">{icon}</span>

            <div className="pt-16">
              <Heading variant="h4" align="center">
                {title}
              </Heading>
            </div>
            {showDescriptionAndButton && (
              <>
                <div className="pt-16 pb-32">
                  <Text variant="tertiary" size="sm" align="center" weight="regular" element="p">
                    {description}
                  </Text>
                </div>
                <Button
                  onClick={() => {
                    onClick();
                  }}
                >
                  {customButtonIcon ? customButtonIcon : <IconAdd className="btn-md-svg" />}
                  <Text size="sm" weight="medium">
                    {buttonText}
                  </Text>
                </Button>
              </>
            )}
          </C6>
        </R>
      </CO>
    </>
  );
};

const TitleSection = ({ ...props }) => {
  const { children } = { ...props };

  return (
    <div className="pt-24 pb-16 pr-0 pl-0">
      {React.Children.toArray(children).map((child, index) => {
        return child;
      })}
    </div>
  );
};

const SectionLayout = ({ title, children, spacers }) => {
  return (
    <>
      <TitleSection>
        <Text size="sm" variant="secondary" weight="bold">
          {title}
        </Text>
      </TitleSection>
      <C props="pl-0 pr-0">
        <Card spacers={[...spacers]}>{children}</Card>
      </C>
    </>
  );
};

SectionLayout.defaultProps = {
  spacers: ["pl-16", "pr-16", "pt-32", "pb-32"],
};

const SectionWithDescriptionLayout = ({ title, description, children, spacers }) => {
  return (
    <>
      <TitleSection>
        <Heading variant="h5" element="div">
          {title}
        </Heading>
        <Text variant="tertiary" size="sm" weight="regular">
          {description}
        </Text>
      </TitleSection>
      <C props="pl-0 pr-0">
        <Card spacers={[...spacers]}>{children}</Card>
      </C>
    </>
  );
};

SectionWithDescriptionLayout.defaultProps = {
  spacers: ["pl-16", "pr-16", "pt-32", "pb-32"],
};
export { PageTitleArea, ManageLayout, EmptyStateLayout, SectionLayout, SectionWithDescriptionLayout };
