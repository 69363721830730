import { Link, createSearchParams } from "react-router-dom";
import { OUTPUT_BE_TYPES } from "../../../../../utils/workflows/enums";
import { ROUTES } from "utils/enums";
import styles from "./ResultFileListCell.module.scss";
import { useTeamId } from "store/hooks/useTeamId";

const getUniqueMetadata = (metadata) =>
  metadata.reduce((acc, item) => {
    if (item?.outputFileName && item?.outputFileUuid) {
      const key = `${item.outputFileName}-${item.outputFileUuid}`;
      if (!acc.has(key)) {
        acc.set(key, item);
      }
    } else if (item?.failureReasonMessage) {
      acc.set(item.failureReasonMessage, item);
    }
    return acc;
  }, new Map());

const ResultFileListCell = (params) => {
  const teamId = useTeamId();

  const {
    data: { metadata },
  } = params || {};

  if (!metadata || metadata?.length === 0) {
    return (
      <ul className={styles["result-list"]}>
        <li>-</li>
      </ul>
    );
  }

  const rows = Array.from(getUniqueMetadata(metadata).values())?.map((m, index) => {
    if (m?.outputFileName && m?.outputFileUuid) {
      const downloadLink = `/api/download/${m?.outputFileUuid}`;

      if (m?.destination?.type == OUTPUT_BE_TYPES.RECONCILIATION) {
        return (
          <li key={index}>
            <a href={downloadLink}>Download data loaded to reconciliation.</a>
          </li>
        );
      }
      if (m?.destination?.type == OUTPUT_BE_TYPES.LOOKUP) {
        return (
          <li key={index}>
            <Link className={styles.link} to={{ pathname: ROUTES.LOOKUPS, search: `?${createSearchParams({ teamId: teamId, tableId: m?.outputFileUuid })}` }}>
              View results in lookup table
            </Link>
          </li>
        );
      }
      return (
        <li key={index}>
          <a href={downloadLink} className={styles.link}>
            {m?.outputFileName}
          </a>
        </li>
      );
    } else if (m?.failureReasonMessage) {
      return (
        <li key={index} className={styles["result-failure"]}>
          {m?.failureReasonMessage}
        </li>
      );
    } else {
      return <li key={index}>-</li>;
    }
  });

  return <ul className={styles["result-list"]}>{rows}</ul>;
};

const ResultFileListCellText = (metadata) => {
  return Array.from(getUniqueMetadata(metadata).values())
    .map((m) => {
      if (m?.outputFileName && m?.outputFileUuid) {
        if (m?.destination?.type === OUTPUT_BE_TYPES.RECONCILIATION) {
          return "Download data loaded to reconciliation.";
        }
        if (m?.destination?.type === OUTPUT_BE_TYPES.LOOKUP) {
          return `View results in lookup table (tableId: ${m?.outputFileUuid})`;
        }
        return m?.outputFileName;
      } else if (m?.failureReasonMessage) {
        return m?.failureReasonMessage;
      }
      return "-";
    })
    .join(", ");
};

export { ResultFileListCell, ResultFileListCellText };
