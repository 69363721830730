import React from "react";
import List from "@material-ui/core/List";
import SelectAndTransformListItem from "./SelectAndTransformListItem";
export default class SelectAndTransformListView extends React.Component {
  constructor(props) {
    super(props);
    this.getListOptions = this.getListOptions.bind(this);
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    } else {
      return (
        <List className="selectAndTransformListView">
          {this.getListOptions()}
        </List>
      );
    }
  }

  /**
   * Returns array of HTML ListItems created for each listOption contained in props
   * @returns {*}
   */
  getListOptions() {
    let listOptions = this.props.listOptions.map((listOption, index) => {
      return (
        <SelectAndTransformListItem
          key={index}
          listOptionSelected={this.props.listOptionSelected}
          listOption={listOption}
          index={index}
        />
      );
    });
    return listOptions;
  }
}
