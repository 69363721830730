import { useEffect, useState } from "react";
import { workflowsStore } from "store/Store";
import { CONNECTION_SUMMARY_METHOD, CONNECTION_SUMMARY_TYPE } from "utils/workflows/enums";

const useImportSftpConnectionData = () => {
  const [sftpData, setSftpData] = useState([]);
  useEffect(() => {
    workflowsStore.getConnectionSummary(CONNECTION_SUMMARY_METHOD.IMPORT, CONNECTION_SUMMARY_TYPE.SFTP).then((data) => {
      setSftpData(data);
    });
  }, []);

  return sftpData;
};

export { useImportSftpConnectionData };
