import { useState, useEffect } from "react";
import { modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { MODAL_IDS } from "utils/workflows/enums";
import { Table } from "components/ag-grid/Ag-grid";
import { agMultiColumnFilter } from "components/ag-grid/filters/filters";
import { VerticalMenu } from "components/ag-grid/verticalMenu/VerticalMenu";
import { generateText } from "components/workflows/ConfigureWorkflow/stages/input/reusable/GenerateText";
import { NoTemplates } from "../noTemplates/NoTemplates";
import { removeInclusionRuleWithinSouceAtIndex } from "components/workflows/reusable";
import { EditTemplate } from "../editTemplate/EditTemplate";

const TemplateTable = ({ connectionData, importType, getImportRules, gridApi, setGridApi, teamId }) => {
  const { workflowsStore } = useStore();
  const [propsData, setPropsData] = useState({});
  const [rowData, setRowData] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params);
  };

  useEffect(() => {
    // Show Ag-Grid spinner until data is loaded
    gridApi?.api?.showLoadingOverlay();

    const { inclusionRules } = connectionData;
    setRowData(inclusionRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, connectionData?.inclusionRules]);

  const getRule = (params) => {
    const { criteria, type } = params.data.rule;
    if (type === "MATCH_ALL") {
      return "Apply this template to all incoming data";
    } else {
      return generateText(criteria, type);
    }
  };

  const returnTemplateMenuItems = () => {
    const menuItems = [
      {
        key: "edit",
        label: "Edit",
        visible: true,
      },
      {
        key: "remove",
        label: "Remove",
        visible: true,
      },
    ];

    return menuItems;
  };

  const removeWorkflowTemplate = async (props, updatedWorkflow) => {
    const { success } = await workflowsStore.removeWorkflowTemplate(updatedWorkflow, updatedWorkflow.teamId, updatedWorkflow.uuid);
    if (success) {
      props.api.applyTransaction({
        remove: [props.node.data],
      });
      getImportRules();
    }
  };

  const handleRemoveTemplate = async () => {
    const {
      data: { workflowUuid, teamId, ruleIndex, importRuleUuid },
    } = propsData;

    if (workflowUuid) {
      const { data: workflowObject, success } = await workflowsStore.getWorkflowByUuidAndTeamId(workflowUuid, teamId);
      if (success) {
        const workflow = removeInclusionRuleWithinSouceAtIndex(importRuleUuid, workflowObject, ruleIndex);
        removeWorkflowTemplate(propsData, workflow);
      }
    }
  };

  const onItemClick = (option, props) => {
    switch (option) {
      case "add":
        modalInstance(MODAL_IDS.ADD_TEMPLATE_IMPORT).toggle();
        break;
      case "edit":
        setPropsData(props);
        modalInstance(MODAL_IDS.EDIT_TEMPLATE_IMPORT.concat(importType.toString())).toggle();
        break;
      case "remove":
        setPropsData(props);
        modalInstance(MODAL_IDS.REMOVE_TEMPLATE_IMPORT.concat(importType.toString())).toggle();
        break;
      default:
        break;
    }
  };

  const gridOptions = {
    suppressCellFocus: true,
    pagination: true,
    suppressRowClickSelection: true,
    noRowsOverlayComponent: NoTemplates,
    onGridReady: onGridReady,
  };
  const columns = [
    { headerName: "Template", field: "templateName", ...agMultiColumnFilter, flex: 2 },
    {
      headerName: "Import rules applied",
      field: "rules",
      valueFormatter: getRule,
      filter: "agTextColumnFilter",
      flex: 2,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (getRule(nodeA) === getRule(nodeB)) return 0;
        return getRule(nodeA).localeCompare(getRule(nodeB));
      },
      filterParams: {
        filters: [
          {
            filter: "agTextColumnFilter",
            display: "subMenu",
          },
          {
            filter: "agSetColumnFilter",
          },
        ],
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          switch (filter) {
            case "contains":
              return getRule(value).includes(filterText);
            case "notContains":
              return !getRule(value).includes(filterText);
            case "equals":
              return getRule(value) === filterText;
            case "notEqual":
              return getRule(value) !== filterText;
            case "startsWith":
              return getRule(value).startsWith(filterText);
            case "endsWith":
              return getRule(value).endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
    {
      headerName: "Associated workflow",
      field: "workflowName",
      ...agMultiColumnFilter,
      flex: 2,
    },
    {
      headerName: "",
      suppressMenu: true,
      field: "rowEndSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      cellRenderer: VerticalMenu,
      cellRendererParams: (params) => {
        return { menuItems: returnTemplateMenuItems(params), onItemClick: onItemClick };
      },
    },
  ];

  return (
    <>
      <EditTemplate propsData={propsData} rowData={connectionData} getImportRules={getImportRules} clearData={() => {}} importType={importType} />
      <WarningConfirmationModal
        modalId={MODAL_IDS.REMOVE_TEMPLATE_IMPORT.concat(importType.toString())}
        heading="Are you sure you want to remove this template?"
        text="This may affect your workflow(s). This template can be accessed from Templates in your team’s resources."
        cancel="Cancel"
        confirm="Remove template"
        onConfirm={() => {
          handleRemoveTemplate();
        }}
      />
      <Table columns={columns} rowData={rowData} agGridOptions={gridOptions} setGridApi={setGridApi} />
    </>
  );
};

export { TemplateTable };
