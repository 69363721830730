import { Route, Routes, createSearchParams, useNavigate } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { observer } from "mobx-react-lite";
import { ErrorModal } from "components/reusable/errorModal/ErrorModal";
import { MODAL_IDS } from "utils/mappings/enums";
import { ROUTES } from "utils/enums";
import { OutputMappingsList } from "./OutputMappingsList/OutputMappingsList";
import { CreateOutputMappingStage } from "components/workflows/OutputMapping/CreateOutputMappingStage";
import { useTeamId } from "store/hooks/useTeamId";

const OutputMappings = observer(() => {
  let navigate = useNavigate();
  const teamId = useTeamId();

  const handleConfirmModal = () => {
    navigate({ pathname: ROUTES.OUTPUT_MAPPINGS, search: `?${createSearchParams({ teamId: teamId })}` });
  };

  const handleOutputMappingCreated = () => {};

  const onConfirm = () => {
    handleConfirmModal();
  };

  const handleStepCompleted = () => {
    handleConfirmModal();
  };

  return (
    <>
      <Routes>
        <ApmRoutes>
          <Route path="/" element={<OutputMappingsList />} />

          {["/create", "/edit/:outputMappingUuid", "/view/:outputMappingUuid"].map((path) => (
            <Route
              path={path}
              element={
                <CreateOutputMappingStage
                  handleOutputMappingCreated={handleOutputMappingCreated}
                  onConfirm={onConfirm}
                  handleStepCompleted={handleStepCompleted}
                />
              }
            />
          ))}
        </ApmRoutes>
      </Routes>

      <ErrorModal
        modalId={MODAL_IDS.ERROR_OUTPUT_MAPPING_NOT_FOUND}
        heading="Sorry, this output mapping doesn't exist in your team workspace!"
        text="Please go to the output mappings section and select from the list of all available output mappings."
        confirm="Go to output mappings"
        onConfirm={handleConfirmModal}
      />
    </>
  );
});

export { OutputMappings };
