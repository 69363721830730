import { appSingleton } from "./AppSingleton";

export default class CommandUtils {
  static findUiCommand(peName) {
    let generalCommands = appSingleton.generalCommands;
    if (generalCommands.length == 0) return undefined;
    return generalCommands.find((uiCommand) => {
      return uiCommand.name.peName === peName;
    });
  }
}
