import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { modalInstance, VerticalMenuDropdown, VerticalMenuListItem } from "@fundrecs/ui-library";
import { PageTitleArea } from "components/layout/Layout";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { MODAL_IDS } from "utils/lookups/enums";
import { AUTHORITIES } from "utils/enums";
import { EditLookupTableModal } from "components/lookups/LookupModals/EditLookupTableModal";
import { DeleteLookupTableModal } from "components/lookups/LookupModals/DeleteLookupTableModal";
import { rolesStore } from "store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const LookupsTableHeader = observer(({ lookupTable }) => {
  const { name, type } = lookupTable || {};

  let navigate = useNavigate();
  const teamId = useTeamId();

  return (
    <>
      <EditLookupTableModal modalId={MODAL_IDS.EDIT_LOOKUP_TABLE_MODAL_ID} />
      <DeleteLookupTableModal modalId={MODAL_IDS.DELETE_LOOKUP_TABLE_MODAL_ID} lookup={lookupTable} />
      <PageTitleArea
        leftArrow={true}
        title={name}
        description={type}
        navigate={() => {
          navigate(`/lookups/?teamId=${teamId}`);
        }}
      >
        <VerticalMenuDropdown>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_EDIT])}>
            <VerticalMenuListItem
              text="Lookup Settings"
              onClick={() => {
                modalInstance(MODAL_IDS.EDIT_LOOKUP_TABLE_MODAL_ID).toggle();
              }}
            />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_DELETE])}>
            <VerticalMenuListItem
              text="Delete"
              onClick={() => {
                modalInstance(MODAL_IDS.DELETE_LOOKUP_TABLE_MODAL_ID).toggle();
              }}
            />
          </AuthWrapper>
        </VerticalMenuDropdown>
      </PageTitleArea>
    </>
  );
});

// Specifies the types for props:
LookupsTableHeader.propTypes = {
  lookupTable: PropTypes.object,
};

// Specifies the default values for props:
LookupsTableHeader.defaultProps = {
  lookupTable: {},
};

export { LookupsTableHeader };
