import React, { useContext } from "react";

import { Grid, TextField } from "@material-ui/core";

import HeaderSelectorButton from "./HeaderSelectorButton.js";

import { LumsContext } from "./LumsCommand";

const SectionInfo = ({ lookupType }) => {
  if (lookupType === "contains") {
    return (
      <span style={{ color: "#4154AF" }}>
        <b>Choose input columns. </b>Choose columns fron the input data that you want to apply the contains rule to
      </span>
    );
  } else {
    return <></>;
  }
};

export default function SelectLookupTableColumns() {
  const lumsContext = useContext(LumsContext);

  const { initialState, inputFile, selectedLookupTableColumns, setSelectedLookupTableColumns, lookupType } = lumsContext;

  const { columns: inputFileColumns } = inputFile;

  const selectedInputFileColumnNames = selectedLookupTableColumns.map((columnIndex) => {
    const inputFileColumn = inputFileColumns[columnIndex];

    const { name: inputFileColumnName } = inputFileColumn;

    return inputFileColumnName;
  });

  return (
    <div className="marginLeft12">
      <div className="marginBottom9">
        <SectionInfo lookupType={lookupType} />
      </div>
      <Grid container>
        <Grid item xs={3}>
          <span className="input-label">Select lookup columns from input file</span>
        </Grid>
        <Grid item xs={9}>
          <TextField
            className="input"
            value={selectedInputFileColumnNames.join(",")}
            placeholder="Select Columns"
            variant="outlined"
            style={{ width: "300px" }}
            InputProps={{
              readOnly: true,
            }}
          />

          <HeaderSelectorButton
            multiple
            onSelect={(indexedValue) => {
              const { index: columnIndex } = indexedValue;

              const columnIndexWithoutOutputColumns = columnIndex - initialState.outputColumns.length;

              if (columnIndexWithoutOutputColumns < 0) {
                return;
              }

              const index = selectedLookupTableColumns.indexOf(columnIndexWithoutOutputColumns);

              if (index === -1) {
                selectedLookupTableColumns.push(columnIndexWithoutOutputColumns);
              } else {
                selectedLookupTableColumns.splice(index, 1);
              }

              setSelectedLookupTableColumns([...selectedLookupTableColumns]);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
