import { useEffect, useState } from "react";
import { workflowsStore } from "store/Store";
import { CONNECTION_SUMMARY_METHOD, CONNECTION_SUMMARY_TYPE } from "utils/workflows/enums";

const useImportEmailConnectionData = () => {
  const [emailData, setEmailData] = useState([]);
  useEffect(() => {
    workflowsStore.getConnectionSummary(CONNECTION_SUMMARY_METHOD.IMPORT, CONNECTION_SUMMARY_TYPE.EMAIL).then((data) => {
      setEmailData(data);
    });
  }, []);

  return emailData;
};

export { useImportEmailConnectionData };
