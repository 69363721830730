import React from "react";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { tbAppChannel } from "../../utils/communication/AppChannels";
import HeaderSelectorButton from "../../selectandtransform/module/HeaderSelectorButton.js";
import CommandArgsModel from "../../utils/domain/CommandArgsModel";
import IndexedValue from "../../utils/domain/selectandtransform/IndexedValue";

export default class DropdownWithRuleValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownAnchor: null,
      menuOpen: false,
    };
  }

  render() {
    const {
      uiCommand,
      uiCommand: { dropDownKeyValuePair },
      userInputArgs,
      argsNameWraper: { peName, defaultValue },
      isNew,
    } = this.props;

    /**
     * When the dropdown button clicked, sets the anchorElement for the menu and sets peName-MenuOpen to true
     * @param event
     */
    const handleDropdownMenuClick = (event) => {
      this.setState({
        menuOpen: true,
        dropdownAnchor: event.currentTarget,
      });
    };

    /**
     * Return a list of MenuItem components, used to populate the dropdown
     * @returns {*}
     */
    const getDropDownList = () => {
      return dropDownKeyValuePair.map((ruleMap, index) => {
        return ruleMap.deleted ? null : (
          <MenuItem
            key={index}
            onClick={() => {
              selectRuleMappings(ruleMap);
            }}
          >
            {ruleMap.name}
          </MenuItem>
        );
      });
    };

    const getColumnSelectorList = () => {
      return [undefined, null].includes(userInputArgs) ||
        [undefined, null].includes(userInputArgs[peName])
        ? null
        : Object.keys(userInputArgs[peName]["validationMappings"]).map(
            (mappingKey, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <HeaderSelectorButton
                    indexedValue={userInputArgs[peName]}
                    onSelection={(indexedValue) => {
                      selectColumn(mappingKey, indexedValue);
                    }}
                  />
                  <p>
                    <span style={{ fontWeight: "bold" }}>{mappingKey}: </span>
                    <span>{getSelectedColumnText(mappingKey)}</span>
                  </p>
                </div>
              );
            }
          );
    };

    const selectRuleMappings = (ruleMap) => {
      let args = userInputArgs || {};
      args[peName] = {};
      args[peName]["ruleId"] = ruleMap.name;
      args[peName]["validationMappings"] = {};
      ruleMap.columns.forEach((columnName) => {
        args[peName]["validationMappings"][columnName.name] = new IndexedValue({
          index: null,
          value: null,
        });
      });

      tbAppChannel.publish(
        "onCommandParamsChange",
        new CommandArgsModel({
          uiCommand: uiCommand,
          userInputargs: args,
          isNew: isNew,
        })
      );
      this.setState({ menuOpen: false });
    };

    const selectColumn = (mappingKey, indexedValue) => {
      let args = userInputArgs || {};
      args[peName]["validationMappings"][mappingKey] = indexedValue;
      tbAppChannel.publish(
        "onCommandParamsChange",
        new CommandArgsModel({
          uiCommand: uiCommand,
          userInputargs: args,
          isNew: isNew,
        })
      );
    };

    const generateButtonText = () => {
      return !userInputArgs || userInputArgs[peName] === undefined
        ? defaultValue
        : "Selected rule: " + userInputArgs[peName]["ruleId"];
    };

    const getSelectedColumnText = (mappingKey) => {
      return !userInputArgs ||
        userInputArgs[peName] === undefined ||
        [undefined, null].includes(
          userInputArgs[peName]["validationMappings"][mappingKey]["value"]
        )
        ? "Nothing Selected"
        : userInputArgs[peName]["validationMappings"][mappingKey]["value"];
    };

    return (
      <div data-name="dropdownWithRuleValidation">
        <div className="row">
          <Button
            className="dropdownButton"
            disabled={this.state.selected}
            onClick={(event) => {
              handleDropdownMenuClick(event);
            }}
          >
            {generateButtonText()}
          </Button>

          <Menu
            id="rule-menu"
            open={this.state.menuOpen}
            onClose={() => {
              this.setState({ menuOpen: false });
            }}
            anchorEl={this.state.dropdownAnchor}
          >
            {getDropDownList()}
          </Menu>
        </div>
        <div className="row">{getColumnSelectorList()}</div>
      </div>
    );
  }
}

export { DropdownWithRuleValidation };
