import { Text } from "@fundrecs/ui-library";
import { AuditItem } from "./AuditItem";

import styles from "./DropdownPanelCell.module.scss";

const DropdownPanelCell = (rowNode) => {
  const {
    description,
    createdDate,
    createdBy,
    lastModifiedDate,
    lastModifiedBy,
    tmoDetail: { name: tmoName },
  } = rowNode.data;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <Text size="xs" weight="medium" element="p" variant="primary">
          Description
        </Text>
        <Text size="xs" weight="regular" element="p" variant="secondary">
          {description}
        </Text>
        <div className="d-flex mt-4">
          <Text size="xs" weight="medium" element="p" variant="primary">
            Mapping to output mapping:
          </Text>
          <span className="pl-8">
            <Text size="xs" weight="regular" element="p" variant="secondary">
              {tmoName}
            </Text>
          </span>
        </div>
      </div>
      <div>
        {lastModifiedDate !== null && lastModifiedDate !== undefined ? (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Last edited by
            </Text>
            <AuditItem user={lastModifiedBy.name} timestamp={lastModifiedDate} />
          </>
        ) : (
          <>
            <Text size="xs" weight="medium" element="p" variant="primary">
              Created by
            </Text>
            <AuditItem user={createdBy.name} timestamp={createdDate} />
          </>
        )}
      </div>
    </div>
  );
};

export { DropdownPanelCell };
