/**
 * Validation for Create New Lookup.
 * UI store interacting can be removed once backend validation implemented.
 **/

/**
 * This checks if the user has added the max amount of columns and throws and error.
 * When proper validation is added to the backed this will be returned from the API and
 * the toast message firing can be removed.
 *
 * @param {*} data
 * @param {*} maxColumnValue
 * @param {*} store
 * @returns
 */
const lookupValidationMaxColumns = (data, maxColumnValue, store) => {
  const handleError = () => {
    store.addNotification("error", "Column cannot be added. The maximum of 60 columns has been reached");
    return false;
  };
  return data.length < maxColumnValue ? true : handleError();
};

/**
 * This checks if at least one of the Lookup Equals table columns are unqiue.
 * If there are no unique columns added then a toast message is fired and false is returned.
 * @param {*} data
 * @param {*} store
 * @returns
 */
const lookupValidationOneUniqueColumn = (data, store) => {
  const handleError = () => {
    store.addNotification("error", "Lookup could not be saved. The table requires at least one unique column");
    return false;
  };

  //Check if any of the columns have the unique flag set
  const checkForUniqueColumn = data.some((col) => col.unique === true);

  if (checkForUniqueColumn) {
    return true;
  } else {
    return handleError();
  }
};

export { lookupValidationMaxColumns, lookupValidationOneUniqueColumn };
