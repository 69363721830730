import React, { useContext } from "react";

import { TextField, InputAdornment, IconButton, List, ListItem, ListItemText } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./styles.css";

import { LumsContext } from "./LumsCommand";

export default function SelectLookupTable(props) {
  const { onChange } = props;

  const lumsContext = useContext(LumsContext);

  const { lookupTables, lookupTable, setLookupTable, lookupType, lookupTableMenu, setLookupTableMenu } = lumsContext;
  const availableLookupTables = lookupTables.filter((lookupTable) => {
    const { deleted, type } = lookupTable;
    /**
     * We will never have a search type in a lookup object.
     * We want to return equals & contains types that aren't deleted if the user selects search
     */
    if (type === lookupType && !deleted) {
      return true;
    } else if (lookupType === "search" && !deleted) {
      //We need to cater for the search type
      return true;
    } else {
      //Don't return the lookupTable
      return false;
    }
  });

  const noAvailableLookupTables = availableLookupTables.length === 0;

  const onSelectLookupTable = (event) => {
    setLookupTable(event);
    setLookupTableMenu(!lookupTableMenu);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="input-combo">
      <span className="input-label">Select Lookup Table</span>

      <span className="select-container">
        <TextField
          className="select-input"
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={noAvailableLookupTables}
                  onClick={() => {
                    setLookupTableMenu(!lookupTableMenu);
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={lookupTable ? lookupTable.name : ""}
          placeholder="Select Lookup"
          onClick={() => {
            setLookupTableMenu(!lookupTableMenu);
          }}
        />

        <span className="select-list">
          <List disablePadding>
            {lookupTableMenu &&
              availableLookupTables.map((lookupTable, index) => {
                const { name } = lookupTable;

                return (
                  <ListItem
                    button
                    key={index}
                    onClick={() => {
                      onSelectLookupTable(lookupTable);
                    }}
                  >
                    <ListItemText primary={name} style={{ wordBreak: "break-all" }} />
                  </ListItem>
                );
              })}
          </List>
        </span>
      </span>
    </div>
  );
}
