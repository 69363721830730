import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const API_PREFIX = "/lums/teams";

const lookups = {
  //Create Lookup Table
  createTable: ({ teamId, requestBody }) => fusionRequest.post(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}`, requestBody),

  //Get Lookup Tables
  getTables: ({ teamId }) => fusionRequest.get(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}`),

  //Get Lookup Tables with Metadata
  getTablesWithMetadata: ({ teamId }) => fusionRequest.get(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}?includeMetadata=true`),

  //Update Lookup Table Details
  updateTable: ({ teamId, requestBody, tableId }) => fusionRequest.put(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}`, requestBody),

  //Delete Lookup Table
  deleteTable: ({ teamId, tableId }) => fusionRequest.delete(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}`),

  //Get rows for Lookup Table
  getTableRows: ({ teamId, tableUuid }) =>
    fusionRequest.get(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableUuid}${API_ENDPOINTS.LOOKUPS.ROWS}`),

  //Create row for Lookup Table
  createTableRow: (tableId, teamId, { requestBody }) =>
    fusionRequest.post(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}`, requestBody),

  //Bulk delete row/rows for Lookup Table
  deleteTableRows: ({ tableId, teamId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}${API_ENDPOINTS.LOOKUPS.BULK}Deleted`,
      requestBody
    ),

  //Bulk approve row/rows for Lookup Table
  approveTableRows: ({ tableId, teamId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}${API_ENDPOINTS.LOOKUPS.BULK}FourEyesApproved`,
      requestBody
    ),

  //Bulk reject row/rows for Lookup Table
  rejectTableRows: ({ tableId, teamId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}${API_ENDPOINTS.LOOKUPS.BULK}Rejected`,
      requestBody
    ),

  //Bulk submit row/rows for Lookup Table
  submitForApprovalTableRows: ({ tableId, teamId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}${API_ENDPOINTS.LOOKUPS.BULK}Pending`,
      requestBody
    ),

  //Bulk submit row/rows for Lookup Table
  submitWithoutApprovalTableRows: ({ tableId, teamId, requestBody }) =>
    fusionRequest.post(
      `${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}${API_ENDPOINTS.LOOKUPS.BULK}Approved`,
      requestBody
    ),

  //Update row details for Lookup Table
  updateTableRow: ({ tableId, teamId, rowId, requestBody }) =>
    fusionRequest.put(`${API_PREFIX}/${teamId}${API_ENDPOINTS.LOOKUPS.TABLES}/${tableId}${API_ENDPOINTS.LOOKUPS.ROWS}/${rowId}`, requestBody),
};

export { lookups };
