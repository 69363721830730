import { makeAutoObservable, toJS } from "mobx";
import { fusionDataApi } from "../api";
import { uiStore } from "./Store";

const { fusionAdmin } = fusionDataApi;

class MeStore {
  me = "";
  adminRoles = [];
  adminActions = [];
  roles = [];
  actions = [];
  teams = [];
  selectedTeam = null;
  defaultTeam = "";
  teamRolesActions = [];
  DATE_FORMAT_CONST = "YYYY-MM-DD";

  constructor() {
    makeAutoObservable(this);
  }

  setMe = async (data) => {
    this.me = data;
    const { adminRolesActions = { actions: [], roles: [] }, teamRolesActions, defaultTeam: defaultTeamId } = data;

    this.adminRoles = adminRolesActions === null ? [] : adminRolesActions.roles;
    this.adminActions = adminRolesActions === null ? [] : adminRolesActions.actions;

    this.teamRolesActions = teamRolesActions;
    this.roles = teamRolesActions === undefined ? [] : teamRolesActions.map((x) => x.roles);
    this.teams = teamRolesActions === undefined ? [] : teamRolesActions.map((x) => x.team);

    let allTeamActions = [];
    if (teamRolesActions) {
      teamRolesActions.forEach((teamData) => {
        allTeamActions = [...allTeamActions, ...teamData.actions];
      });
    }
    this.actions = allTeamActions;

    this.defaultTeam = this.getTeamById(defaultTeamId);
  };

  getUserDateFormat() {
    return this.DATE_FORMAT_CONST;
  }

  getAllActions() {
    return toJS(this.adminActions.concat(this.actions));
  }

  getAllRoles() {
    return toJS(this.adminRoles.concat(this.roles));
  }

  getActionsByTeamId(teamId) {
    // ensure lookup ID is an integer
    const id = parseInt(teamId, 10);

    const rolesActions = toJS(this.teamRolesActions?.find((teamRolesActions) => teamRolesActions.team.id === id));
    return rolesActions ? rolesActions.actions : [];
  }

  getTeams() {
    return toJS(this.teams.slice().sort((a, b) => a.name.localeCompare(b.name)));
  }

  getTeamById(teamId) {
    return toJS(this.teams.find((team) => team.id == teamId));
  }

  getDefaultTeam() {
    return toJS(this.defaultTeam);
  }

  /**
   * returns defaultTeam if present or else the first team in getTeams() by default sort.
   */
  getDefaultTeamOrFirstInList() {
    return toJS(this.defaultTeam ? this.defaultTeam : this.getTeams()[0]);
  }

  isSuperAdmin() {
    return this.me.superAdmin;
  }

  ping = () => {
    console.log("You have pinged the user store");
  };

  getMe = () => {
    return toJS(this.me);
  };

  /**
   * Sets the selected team.
   * @param {object} team - The team to select.
   */
  setSelectedTeam(team) {
    this.selectedTeam = team;
  }

  /**
   * Gets the currently selected team.
   * @returns {object} - The selected team.
   */
  getSelectedTeam() {
    return toJS(this.selectedTeam);
  }

  setTeams = (teams) => {
    this.teams = teams;
  };

  getName = () => {
    const { name } = this.me;
    return name;
  };

  isAdmin = () => {
    const { admin } = this.me;
    return admin;
  };

  getEmail = () => {
    const { email } = this.me;
    return email;
  };

  getId = () => {
    const { id } = this.me;
    return id;
  };

  requestTeamSetting = async (teamId) => {
    const {
      data: { settings },
    } = await fusionAdmin.getTeamSettings(teamId);

    return settings;
  };

  requestMe = async () => {
    const { data, status } = await fusionAdmin.me();
    if (status === 200) {
      this.setMe(data);
      const { teamRolesActions } = data;
      if (teamRolesActions === undefined || teamRolesActions.length === 0) {
        uiStore.addNotification("error", "You currently have no teams. Please contact your administrator to be added as a member to a team.");
      }
      return true;
    }
    return false;
  };

  /**
   * This method sends a request to update the logged in user details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains data needed on the backend
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    const response = await fusionAdmin.updateMe(request).catch((error) => {
      uiStore.addNotification("error", `${error}`);
      return { data: "", response: false };
    });

    if (response.status && response.status === 200) {
      uiStore.addNotification("success", `${success}`);
      return { ...response, ...{ success: true } };
    } else {
      uiStore.addNotification("error", `${error}`);
      return { ...response, ...{ success: false } };
    }
  };
}

export { MeStore };
