import moment from "moment";
import { isToday } from "../../../../utils/datetime";

/**
 * AuditCell
 *
 * Renders a string with a date-time representing the Workflow's
 * audit trail, using the following display conditions:
 *
 * - If PUBLISHED, show the published date
 * - If modified after creation, then show the last modified date
 * - If neither of above are true, then show the creation date
 *
 * @param {RowNode} rowNode - instance of AG Grid Row object https://www.ag-grid.com/react-data-grid/row-object/
 */
const AuditCell = (rowNode) => {
  const { createdAt, lastModifiedAt, publishedAt } = rowNode.data;

  const timestamp = publishedAt || lastModifiedAt || createdAt;
  const datePrefix = publishedAt ? "Published" : lastModifiedAt ? "Last edited" : "Created";

  const date = new Date(timestamp);
  const dateString = isToday(date) ? "today" : moment(date).format("D MMM YYYY");
  const timeString = moment(date).format("HH:mm");

  const displayText = `${datePrefix} ${dateString}, ${timeString}`;

  return <div>{displayText}</div>;
};

const auditCellText = (rowNode) => {
  const { createdAt, lastModifiedAt, publishedAt } = rowNode.data;

  const timestamp = publishedAt || lastModifiedAt || createdAt;
  const datePrefix = publishedAt ? "Published" : lastModifiedAt ? "Last edited" : "Created";

  const date = new Date(timestamp);
  const dateString = isToday(date) ? "today" : moment(date).format("D MMM YYYY");
  const timeString = moment(date).format("HH:mm");

  const displayText = `${datePrefix} ${dateString}, ${timeString}`;

  return displayText;
};

export { AuditCell, auditCellText };
