import React from "react";
import "./file-uploader.css";
import { tbAppChannel } from "../utils/communication/AppChannels";
import HttpMediator from "../utils/http/HttpMediator";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Input,
  Slide,
  LinearProgress,
  Button,
  TextField,
} from "@material-ui/core";

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.state = {
      templateName: "",
      templateType: "Admin",
      formVisible: true,
    };
  }

  uploadFile() {
    let formData = new FormData();

    formData.append("templateName", this.templateName.value);
    formData.append("templateType", this.templateType.value);

    formData.append("file", this.file.files[0]);

    HttpMediator.parseFile(formData, () => {
      tbAppChannel.publish("hideUploadModal", {});
    });
    this.setState({ formVisible: false });
  }

  closeUploadModal() {
    tbAppChannel.publish("hideUploadModal", {});
  }

  render() {
    let FileUploaderAction = () => {
      if (this.state.formVisible) {
        return (
          <span>
            <DialogContent className="robotoFont">
              <form
                id="fileUploaderForm"
                method="post"
                encType="multipart/form-data"
              >
                <TextField
                  required
                  name="templateName"
                  inputRef={(el) => (this.templateName = el)}
                />
                <TextField
                  required
                  name="templateType"
                  label="Template Type"
                  value={this.state.templateType}
                  inputRef={(e2) => (this.templateType = e2)}
                />
                <Input
                  type="file"
                  name="file"
                  placeholder="Select File"
                  inputRef={(e3) => (this.file = e3)}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.uploadFile}>Submit</Button>
              <Button onClick={this.closeUploadModal}>Cancel</Button>
            </DialogActions>
          </span>
        );
      } else {
        return <LinearProgress />;
      }
    };

    return (
      <Dialog open={true} transition={<Slide direction="down" />}>
        {FileUploaderAction()}
      </Dialog>
    );
  }
}
