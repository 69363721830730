import { observer } from "mobx-react-lite";
import { Text, C, C4, R, Panel, PanelBody, PanelHeader, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { useStore } from "store/Store";
import { SectionLayout } from "components/layout/Layout";
import { EditableText } from "components/editable/EditableText";
import { MODAL_IDS } from "utils/workflows/enums";
import { StatusCell } from "components/ag-grid/StatusCell/StatusCell";

const WorkflowSettings = observer(({ workflow }) => {
  const { workflowsStore } = useStore();
  const { uuid } = workflow;
  const workflowEntity = workflowsStore.getWorkflowByUuid(uuid);
  const { name, description, status, createdBy: { name: createdByName } = {}, createdAt } = workflowEntity || {};

  /**
   * handle setting and saving changes to the Workflow
   * TODO: post-MVP this should be managed within the Workflow entity
   */
  const submitWorkflowSettings = async (key, value) => {
    // check key exists in Workflow
    if (!workflowEntity.hasOwnProperty(key)) {
      return;
    }

    // cache old value in case saving fails
    const oldValue = workflowEntity[key];

    // update Workflow property
    workflowEntity[key] = value;

    // attempt to save
    workflowEntity.save().catch(() => {
      // saving failed so reset value
      workflowEntity[key] = oldValue;
    });
  };

  return (
    <>
      <Panel panelId={MODAL_IDS.EDIT_WORKFLOW_SETTINGS}>
        <PanelBody>
          <PanelHeader header="Workflow Settings" />

          <SectionLayout title="">
            <R props={"pb-12"}>
              <EditableText
                name="name"
                label="Name"
                value={name}
                onSave={(value) => {
                  submitWorkflowSettings("name", value);
                }}
                warningText="Workflow name is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <EditableText
                name="description"
                label="Description"
                value={description}
                onSave={(value) => {
                  submitWorkflowSettings("description", value);
                }}
                warningText="Workflow description is required"
              />
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Status
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  <StatusCell value={status} />
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12 pb-12"}>
              <C4>
                <Text variant="tertiary" size="sm" weight="regular" element="div">
                  Approval
                </Text>
              </C4>

              <C>
                <Text variant="primary" size="sm" weight="medium" element="div">
                  Not required
                </Text>
              </C>
            </R>
            <SectionDivider />
            <R props={"pt-12"}>
              <C4>
                <Text size="sm" variant="tertiary" weight="regular">
                  Created by
                </Text>
              </C4>
              <C props="pl-0">
                <CreatedBy user={{ name: createdByName }} date={createdAt} />
              </C>
            </R>
          </SectionLayout>
        </PanelBody>
      </Panel>
    </>
  );
});

export { WorkflowSettings };
