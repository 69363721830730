import { useReducer } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { modalInstance, Input, InputLabel, Text, Button, Panel, PanelBody, PanelHeader, PanelFooter, TextArea } from "@fundrecs/ui-library";
import { MODAL_IDS, WORKFLOW_TYPE } from "../../../utils/workflows/enums";
import { useStore } from "../../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";
import styles from "./CreateWorkflow.module.scss";

const NAME_INPUT_MAX_CHARS = 100;
const DESCRIPTION_INPUT_MAX_CHARS = 150;

const initialState = {
  name: "",
  description: "",
  // Post MVP this will be a dynamic value
  type: WORKFLOW_TYPE,
};

function reducer(state, action) {
  const { type, value } = action;

  // TODO: check if we need any other validations here
  switch (type) {
    case "updateName":
      // clamp the length to our max value
      const name = value.substring(0, NAME_INPUT_MAX_CHARS);

      return { ...state, name };
    case "updateDescription":
      const description = value.substring(0, DESCRIPTION_INPUT_MAX_CHARS);

      return { ...state, description };
    case "reset":
      return initialState;
    default:
      throw new Error("createWorkflow reducer called with invalid action");
  }
}

const CreateWorkflow = observer(() => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const teamId = useTeamId();

  const { workflowsStore } = useStore();
  const navigate = useNavigate();
  const isValidState = state.name && state.description;

  const handleClose = () => {
    modalInstance(MODAL_IDS.CREATE_WORKFLOW).hide();
    dispatch({ type: "reset" });
  };

  const handleCreateWorkflow = () => {
    if (isValidState) {
      workflowsStore.createNewWorkflow(teamId, state).then((newWorkflow) => {
        handleClose();
        navigate({ pathname: `${newWorkflow.uuid}/configure`, search: `?${createSearchParams({ teamId: teamId })}` });
      });
    } else {
      throw new Error("createWorkflow called with invalid state");
    }
  };

  return (
    <Panel
      description="Configure this workflow to automate the process of transforming your data from a source and send onward to a destination."
      header="New workflow"
      panelId={MODAL_IDS.CREATE_WORKFLOW}
    >
      <PanelBody>
        <PanelHeader
          description="Configure this workflow to automate the process of transforming your data from a source and send onward to a destination."
          header="New workflow"
          onClick={() => {
            dispatch({ type: "reset" });
          }}
        />
      </PanelBody>
      <div className="pt-12 pb-12 pl-32 pr-32">
        <div className="d-flex">
          <InputLabel label="Workflow name *" />
          <div className={["ms-auto", `${styles.labelText}`].join(" ")}>
            {state.name.length}
            {NAME_INPUT_MAX_CHARS && `/${NAME_INPUT_MAX_CHARS}`}
          </div>
        </div>
        <div className="pt-8">
          <Input value={state.name} onChange={(event) => dispatch({ type: "updateName", value: event.target.value })} size="sm" />
        </div>
      </div>
      <div className="pt-12 pb-12 pl-32 pr-32">
        <div className="d-flex">
          <InputLabel label="Description *" />
          <div className={["ms-auto", `${styles.labelText}`].join(" ")}>
            {state.description.length}
            {DESCRIPTION_INPUT_MAX_CHARS && `/${DESCRIPTION_INPUT_MAX_CHARS}`}
          </div>
        </div>
        <div className="pt-4">
          <TextArea
            value={state.description}
            placeholderText={state.description.length === 0 && "Add a description"}
            style={{ minHeight: "112px !important" }}
            onChange={(event) => dispatch({ type: "updateDescription", value: event.target.value })}
            size="sm"
          />
        </div>
      </div>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={handleCreateWorkflow} size="md" disabled={!isValidState}>
          <Text size="sm">Create workflow</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});

export { CreateWorkflow };
