import React, { useContext } from "react";

import { Button } from "@material-ui/core";

import { LumsContext } from "./LumsCommand";

export default function Actions(props) {
  const { onClear, onSaveLookup } = props;

  const lumsContext = useContext(LumsContext);

  const { lookupType, lookupTable, selectedLookupTableColumns, mappedLookupTableColumns, outputColumns } = lumsContext;

  const emptyMappedLookupTableColumns = mappedLookupTableColumns.reduce((columns, column) => {
    const { columnIndex, lookupColumnName } = column;

    if (columnIndex === -1 || lookupColumnName === "") {
      columns.push(column);
    }

    return columns;
  }, []);

  const invalid =
    !lookupTable ||
    (lookupType === "equals" && emptyMappedLookupTableColumns.length > 0) ||
    (lookupType === "search" && selectedLookupTableColumns.length === 0) ||
    (lookupType === "contains" && selectedLookupTableColumns.length === 0) ||
    outputColumns.length === 0;

  return (
    <div className="right">
      <Button className="action" variant="contained" onClick={onClear}>
        Clear
      </Button>

      <Button
        className="action"
        variant="contained"
        color="primary"
        onClick={() => {
          onSaveLookup();
        }}
        disabled={invalid}
      >
        Save Lookup
      </Button>
    </div>
  );
}
