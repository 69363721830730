import { modalInstance, Text, R, IconPencil, IconDelete } from "@fundrecs/ui-library";
import { EntityInputField } from "components/workflows/ConfigureWorkflow/EntityField/EntityField";
import { MODAL_IDS } from "utils/workflows/enums";
import { RULE_TYPE } from "utils/enums";
import { useStore, workflowsStore } from "store/Store";
import { generateText } from "../../reusable/GenerateText";
import { ActionMenu } from "components/workflows/ConfigureWorkflow/ActionMenu/ActionMenu";
import styles from "../../InputEditView.module.scss";

const renderRuleType = (criteria, type) => {
  if (criteria && type !== RULE_TYPE.MATCH_ALL) {
    return (
      <Text element="span" size="xs" variant="tertiary" weight="regular" align="left">
        {generateText(criteria, type)}
      </Text>
    );
  } else {
    return (
      <Text element="span" size="xs" variant="tertiary" weight="regular" align="left">
        Apply this template to all incoming data
      </Text>
    );
  }
};

const TemplateSection = ({
  fieldValues,
  uuid,
  teamId,
  addRuleDetails,
  setEditableRule,
  setEditableRuleIndex,
  setRuleToDelete,
  setTemplateToRemove,
  updateTemplateToChange,
  importRuleUuid,
  createFromScratch,
}) => {
  const { templatesStore, tbStore } = useStore();

  let data = {
    templateName: "", //Visual requirement for render
    templateUuid: "", //Should always be the same, but need it for removing or changing template etc
    rules: [], //This stores an object which holds the index of the rule, so we know what object this is in the templates list fr updating rules.
  };

  const isTemplateValid = (templateUuid) => {
    const templates = templatesStore.getTemplatesLinkedToOutputMapping();
    return templates.some((template) => template.templateUuid === templateUuid);
  };

  fieldValues.templates.map(({ templateUuid, criteria = [], type }, index) => {
    if (uuid === templateUuid) {
      const { name = "" } = templatesStore.getTemplates().filter((template) => template.templateUuid === templateUuid)[0] || {};
      data.templateName = name;
      data.templateUuid = templateUuid;
      data.isTemplateValid = !isTemplateValid(templateUuid);
      data.rules.push({ index, criteria, type, templateUuid });
    }
  });

  const actionMenuItems = [
    {
      key: "VIEW",
      label: "View template",
    },
    {
      key: "CHANGE",
      label: "Change template",
    },
    {
      key: "ADD",
      label: "Add import rule to template",
    },
    {
      key: "REMOVE",
      label: "Remove template from workflow",
    },
  ];

  const onActionClick = (event) => {
    switch (event) {
      case "VIEW":
        workflowsStore.setIsTemplateView(true);
        tbStore.init(data.templateUuid, teamId);
        tbStore.templateBuilderDoneButtonDisabled(true);
        createFromScratch();
        break;
      case "CHANGE":
        updateTemplateToChange(data.templateUuid);
        modalInstance(MODAL_IDS.CHANGE_TEMPLATE.concat(importRuleUuid)).show();
        break;
      case "ADD":
        addRuleDetails({ templateUuid: data.templateUuid });
        modalInstance(MODAL_IDS.ADD_INCLUSION_RULE.concat(importRuleUuid)).show();
        break;
      case "REMOVE":
        modalInstance(MODAL_IDS.CONFIRM_REMOVE_TEMPLATE.concat(importRuleUuid)).show();
        setTemplateToRemove(data.templateUuid);
        break;
      default:
    }
  };

  return (
    <R>
      <EntityInputField name={data.templateName} error={data.isTemplateValid}>
        <div className="justify-content-end">
          <ActionMenu items={actionMenuItems} onItemClick={onActionClick} />
        </div>
      </EntityInputField>
      {data.rules.map(({ index, criteria, type, templateUuid }) => {
        return (
          <div className={[`${styles.ruleStyle}`, "d-flex"].join(" ")}>
            <div>{renderRuleType(criteria, type)}</div>
            <div className={["ms-auto pl-8", `${styles.noFlex}`].join(" ")}>
              <IconPencil
                className={[" btn-md-svg mr-12", `${styles.cursorPointer}`].join(" ")}
                onClick={() => {
                  if (type === RULE_TYPE.MATCH_ALL) {
                    addRuleDetails({ templateUuid: data.templateUuid });
                    modalInstance(MODAL_IDS.ADD_INCLUSION_RULE.concat(importRuleUuid)).show();
                  } else {
                    addRuleDetails({ index: index, templateUuid: templateUuid });
                    setEditableRuleIndex(index);
                    setEditableRule({ criteria: criteria, type: type });
                    modalInstance(MODAL_IDS.ADD_INCLUSION_RULE.concat(importRuleUuid)).show();
                  }
                }}
              />
              <IconDelete
                className={[" btn-md-svg"].join(" ")}
                onClick={() => {
                  modalInstance(MODAL_IDS.CONFIRM_DELETE_IMPORT_RULE.concat(importRuleUuid)).show();
                  setRuleToDelete({ index, templateUuid });
                }}
              />
            </div>
          </div>
        );
      })}
      <div className={styles.borderbottom} />
    </R>
  );
};

export { TemplateSection };
