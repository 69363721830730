import moment from "moment";
/**
 * Uses the value of passed to the component to render  the correct Date format
 */

const checkDateIsToday = (date) => {
  return moment(date).isSame(moment(), "day");
};

const getCalendarDate = (date) => {
  return moment(date).calendar(null, {
    sameDay: "[today]",
    nextDay: "[tomorrow]",
    nextWeek: "dddd",
    lastDay: "[yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "D MMM YYYY, h:mm",
  });
};

const getTime = (date) => {
  return moment(date).format("hh:mm:ss a").toString();
};

const yesterday = moment().subtract(1, "day");

/**
 * This is the only date we have is created
 * @param {date} date
 */
const createdDate = (date) => {
  if (checkDateIsToday(date)) {
    //If created date is today return this
    return <>{"Created Today, ".concat(getTime(date))}</>;
  } else if (moment(date).isSame(yesterday, "day")) {
    //If created date is the same yesterday
    return <>{"Created Yesterday, ".concat(getTime(date))}</>;
  } else {
    return <>{"Created ".concat(getCalendarDate(date))}</>;
  }
};

const editedDate = (date) => {
  if (checkDateIsToday(date)) {
    //If created date is today return this
    return <>{"Last Edited Today, ".concat(getTime(date))}</>;
  } else if (moment(date).isSame(yesterday, "day")) {
    //If created date is the same yesterday
    return <>{"Edited Yesterday, ".concat(getTime(date))}</>;
  } else {
    return <>{"Last Edited, ".concat(getCalendarDate(date))}</>;
  }
};

//Edited date proceeds, created date.
const CreatedEditedDate = (props) => {
  const {
    data: { createdAt, editedAt },
  } = props;
  getCalendarDate(editedAt);
  if (editedAt) {
    return editedDate(editedAt);
  } else {
    return createdDate(createdAt);
  }
};

const createdEditedDateText = (props) => {
  const {
    data: { createdAt, editedAt },
  } = props;
  if (editedAt) {
    return editedDate(editedAt).props.children;
  } else {
    return createdDate(createdAt).props.children;
  }
};
export { CreatedEditedDate, createdEditedDateText };
