import { Button, Text, IconRightArrow, R } from "@fundrecs/ui-library";
import { SelectMethodHeading } from "../../reusable/selectMethod/selectMethodHeading";
import { MethodList } from "../../reusable/methodList/methodList";
import { ModalFooter } from "../../layout/ModalHeader";

const SelectTemplateMethod = ({ setActiveStep }) => {
  const methods = [
    { value: "create", name: "Create new template", description: "Manually transform and map your input data in new template", disabled: false },
    { value: "add", name: "Add template from library", description: "Select a template from your team or company libraries", disabled: true },
    { value: "import", name: "Import template", description: "Import a template to transform and map your input data", disabled: true },
  ];

  return (
    <>
      <R props="ml-72 mr-72 mb-72">
        <SelectMethodHeading
          heading={"How do you want to create your template?"}
          description={"Select the method you want to use to create the template to transform and map your input data."}
          align="left"
        />
        <div className="d-flex justify-content-center mt-24">
          <MethodList methods={methods} def="create" />
        </div>
      </R>
      <ModalFooter>
        <Button
          size="lg"
          color="primary"
          disabled={false}
          onClick={() => {
            setActiveStep({ name: "STEP 02", index: 2 });
          }}
        >
          <Text size="md" weight="medium">
            Next - name & description
          </Text>
          <IconRightArrow style={{ width: "18px", height: "18px", verticalAlign: "baseline" }} />
        </Button>
      </ModalFooter>
    </>
  );
};

export { SelectTemplateMethod };
