/**
 * Represents a stage of the select and transform process
 * viewName - String describes the type of S&T view
 * descriptor - String which describes current state of S&T for user
 * listView - Boolean, true if this view is a list view
 * inputsView - String, 'select' for selection inputs, 'transform' for transformation inputs, else empty
 * andSelect - Array of 'and' selections
 * orSelect - Array of 'or' selections
 * selectAll - Boolean true if all rows selected
 * currentTransformation - Object containing data for selected transformation
 * currentSelection - Currently selected Selection rule, used before selection is saved
 * currentSelectOptions Array Currently available select options based on col->col / col->val comparison
 */
export default class SelectAndTransformView {
  constructor(props) {
    this.viewName = typeof props.viewName !== "undefined" ? props.viewName : "";
    this.descriptor =
      typeof props.descriptor !== "undefined" ? props.descriptor : "";
    this.listView =
      typeof props.listView !== "undefined" ? props.listView : true;
    this.inputsView =
      typeof props.inputsView !== "undefined" ? props.inputsView : "";
    this.andSelect =
      typeof props.andSelect !== "undefined" ? props.andSelect : [];
    this.orSelect = typeof props.orSelect !== "undefined" ? props.orSelect : [];
    this.selectAll =
      typeof props.selectAll !== "undefined" ? props.selectAll : false;
    this.currentTransformation =
      typeof props.currentTransformation !== "undefined"
        ? props.currentTransformation
        : {};
    this.currentSelection =
      typeof props.currentSelection !== "undefined"
        ? props.currentSelection
        : {};
  }
}
