import { useContext } from "react";
import { meStore } from "store/Store";
import { LookupsTableContext } from "../LookupsTable";
import { AddRowButton } from "./buttonsType/AddRow";
import { ApproveRowsButton } from "./buttonsType/ApproveRowsButton";
import { DeleteRowsButtons } from "./buttonsType/DeleteRowsButton";
import { RejectRowsButton } from "./buttonsType/RejectRowsButton";
import { SaveRowButtons } from "./buttonsType/SaveRowButtons";
import { SubmitRowsButton } from "./buttonsType/SubmitRowsButton";
import { HEADER_BUTTON_TYPES } from "utils/lookups/enums";
import { useSubheaderButtonsType } from "../hooks/useSubheaderButtonsType";

const SubheaderButtons = () => {
  const { gridApi, selectedRowsCount, isAddRow } = useContext(LookupsTableContext);

  const buttonsType = useSubheaderButtonsType(gridApi, selectedRowsCount, isAddRow);

  // Only show approve button if at least one selected row can be approved
  const canApproveRows = () => {
    if (selectedRowsCount > 0) {
      const canBeApproved = gridApi.getSelectedRows().filter((row) => {
        // User can only approve rows submitted by other users
        const { email } = meStore.getMe();
        const { createdBy } = row;
        return email !== createdBy;
      });
      return canBeApproved.length > 0;
    }
    return false;
  };

  const renderButtons = (buttonsType) => {
    switch (buttonsType) {
      case HEADER_BUTTON_TYPES.ADD_ROW:
        return <AddRowButton />;

      case HEADER_BUTTON_TYPES.SAVE_ROW:
        return <SaveRowButtons />;
      case HEADER_BUTTON_TYPES.APPROVE_REJECT_ROWS:
        return (
          <>
            <RejectRowsButton />
            <span className="pl-8" />
            {canApproveRows() && (
              <>
                <ApproveRowsButton />
                <span className="pl-8" />
              </>
            )}
            <AddRowButton />
          </>
        );
      case HEADER_BUTTON_TYPES.ARCHIVE_ROWS:
        return <AddRowButton />;
      case HEADER_BUTTON_TYPES.SUBMIT_FOR_APPROVAL_OR_DELETE:
        return (
          <>
            <SubmitRowsButton />
            <span className="pl-8" />
            <DeleteRowsButtons />
            <span className="pl-8" />
            <AddRowButton />
          </>
        );
      default:
        return <AddRowButton />;
    }
  };

  return <>{renderButtons(buttonsType)}</>;
};

export { SubheaderButtons };
