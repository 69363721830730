import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Text, Card, Toggle, R, C2, C4, C6, Input, IconAdd } from "@fundrecs/ui-library";
import { TABLE_TYPES, LOOKUP_PROPERTIES } from "../../../../utils/lookups/enums";
import { LookupTable } from "./LookupTable";
import { SubmitLookupTable } from "./SubmitLookupTable";
import { uiStore } from "../../../../store/Store";
import { lookupValidationMaxColumns } from "../../../../validation/lookup/createNewLookup";

/**
 * The tableType props is either equals or contains
 * @param {tableType} tableType
 *
 * The dispatch prop is a function used to update state in the parent
 * @param {dispatch} dispatch
 *
 * The state prop is given a new name as it clashes with the state in the child.
 * It is the parents state which is passed down to a child for the final submission of your lookup.
 * @param {state} lookupSettings
 *
 * The closeModalAndResetFields prop is a function which is passed to child to close the modal after submission.
 * @param {closeModalAndResetFields} closeModalAndResetFields
 *
 * @returns
 */
const SetupTable = ({ tableType, dispatch, state: lookupSettings, closeModalAndResetFields }) => {
  const { LOOKUP_TABLE_TYPE_EQUALS, LOOKUP_TABLE_TYPE_CONTAINS } = TABLE_TYPES;
  const { LOOKUP_MAX_COLUMNS } = LOOKUP_PROPERTIES;

  const defaultState = {
    header: "",
    unique: false,
    warningBoolean: false,
  };

  const [state, setState] = useState(defaultState);
  const [rowData, setRowData] = useState([]);

  const updateState = (value, key) => {
    setState({ ...state, ...{ [key]: value } });
  };

  /**
   * Checks if string length is within range
   * @returns {Boolean}
   */
  const inRange = (x, min, max) => {
    return x >= min && x <= max;
  };

  const addNewRow = () => {
    if (inRange(state.header.length, 1, 60)) {
      setRowData([...rowData, state]);
      resetForm();
    } else {
      updateState(true, "warningBoolean");
    }
  };

  const resetForm = () => {
    setState(defaultState);
  };

  return (
    <>
      <Text size="lg" weight="bold">
        Add Column Headers
      </Text>

      <div className="mt-16">
        {tableType === LOOKUP_TABLE_TYPE_EQUALS && (
          <Text weight="regular">Define the column headers for your lookup table and select which is the key, unique header (this can be changed later).</Text>
        )}
        {tableType === LOOKUP_TABLE_TYPE_CONTAINS && <Text weight="regular">Define the column headers for your lookup table (this can be changed later)</Text>}
      </div>
      <div className="mt-32">
        <Card>
          <R props="light-grey-bg">
            <C6>
              <div className="pt-16 pl-16 pr-16 pb-16">
                <Text weight="medium">Column Name</Text>
              </div>
            </C6>
            {tableType === LOOKUP_TABLE_TYPE_EQUALS && (
              <C4>
                <div className="pt-16 pl-16 pr-16 pb-16">
                  <Text weight="medium">Unique</Text>
                </div>
              </C4>
            )}
          </R>
          <R>
            <C6>
              <div className="pt-16 pl-16 pr-16 pb-16">
                <Input
                  onChange={(event) => {
                    const { target } = event;
                    const { value } = target;
                    const warning = !state.warningBoolean && false;
                    setState({ ...state, ...{ header: value, warningBoolean: warning } });
                  }}
                  value={state.header}
                  placeholder="Add column header"
                  warning={state.warningBoolean}
                  warningMessage="Alias Table columns must be between 1 and 60 characters in length"
                />
              </div>
            </C6>
            <C4>
              <div className="pt-16 pl-16 pr-16 pb-16">
                {tableType === LOOKUP_TABLE_TYPE_EQUALS && (
                  <Toggle
                    checked={state.unique}
                    onChange={() => {
                      updateState(!state.unique, "unique");
                    }}
                  />
                )}
              </div>
            </C4>
            <C2 props="text-right">
              <div className="pt-16 pl-16 pr-16 pb-16">
                <Button
                  onClick={() => {
                    lookupValidationMaxColumns(rowData, LOOKUP_MAX_COLUMNS, uiStore) && addNewRow();
                  }}
                  size="md"
                >
                  <IconAdd className="btn-md-svg" />
                  <Text size="md">Add</Text>
                </Button>
              </div>
            </C2>
          </R>
        </Card>
      </div>
      <LookupTable rowData={rowData} tableType={tableType} />
      <SubmitLookupTable state={lookupSettings} rowData={rowData} dispatch={dispatch} closeModalAndResetFields={closeModalAndResetFields} />
    </>
  );
};

//Proptypes for SetupTable
SetupTable.propTypes = {
  tableType: PropTypes.string,
  state: PropTypes.object,
  closeModalAndResetFields: PropTypes.func,
  dispatch: PropTypes.func,
};

// Specifies the default values for props:
SetupTable.defaultProps = {
  tableType: PropTypes.oneOf([TABLE_TYPES.LOOKUP_TABLE_TYPE_CONTAINS, TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS]),
  closeModalAndResetFields: () => {},
  dispatch: () => {},
};

export { SetupTable };
