/**
 * Represents the Options to show in selectandtransform's select operations.
 */

export default class TransformOptions {
  constructor(props) {
    this.peName = props.peName;
    this.uiName = props.uiName;
    this.uiDesc = props.uiDesc;
    this.isArg1Index = props.isArg1Index;
    this.isArg2Index = props.isArg2Index;
    this.argSeperator = props.argSeperator;
    this.isTransformChainExclusive = props.isTransformChainExclusive;
    this.type = props.type;
  }

  /**
   * Builds the list of commands available in backend from json response*
   * @param commandListJson
   * @returns {*}
   */
  static buildTransformOptionsFromJson(transformOptionsJson) {
    return transformOptionsJson.map((transformJson) => {
      return new TransformOptions(transformJson);
    });
  }
}
