import React from "react";
import "./commandparams.css";
import {
  Menu,
  MenuItem,
  TextField,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  Checkbox,
  Typography,
} from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ClearIcon from "@material-ui/icons/Clear";

import { tbAppChannel } from "../utils/communication/AppChannels";
import { appSingleton } from "../utils/AppSingleton";
import HttpMediator from "../utils/http/HttpMediator";

export default class ParseFileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined, //used to set where the popup menus will appear , both parserMneu and FileMenu
      openParserMenu: false,
      openFileMenu: false,
      parserName: this.props.commandParams.userInputargs.parserName,
      parserUIName: this.getParserUiName(
        this.props.commandParams.userInputargs.parserName
      ),
      parserConfig: this.props.commandParams.userInputargs.parserConfig,
      updateFile: false, //true when user chooses to change the file (either to new or previous)
      parserConfigChanged: false,
    };
  }

  ParseFileComponentUpdate = () => {
    if (
      this.file != undefined ||
      this.state.parserName !=
        this.props.commandParams.userInputargs.parserName ||
      this.state.parserConfigChanged
    ) {
      return (
        <Button
          className="update-button frButtonStyle"
          variant="raised"
          onClick={this.postUpdate.bind(this)}
        >
          Update
        </Button>
      );
    }
  };

  ParseFileComponentSortText = (parserConfigParams) => {
    if (parserConfigParams.length == 0) {
      return (
        <span>
          <Typography>No Parsing Options</Typography>
        </span>
      );
    } else {
      return (
        <Grid container spacing={8}>
          {Object.keys(parserConfigParams).map((property, index) => {
            if (property == "sortByPosition") {
              return (
                <Grid item xs={3} key={index}>
                  <div>
                    <Typography>Sort text by Position</Typography>
                    <Checkbox
                      defaultChecked={parserConfigParams[property] == "true"}
                      label="Sort text by Position"
                      className="command-params-input-field"
                      data-name="sortByPosition"
                      onChange={(event) => {
                        const parserConfig = parserConfigParams;
                        if (event.target.checked) {
                          parserConfig[property] = "true";
                        } else {
                          parserConfig[property] = "false";
                        }
                        this.setState({
                          parserConfig: parserConfig,
                          parserConfigChanged: true,
                        });
                      }}
                    />
                  </div>
                </Grid>
              );
            }
            return (
              <Grid item xs={3} key={index}>
                <div>
                  <Typography className="command-params-title">
                    {property}
                  </Typography>
                  <TextField
                    style={{
                      backgroundColor: "white",
                      borderColor: "black",
                      border: "1px solid",
                    }}
                    value={parserConfigParams[property]}
                    onChange={(event) => {
                      const parserConfig = parserConfigParams;
                      parserConfig[property] = event.target.value;
                      this.setState({
                        parserConfig: parserConfig,
                        parserConfigChanged: true,
                      });
                    }}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      );
    }
  };

  render() {
    return (
      <Paper className="command-params-root">
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <List>
              <ListItem
                button
                aria-owns="parse-file-menu"
                aria-haspopup="true"
                aria-label={this.state.parserUIName}
                onClick={this.handleParserNamesMenuClick.bind(this)}
                divider={true}
                disabled={appSingleton.readOnly}
              >
                <ListItemText
                  primary={this.state.parserUIName}
                  secondary="Change File Type"
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary="Current File"
                  secondary={appSingleton.allFileS3Urns[0][0]}
                />
              </ListItem>
              <Menu
                id="parse-file-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.openParserMenu}
                onClose={this.handleParserNamesMenuClose.bind(this)}
              >
                {appSingleton.parsers.map((option, index) => {
                  return (
                    <MenuItem
                      key={index}
                      selected={
                        this.props.commandParams.userInputargs.parserName ===
                        option.parserName
                      }
                      onClick={(event) =>
                        this.handleMenuItemClick(event, option)
                      }
                    >
                      {option.uiName}
                    </MenuItem>
                  );
                })}
              </Menu>
            </List>
          </Grid>
          <Grid item xs={7}>
            {this.getCommandParams()}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              title="Back"
              onClick={() => {
                tbAppChannel.publish(
                  this.props.title
                    ? "hideCommandParamsBackToCommandList"
                    : "hideCommandParams",
                  {}
                );
              }}
            >
              <KeyboardReturnIcon/>
            </IconButton>
            <IconButton
              name="back"
              title="Close command details"
              onClick={() => {
                tbAppChannel.publish("hideCommandParams", {});
              }}
            >
              <ClearIcon/>
            </IconButton>

            {this.ParseFileComponentUpdate()}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  handleParserNamesMenuClick(event) {
    this.setState({ openParserMenu: true, anchorEl: event.currentTarget });
  }

  handleParserNamesMenuClose(event) {
    this.setState({ openParserMenu: false });
  }

  handleMenuItemClick(event, option) {
    this.setState({
      openParserMenu: false,
      parserName: option.parserName,
      parserConfig: option.defaultOptions,
      parserUIName: this.getParserUiName(option.parserName),
    });
  }

  handleFileMenuItemClick(event, fileDetails) {
    //this.setState({ openParserMenu: true, anchorEl: event.currentTarget });
    this.setState({
      fileName: fileDetails[0],
      fileS3Urn: fileDetails[1],
      openFileMenu: false,
    });
  }

  handleFileMenuButton(event) {
    this.setState({ openFileMenu: true, anchorEl: event.currentTarget });
  }

  postUpdate() {
    const { parserName, parserConfig } = this.state;

    HttpMediator.updateParseFile({ parserName, parserConfig });

    tbAppChannel.publish("hideCommandParams", {});
  }

  getCommandParams() {
    let parserConfigParams = this.state.parserConfig;

    if (parserConfigParams == null || parserConfigParams == undefined)
      parserConfigParams = {};

    return <div>{this.ParseFileComponentSortText(parserConfigParams)}</div>;
  }

  getParserUiName(parserName) {
    let parserDesc = appSingleton.parsers.find(
      (parserDesc) => parserDesc.parserName == parserName
    );
    return parserDesc.uiName;
  }
}
