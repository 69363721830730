import PropTypes from "prop-types";
import { Text, R, C, C10, Toggle, Section } from "@fundrecs/ui-library";

const ToggleSwitchSection = ({ title, description, onChange, checked, disabled }) => {
  return (
    <Section top={true} bottom={true}>
      <R>
        <C10 props="align-self-center pl-0">
          <Text variant="primary" size="sm" weight="medium" element="div">
            {title}
          </Text>
        </C10>
        <C props="d-flex flex-row-reverse pr-0">
          <Toggle
            disabled={disabled}
            checked={checked}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </C>
      </R>
      <R>
        <C10 props="align-self-center pl-0">
          <Text variant="tertiary" size="xs" weight="regular" element="div">
            {description}
          </Text>
        </C10>
      </R>
    </Section>
  );
};

//Proptypes for ToggleSwitchSection
ToggleSwitchSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

// Specifies the default values for props:
ToggleSwitchSection.defaultProps = {
  title: "",
  description: "",
  onChange: () => {},
  checked: false,
  disabled: false,
};

export { ToggleSwitchSection };
