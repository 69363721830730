import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { VerticalMenuDropdown, VerticalMenuListItem, Button, IconCheck, Text, modalInstance, IconCloseRemove, IconAiGrey } from "@fundrecs/ui-library";
import { AuthWrapper } from "components/AuthorizationWrapper";
import { AUTHORITIES } from "utils/enums";
import { MODAL_IDS, STATUS_VALUES } from "utils/templates/enums";
import { useStore } from "store/Store";
import { DraftAndRejectedTemplateSubmitAndExitModal } from "../Modals/DraftAndRejectedTemplateSubmitAndExitModal";
import { FourEyesDisabledPublishAndExitModal } from "../Modals/FourEyesDisabledPublishAndExitModal";
import { PendingTemplateApproveAndExitModal } from "../Modals/PendingTemplateApproveAndExitModal";
import { PendingTemplateRejectAndExitModal } from "../Modals/PendingTemplateRejectAndExitModal";
import { ExplainTemplateModal } from "../Modals/ExplainTemplateModal";
import { useExplainTemplate } from "components/templates/hooks/useExplainTemplate";
import styles from "./HeaderButtons.module.scss";

const HeaderButtons = observer(({ teamId, template, onClose }) => {
  const { tbStore, templatesStore, rolesStore, meStore } = useStore();
  const { status = STATUS_VALUES.DRAFT, name = "" } = template;

  const [templateFourEyes, setTemplateFourEyes] = useState(false);
  const { requesting, setRequesting, explainedTemplate, setExplainedTemplate, handleExplainTemplate } = useExplainTemplate();

  useEffect(async () => {
    const settings = await meStore.requestTeamSetting(teamId);
    const templateSettings = settings.filter((setting) => {
      const { settingFor } = setting;
      return settingFor.includes("templates");
    });

    const { value } = templateSettings[0];

    setTemplateFourEyes(value);
  }, []);

  const handleApproveAndExit = async () => {
    const { success } = await templatesStore.approveTemplate(teamId, template.templateUuid);
    if (success) {
      modalInstance(MODAL_IDS.PENDING_TEMPLATE_APPROVE_AND_EXIT).toggle();
      templatesStore.loadTemplates(teamId);
      onClose();
    }
  };

  const handlePublishAndExit = async () => {
    const { success } = await templatesStore.publishTemplate(teamId, template.templateUuid);
    if (success) {
      modalInstance(MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT).toggle();
      templatesStore.loadTemplates(teamId);
      onClose();
    }
  };

  const handleSubmitAndExit = async () => {
    const { success } = await templatesStore.submitTemplate(teamId, template.templateUuid);
    if (success) {
      modalInstance(MODAL_IDS.DRAFT_TEMPLATE_SUBMIT_AND_EXIT).toggle();
      templatesStore.loadTemplates(teamId);
      onClose();
    }
  };

  const handleRejectAndExit = async () => {
    const { success } = await templatesStore.rejectTemplate(teamId, template.templateUuid);
    if (success) {
      modalInstance(MODAL_IDS.PENDING_TEMPLATE_REJECT_AND_EXIT).toggle();
      templatesStore.loadTemplates(teamId);
      onClose();
    }
  };

  const handleClone = async () => {
    const { success } = await templatesStore.cloneTemplate(teamId, template.templateUuid);
    if (success) {
      templatesStore.loadTemplates(teamId);
    }
  };

  const templateFourEyesBoolean = templateFourEyes === "true" ? true : false;

  return (
    <div className="d-flex">
      <ExplainTemplateModal template={template} explainedTemplate={explainedTemplate} setExplainedTemplate={setExplainedTemplate} />
      <DraftAndRejectedTemplateSubmitAndExitModal name={name} onConfirm={handleSubmitAndExit} />
      <FourEyesDisabledPublishAndExitModal name={name} onConfirm={handlePublishAndExit} />
      <PendingTemplateApproveAndExitModal name={name} onConfirm={handleApproveAndExit} />
      <PendingTemplateRejectAndExitModal name={name} onConfirm={handleRejectAndExit} />

      {!templateFourEyesBoolean && status === STATUS_VALUES.DRAFT && (
        <>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCheck className="btn-md-svg" />
            <Text size="sm">Publish & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      {!templateFourEyesBoolean && status === STATUS_VALUES.PENDING && (
        <>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCheck className="btn-md-svg" />
            <Text size="sm">Publish & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      {!templateFourEyesBoolean && status === STATUS_VALUES.REJECTED && (
        <>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.FOUR_EYES_DISABLED_PUBLISH_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCheck className="btn-md-svg" />
            <Text size="sm">Publish & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      {templateFourEyesBoolean && status === STATUS_VALUES.PENDING && (
        <>
          <span className="mr-12">
            <Button
              size="md"
              color="success-primary"
              onClick={() => {
                modalInstance(MODAL_IDS.PENDING_TEMPLATE_APPROVE_AND_EXIT).toggle();
              }}
              disabled={tbStore.doneButtonDisabled}
            >
              <IconCheck className="btn-md-svg" />
              <Text size="sm">Approve & exit</Text>
            </Button>
          </span>
          <Button
            size="md"
            color="danger"
            onClick={() => {
              modalInstance(MODAL_IDS.PENDING_TEMPLATE_REJECT_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCloseRemove className="btn-md-svg" />
            <Text size="sm">Reject & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      {templateFourEyesBoolean && status === STATUS_VALUES.DRAFT && (
        <>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.DRAFT_TEMPLATE_SUBMIT_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCheck className="btn-md-svg" />
            <Text size="sm">Submit & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      {templateFourEyesBoolean && status === STATUS_VALUES.REJECTED && (
        <>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              modalInstance(MODAL_IDS.DRAFT_TEMPLATE_SUBMIT_AND_EXIT).toggle();
            }}
            disabled={tbStore.doneButtonDisabled}
          >
            <IconCheck className="btn-md-svg" />
            <Text size="sm">Submit & exit</Text>
          </Button>
          <div className={styles.verticalDivider} />
        </>
      )}

      <div className={styles.betaStatus}>Beta</div>
      <Button
        size="md"
        color="tertiary"
        onClick={() => {
          setRequesting(true);
          handleExplainTemplate(template);
        }}
        disabled={requesting}
        classes={styles.aiSummaryButton}
      >
        <IconAiGrey className="btn-md-svg" />
        <Text size="sm">AI summary</Text>
      </Button>

      <span className="pl-16" />
      <div className={styles.circle}>
        <VerticalMenuDropdown>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.TEMPLATE_EDIT])}>
            <VerticalMenuListItem
              text="Settings"
              onClick={() => {
                modalInstance(MODAL_IDS.EDIT_TEMPLATE_SETTINGS).toggle();
              }}
            />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.TEMPLATE_CREATE])}>
            <VerticalMenuListItem text="Clone" onClick={handleClone} />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.TEMPLATE_CREATE])}>
            <VerticalMenuListItem text="Export" disabled={true} />
          </AuthWrapper>
          <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.TEMPLATE_DELETE])}>
            <VerticalMenuListItem text="Delete" onClick={() => modalInstance(MODAL_IDS.DELETE_TEMPLATE).toggle()} />
          </AuthWrapper>
        </VerticalMenuDropdown>
      </div>
    </div>
  );
});
export { HeaderButtons };
