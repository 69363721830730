import { useNavigate, createSearchParams } from "react-router-dom";
import { Heading, R, C, Text, Button, IconAdd, EmptyWorkflow } from "@fundrecs/ui-library";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES } from "../../utils/enums";
import { useStore } from "../../store/Store";
import { useTeamId } from "store/hooks/useTeamId";

const NoTemplates = () => {
  const pointerStyle = {
    pointerEvents: "auto",
  };

  const { rolesStore } = useStore();
  const teamId = useTeamId();
  const navigate = useNavigate();

  const handleCreateTemplate = () => {
    navigate({ pathname: "/templates/create", search: `?${createSearchParams({ teamId: teamId })}` });
  };

  return (
    <>
      <R>
        <span className="emptyState-icon">
          <EmptyWorkflow />
        </span>
      </R>
      <R>
        <C props="pt-16">
          <Heading variant="h4" align="center">
            No templates
          </Heading>
        </C>
      </R>
      <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.WORKFLOW_CREATE])}>
        <R>
          <C>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                Click the "+ New template" button below to add your first
              </Text>
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="div">
                template to your team's resources
              </Text>
            </div>
          </C>
        </R>
        <R>
          <C>
            <div className="pt-32 pb-32" style={pointerStyle}>
              <Button className={pointerStyle} onClick={handleCreateTemplate} size="md">
                <IconAdd className={"btn-md-svg"} />
                <Text size="sm">New template</Text>
              </Button>
            </div>
          </C>
        </R>
      </AuthWrapper>
    </>
  );
};

export { NoTemplates };
