import React, { useContext } from "react";

import {
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { useSelect } from "downshift";

import "./styles.css";

import { LumsContext } from "./LumsCommand";

const options = [
  { label: "Newest First", value: "first" },
  { label: "Oldest First", value: "last" },
];

export default function SelectReturnOrder() {
  const lumsContext = useContext(LumsContext);

  const { returnOrder, setReturnOrder } = lumsContext;

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } =
    useSelect({
      items: options,
      onSelectedItemChange: (changes) => {
        const { selectedItem: option } = changes;

        const { value } = option;

        setReturnOrder(value);
      },
    });

  const selectedOption = options.find((option) => option.value === returnOrder);

  return (
    <div className="input-combo">
      <span className="input-label">
        For values with the same priority return
      </span>

      <span className="select-container">
        <TextField
          className="select-input"
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={selectedOption.label}
          {...getToggleButtonProps()}
        />

        <span className="select-list">
          <List disablePadding {...getMenuProps()}>
            {isOpen &&
              options.map((option, index) => {
                const { label } = option;

                return (
                  <ListItem
                    button
                    key={index}
                    {...getItemProps({ item: option, index })}
                  >
                    <ListItemText primary={label} />
                  </ListItem>
                );
              })}
          </List>
        </span>
      </span>
    </div>
  );
}
