import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import moment from "moment";
import { useStore } from "store/Store";
import { R, Text, Button, IconAdd, modalInstance, C3 } from "@fundrecs/ui-library";
import { AuthWrapper, isUserAuthorized } from "components/AuthorizationWrapper";
import { ManageLayout, PageTitleArea } from "components/layout/Layout";
import { MODAL_IDS } from "utils/lookups/enums";
import { AUTHORITIES } from "utils/enums";
import { Table } from "components/ag-grid/Ag-grid";
import { VerticalMenu } from "components/ag-grid/verticalMenu/VerticalMenu";
import { CreatedEditedDate, createdEditedDateText } from "components/ag-grid/createdEditedDate/CreatedEditedDate";
import { CreateLookup } from "../CreateLookup/CreateLookup";
import { DeleteLookupTableModal } from "../LookupModals/DeleteLookupTableModal";
import { LookupTableTypeFilter } from "../LookupFilters/Filters";
import { DropdownPanelCell } from "./dropdownPanel/DropdownPanelCell";
import { PendingRowsIcon } from "./pendingRowsIcon/PendingRowsIcon";
import { NoLookups } from "./noLookups/NoLookups";
import { DownloadTable } from "components/reusable/Button/DownloadTable";
import { useSelectedTeam } from "store/hooks/useSelectedTeam";

const LookupsList = observer(({ teamId }) => {
  const navigate = useNavigate();
  const { meStore, rolesStore, lookupsStore } = useStore();
  const [gridApi, setGridApi] = useState(null);
  const [lookupTable, setLookupTable] = useState({});
  const [lookups, setLookups] = useState(null);

  const { teamName } = useSelectedTeam();

  const today = moment().format(meStore.getUserDateFormat());
  const fileName = `All lookups for ${teamName}-${today}`;

  const onGridReady = (params) => {
    if (!gridApi) {
      setGridApi(params);
    }
  };

  useEffect(() => {
    // Show ag-grid spinner until reaction updates state with data from store
    gridApi?.api?.showLoadingOverlay();

    /**
     * Mobx Reaction to keep useState in sync with store
     * Whenever the observable data changes, it triggers the effect
     * The effect checks if the component is mounted and the store already finished to fetch the data
     *
     * For more details refer to:
     * https://mobx.js.org/reactions.html#reaction
     *
     * @param {Function} ObservableData - Tracks the observable.
     * @param {Function} Effect - Reacts to changes in the observable.
     */
    const disposer = reaction(
      () => lookupsStore.getLookupTables(), // Observable data
      (lookupsListForSelectedTeam) => {
        // Effect
        setLookups(lookupsListForSelectedTeam?.length > 0 ? lookupsListForSelectedTeam : []);
      }
    );
    // Cleanup reaction
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    // Keep first row expanded if data changes after onFirstDataRendered
    if (lookups?.length > 0) {
      gridApi?.api?.getDisplayedRowAtIndex(0).setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookups]);

  const onCellClick = (event) => {
    if (event?.column?.colId !== "rowEndSettings") {
      navigate({ pathname: "/lookups", search: `?${createSearchParams({ teamId: event?.data?.teamId, tableId: event?.data?.uuid })}` });
    }
  };

  const toggleDeleteModal = (props) => {
    const { data } = props;
    setLookupTable(data);
    modalInstance(MODAL_IDS.DELETE_LOOKUP_TABLE_MODAL_ID).toggle();
  };

  const onItemClick = (option, props) => {
    switch (option) {
      case "view":
        const { data } = props;
        navigate({ pathname: "/lookups", search: `?${createSearchParams({ teamId: data?.teamId, tableId: data?.uuid })}` });
        break;
      case "delete":
        toggleDeleteModal(props);
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      key: "view",
      label: "View",
      visible: true,
    },

    {
      key: "delete",
      label: "Delete",
      visible: isUserAuthorized({ teamId, allRequired: rolesStore.getActions([AUTHORITIES.LOOKUP_DELETE]) }),
    },
  ];

  const onFirstDataRendered = (params) => {
    setTimeout(() => {
      params?.api?.getDisplayedRowAtIndex(0).setExpanded(true);
    }, 0);
  };

  const columns = [
    {
      headerName: "",
      field: "PendingRowsIcon",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      cellRenderer: PendingRowsIcon,
      width: 50,
      sortable: false,
      suppressMenu: true,
    },
    {
      headerName: "",
      field: "dropdown_trigger",
      cellRenderer: "agGroupCellRenderer",
      width: 60,
      sortable: false,
      suppressMenu: true,
    },
    { headerName: "Name", field: "name", flex: 2, rowDrag: false, wrapText: false },
    {
      headerName: "Description",
      field: "description",
      flex: 2,
      rowDrag: false,
      wrapText: false,
    },
    {
      headerName: "Audit log",
      field: "createdAt",
      flex: 2,
      rowDrag: false,
      wrapText: false,
      cellRenderer: CreatedEditedDate,
      sort: "desc",
      filter: "agTextColumnFilter",
      filterParams: {
        valueGetter: function (params) {
          return params;
        },
        textFormatter: function (r) {
          return r;
        },
        textCustomComparator: function (filter, value, filterText) {
          switch (filter) {
            case "contains":
              return createdEditedDateText(value).includes(filterText);
            case "notContains":
              return !createdEditedDateText(value).includes(filterText);
            case "equals":
              return createdEditedDateText(value) === filterText;
            case "notEqual":
              return createdEditedDateText(value) !== filterText;
            case "startsWith":
              return createdEditedDateText(value).startsWith(filterText);
            case "endsWith":
              return createdEditedDateText(value).endsWith(filterText);
            default:
              return false;
          }
        },
      },
    },
    {
      headerName: "",
      field: "rowEndSettings",
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      pinned: "right",
      width: 60,
      sortable: false,
      cellRenderer: VerticalMenu,
      suppressMenu: true,
      cellRendererParams: { menuItems: menuItems, onItemClick: onItemClick },
    },
    { headerName: "Type", field: "type", editable: false, hide: true }, // Used for filtering only
  ];

  const gridOptions = {
    suppressCellFocus: true,
    masterDetail: true,
    detailRowAutoHeight: true,
    suppressContextMenu: true,
    suppressRowClickSelection: true,
    noRowsOverlayComponent: NoLookups,
    onFirstDataRendered: onFirstDataRendered,
    onGridReady: onGridReady,
    onCellClicked: onCellClick,
    detailCellRenderer: "myDetailCellRenderer",
    components: { myDetailCellRenderer: DropdownPanelCell },
  };

  return (
    <>
      <CreateLookup CREATE_NEW_LOOKUP_MODAL_ID={MODAL_IDS.CREATE_NEW_LOOKUP_MODAL_ID} teamId={teamId} />
      <DeleteLookupTableModal modalId={MODAL_IDS.DELETE_LOOKUP_TABLE_MODAL_ID} lookup={lookupTable} />
      <PageTitleArea leftArrow={false} title="Lookups" description={<div className="text-medium pb-4">View your team's lookup tables</div>}>
        <AuthWrapper teamId={teamId} allRequired={rolesStore.getActions([AUTHORITIES.LOOKUP_CREATE])}>
          <Button size="md" color="primary" onClick={handleCreateLookupClick}>
            <IconAdd className={"btn-md-svg"} />
            <Text size="sm">New Lookup</Text>
          </Button>
        </AuthWrapper>
      </PageTitleArea>
      <ManageLayout>
        <R props="d-flex pt-32 pl-0 pb-20">
          <C3>
            <div className="d-flex">
              <LookupTableTypeFilter />
              <span className="pl-16 align-self-center">
                <DownloadTable fileName={fileName} gridRef={gridApi} includeColumns={["name", "description", "createdAt", "type"]} />
              </span>
            </div>
          </C3>
        </R>
        <Table columns={columns} rowData={lookups} agGridOptions={gridOptions} />
      </ManageLayout>
    </>
  );
});

const handleCreateLookupClick = () => {
  modalInstance(MODAL_IDS.CREATE_NEW_LOOKUP_MODAL_ID).toggle();
};

export { LookupsList, handleCreateLookupClick };
