import { observer } from "mobx-react-lite";
import { Button, Text, IconRightArrow } from "@fundrecs/ui-library";
import { SelectMethodHeading } from "../../reusable/selectMethod/selectMethodHeading";
import { MethodList } from "../../reusable/methodList/methodList";
import { FixedFooter } from "components/layout/FixedFooter";

const SelectOutputMappingMethod = observer(({ setActiveStep }) => {
  const methods = [
    {
      value: "create_new",
      name: "Create new output mapping",
      description: "Manually add the output columns you want for your output file",
      disabled: false,
      props: <></>,
    },
    {
      value: "create_file",
      name: "Create output mapping using a file",
      description: (
        <>
          <div className="d-flex">
            <Text element="div" size="sm" weight="regular" variant="secondary">
              Select or upload a file to add your output columns.
            </Text>

            <span className="pl-4">
              <Text element="div" size="sm" weight="regular" variant="link">
                Download template
              </Text>
            </span>
          </div>
        </>
      ),
      disabled: true,
      props: <></>,
    },
    {
      value: "select",
      name: "Select output mapping from library",
      description: "Select and output mapping from your team or company libraries",
      disabled: true,
      props: <></>,
    },
    { value: "import", name: "Import output mapping", description: "Import an output mapping to add your output columns", disabled: true, props: <></> },
  ];

  return (
    <>
      <div className="ml-72 mr-72 mb-72">
        <SelectMethodHeading
          heading={"How do you want to create your output mapping?"}
          description={"Select the method you want to use to create the output mapping that contains your output columns"}
          align="left"
        />
        <div className="d-flex justify-content-center mt-24">
          <MethodList methods={methods} def="create_new" />
        </div>
      </div>
      <FixedFooter>
        <Button
          size="lg"
          color="primary"
          disabled={false}
          onClick={() => {
            setActiveStep({ name: "STEP 02", index: 2 });
          }}
        >
          <Text size="md" weight="medium">
            Next - name & description
          </Text>
          <IconRightArrow />
        </Button>
      </FixedFooter>
    </>
  );
});

export { SelectOutputMappingMethod };
