import { NumberedInputPanelWithTitleDescription } from "../reusable/NumberedInputPanelWithTitleDescription";
import PropTypes from "prop-types";
import { TABLE_TYPES } from "../../../../utils/lookups/enums";
/**
 * This component returns the Additional Settings section within tab 1 of the Setup New Lookup Section.
 * This only renders if you have already selected a table type.
 */
const SetupAdditonalSettings = ({ tableType, children }) => {
  return (
    <>
      {tableType && (
        <NumberedInputPanelWithTitleDescription
          index={2}
          title="Additional Settings"
          description="Choose what settings you'd like for this lookup table. We recommend having lookup entries approval switched on."
        >
          {children}
        </NumberedInputPanelWithTitleDescription>
      )}
    </>
  );
};

//Proptypes for SetupAdditonalSettings
SetupAdditonalSettings.propTypes = {
  tableType: PropTypes.oneOf([TABLE_TYPES.LOOKUP_TABLE_TYPE_CONTAINS, TABLE_TYPES.LOOKUP_TABLE_TYPE_EQUALS, ""]),
  children: PropTypes.node,
};

export { SetupAdditonalSettings };
