import PropTypes from "prop-types";
import {
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  DropdownWarning,
  ItemBody,
  IconDownArrow,
  DropdownItem,
  DropdownList,
  DropdownListItem,
} from "@fundrecs/ui-library";
import { NumberedInputPanelWithTitleDescription } from "../reusable/NumberedInputPanelWithTitleDescription";

/**
 * This component returns the Lookup Type setup section within tab 1 of the Setup New Lookup Section.
 */
const SetupLookupType = ({ tableType, dropdownWarning, lookupTypeSaved, tableTypeArray, dispatch }) => {
  return (
    <NumberedInputPanelWithTitleDescription index={1} title="Lookup Type" description="Select the appropriate lookup type">
      <div className="mb-12">
        <Text size="md" weight="regular" element="div">
          The lookup type will define how the lookup can be used
        </Text>
      </div>
      <Dropdown spacers={["mr-12"]}>
        <DropdownButton warning={dropdownWarning} disabled={lookupTypeSaved} size={"md"}>
          <DropdownButtonText>
            <ItemBody>
              <>{tableType === "" ? "Select Type" : tableType}</>
            </ItemBody>
            <IconDownArrow className={`btn-${"md"}-svg`} />
          </DropdownButtonText>
        </DropdownButton>
        <DropdownWarning warning={dropdownWarning} warningMessage={"A lookup type must be selected"} />
        <DropdownList>
          {tableTypeArray.map((item, index) => {
            return (
              <DropdownListItem
                key={index}
                onClick={() => {
                  //Reset the warning when the user is interacting with the dropdown again.
                  const warning = !dropdownWarning && false;
                  dispatch({
                    lookupEntriesRequireApproval: false,
                    lookupEntriesAreCaseSensitive: false,
                    lookupEntriesCanBeOverwritten: false,
                    tableType: item,
                    dropdownWarning: warning,
                  });
                }}
              >
                <DropdownItem active={false} index={index}>
                  <ItemBody>{item}</ItemBody>
                </DropdownItem>
              </DropdownListItem>
            );
          })}
        </DropdownList>
      </Dropdown>
    </NumberedInputPanelWithTitleDescription>
  );
};

//Proptypes for SetupLookupType
SetupLookupType.propTypes = {
  tableType: PropTypes.string,
  dropdownWarning: PropTypes.bool,
  lookupTypeSaved: PropTypes.bool,
  tableTypeArray: PropTypes.array,
  dispatch: PropTypes.func,
};

// Specifies the default values for props:
SetupLookupType.defaultProps = {
  tableType: "",
  dropdownWarning: false,
  lookupTypeSaved: false,
  tableTypeArray: [],
  dispatch: () => {},
};

export { SetupLookupType };
