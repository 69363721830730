import { useState, useEffect, useRef } from "react";
import "flatpickr/dist/themes/material_blue.css";
import flatpickr from "flatpickr";

import {
  R,
  C,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  SelectedIcon,
} from "@fundrecs/ui-library";
//import { LookupsContext } from "../Lookups";

// For MVP the filters are just disabled components
// When refactoring Lookups on mid 2022 this file was just commented out to not use things removed from context
// This was NOT refactored!

/**
 * Filter used in the subheader on manage tables view.
 * This file is not complete as it is not part of MVP.
 */

const LookupTableTypeFilter = () => {
  //const { gridApi, tables = [] } = {}; useContext(LookupsContext);

  const options = [
    { label: "All lookups", value: 0, tableType: "" },
    { label: "Equals lookups", value: 1, tableType: "equals" },
    { label: "Contains lookups", value: 2, tableType: "contains" },
  ];
  const [selectedValue] = useState(0);
  const selectedOption = options.find((option) => option.value === selectedValue);

  /*const filterTable = (tableType) => {
    gridApi.getFilterInstance("type", (filterInstance) => {
      if (tableType === "") {
        filterInstance.setModel(null);
      } else {
        filterInstance.setModel({
          type: "equals", //this is the filter type, not related to table type equals/contains
          filter: tableType,
        });
      }

      gridApi.onFilterChanged();
    });
  };*/

  /*if (tables.length === 0) {
    return <></>;
  } else {*/
  return (
    <Dropdown>
      <DropdownButton size="sm" disabled={true}>
        <DropdownButtonText>
          <ItemBody>
            <span>Show: {selectedOption.label}</span>
          </ItemBody>
          <IconDownArrow className="btn-sm-svg" />
        </DropdownButtonText>
      </DropdownButton>

      <DropdownList>
        {options.map(({ value, label, tableType }, index) => (
          <DropdownListItem
            key={index}
            onClick={() => {
              //setSelectedValue(value);
              //filterTable(tableType);
            }}
          >
            <DropdownItem active={selectedValue} index={index}>
              <ItemBody>{label}</ItemBody>
              <SelectedIcon active={selectedValue} index={index} />
            </DropdownItem>
          </DropdownListItem>
        ))}
      </DropdownList>
    </Dropdown>
  );
  //}
};

/**
 * Filter used in the subheader on contains and equals views
 */
const LookupRowsFilter = () => {
  /*const { gridApi } = {}; useContext(LookupsContext);
  useEffect(() => {
    if (gridApi) {
      // filterTable();
    }
  }, [gridApi]);

  const selectAllOption = "All rows";
  const initialOptions = [
    {
      checked: false,
      label: "Pending",
      filterText: "Pending",
    },
    {
      checked: false,
      label: "Published",
      filterText: "Published",
    },
    {
      checked: false,
      label: "Draft",
      filterText: "Draft",
    },
    {
      checked: false,
      label: "Archived",
      filterText: "Archived",
    },
    {
      checked: true,
      label: "All rows",
      filterText: "all",
    },
  ];

  const [filterOptions, setFilterOptions] = useState(initialOptions);

  const generateDisplayText = () => {
    const selectedOptions = filterOptions.filter((o) => o.checked === true);
    let text = "Show:";

    if (selectedOptions.length) {
      selectedOptions.forEach((option) => {
        text += `${option.label}, `;
      });
      // Remove final comma and space from the string
      text = text.slice(0, text.length - 2);
    }
    return text;
  };

  const [displayText, setDisplayText] = useState(generateDisplayText());

  const toggleOption = (event, option) => {
    event.stopPropagation();
    const { checked } = event.target;

    // Add all options to selectedOptions
    if (option.label === selectAllOption && checked) {
      const selectAllOptions = filterOptions.map((op) => ({ ...op, checked: true }));
      setFilterOptions(selectAllOptions);
      setDisplayText(`Show: ${selectAllOption}`);
      filterTable(true);
    } else {
      let op = filterOptions.find((o) => o.label === option.label);
      let index = filterOptions.indexOf(op);
      filterOptions.fill((op.checked = checked), index, index++);
      setFilterOptions(filterOptions);
      setDisplayText(generateDisplayText());
      filterTable();
    }
  };

  const filterTable = (clear = false) => {
    gridApi.getFilterInstance("status", (filterInstance) => {
      if (clear) {
        filterInstance.setModel(null);
      } else {
        filterInstance.setModel([]);
        filterInstance.doesFilterPass = doesFilterPass;
      }

      gridApi.onFilterChanged();
    });
  };

  const doesFilterPass = (node) => {
    const checkedOptions = filterOptions.filter((option) => option.checked).map((option) => option.filterText);
    const {
      data: { status: rowStatus },
    } = node || {};
    return checkedOptions.includes(rowStatus);
  };
*/
  return (
    <>
      <R>
        <C props="d-flex pr-0 pl-0">
          <Dropdown spacers={["mr-12"]}>
            <DropdownButton warning={false} disabled={true} size={"md"}>
              <DropdownButtonText>
                <ItemBody>Show: All rows</ItemBody>
                <IconDownArrow className="btn-md-svg" />
              </DropdownButtonText>
            </DropdownButton>
            {/*}
            <DropdownList>
              {filterOptions.map((option, index) => {
                return (
                  <DropdownListItem
                    key={Math.random()}
                    onClick={(event) => {
                      //toggleOption(event, option);
                    }}
                  >
                    <DropdownItem active={true} index={index}>
                      <Checkbox checked={option.checked} spacers={["mr-12", "mt-0"]} />
                      <ItemBody>{option.label}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>*/}
          </Dropdown>
        </C>
      </R>
    </>
  );
};

/**
 * Date Filter used in the subheader on manage tables, contains and equals views
 */
const DateFilter = () => {
  /*const { gridApi } = {};  useContext(LookupsContext);
  const tables = [];

  const [dateRange, setDateRange] = useState({ minDate: null, maxDate: null });
  const [labelCustomDate, setLabelCustomDate] = useState("");

  const options = [
    { label: "Today", value: 0, minDate: new Date() },
    { label: "Last week", value: 1, minDate: new Date(new Date().setDate(new Date().getDate() - 7)) },
    { label: "Last 2 weeks", value: 2, minDate: new Date(new Date().setDate(new Date().getDate() - 14)) },
    { label: "Last month", value: 3, minDate: new Date(new Date().setDate(new Date().getDate() - 30)) },
    { label: "Last quarter", value: 4, minDate: new Date(new Date().setDate(new Date().getDate() - 90)) },
    { label: "Last year", value: 5, minDate: new Date(new Date().setDate(new Date().getDate() - 365)) },
  ];
  const [selectedValue, setSelectedValue] = useState(5);
  const selectedOption = options.find((option) => option.value === selectedValue) || {
    label: labelCustomDate,
  };

  useEffect(() => {
    if (gridApi) {
      //filterTable();
    }
  }, [dateRange]);

  const filterTable = () => {
    const isCustomDate = dateRange.maxDate !== null;
    if (isCustomDate) {
      setLabelCustomDate(`${dateRange.minDate.toLocaleDateString("en-GB").split(",")[0]} - ${dateRange.maxDate.toLocaleDateString("en-GB").split(",")[0]}`);
    }

    gridApi.getFilterInstance("dateCreated", (filterInstance) => {
      filterInstance.setModel([]);
      filterInstance.doesFilterPass = doesFilterPass;

      gridApi.onFilterChanged();
    });
  };

  const doesFilterPass = (node) => {
    const {
      data: { dateCreated },
    } = node || {};

    const { minDate, maxDate } = dateRange;
    const date = new Date(dateCreated); // convert string to date

    if (maxDate) {
      return date >= minDate && date <= maxDate;
    } else {
      return date >= minDate;
    }
  };
*/
  /*if (tables.length === 0) {
    return <></>;
  } else {*/
  return (
    <Dropdown>
      <DropdownButton size="md" disabled={true}>
        <DropdownButtonText>
          <ItemBody>
            <span>Show: Last year{/*selectedOption.label*/}</span>
          </ItemBody>
          <IconDownArrow className="btn-sm-svg" />
        </DropdownButtonText>
      </DropdownButton>
      {/*}
      <DropdownList>
        {options.map(({ value, label, minDate }, index) => (
          <DropdownListItem
            key={index}
            onClick={() => {
              setSelectedValue(value);
              setDateRange({ minDate: minDate, maxDate: null });
            }}
          >
            <DropdownItem active={selectedValue} index={index}>
              <ItemBody>{label}</ItemBody>
              <SelectedIcon active={selectedValue} index={index} />
            </DropdownItem>
          </DropdownListItem>
        ))}

        <DropdownListItem
          key={6}
          onClick={() => {
            setSelectedValue(6);
          }}
        >
          <DropdownItem active={selectedValue} index={6}>
            <ItemBody>
              <DatePicker setDateRange={setDateRange} />
            </ItemBody>
            <SelectedIcon active={selectedValue} index={6} />
          </DropdownItem>
        </DropdownListItem>
        </DropdownList>*/}
    </Dropdown>
  );
  //}
};

const DatePicker = ({ setDateRange }) => {
  const [picker, setPicker] = useState(null);
  const refFlatPickr = useRef();
  const refInput = useRef();

  const onDateChanged = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateRange({ minDate: selectedDates[0], maxDate: selectedDates[1] });
    }
  };

  useEffect(() => {
    setPicker(
      flatpickr(refFlatPickr.current, {
        onChange: onDateChanged,
        dateFormat: "d/m/Y",
        wrap: true,
        mode: "range",
      })
    );
  }, []);

  return (
    <div role="presentation" ref={refFlatPickr}>
      <input value="+ Custom date" type="text" ref={refInput} data-input style={{ width: "100%", outline: "none", border: "none" }} />
    </div>
  );
};

export { LookupTableTypeFilter, LookupRowsFilter, DateFilter };
