import React, { useContext } from "react";

import { TextField, InputAdornment, IconButton, List, ListItem, ListItemText } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./styles.css";

import { LumsContext } from "./LumsCommand";

const options = [
  { label: "Equals", value: "equals" },
  { label: "Search", value: "search" },
  { label: "Contains", value: "contains" },
];

export default function SelectLookupType(props) {
  const { onChange } = props;
  const lumsContext = useContext(LumsContext);

  const { lookupType, setLookupType, setLookupTypeMenu, lookupTypeMenu } = lumsContext;

  const onSelectLookupType = (event) => {
    setLookupType(event);
    setLookupTypeMenu(!lookupTypeMenu);

    if (onChange) {
      onChange(event);
    }
  };
  const selectedOption = options.find((option) => option.value === lookupType);

  return (
    <div className="input-combo">
      <span className="input-label">Select Lookup Type</span>

      <span className="select-container">
        <TextField
          className="select-input"
          variant="outlined"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setLookupTypeMenu(!lookupTypeMenu);
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={() => {
            setLookupTypeMenu(!lookupTypeMenu);
          }}
          value={selectedOption.label}
        />

        <span className="select-list">
          <List disablePadding selected={selectedOption.value}>
            {lookupTypeMenu &&
              options.map((option, index) => {
                const { label, value } = option;

                return (
                  <ListItem
                    button
                    key={index}
                    onClick={() => {
                      onSelectLookupType(value);
                    }}
                  >
                    <ListItemText primary={label} />
                  </ListItem>
                );
              })}
          </List>
        </span>
      </span>
    </div>
  );
}
