import React from "react";
import { ROW_STATUS_VALUES } from "../../../utils/lookups/enums";

import styles from "./StatusCell.module.scss";

const StatusCell = ({ value: status }) => {
  if (!status || !status.length) {
    return null;
  }

  const statusClassName = styles[status.toLowerCase()];
  const statusDisplayName = ROW_STATUS_VALUES[status.toUpperCase()];

  return <div className={[styles.statusBody, statusClassName].join(" ")}>{statusDisplayName}</div>;
};

/**
 * StatusCellText - Returns the status display name as a text string.
 *
 * @param {Object} params - The parameter object containing the value property.
 * @param {string} params.value - The status value.
 * @returns {string} - The display name of the status.
 */
const StatusCellText = ({ value: status }) => {
  if (!status || !status.length) {
    return "";
  }

  return ROW_STATUS_VALUES[status.toUpperCase()] || "";
};

export { StatusCell, StatusCellText };
