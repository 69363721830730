import { useNavigate, createSearchParams } from "react-router-dom";
import { modalInstance, Button, Text, Modal, ModalBody, ModalSection, Error, ModalHeader, ModalFooter } from "@fundrecs/ui-library";
import { ROUTES } from "utils/enums";
const CannotDeleteTemplateModal = ({ modalId, name, workflows = [], teamId }) => {
  let navigate = useNavigate();

  return (
    <Modal modalId={modalId}>
      <ModalBody>
        <ModalSection>
          <Error />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading={`Template '${name}' cannot be deleted as it is in use`} />
          <ModalSection />
          <ModalSection>
            <Text size="sm" variant="secondary" weight="regular" element="div">
              This template is currently in use by the following workflows:
            </Text>
          </ModalSection>
          <ModalSection>
            {workflows.map((wokflow) => {
              const { name } = wokflow;
              return (
                <Text size="sm" variant="secondary" weight="medium" element="div">
                  {name}
                </Text>
              );
            })}
          </ModalSection>
          <ModalSection>
            <Text size="sm" variant="secondary" weight="regular" element="div">
              Deleting assigned templates would result in these workflows failing to run. You will be able to delete your selected template by removing
              association with any workflows.
            </Text>
          </ModalSection>
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          size="md"
          color="tertiary"
          onClick={() => {
            modalInstance(modalId).toggle();
            navigate({ pathname: ROUTES.WORKFLOWS, search: `?${createSearchParams({ teamId })}` });
          }}
        >
          <Text size="sm">Go to workflows</Text>
        </Button>
        <Button
          size="md"
          onClick={() => {
            modalInstance(modalId).toggle();
          }}
        >
          <Text size="sm">Ok</Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { CannotDeleteTemplateModal };
