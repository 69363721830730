import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import {
  Text,
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter,
  Button,
  modalInstance,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  IconDownArrow,
  DropdownList,
  DropdownItem,
  DropdownListItem,
  Radio,
  ToolTip,
  IconInformation,
} from "@fundrecs/ui-library";
import { MODAL_IDS } from "utils/workflows/enums";
import { useStore } from "store/Store";
import styles from "./addTemplate.module.scss";
import { useTeamId } from "store/hooks/useTeamId";
import {
  getWorkflowReportsOutputMappingUuids,
  getReports,
  addNewTemplateRuleToWorkflow,
  getImportRulesForSource,
  addSourceToWorkflow,
} from "components/workflows/reusable";
import { ConditionsList } from "components/workflows/ConfigureWorkflow/stages/input/conditions/ConditionsList";
import { FE_SOURCE_TYPES } from "utils/enums";

const AddTemplate = observer(({ importType, rowData, getImportRules }) => {
  const [workflows, setWorkflows] = useState([]);

  const sourceKey = importType === FE_SOURCE_TYPES.SFTP ? "inputMappingId" : "connectionUuid";

  const { [sourceKey]: sourceKeyValue } = rowData;

  const initialState = {
    criteria: [
      {
        paramName: "filename",
        operator: "string_contains",
        paramValue: "",
      },
    ],
    type: "OR",
  };

  const [state, setState] = useState({ ...initialState });
  const dispatch = (object) => {
    setState({ ...state, ...object });
  };
  const { sftpDirectoryName = "", connectionName = "" } = rowData || {};
  const [workflow, setWorkflow] = useState(null);
  const [workflowIndex, setWorkflowIndex] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [templateIndex, setTemplateIndex] = useState(null);

  const [importRule, setImportRule] = useState(null);
  const [importRules, setImportRules] = useState([]);
  const [importRuleIndex, setImportRuleIndex] = useState(null);

  const [rules, showRules] = useState(false);
  const { workflowsStore, templatesStore } = useStore();
  const teamId = useTeamId();

  useEffect(() => {
    // Fetch workflows for the specified teamId
    const fetchWorkflows = async () => {
      if (teamId) {
        const { success, data } = await workflowsStore.loadWorkflows(teamId);
        if (success) {
          // Filter workflows to include only those with reports
          const filteredWorkflows = data.filter((wf) => getReports(wf).length > 0);
          setWorkflows(filteredWorkflows);
        } else {
          setWorkflows([]);
          console.error("Failed to load workflows");
        }
      }
    };

    fetchWorkflows();
  }, [teamId, workflowsStore, rowData]);

  useEffect(() => {
    if (workflow !== null) {
      const outputMappingsUuids = getWorkflowReportsOutputMappingUuids(workflow);
      if (outputMappingsUuids.length > 0) {
        setImportRules(getImportRulesForSource(sourceKey, sourceKeyValue, workflow));
        if (getImportRulesForSource(sourceKey, sourceKeyValue, workflow).length > 0) {
          setImportRule(getImportRulesForSource(sourceKey, sourceKeyValue, workflow)[0]);
          setImportRuleIndex(0);
        }
        loadTemplatesLinkedToOutputMappings(teamId, outputMappingsUuids);
      } else {
        setTemplateIndex(null);
        setTemplate(null);
        setTemplates([]);
      }
    }
  }, [workflow]);

  const loadTemplatesLinkedToOutputMappings = async (teamId, outputMappingsUuids) => {
    const { success, data } = await templatesStore.loadTemplatesLinkedToOutputMappings(teamId, outputMappingsUuids);
    if (success) {
      setTemplates(data);
    }
  };

  const sourceName = importType === FE_SOURCE_TYPES.SFTP ? sftpDirectoryName : connectionName;

  const updateWorkflow = async (updatedWorkflow) => {
    const { success } = await workflowsStore.addWorkflowTemplate(updatedWorkflow, updatedWorkflow.teamId, updatedWorkflow.uuid);
    if (success) {
      handleClose();
    }
  };

  const handleClose = () => {
    reset();
    getImportRules();
    modalInstance(MODAL_IDS.ADD_TEMPLATE_IMPORT).hide();
  };

  const handleAddRule = () => {
    if (importRules.length === 0) {
      const importRuleUuid = uuidv4();
      let inputMapping = {
        exclusionRules: null,
        importRuleName: "Input_".concat(workflow.getCurrentWorkflowTemplates().length + 1),
        importRuleUuid: importRuleUuid,
        inclusionRules: [],
        source: {
          type: importType,
          connectionUuid: rowData.connectionUuid,
          inputMappingId: importType === FE_SOURCE_TYPES.SFTP ? sourceKeyValue : null,
        },
      };

      if (rules) {
        const newTemplate = {
          priority: null,
          ...state,
          templateUuid: template.templateUuid,
          type: "OR",
        };

        inputMapping.inclusionRules.push(newTemplate);
      } else {
        const newTemplate = {
          priority: null,
          templateUuid: template.templateUuid,
          type: "MATCH_ALL",
        };

        inputMapping.inclusionRules.push(newTemplate);
      }
      const updatedWorkflow = addSourceToWorkflow(workflow, inputMapping);
      updateWorkflow(updatedWorkflow);
    } else {
      if (rules) {
        const newTemplate = {
          priority: null,
          ...state,
          templateUuid: template.templateUuid,
          type: "OR",
        };

        const updatedWorkflow = addNewTemplateRuleToWorkflow(importRule, workflow, newTemplate);

        updateWorkflow(updatedWorkflow);
      } else {
        const newTemplate = {
          priority: null,
          templateUuid: template.templateUuid,
          type: "MATCH_ALL",
        };

        const updatedWorkflow = addNewTemplateRuleToWorkflow(importRule, workflow, newTemplate);
        updateWorkflow(updatedWorkflow);
      }
    }
  };

  const importRuleInputMessage = () => {
    if (workflow) {
      if (importRules.length === 0) {
        return "You have no inputs for this source, a new one will be generated!";
      } else {
        return importRule === null ? "Select" : importRule.importRuleName;
      }
    } else {
      return "Select";
    }
  };

  const reset = () => {
    setWorkflow(null);
    setWorkflowIndex(null);
    setTemplate(null);
    setTemplateIndex(null);
    showRules(false);
    setState(initialState);
    document.getElementById("all").checked = true;
  };

  return (
    <Panel panelId={MODAL_IDS.ADD_TEMPLATE_IMPORT}>
      <PanelBody>
        <PanelHeader
          description={
            <span>
              Map the template you want applied to the <b>{sourceName}</b> source
            </span>
          }
          header="Add template"
          onClick={() => {
            reset();
            modalInstance(MODAL_IDS.ADD_TEMPLATE_IMPORT).hide();
          }}
        />

        <div className=" pt-12">
          <div className="mb-8">
            <Text size="sm" weight="medium">
              Choose workflow the template should belong to
            </Text>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md">
                <DropdownButtonText>
                  <ItemBody>{<>{workflow === null ? "Select" : workflow.name}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                {workflows.map((workflowEntity, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setWorkflow(workflowEntity);
                        setWorkflowIndex(index);
                        setTemplate(null);
                        setTemplateIndex(null);
                        setImportRule(null);
                        setImportRuleIndex(null);
                      }}
                    >
                      <DropdownItem active={workflowIndex} index={index}>
                        <ItemBody>{workflowEntity.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>

        <div className="pb-12 pt-12">
          <div className="mb-8">
            <Text size="sm" weight="medium">
              Choose input the template should belong to
            </Text>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md" disabled={importRules.length === 0}>
                <DropdownButtonText>
                  <ItemBody>{<>{importRuleInputMessage()}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList classes={styles.dropdownList}>
                {importRules.map((importEntity, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setImportRule(importEntity);
                        setImportRuleIndex(index);
                      }}
                    >
                      <DropdownItem active={importRuleIndex} index={index}>
                        <ItemBody>{importEntity.importRuleName}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>
        <div className="pb-12">
          <div className="mb-8 ">
            <Text size="sm" weight="medium">
              Choose template to apply to incoming data
            </Text>
            <ToolTip
              text="You may need to create your template(s) either from the workflow selected above, or from the Templates area in Fusion before they are available to choose from here."
              direction="top"
            >
              <IconInformation className="light-text-muted icon-size ml-4" />
            </ToolTip>
          </div>
          <div className="d-flex">
            <Dropdown>
              <DropdownButton size="md">
                <DropdownButtonText>
                  <ItemBody>{<>{template === null ? "Select" : template.name}</>}</ItemBody>
                  <IconDownArrow className="btn-md-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {templates.map((templateEntity, index) => {
                  return (
                    <DropdownListItem
                      key={index}
                      onClick={() => {
                        setTemplate(templateEntity);
                        setTemplateIndex(index);
                      }}
                    >
                      <DropdownItem active={templateIndex} index={index}>
                        <ItemBody>{templateEntity.name}</ItemBody>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </div>
        </div>

        <div className="pt-0 pb-12 pl-0 pr-32">
          <div className="pt-12">
            <Text size="sm" weight="medium" variant="secondary">
              Input data to apply this template to:
            </Text>
          </div>
          <div className="pt-20 d-flex">
            <Radio
              onClick={() => {
                showRules(false);
              }}
              checked={!rules}
              name="edit"
              id="all"
            />
            <div className="pl-12">
              <div className="pt-4">
                <Text variant="primary" size="sm" weight="regular" element="div">
                  Apply this template to <b>all</b> incoming files
                </Text>
              </div>
            </div>
          </div>
          <div className="pt-20 d-flex">
            <Radio
              onClick={() => {
                showRules(true);
              }}
              name="edit"
              id="meet"
            />
            <div className="pl-12">
              <div className="pt-4">
                <Text variant="primary" size="sm" weight="regular" element="div">
                  Apply this template to files that <b>meet</b> certain conditions
                </Text>
              </div>
            </div>
          </div>
          {rules && <ConditionsList state={state} dispatch={dispatch} />}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button color="tertiary" onClick={handleClose}>
          <Text size="sm">Cancel</Text>
        </Button>
        <Button onClick={handleAddRule} size="md" disabled={template === null}>
          <Text size="sm">Done</Text>
        </Button>
      </PanelFooter>
    </Panel>
  );
});
export { AddTemplate };
