import PropTypes from "prop-types";
import { Text, R, C2, C4, C8, Circle, Card } from "@fundrecs/ui-library";

const NumberedInputPanelWithTitleDescription = ({ index, title, description, children }) => {
  return (
    <R props={["pb-32"]}>
      <C4 props="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 pl-0">
        <R>
          <div className="d-flex pl-0 pr-0 addNewLayout-title">
            <C2 props="col-xs-1 col-sm-1 col-md-1 col-lg-2 col-xl-2">
              <Circle number={(index += 1).toString()} />
            </C2>
            <div className="addNewLayout-description">
              <div className="pb-4">
                <Text variant="secondary" size="sm" weight="bold" element="div">
                  {title}
                </Text>
              </div>
              <Text variant="tertiary" size="xs" weight="regular" element="div">
                {description}
              </Text>
            </div>
          </div>
        </R>
      </C4>
      <C8 props="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
        <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>{children}</Card>
      </C8>
    </R>
  );
};

//Proptypes for NumberedInputPanelWithTitleDescription
NumberedInputPanelWithTitleDescription.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

// Specifies the default values for props:
NumberedInputPanelWithTitleDescription.defaultProps = {
  index: 0,
  title: "",
  description: "",
};

export { NumberedInputPanelWithTitleDescription };
