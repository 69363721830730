import { Text } from "@fundrecs/ui-library";

import styles from "./SummaryItem.module.scss";

const SummaryItem = ({ name, value }) => {
  return (
    <div className={styles.container}>
      <Text element="dt" size="xs" variant="secondary">
        {name}:
      </Text>
      <Text element="dd" size="xs" weight="regular" variant="secondary">
        {value || "–"}
      </Text>
    </div>
  );
};

export { SummaryItem };

